import { EventEmitter, Injectable } from '@angular/core';
import { set } from 'lodash';
import { Observable } from 'rxjs';
// import { GlobalService } from 'src/api/services';

const TENANT_SETTINGS = 'tenant_settings';

@Injectable()
export class UseTenantService {
  private defaultConfig: any;
  constructor(/*private globalService: GlobalService*/) {
    this.defaultConfig = {
      theme: 'light', // two possible values: light, dark
      dir: 'ltr', // two possible values: ltr, rtl
      layout: 'vertical', // fixed value. shouldn't be changed.
      sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
      sidebarpos: 'fixed', // two possible values: fixed, absolute
      headerpos: 'fixed', // two possible values: fixed, absolute
      boxed: 'full', // two possible values: full, boxed
      navbarbg: 'skin1', // '#1d2a54', // six possible values: skin(1/2/3/4/5/6)
      sidebarbg: 'skin1', // '#1d2a54', // six possible values: skin(1/2/3/4/5/6)
      logobg: 'skin1', // '#1d2a54', // six possible values: skin(1/2/3/4/5/6)
      // mainbg: '#FFFFFF'
    };
  }

  private _tenantConfig: any;
  public tenantEmitter = new EventEmitter<any>();
  /**
   * Try to get the tenant config from API
   * @returns
   */
  public getTenantConfig() {
    return new Observable<any>((sub) => {
      if (this._tenantConfig) {
        sub.next(this._tenantConfig);
      } else {
        //TEST CSS
        // const testVal = {
        //     "background-color": "black"
        // }
        // setTimeout(() => {
        //     sub.next(testVal);
        // }, 1000);

        //GET RIGHT TENANT VALUE
        // this.globalService.globalConfig().subscribe({
        //     next : val => sub.next(this._tenantConfig)
        // })

        //GET LOCAL STORAGE VALUE
        setTimeout(() => {
          this._tenantConfig = JSON.parse(
            localStorage.getItem(TENANT_SETTINGS) ?? '{}',
          );
          this._tenantConfig = Object.assign(
            this.defaultConfig,
            this._tenantConfig,
          );
          sub.next(this._tenantConfig);
        }, 1000);
      }
    });
    //return this.globalService.globalConfig();
  }

  public setTenantConfig(path: string | Array<any>, val: any) {
    set(this._tenantConfig, path, val);
    console.log(val);
    localStorage.setItem(TENANT_SETTINGS, JSON.stringify(this._tenantConfig));
    this.tenantEmitter.emit(this._tenantConfig);
  }

  public generateCSS() {
    return new Observable<any>((sub) => {
      this.getTenantConfig().subscribe((val) => {
        let cssCode = 'body{';

        for (const [key, value] of Object.entries(val)) {
          cssCode += key + ':' + value + ';';
        }

        cssCode += '}';

        let additionalCssStyle = document.getElementById('additionalCss');
        if (!additionalCssStyle) {
          additionalCssStyle = document.createElement('style');
          additionalCssStyle.id = 'additionalCss';
          document.head.appendChild(additionalCssStyle);
        }
        additionalCssStyle.innerText = cssCode;

        console.log('done !');
      });
    });
  }
}
