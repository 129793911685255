import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';

@Injectable()
export class GravatarService {
  /**
   * Get Hash from an email adress
   * @param email
   * @returns
   */
  getHash(email: string) {
    const md5 = new Md5();

    md5.appendStr(email.toLowerCase().trim());
    return md5.end();
  }

  /**
   * Get picture url from an email using Gravatar's url
   * @param email
   * @returns
   */
  getPictureURL(email: string, size = 100, type = 'identicon'): string {
    const hash = this.getHash(email);
    if (!hash) {
      return '';
    }
    return `https://s.gravatar.com/avatar/${hash.toString()}?s=${size}&d=${type}`;
  }
}
