/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CompanyResponse } from '../../models/company-response';
import { Filter } from '../../models/filter';
import { Order } from '../../models/order';

export interface ListCompany$Params {

/**
 * Select field
 */
  fields?: string;

/**
 * [Modify](https://vincit.github.io/objection.js/recipes/modifiers.html)
 */
  modifiers?: string;

/**
 * [Fetch graph](https://vincit.github.io/objection.js/api/query-builder/eager-methods.html#withgraphfetched)
 */
  fetch?: string;

/**
 * [Join graph](https://vincit.github.io/objection.js/api/query-builder/eager-methods.html#withgraphjoined)
 */
  join?: string;
  filter?: Filter;
  order?: Order;

/**
 * Maximum number of items to return
 */
  limit?: number;

/**
 * Number of items to skip before returning the results
 */
  offset?: number;

/**
 * Debug (TODO: admin only)
 */
  debug?: boolean;
}

export function listCompany(http: HttpClient, rootUrl: string, params?: ListCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<CompanyResponse>;
'total'?: number;
}>> {
  const rb = new RequestBuilder(rootUrl, listCompany.PATH, 'get');
  if (params) {
    rb.query('fields', params.fields, {});
    rb.query('modifiers', params.modifiers, {});
    rb.query('fetch', params.fetch, {});
    rb.query('join', params.join, {});
    rb.query('filter', params.filter, {});
    rb.query('order', params.order, {});
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('debug', params.debug, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'data'?: Array<CompanyResponse>;
      'total'?: number;
      }>;
    })
  );
}

listCompany.PATH = '/companies';
