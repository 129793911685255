/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { commands } from '../fn/commands/commands';
import { Commands$Params } from '../fn/commands/commands';

@Injectable({ providedIn: 'root' })
export class CommandsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `commands()` */
  static readonly CommandsPath = '/datalink/search';

  /**
   * show commands.
   *
   * show commands. required operator & (ref or status). available status: INITIAL, VALIDATED, CANCELED, WAITING_CUSTOMER_RETURN, CLOSING, IN_PROGRESS.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commands()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commands$Response(params: Commands$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return commands(this.http, this.rootUrl, params, context);
  }

  /**
   * show commands.
   *
   * show commands. required operator & (ref or status). available status: INITIAL, VALIDATED, CANCELED, WAITING_CUSTOMER_RETURN, CLOSING, IN_PROGRESS.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `commands$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  commands(params: Commands$Params, context?: HttpContext): Observable<{
}> {
    return this.commands$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
