/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { buildingInfos } from '../fn/eligibility/building-infos';
import { BuildingInfos$Params } from '../fn/eligibility/building-infos';
import { buildingInfosReverse } from '../fn/eligibility/building-infos-reverse';
import { BuildingInfosReverse$Params } from '../fn/eligibility/building-infos-reverse';
import { buildingStructure } from '../fn/eligibility/building-structure';
import { BuildingStructure$Params } from '../fn/eligibility/building-structure';
import { checkFiber } from '../fn/eligibility/check-fiber';
import { CheckFiber$Params } from '../fn/eligibility/check-fiber';
import { eligFreePro } from '../fn/eligibility/elig-free-pro';
import { EligFreePro$Params } from '../fn/eligibility/elig-free-pro';
import { eligNexloop } from '../fn/eligibility/elig-nexloop';
import { EligNexloop$Params } from '../fn/eligibility/elig-nexloop';
import { eligQuantic } from '../fn/eligibility/elig-quantic';
import { EligQuantic$Params } from '../fn/eligibility/elig-quantic';
import { eligSetiar } from '../fn/eligibility/elig-setiar';
import { EligSetiar$Params } from '../fn/eligibility/elig-setiar';
import { eligXpfibre } from '../fn/eligibility/elig-xpfibre';
import { EligXpfibre$Params } from '../fn/eligibility/elig-xpfibre';
import { funnelEligibility } from '../fn/eligibility/funnel-eligibility';
import { FunnelEligibility$Params } from '../fn/eligibility/funnel-eligibility';
import { getElig } from '../fn/eligibility/get-elig';
import { GetElig$Params } from '../fn/eligibility/get-elig';
import { listProviders } from '../fn/eligibility/list-providers';
import { ListProviders$Params } from '../fn/eligibility/list-providers';
import { testingTelco } from '../fn/eligibility/testing-telco';
import { TestingTelco$Params } from '../fn/eligibility/testing-telco';


/**
 * Connectivity catalog
 */
@Injectable({ providedIn: 'root' })
export class EligibilityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listProviders()` */
  static readonly ListProvidersPath = '/eligibility/list';

  /**
   * Eligibility list.
   *
   * Providers eligibility listing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listProviders()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProviders$Response(params?: ListProviders$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'providers'?: Array<{
'slug'?: string;
'name'?: string;
'color'?: string;
'logo'?: string;
'label'?: string;
'status'?: {
'severity'?: string;
'message'?: string;
'color'?: string;
};
'techs'?: Array<string>;
'isNew'?: boolean;
}>;
}>> {
    return listProviders(this.http, this.rootUrl, params, context);
  }

  /**
   * Eligibility list.
   *
   * Providers eligibility listing
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listProviders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProviders(params?: ListProviders$Params, context?: HttpContext): Observable<{
'providers'?: Array<{
'slug'?: string;
'name'?: string;
'color'?: string;
'logo'?: string;
'label'?: string;
'status'?: {
'severity'?: string;
'message'?: string;
'color'?: string;
};
'techs'?: Array<string>;
'isNew'?: boolean;
}>;
}> {
    return this.listProviders$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'providers'?: Array<{
'slug'?: string;
'name'?: string;
'color'?: string;
'logo'?: string;
'label'?: string;
'status'?: {
'severity'?: string;
'message'?: string;
'color'?: string;
};
'techs'?: Array<string>;
'isNew'?: boolean;
}>;
}>): {
'providers'?: Array<{
'slug'?: string;
'name'?: string;
'color'?: string;
'logo'?: string;
'label'?: string;
'status'?: {
'severity'?: string;
'message'?: string;
'color'?: string;
};
'techs'?: Array<string>;
'isNew'?: boolean;
}>;
} => r.body)
    );
  }

  /** Path part for operation `eligFreePro()` */
  static readonly EligFreeProPath = '/eligibility/freepro';

  /**
   * Eligibility FreePro.
   *
   * Get Eligibility FreePro
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eligFreePro()` instead.
   *
   * This method doesn't expect any request body.
   */
  eligFreePro$Response(params?: EligFreePro$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return eligFreePro(this.http, this.rootUrl, params, context);
  }

  /**
   * Eligibility FreePro.
   *
   * Get Eligibility FreePro
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eligFreePro$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eligFreePro(params?: EligFreePro$Params, context?: HttpContext): Observable<{
}> {
    return this.eligFreePro$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `eligNexloop()` */
  static readonly EligNexloopPath = '/eligibility/nexloop';

  /**
   * Eligibility Nexloop.
   *
   * Get Eligibility Nexloop
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eligNexloop()` instead.
   *
   * This method doesn't expect any request body.
   */
  eligNexloop$Response(params?: EligNexloop$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return eligNexloop(this.http, this.rootUrl, params, context);
  }

  /**
   * Eligibility Nexloop.
   *
   * Get Eligibility Nexloop
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eligNexloop$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eligNexloop(params?: EligNexloop$Params, context?: HttpContext): Observable<{
}> {
    return this.eligNexloop$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `eligQuantic()` */
  static readonly EligQuanticPath = '/eligibility/quantic';

  /**
   * Eligibility Quantic Telecom.
   *
   * Get Eligibility Quantic Telecom
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eligQuantic()` instead.
   *
   * This method doesn't expect any request body.
   */
  eligQuantic$Response(params?: EligQuantic$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return eligQuantic(this.http, this.rootUrl, params, context);
  }

  /**
   * Eligibility Quantic Telecom.
   *
   * Get Eligibility Quantic Telecom
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eligQuantic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eligQuantic(params?: EligQuantic$Params, context?: HttpContext): Observable<{
}> {
    return this.eligQuantic$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `eligXpfibre()` */
  static readonly EligXpfibrePath = '/eligibility/xpfibre';

  /**
   * xpfibre eligibility.
   *
   * Get eligibility offers with lineNumber or endpoint or buildingCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eligXpfibre()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eligXpfibre$Response(params: EligXpfibre$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return eligXpfibre(this.http, this.rootUrl, params, context);
  }

  /**
   * xpfibre eligibility.
   *
   * Get eligibility offers with lineNumber or endpoint or buildingCode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eligXpfibre$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eligXpfibre(params: EligXpfibre$Params, context?: HttpContext): Observable<{
}> {
    return this.eligXpfibre$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `getElig()` */
  static readonly GetEligPath = '/getEligibility';

  /**
   * Providers eligibility.
   *
   * Get eligibility offers with lineNumber or endpoint or buildingCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getElig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getElig$Response(params: GetElig$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'status'?: boolean;
'message'?: string;
'data'?: {
'provider'?: {
'status'?: boolean;
'provider'?: string;
'size'?: number;
'list'?: Array<{
'id'?: string;
'name'?: string;
'engagement'?: number;
'status'?: number;
'conditions'?: string;
'techno'?: string;
'supplierRef'?: string;
'code'?: string;
'speed'?: number;
'zone'?: string;
'sla'?: string;
'slaCode'?: string;
'deliveryInfo'?: string;
'setup'?: number;
'recur'?: number;
'resil'?: number;
'sellSetup'?: number;
'sellRecur'?: number;
'sellResil'?: number;
}>;
'time'?: number;
};
};
'time'?: number;
'params'?: {
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'address'?: string;
'lineNumber'?: string;
'activationType'?: string;
'companyName'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'websocket'?: {
'room'?: string;
};
'ts'?: string;
'providers'?: Array<string>;
};
}>> {
    return getElig(this.http, this.rootUrl, params, context);
  }

  /**
   * Providers eligibility.
   *
   * Get eligibility offers with lineNumber or endpoint or buildingCode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getElig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getElig(params: GetElig$Params, context?: HttpContext): Observable<{
'status'?: boolean;
'message'?: string;
'data'?: {
'provider'?: {
'status'?: boolean;
'provider'?: string;
'size'?: number;
'list'?: Array<{
'id'?: string;
'name'?: string;
'engagement'?: number;
'status'?: number;
'conditions'?: string;
'techno'?: string;
'supplierRef'?: string;
'code'?: string;
'speed'?: number;
'zone'?: string;
'sla'?: string;
'slaCode'?: string;
'deliveryInfo'?: string;
'setup'?: number;
'recur'?: number;
'resil'?: number;
'sellSetup'?: number;
'sellRecur'?: number;
'sellResil'?: number;
}>;
'time'?: number;
};
};
'time'?: number;
'params'?: {
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'address'?: string;
'lineNumber'?: string;
'activationType'?: string;
'companyName'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'websocket'?: {
'room'?: string;
};
'ts'?: string;
'providers'?: Array<string>;
};
}> {
    return this.getElig$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'status'?: boolean;
'message'?: string;
'data'?: {
'provider'?: {
'status'?: boolean;
'provider'?: string;
'size'?: number;
'list'?: Array<{
'id'?: string;
'name'?: string;
'engagement'?: number;
'status'?: number;
'conditions'?: string;
'techno'?: string;
'supplierRef'?: string;
'code'?: string;
'speed'?: number;
'zone'?: string;
'sla'?: string;
'slaCode'?: string;
'deliveryInfo'?: string;
'setup'?: number;
'recur'?: number;
'resil'?: number;
'sellSetup'?: number;
'sellRecur'?: number;
'sellResil'?: number;
}>;
'time'?: number;
};
};
'time'?: number;
'params'?: {
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'address'?: string;
'lineNumber'?: string;
'activationType'?: string;
'companyName'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'websocket'?: {
'room'?: string;
};
'ts'?: string;
'providers'?: Array<string>;
};
}>): {
'status'?: boolean;
'message'?: string;
'data'?: {
'provider'?: {
'status'?: boolean;
'provider'?: string;
'size'?: number;
'list'?: Array<{
'id'?: string;
'name'?: string;
'engagement'?: number;
'status'?: number;
'conditions'?: string;
'techno'?: string;
'supplierRef'?: string;
'code'?: string;
'speed'?: number;
'zone'?: string;
'sla'?: string;
'slaCode'?: string;
'deliveryInfo'?: string;
'setup'?: number;
'recur'?: number;
'resil'?: number;
'sellSetup'?: number;
'sellRecur'?: number;
'sellResil'?: number;
}>;
'time'?: number;
};
};
'time'?: number;
'params'?: {
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'address'?: string;
'lineNumber'?: string;
'activationType'?: string;
'companyName'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'websocket'?: {
'room'?: string;
};
'ts'?: string;
'providers'?: Array<string>;
};
} => r.body)
    );
  }

  /** Path part for operation `eligSetiar()` */
  static readonly EligSetiarPath = '/orangeEligibility';

  /**
   * Eligibility setiar.
   *
   * Get Eligibility setiar
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eligSetiar()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eligSetiar$Response(params: EligSetiar$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return eligSetiar(this.http, this.rootUrl, params, context);
  }

  /**
   * Eligibility setiar.
   *
   * Get Eligibility setiar
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eligSetiar$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eligSetiar(params: EligSetiar$Params, context?: HttpContext): Observable<{
}> {
    return this.eligSetiar$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `buildingInfosReverse()` */
  static readonly BuildingInfosReversePath = '/buildingInfos';

  /**
   * Building infos reverse.
   *
   * Building infos Arcep
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingInfosReverse()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingInfosReverse$Response(params?: BuildingInfosReverse$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return buildingInfosReverse(this.http, this.rootUrl, params, context);
  }

  /**
   * Building infos reverse.
   *
   * Building infos Arcep
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingInfosReverse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingInfosReverse(params?: BuildingInfosReverse$Params, context?: HttpContext): Observable<{
}> {
    return this.buildingInfosReverse$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `buildingInfos()` */
  static readonly BuildingInfosPath = '/buildingInfos';

  /**
   * Building infos.
   *
   * Building infos Arcep
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingInfos()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buildingInfos$Response(params: BuildingInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return buildingInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * Building infos.
   *
   * Building infos Arcep
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingInfos$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buildingInfos(params: BuildingInfos$Params, context?: HttpContext): Observable<{
}> {
    return this.buildingInfos$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `buildingStructure()` */
  static readonly BuildingStructurePath = '/buildingStructure';

  /**
   * building Structure and pto.
   *
   * building Structure and pto
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingStructure()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingStructure$Response(params?: BuildingStructure$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return buildingStructure(this.http, this.rootUrl, params, context);
  }

  /**
   * building Structure and pto.
   *
   * building Structure and pto
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingStructure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingStructure(params?: BuildingStructure$Params, context?: HttpContext): Observable<{
}> {
    return this.buildingStructure$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `testingTelco()` */
  static readonly TestingTelcoPath = '/testingTelco';

  /**
   * Testing.
   *
   * Testing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testingTelco()` instead.
   *
   * This method doesn't expect any request body.
   */
  testingTelco$Response(params?: TestingTelco$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return testingTelco(this.http, this.rootUrl, params, context);
  }

  /**
   * Testing.
   *
   * Testing
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `testingTelco$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  testingTelco(params?: TestingTelco$Params, context?: HttpContext): Observable<{
}> {
    return this.testingTelco$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `funnelEligibility()` */
  static readonly FunnelEligibilityPath = '/eligibility/funnel';

  /**
   * Eligibility funnel.
   *
   * Test the eligibility of a number or an address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `funnelEligibility()` instead.
   *
   * This method doesn't expect any request body.
   */
  funnelEligibility$Response(params?: FunnelEligibility$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'q'?: string;
'address'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'buildings'?: {
'label'?: string;
'buildingCodeOC'?: string;
'buildingCodeOI'?: string;
};
'endpoints'?: Array<{
'ndi'?: string;
'label'?: string;
'status'?: string;
}>;
'characteristics'?: {
};
}>> {
    return funnelEligibility(this.http, this.rootUrl, params, context);
  }

  /**
   * Eligibility funnel.
   *
   * Test the eligibility of a number or an address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `funnelEligibility$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  funnelEligibility(params?: FunnelEligibility$Params, context?: HttpContext): Observable<{
'q'?: string;
'address'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'buildings'?: {
'label'?: string;
'buildingCodeOC'?: string;
'buildingCodeOI'?: string;
};
'endpoints'?: Array<{
'ndi'?: string;
'label'?: string;
'status'?: string;
}>;
'characteristics'?: {
};
}> {
    return this.funnelEligibility$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'q'?: string;
'address'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'buildings'?: {
'label'?: string;
'buildingCodeOC'?: string;
'buildingCodeOI'?: string;
};
'endpoints'?: Array<{
'ndi'?: string;
'label'?: string;
'status'?: string;
}>;
'characteristics'?: {
};
}>): {
'q'?: string;
'address'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'buildings'?: {
'label'?: string;
'buildingCodeOC'?: string;
'buildingCodeOI'?: string;
};
'endpoints'?: Array<{
'ndi'?: string;
'label'?: string;
'status'?: string;
}>;
'characteristics'?: {
};
} => r.body)
    );
  }

  /** Path part for operation `checkFiber()` */
  static readonly CheckFiberPath = '/eligibility/checkFiber/{nd}';

  /**
   * check Fiber building.
   *
   * fiber building check on ND or address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkFiber()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkFiber$Response(params: CheckFiber$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return checkFiber(this.http, this.rootUrl, params, context);
  }

  /**
   * check Fiber building.
   *
   * fiber building check on ND or address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkFiber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkFiber(params: CheckFiber$Params, context?: HttpContext): Observable<{
}> {
    return this.checkFiber$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
