<div class="modal-header bg-{{ color }} text-white">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body bg-white">
  <ngb-alert *ngIf="alert" [type]="alert.type">
    <i
      *ngIf="alert.icon"
      class="fa fa-{{ alert.icon }} float-start me-2 p-1"
    ></i>
    {{ alert.title | translate }}
    <div *ngIf="alert.message" [innerHTML]="alert.message"></div>
  </ngb-alert>
  <app-dynamic-model-form
    (success)="onSuccess($event, activeModal)"
    (failure)="onFailure($event)"
    [action]="action"
    [id]="id"
    [model]="model"
    [form]="form"
    [color]="color"
  ></app-dynamic-model-form>
</div>
<!-- <div class="modal-footer"> -->
<!--     <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">{{ 'Close' | translate}}</button> -->
<!-- </div> -->
