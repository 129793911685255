import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTableRender } from '../models/dynamic-table-render';
// import { UrlService } from '../services/url.service';
import { BaseTemplateComponent } from './base.template';
import { CompanyResponse } from 'src/api/models/company-response';
import { DynamicModelFormRender } from 'src/app/shared/models/dynamic-model-form-render';
import { GravatarService } from 'src/app/core/services/gravatar.service';

@Component({
  templateUrl: './company.template.html',
  imports: [CommonModule],
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CompanyTemplate extends BaseTemplateComponent<CompanyResponse> {
  override icon = 'building';
  // public urlService = this.injector.get(UrlService);

  override defaultViewParams = {
    fields: ['id', 'tenantId', 'name', 'siret', 'createdAt', 'updatedAt'],
    fetch: 'tenant',
  };

  public override getForm(): DynamicModelFormRender {
    // const input = this.form.controls;
    const gravatarService = new GravatarService();
    return {
      model: 'Company',
      action: this.action,
      fields: {
        Company: {
          type: 'tab-form',
          tabs: {
            Infos: {
              props: {
                title: 'Informations',
                //icon: '<i class="fa fa-fw fa-user"></i>',
                //subTitle: 'Détails de l\'entreprise',
              },
              fieldGroup: {
                // 'parentId': {
                // type: 'select',
                // class: 'col-6',
                // label: 'Parent Company',
                // required: true, // TODO: except root
                // onChange : (field,event) => {
                // console.log(field),
                // console.log(event)
                // },
                // templateOptions: {
                // resource: 'company',
                // actions: [
                // {
                // class: 'btn btn-primary',
                // type: 'button' as const,
                // title: 'Modifier l\'entreprise',
                // icon: 'pencil',
                // onClick(event: Event, action: ButtonActionComponent) {
                // console.log(event);
                // console.log(action.data.value);
                // if (action.data.value) {
                // self.createModalService.newModalForm({ action: 'patch', model: 'company', id: action.data.value });
                // }
                // },
                // hidden: (action: ButtonActionComponent) => !action.data.value,
                // /* 'role' => 'manager', */
                // },
                // {
                // type: 'button' as const,
                // class: 'btn btn-success',
                // onClick(event: Event, action: ButtonActionComponent) {
                // self.createModalService.newModalForm({ action: 'create', model: 'company' });
                // },
                // title: 'Nouvelle entreprise',
                // icon: 'plus',
                // },
                // ],
                // },
                // },
                tenantId: {
                  type: 'select',
                  class: 'col-6',
                  label: 'Tenant',
                  required: true,
                  props: {
                    resource: {
                      model: 'tenant',
                    },
                  },
                },
                name: {
                  onChange: (field: any, event: Event) => {},
                  type: 'input',
                  label: 'Name',
                  class: 'col-6',
                  row: true,
                  required: true,
                },
                siret: {
                  type: 'input',
                  label: 'Siret',
                  class: 'col-6',
                  required: false,
                },
                // 'TestEditor': {
                //   ignore : true,
                //   type : 'editor',
                //   onChange(field,event) {
                //     console.log(event)
                //   }
                // }

                // addresses: {
                // required: false,
                // noImport: true,
                // customImport : {
                // action : 'showAddress',
                // model : 'Address',
                // params : (data) => ({ id : data.id }),
                // data : (data) => data.data,
                // },
                // customExport : {
                // action : 'createAddress',
                // model : 'Address',
                // params : (data) => ({ id : data.id, body: data.addresses })
                // },
                // type: 'repeat',
                // fieldArray: {
                // modelTemplate: {
                // action: 'update',
                // name: 'Address',
                // }
                // }
                // },
              },
            },
            // 'TestTab2' : {
            //   templateOptions: {
            //     title: 'Test tab2',
            //     icon: '<i class="fa fa-fw fa-user"></i>',
            //     subTitle:'Un test'
            //   },
            //   fieldGroup : {
            //     'testInput': {
            //       type: 'input',
            //       extraKey:true,
            //       required:true
            //     }
            //   }
            // },
            Contacts: {
              active: () => this.action != 'create',
              //label: 'TestTab3',
              props: {
                title: 'Contacts',
                icon: '<i class="fa fa-fw fa-plus"></i>',
                subTitle: 'Détails',
              },
              fieldGroup: {
                Contacts: {
                  type: 'model-list',
                  extraKey: true,
                  noImport: true,
                  props: {
                    resource: 'user',
                    parentIdKey: 'id',
                    searchIdKey: 'companyId',
                    render: (data: any) => {
                      return (
                        '<img src="' +
                        gravatarService.getPictureURL(data.email, 50) +
                        '">' +
                        ' ' +
                        data.firstName +
                        ' ' +
                        data.lastName
                      );
                    },
                    paramsBuilder: {
                      fields: [
                        'firstName',
                        'lastName',
                        'email',
                        'id',
                        'companyId',
                      ],
                      termSearch: [
                        {
                          fields: {
                            email: {
                              whereILike: (data) => {
                                return `%${data}%`;
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    return {
      // join: ['parent'],
      buttons: this.getButtons('Company'),
      // actionsCol: this.getActions('Company'),
      /**
       * Filters
       */
      dtFilters: {
        _all: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-6',
          showDelete: true,
          filter: true,
        },
        // 'parentId': {
        //   type: 'select',
        //   multiple: true,
        //   resource: 'company',
        //   idKey: 'id',
        //   title : 'Company',
        //   where: 'orWhereIn'
        // },
        name: {
          type: 'search',
          title: 'Nom',
          where: 'whereLike',
        },
      },
      /**
       * Columns
       */
      columns: {
        // v1Id: {
        //   title: 'ID v1',
        //   searchable : false,
        //   width: '1%',
        // },
        id: {
          title: 'ID',
          visible: false,
          searchable: false,
        },
        // parentId: {
        //   title: 'ID Parent',
        //   visible: false,
        //   searchable : false,
        // },
        name: {
          title: 'Nom',
          actions: [
            {
              type: 'input-editor',
              modelName: 'Company',
              modelId: (data) => data.id,
              inputs: {
                name: {
                  type: 'input',
                },
              },
              onReally: (event, action) => {
                this.getDynamicTableComponent().ajaxRefresh();
              },
              render: (data) => {
                return data.name;
              },
            },
          ],
        },
        // parentName: {
        //   title: 'Parent',
        //   fields: ['parent.name as parentName'],
        //   searchable: false,
        // },
        siret: {
          title: 'Siret',
        },
        updatedAt: true,
        createdAt: true,
      },
      //rowClick : (row,data,index) => {
      //  //this.router.navigate(['companies',data.id]);
      //},
    };
  }
}
