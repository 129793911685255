import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CompanyService, UserService } from 'src/api/services';
import { LocalUserService } from 'src/app/core/services/local-user.service';
import { ParamsBuilderService } from 'src/app/shared/services/params-builder.service';

@Component({
  template: '',
})
export abstract class OrderTemplateComponent {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() model: any = {};

  constructor(
    public companyService: CompanyService,
    public userService: UserService,
    public paramsBuilderService: ParamsBuilderService,
    public localUserService: LocalUserService,
  ) {}

  public abstract getForm(): FormlyFieldConfig[];

  public abstract getTable(): DataTables.Settings;
}
