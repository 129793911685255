import { Injectable } from '@angular/core';
import { cloneDeep, merge } from 'lodash';
import {
  ParamsBuilder,
  SearchBuilder,
  SearchParamsBuilder,
} from '../../core/models/params-builder';
import { AnyInput, BaseInputWithoutType } from '../models/inputs/input';
import { Filter } from '../../../api/models/filter';
import { FilterClause } from '../../../api/models/filter-clause';
import { ModelTemplateService } from './model-template.service';
import { UtilsService } from './utils.service';

export type SearchField =
  | { value?: any; where?: string; key: string }
  | undefined;

@Injectable()
export class ParamsBuilderService {
  constructor(
    private utils: UtilsService,
    private modelTemplateService: ModelTemplateService,
  ) {}

  /**
   * get filter search params from a SearchBuilder and search term.
   * @param paramsBuilder
   * @param term
   * @returns
   */
  private buildSearch(searchBuilder: SearchBuilder[], term?: string) {
    //Search Params for ParamsBuilder
    const filterSearch: SearchParamsBuilder = {};

    for (const search of searchBuilder) {
      const combine = search.combine || 'where';
      filterSearch[combine] ??= [];

      const tempFilterSearch: SearchParamsBuilder = {};

      //For each search data (name, id)
      for (const [dataKey, whereList] of Object.entries(search.fields)) {
        //For each where defined (orWhereILike...)
        for (const [whereKey, whereVal] of Object.entries(whereList)) {
          if (term && term != '') {
            const searchObj: any = {};
            searchObj[dataKey] = whereVal(term);
            (<any>tempFilterSearch)[whereKey] ??= [];
            (<any>tempFilterSearch)[whereKey].push(searchObj);
          }
        }
      }
      //Add tempFilterSearch to filterSearch
      filterSearch[combine]!.push(tempFilterSearch);
    }

    return filterSearch as SearchParamsBuilder;
  }

  /**
   * Construct search params from fullSearch and termSearch
   * @param paramsBuilder
   * @param term
   */
  private getSearchParams(paramsBuilder: ParamsBuilder, term?: string) {
    let finalSearchParams: SearchParamsBuilder = {};

    if (paramsBuilder.termSearch && (term != undefined || term != null)) {
      finalSearchParams = this.buildSearch(paramsBuilder.termSearch, term);
    }

    if (paramsBuilder.fullSearch) {
      const fullSearch = cloneDeep(paramsBuilder.fullSearch);
      finalSearchParams = merge(fullSearch, finalSearchParams);
    }

    //orWhereIn case -> tranform each object in array
    this.transformOrWhereIn(finalSearchParams);
    return finalSearchParams;
  }

  transformOrWhereIn(finalSearchParams: SearchParamsBuilder) {
    if (finalSearchParams.orWhereIn) {
      const orWhereIn: any = {};
      if (Array.isArray(finalSearchParams.orWhereIn)) {
        //Find every and regroup by key
        finalSearchParams.orWhereIn.forEach((orWhereInObj: any) => {
          for (const [key, value] of Object.entries(orWhereInObj)) {
            orWhereIn[key] ??= [];
            orWhereIn[key].push(value);
          }
        });
      } else {
        //Just tranform each object's values in array
        for (const [key, value] of Object.entries(
          finalSearchParams.orWhereIn,
        )) {
          if (Array.isArray(value)) {
            orWhereIn[key] = value;
          } else {
            orWhereIn[key] = [value];
          }
        }
      }

      finalSearchParams.orWhereIn = orWhereIn;
    }
    //Check all subobjects if they have orWhereIn
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_key, value] of Object.entries(finalSearchParams)) {
      if (value && Array.isArray(value)) {
        value.forEach((subObj: any) => {
          this.transformOrWhereIn(subObj);
        });
      }
    }
  }

  /**
   * Create params request for call api from paramsBuilder
   * @param paramsBuilder
   * @returns
   */
  build(paramsBuilder: ParamsBuilder, searchTerm?: string) {
    const params: any = {};

    if (paramsBuilder.limit && paramsBuilder.limit > 0) {
      params.limit = paramsBuilder.limit;
    }

    if (paramsBuilder.offset) {
      params.offset = paramsBuilder.offset;
    }

    if (paramsBuilder.fields && paramsBuilder.fields.length > 0) {
      params.fields = paramsBuilder.fields.join(',');
    }

    if (paramsBuilder.fetch && paramsBuilder.fetch.length > 0) {
      if (Array.isArray(paramsBuilder.fetch)) {
        let fetch = '[';
        paramsBuilder.fetch.forEach((element: string) => {
          fetch += element + ',';
        });
        fetch = fetch.substring(0, fetch.length - 1) + ']';
        params.fetch = fetch;
      } else {
        params.fetch = paramsBuilder.fetch;
      }
    }
    if (paramsBuilder.join && paramsBuilder.join.length > 0) {
      if (Array.isArray(paramsBuilder.join)) {
        let join = '[';
        paramsBuilder.join.forEach((element: string) => {
          join += element + ',';
        });
        join = join.substring(0, join.length - 1) + ']';
        params.join = join;
      } else {
        params.join = paramsBuilder.join;
      }
    }

    if (paramsBuilder.modifiers && paramsBuilder.modifiers.length > 0) {
      params.modifiers = paramsBuilder.modifiers.join(',');
    }

    if (paramsBuilder.order && paramsBuilder.order.length > 0) {
      params.order = JSON.stringify(paramsBuilder.order);
    }

    if (paramsBuilder.fullSearch || paramsBuilder.termSearch) {
      const seen: any[] = [];
      params.filter = JSON.stringify(
        this.getSearchParams(paramsBuilder, searchTerm),
        // exclude already serialized objects
        function (key, val) {
          if (typeof val == 'object') {
            if (seen.indexOf(val) >= 0) return;
            seen.push(val);
          }
          return val;
        },
      );
    }

    return params;
  }

  /**
   * Use paramsBuilder to filter data object
   * @param data
   * @param paramsBuilder
   * @returns
   */
  filterData(data: any, paramsBuilder: ParamsBuilder) {
    ['extraKey', 'fields', 'join', 'modifiers'].forEach((element: string) => {
      const filterField = (<any>paramsBuilder)[element] as string[];
      if (filterField && filterField.length > 0) {
        data = Object.keys(data)
          .filter((key) => filterField.includes(key))
          .reduce((res, key) => Object.assign(res, { [key]: data[key] }), {});
      }
    });

    return data;
  }

  /**
   * Get the fields from DynamicFormField objects, with a specific mode. Allows to make a whitelist.
   * @param fields
   * @param mode get,post, extraKey
   * @returns
   */
  public fromDynamicFormFields(
    fields: { [key: string]: AnyInput | BaseInputWithoutType } | undefined,
    mode = 'import',
  ): ParamsBuilder {
    let paramsBuilder: ParamsBuilder = {
      fields: [],
      extraKey: [],
    };

    try {
      if (fields) {
        for (const [key, value] of Object.entries(fields)) {
          if (value.active && !value.active()) {
            break;
          }

          /**If fieldGroup, get fieldsTab */
          if (value.fieldGroup) {
            paramsBuilder = this.merge(
              paramsBuilder,
              this.fromDynamicFormFields(value.fieldGroup, mode),
            );
          } else if ('modelTemplate' in value) {
            //Not add field if modelTemplate
            // const templateNameGroup = this.utils.transformModelName(value.modelTemplate.name);
            // const templateInstance = this.modelTemplateService.getTemplateInstance(templateNameGroup, value.modelTemplate.action);
            // paramsBuilder = this.merge(paramsBuilder, this.fromDynamicFormFields(templateInstance.getForm().fields, mode));
          } else if ('tabs' in value) {
            value.ignore = true;
            (<any>value).tabs = Object.entries(value.tabs as any)
              .map((field) => ({
                [field[0]]: Object.assign({ ignore: true }, field[1]),
              }))
              .reduce((res, field) => Object.assign(res, field), {});
            paramsBuilder = this.merge(
              paramsBuilder,
              this.fromDynamicFormFields(value.tabs, mode),
            );
          }

          if (value.fieldArray) {
            paramsBuilder = this.merge(
              paramsBuilder,
              this.fromDynamicFormFields(
                { '': (value as any).fieldArray },
                mode,
              ),
            );
          }

          /**Then get field from main field if not ignored */
          if (!value.ignore && key != '') {
            switch (mode) {
              case 'export':
                if (!value.extraKey && !value.template && !value.noExport) {
                  paramsBuilder.fields!.push((value.key ?? key) as string);
                }
                break;
              case 'import':
                if (!value.extraKey && !value.template && !value.noImport) {
                  paramsBuilder.fields!.push((value.key ?? key) as string);
                }
                break;
              case 'extraKey':
                if (value.extraKey) {
                  paramsBuilder.extraKey!.push((value.key ?? key) as string);
                }
                break;
              default:
                throw new Error('wrong mode : ' + mode);
            }
          }
        }
      }
    } catch (error) {
      console.error('paramsBuilder fromDynamicFormFields', error);
      return paramsBuilder;
    }

    return paramsBuilder;
  }

  /**
   * Fusion multiple paramsBuilder
   * @param paramsBuilder
   * @returns
   */
  public merge(...paramsBuilder: ParamsBuilder[]) {
    const finalParamsBuilder: ParamsBuilder = {};

    ['extraKey', 'fields', 'join', 'modifiers'].forEach((element: string) => {
      (<any>finalParamsBuilder)[element] = this.utils.mergeArraysNoDuplicate(
        ...paramsBuilder.map((paramsBuilder) => (<any>paramsBuilder)[element]),
      );
    });

    // paramsBuilder.forEach((paramsBuilder) => {
    //     finalParamsBuilder.search = Object.assign(finalParamsBuilder.search ?? {}, paramsBuilder.search)
    // });

    return finalParamsBuilder;
  }

  /**
   * Add where to a searchBuilder
   * @param whereType
   * @param key
   * @param searchColumns
   * @param searchBuilder
   * @param value
   */
  setWhere(
    whereType: string,
    key: string,
    searchColumns: string[],
    searchBuilder: SearchParamsBuilder,
    value?: any,
  ) {
    const fields = key === '_all' ? searchColumns : [key];

    if (whereType.includes('Like') && value) {
      value = `%${value}%`;
    }

    fields?.forEach((field) => {
      let whereTargetObject = (<any>searchBuilder)[whereType] ?? {};
      //If its an array, get the last object
      if (Array.isArray((<any>searchBuilder)[whereType])) {
        whereTargetObject = (<any>searchBuilder)[whereType][
          (<any>searchBuilder)[whereType].length - 1
        ];
      }

      //If fields already exit, transform where into array and push a new object
      if (whereTargetObject[field]) {
        //If all objects are not already in an array, tranform it
        if (!Array.isArray((<any>searchBuilder)[whereType])) {
          (<any>searchBuilder)[whereType] = [(<any>searchBuilder)[whereType]];
        }

        //Set a new object and push it
        whereTargetObject = { [field]: value };
        (<any>searchBuilder)[whereType].push(whereTargetObject);
      } else {
        //Set the target object
        whereTargetObject[field] = value;
        (<any>searchBuilder)[whereType] = whereTargetObject;
      }
    });
  }

  setWhereForTerm(
    op: string,
    key: string,
    searchColumns: string[],
    searchBuilder: SearchParamsBuilder,
    term?: any,
  ) {
    // console.log(op, key, term);
    if (Array.isArray(term)) {
      //If array, set where multiple
      term.forEach((value) => {
        this.setWhere(op, key, searchColumns, searchBuilder, value);
      });
    } else {
      term
        .trim()
        .split(/\s+/)
        .forEach((value: string) => {
          //If one value, set where once
          this.setWhere(op, key, searchColumns, searchBuilder, value);
        });
    }
  }

  /**
   * Create a searchBuilder from a list of fields/values
   * @param fields
   * @param searchColumns
   * @param urlParams
   * @returns
   */
  createSearchParamsBuilder(
    fields: SearchField[],
    searchColumns: string[],
    urlParams?: any,
  ) {
    /* let tempDtFilterSearchBuilder: SearchParamsBuilder = {};
    let levelFilterSearchBuilder: SearchParamsBuilder[] = [];

    const searchBuilder: SearchParamsBuilder = {
      'where': levelFilterSearchBuilder
    };

    const newParams = Object.assign({}, urlParams);

    //For each filter, create their own where with AND combine
    fields.forEach((field) => {
      if(field == undefined) {
        return;
      }
      tempDtFilterSearchBuilder = {};

      //Search part
      //If array transform it to a list of ids
      let inputValue = field.value;

      if (Array.isArray(inputValue)) {
        const ids = inputValue.map(item => item.id || item);
        inputValue = ids;
      }

      if (inputValue) {
        //Change url params
        Object.assign(newParams, { [field.key]: inputValue });
        this.setWhereForTerm(field.where ?? 'orWhereILike', field.key, searchColumns, tempDtFilterSearchBuilder, inputValue);
      } else {
        //Remove url params
        delete newParams[field.key];
      }

      tempDtFilterSearchBuilder['where'] = [];
      levelFilterSearchBuilder.push(tempDtFilterSearchBuilder);
      levelFilterSearchBuilder = tempDtFilterSearchBuilder['where'] as SearchParamsBuilder[];
    }); */

    const newParams = Object.assign({}, urlParams);
    const filter: Filter = [];
    for (const field of fields) {
      if (typeof field === 'undefined') continue;
      const columns = field.key === '_all' ? searchColumns : [field.key];
      const operation = field.where ?? 'orWhereILike';

      let inputValue = field.value;
      if (!inputValue || inputValue == '') {
        // TODO handle falsy values?
        //Remove url params
        newParams[field.key] = null;
        continue;
      }

      if (Array.isArray(inputValue)) {
        const ids = inputValue.map((item) => item.id || item);
        inputValue = ids;
      }

      //Change url params
      Object.assign(newParams, { [field.key]: inputValue });

      if (typeof inputValue === 'string') {
        // Full text search, split words
        inputValue
          .trim()
          .split(/\s+/)
          .forEach((value) => {
            const where: FilterClause = {};
            columns.forEach((col: string) => {
              // where[col] = operation.endsWith('Like') ? `%${value}%` : value;
              Object.assign(where, {
                [col]: operation.endsWith('Like') ? `%${value}%` : value,
              });
              // filter.push({ [operation]: { [col]: value } });
            });
            filter.push({ [operation]: where });
          });
      } else {
        filter.push({ [operation]: inputValue });
      }
    }
    return { searchBuilder: filter, newParams };
  }

  noImportedFields(fields: any, paramsBuilderFields: any): void {
    for (const [key, value] of Object.entries(
      fields as { [key: string]: any },
    )) {
      if (typeof value !== 'object') continue;
      if (value.tabs) {
        this.noImportedFields(value, paramsBuilderFields);
        return;
      } else if (value.type) {
        if (value.importField) paramsBuilderFields.push(value.importField);
        else if (value.fields) paramsBuilderFields.push(value.fields);
        else if (value.noImport === true || value.ignore === true) continue;
        else paramsBuilderFields.push(key);
      } else if (value.fieldGroup) {
        for (const [key2, value2] of Object.entries(
          value.fieldGroup as { [key: string]: any },
        )) {
          if (value2.fields) paramsBuilderFields.push(value2.fields);
          else if (value2.noImport === true || value2.ignore === true) continue;
          else paramsBuilderFields.push(key2);
        }
      } else {
        this.noImportedFields(value, paramsBuilderFields);
      }
    }
  }

  noExportedFields(fields: any, data: any, paramsBuilderFields: any): void {
    for (const [key, value] of Object.entries(
      fields as { [key: string]: any },
    )) {
      if (typeof value !== 'object') continue;
      if (value.tabs) {
        this.noExportedFields(value, data, paramsBuilderFields);
        return;
      } else if (value.type) {
        if (value.submitField && data[key]) {
          data[value.submitField] = data[key].id;
          delete data[key];
          paramsBuilderFields.push(value.submitField);
        } else if (value.noExport === true || value.ignore === true) continue;
        else paramsBuilderFields.push(key);
      } else if (value.fieldGroup) {
        for (const [key2, value2] of Object.entries(
          value.fieldGroup as { [key: string]: any },
        )) {
          if (value2.submitField && data[key2]) {
            data[value2.submitField] = data[key2].id;
            delete data[key2];
            paramsBuilderFields.push(value2.submitField);
          } else if (value2.noExport === true || value2.ignore === true)
            continue;
          else paramsBuilderFields.push(key2);
        }
      } else {
        this.noExportedFields(value, data, paramsBuilderFields);
      }
    }
  }
}
