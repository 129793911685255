/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ShowOrder$Params {

/**
 * Order id
 */
  orderId: string;

/**
 * type of order
 */
  type: string;
}

export function showOrder(http: HttpClient, rootUrl: string, params: ShowOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: {
'id'?: string;
'code'?: string;
'name'?: string;
'label'?: string;
'description'?: string;
'reference'?: string;
'v1Id'?: number;
'type'?: string;
'status'?: string;
'ndi'?: string;
'enabled'?: boolean;
'startsAt'?: string;
'endsAt'?: string;
'vlan'?: string;
'meta'?: {
};
'createdAt'?: string;
'updatedAt'?: string;
'deletedAt'?: string;
'provider'?: {
'name'?: string;
};
'category'?: {
'name'?: string;
'typeSlug'?: string;
};
'building'?: {
'code'?: string;
'name'?: string;
'stairs'?: string;
'floor'?: string;
};
'company'?: {
'id'?: string;
'name'?: string;
'v1Id'?: number;
'siret'?: string;
'tenant'?: {
'name'?: string;
};
};
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
'product'?: {
'name'?: string;
'label'?: string;
'type'?: string;
'techno'?: string;
};
'address'?: {
'houseNumber'?: string;
'roadType'?: string;
'road'?: string;
'postcode'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'city'?: string;
'hexacle'?: string;
};
'contact'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'phone'?: string;
};
'appointment'?: {
'name'?: string;
'tel'?: string;
'site'?: string;
'code'?: string;
'startDate'?: string;
'endDate'?: string;
};
'options'?: Array<{
'name'?: string;
'code'?: string;
}>;
'comments'?: Array<{
'name'?: string;
'content'?: string;
'createdAt'?: string;
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
}>;
'realm'?: string;
};
}>> {
  const rb = new RequestBuilder(rootUrl, showOrder.PATH, 'get');
  if (params) {
    rb.query('orderId', params.orderId, {});
    rb.query('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'data'?: {
      'id'?: string;
      'code'?: string;
      'name'?: string;
      'label'?: string;
      'description'?: string;
      'reference'?: string;
      'v1Id'?: number;
      'type'?: string;
      'status'?: string;
      'ndi'?: string;
      'enabled'?: boolean;
      'startsAt'?: string;
      'endsAt'?: string;
      'vlan'?: string;
      'meta'?: {
      };
      'createdAt'?: string;
      'updatedAt'?: string;
      'deletedAt'?: string;
      'provider'?: {
      'name'?: string;
      };
      'category'?: {
      'name'?: string;
      'typeSlug'?: string;
      };
      'building'?: {
      'code'?: string;
      'name'?: string;
      'stairs'?: string;
      'floor'?: string;
      };
      'company'?: {
      'id'?: string;
      'name'?: string;
      'v1Id'?: number;
      'siret'?: string;
      'tenant'?: {
      'name'?: string;
      };
      };
      'user'?: {
      'firstName'?: string;
      'lastName'?: string;
      'email'?: string;
      'v1Id'?: number;
      };
      'product'?: {
      'name'?: string;
      'label'?: string;
      'type'?: string;
      'techno'?: string;
      };
      'address'?: {
      'houseNumber'?: string;
      'roadType'?: string;
      'road'?: string;
      'postcode'?: string;
      'inseeCode'?: string;
      'rivoliCode'?: string;
      'city'?: string;
      'hexacle'?: string;
      };
      'contact'?: {
      'firstName'?: string;
      'lastName'?: string;
      'email'?: string;
      'phone'?: string;
      };
      'appointment'?: {
      'name'?: string;
      'tel'?: string;
      'site'?: string;
      'code'?: string;
      'startDate'?: string;
      'endDate'?: string;
      };
      'options'?: Array<{
      'name'?: string;
      'code'?: string;
      }>;
      'comments'?: Array<{
      'name'?: string;
      'content'?: string;
      'createdAt'?: string;
      'user'?: {
      'firstName'?: string;
      'lastName'?: string;
      'email'?: string;
      'v1Id'?: number;
      };
      }>;
      'realm'?: string;
      };
      }>;
    })
  );
}

showOrder.PATH = '/order/show';
