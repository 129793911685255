/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { activateByZen } from '../fn/by-zen/activate-by-zen';
import { ActivateByZen$Params } from '../fn/by-zen/activate-by-zen';
import { routerByZen } from '../fn/by-zen/router-by-zen';
import { RouterByZen$Params } from '../fn/by-zen/router-by-zen';
import { showOrderByzen } from '../fn/by-zen/show-order-byzen';
import { ShowOrderByzen$Params } from '../fn/by-zen/show-order-byzen';
import { showSim } from '../fn/by-zen/show-sim';
import { ShowSim$Params } from '../fn/by-zen/show-sim';


/**
 * Sim Bouygues
 */
@Injectable({ providedIn: 'root' })
export class ByZenService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activateByZen()` */
  static readonly ActivateByZenPath = '/byzen/activate';

  /**
   * Activate ByZen.
   *
   * Activate ByZen
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateByZen()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateByZen$Response(params: ActivateByZen$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return activateByZen(this.http, this.rootUrl, params, context);
  }

  /**
   * Activate ByZen.
   *
   * Activate ByZen
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateByZen$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateByZen(params: ActivateByZen$Params, context?: HttpContext): Observable<{
}> {
    return this.activateByZen$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `routerByZen()` */
  static readonly RouterByZenPath = '/byzen/router';

  /**
   * Router ByZen.
   *
   * Router ByZen
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `routerByZen()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  routerByZen$Response(params: RouterByZen$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return routerByZen(this.http, this.rootUrl, params, context);
  }

  /**
   * Router ByZen.
   *
   * Router ByZen
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `routerByZen$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  routerByZen(params: RouterByZen$Params, context?: HttpContext): Observable<{
}> {
    return this.routerByZen$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `showOrderByzen()` */
  static readonly ShowOrderByzenPath = '/byzen/show/order/{id}';

  /**
   * Show order.
   *
   * Show order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderByzen()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderByzen$Response(params: ShowOrderByzen$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return showOrderByzen(this.http, this.rootUrl, params, context);
  }

  /**
   * Show order.
   *
   * Show order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderByzen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderByzen(params: ShowOrderByzen$Params, context?: HttpContext): Observable<{
}> {
    return this.showOrderByzen$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `showSim()` */
  static readonly ShowSimPath = '/byzen/show/sim/{reference}';

  /**
   * Show sim.
   *
   * Show sim and link infos
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showSim()` instead.
   *
   * This method doesn't expect any request body.
   */
  showSim$Response(params: ShowSim$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return showSim(this.http, this.rootUrl, params, context);
  }

  /**
   * Show sim.
   *
   * Show sim and link infos
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showSim$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showSim(params: ShowSim$Params, context?: HttpContext): Observable<{
}> {
    return this.showSim$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
