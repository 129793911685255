import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class GlobalErrorInterceptor implements ErrorHandler {
  constructor(private notifierService: NotifierService) {}

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      this.notifierService.notify('error', error);
      console.log('Error from global error handler:', error);
      console.error(error.stack || error);
    }
  }
}
