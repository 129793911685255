<div *ngIf="data">
  <img src="" width="64" height="64" class="float-start me-3 mb-1" />
  <b>{{ data.firstName }} {{ data.lastName }}</b>
  <br />
  &lt;<a href="mailto:{{ data.email }}">{{ data.email }}</a>&gt;
  <br />
  <i class="fa fa-building me-1"></i>
  <a [routerLink]="'/companies/' + data.company?.id"
    >{{ data.company?.name }}</a
  >
</div>
