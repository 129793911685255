<div class="modal-header bg-{{ color }} text-white">
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body bg-white">
  <app-dynamic-model-table
    [model]="model"
    [id]="id"
    [related]="related"
    [action]="action"
  ></app-dynamic-model-table>
</div>
