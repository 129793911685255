import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsModel } from '../models/settings';
import { SettingsService } from './settings.service';

const LOCK = 'lock_session';

@Injectable()
export class LockService implements OnDestroy {
  private isLock: boolean;
  lockEmitter: EventEmitter<boolean> = new EventEmitter();
  private settings: SettingsModel;
  private settingsSubscription: Subscription;
  private userActivity: any; //store timeout
  constructor(private settingsService: SettingsService) {
    this.isLock = Boolean(localStorage.getItem(LOCK) ?? false);

    this.settings = settingsService.getSettings();
    this.settingsSubscription = this.settingsService.settingsEmitter.subscribe(
      (val) => (this.settings = val),
    );
  }

  /**
   * Refresh lock time if started
   */
  refreshLockTime() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  private setTimeout() {
    if (this.settings.formLock) {
      this.userActivity = setTimeout(
        () => this.lock(),
        this.settings.timeBeforeLock,
      );
    }
  }

  lock() {
    localStorage.setItem(LOCK, 'true');
    this.isLock = true;
    this.lockEmitter.emit(this.isLock);
  }

  unlock() {
    localStorage.removeItem(LOCK);
    this.isLock = false;
    this.lockEmitter.emit(this.isLock);
  }

  getLock() {
    return this.isLock;
  }

  ngOnDestroy(): void {
    clearTimeout(this.userActivity);
    this.settingsSubscription.unsubscribe();
  }
}
