import { Pipe, PipeTransform } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentTimeAgo',
})
export class MomentTimeAgoPipe implements PipeTransform {
  // constructor(translate: TranslateService) {
  //   moment.locale(translate.currentLang);
  // }

  public transform(date: any): string {
    return moment(date).isValid() ? moment(date).fromNow() : '-';
  }
}

@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
  // constructor(translate: TranslateService) {
  //   moment.locale(translate.currentLang);
  // }

  public transform(date: any, format = 'MM/DD/YYYY'): string {
    return moment(date).isValid() ? moment(date).format(format) : '-';
  }
}
