import { Component } from '@angular/core';
import { DynamicModelFormRender } from '../models/dynamic-model-form-render';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { BaseTemplateComponent } from './base.template';

@Component({
  selector: 'app-plan-template',
  templateUrl: './plan.template.html',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PlanTemplate extends BaseTemplateComponent {
  override icon = 'book';

  public override getForm(): DynamicModelFormRender {
    return {
      model: 'Plan',
      action: this.action,
      join: ['tenant'],
      fields: {
        Plan: {
          type: 'tab-form',
          tabs: {
            General: {
              props: {
                title: 'Général',
              },
              fieldGroup: {
                id: {
                  key: 'id',
                  type: 'input',
                  noExport: true,
                  hide: true,
                },
                name: {
                  type: 'input',
                  class: 'col-6',
                  required: true,
                  props: {
                    label: 'Nom',
                  },
                },
                tenant: {
                  type: 'select',
                  class: 'col-6',
                  submitField: 'tenantId',
                  required: true,
                  noImport: true,
                  props: {
                    allowClear: true,
                    resource: {
                      model: 'tenant',
                    },
                    label: 'Tenant',
                    placeholder: 'Tenant',
                    labelProp: 'name',
                    mapOptions: (options) => {
                      return {
                        id: options,
                        name: options.name,
                      };
                    },
                  },
                },
                startsAt: {
                  type: 'datepicker',
                  class: 'col-3',
                  required: true,
                  props: {
                    type: 'date',
                    label: 'Date de Début',
                    defaultValue: new Date(),
                  },
                },
                endsAt: {
                  type: 'datepicker',
                  class: 'col-3',
                  props: {
                    type: 'date',
                    label: 'Date de Fin',
                  },
                },
                enabled: {
                  type: 'checkbox',
                  class: 'col-3 mt-4 d-flex align-items-center',
                  defaultValue: true,
                  props: {
                    label: 'Actif',
                  },
                },
                priority: {
                  type: 'input', // 'maskedInput',
                  class: 'col-3',
                  defaultValue: 1,
                  required: true,
                  props: {
                    label: 'Priorité',
                    type: 'number',
                    min: 0,
                    step: 1,
                    // mask: '000',
                    placeholder: '1', // TODO: default value
                  },
                },
              },
            },
            Prix: {
              active: () => this.action === 'patch',
              props: {
                title: 'Règles de prix',
              },
              fieldGroup: {
                alert: {
                  type: 'alert',
                  ignore: true,
                  props: {
                    color: 'info',
                    message:
                      "Vous pouvez déplacer les règles de prix en les glissant afin de changer leur ordre d'application.",
                  },
                },
                priceRule: {
                  type: 'plan-list',
                  noImport: true,
                  props: {
                    label: 'Règles de prix',
                    resource: 'PriceRule',
                    parentIdKey: 'planId',
                    needParentId: true,
                    fetch: ['categories', 'offers', 'providers'],
                  },
                },
              },
            },
            Reecriture: {
              active: () => this.action === 'patch',
              props: {
                title: "Réécriture d'offre",
              },
              fieldGroup: {
                offerRewrite: {
                  type: 'model-list',
                  noImport: true,
                  props: {
                    label: "Réécriture d'offre",
                    resource: 'offerRewrite',
                    parentIdKey: 'planId',
                    needParentId: true,
                    render: (data) => {
                      return `${data.name}`;
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    return {
      join: ['tenant'],
      dtFilters: {
        _all: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-6',
          title: 'Recherche',
          filter: true,
        },
      },
      columns: {
        id: {
          title: 'ID',
          className: 'no-export',
          visible: false,
        },
        tenantName: {
          title: 'Tenant',
          fields: ['tenant.name as tenantName'],
          type: 'string',
        },
        name: {
          title: 'Nom',
          type: 'string',
        },
        description: {
          title: 'Description',
          type: 'string',
        },
        priority: {
          title: 'Priorité',
          type: 'number',
        },
        startsAt: {
          title: 'Début',
          type: 'date',
        },
        endsAt: {
          title: 'Fin',
          type: 'date',
        },
        createdAt: {
          title: 'Créé le',
          type: 'date',
        },
        updatedAt: {
          title: 'Modifié le',
          type: 'date',
        },
      },
      buttons: this.getButtons('Plan'),
    };
  }
}
