/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OfferResponse } from '../../models/offer-response';

export interface FakeOffer$Params {
  data?: string;
}

export function fakeOffer(http: HttpClient, rootUrl: string, params?: FakeOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
  const rb = new RequestBuilder(rootUrl, fakeOffer.PATH, 'get');
  if (params) {
    rb.query('data', params.data, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OfferResponse>;
    })
  );
}

fakeOffer.PATH = '/offers/faker';
