import { Injectable } from '@angular/core';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { BaseTemplateComponent } from './base.template';
import { AddressResponse } from 'src/api/models/address-response';
import { DynamicModelFormRender } from 'src/app/shared/models/dynamic-model-form-render';

@Injectable()
export class AddressTemplate extends BaseTemplateComponent<AddressResponse> {
  public override getForm(): DynamicModelFormRender {
    // const input = this.form.controls;

    return {
      model: 'Address',
      action: this.action,
      fields: {
        houseNumber: {
          label: 'Numéro',
          type: 'input',
          required: true,
          onChange(field, event) {
            console.log(field);
          },
        },
        road: {
          label: 'Nom de la rue',
          type: 'input',
          required: true,
        },
        unit: {
          label: 'unité ?',
          type: 'input',
        },
        level: {
          label: 'Niveau',
          type: 'input',
        },
        staircase: {
          label: "cage d'escalier",
          type: 'input',
        },
        entrance: {
          label: 'entrée',
          type: 'input',
        },
        postcode: {
          label: 'Code postal',
          type: 'input',
          props: {
            maxLength: 5,
          },
        },
        city: {
          label: 'Ville',
          type: 'input',
          required: true,
        },
        state: {
          label: 'Etat',
          type: 'input',
        },
        country: {
          label: 'Pays',
          type: 'input',
          required: true,
        },
        countryCode: {
          label: 'Code du pays',
          type: 'input',
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    return {
      columns: {
        id: {
          type: 'input',
        },
        houseNumber: {
          type: 'input',
        },
        road: {
          type: 'input',
        },
        unit: {
          type: 'input',
        },
        level: {
          type: 'input',
        },
        staircase: {
          type: 'input',
        },
        entrance: {
          type: 'input',
        },
        postcode: {
          type: 'input',
        },
        city: {
          type: 'input',
        },
        state: {
          type: 'input',
        },
        country: {
          type: 'input',
        },
        countryCode: {
          type: 'input',
        },
      },
    } as DynamicTableRender;
  }
}
