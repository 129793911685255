import { Type } from '@angular/core';
import { BaseTemplateComponent } from './base.template';
import { CompanyTemplate } from './company.template';
import { UserTemplate } from './user.template';
import { AddressTemplate } from './address.template';
import { TenantTemplate } from './tenant.template';
import { RoleTemplate } from './role.template';
import { OfferTemplate } from './offer.template';
import { OrderTemplate } from './order.template';
import { CompanyAddressTemplate } from './company-address.template';
import { PlanTemplate } from './plan.template';
import { PriceRuleTemplate } from './price-rule.template';
import { OfferRewriteTemplate } from './offer-rewrite.template';
import { StaticPlanTemplate } from './static-plan.template';
import { CategoryTemplate } from './category.template';

export const templates: { [key: string]: Type<BaseTemplateComponent> } = {
  AddressTemplate,
  CompanyTemplate,
  TenantTemplate,
  UserTemplate,
  RoleTemplate,
  OrderTemplate,
  CompanyAddressTemplate,

  // Catalog
  CategoryTemplate,
  OfferTemplate,
  PlanTemplate,
  PriceRuleTemplate,
  OfferRewriteTemplate,
  StaticPlanTemplate,
};
