import { Component } from '@angular/core';
import { map } from 'rxjs';
import { DynamicModelFormRender } from '../models/dynamic-model-form-render';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { BaseTemplateComponent } from './base.template';
import { PriceRuleResponse } from 'src/api/models';

@Component({
  selector: 'app-price-rule-template',
  templateUrl: './price-rule.template.html',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PriceRuleTemplate extends BaseTemplateComponent<PriceRuleResponse> {
  override icon = ' mdi mdi-hexagon-multiple';

  public override getForm(): DynamicModelFormRender {
    return {
      model: 'PriceRule',
      action: this.action,
      fetch: ['categories', 'offers', 'providers'],
      graph: true,
      fields: {
        id: {
          key: 'id',
          type: 'input',
          noExport: true,
          hide: true,
        },
        planId: {
          key: 'planId',
          type: 'input',
          hide: true,
        },
        name: {
          type: 'input',
          class: 'col-6',
          label: 'Nom',
          required: true,
        },
        description: {
          type: 'textarea',
          class: 'col-12',
          label: 'Description',
          required: false,
          props: {
            rows: 3,
          },
        },
        position: {
          type: 'maskedInput',
          props: {
            mask: '000',
            hidden: true,
            countDefaultValue: this.priceRuleService
              .countPriceRule$Response(
                this.paramsBuilderService.build({
                  fullSearch: {
                    where: { planId: this.form.get('planId')?.value },
                  },
                }),
              )
              .pipe(map((res) => res.body.data! + 1)),
          },
          required: true,
        },
        startsAt: {
          type: 'datepicker',
          class: 'col-6',
          required: true,
          props: {
            type: 'date',
            label: 'Date de Début',
            defaultValue: new Date(),
          },
        },
        endsAt: {
          type: 'datepicker',
          class: 'col-6',
          props: {
            type: 'date',
            label: 'Date de Fin',
          },
        },
        ignorePreviousRules: {
          type: 'checkbox',
          class: 'col-6 d-flex align-items-center justify-content-center',
          defaultValue: false,
          props: {
            label: 'Ignorer les règles précédentes',
          },
        },
        type: {
          type: 'select',
          class: 'col-6',
          required: true,
          defaultValue: 'margin_rate',
          props: {
            label: 'Type',
            options: [
              { id: 'multiplier', name: 'Coefficient' },
              { id: 'margin_rate', name: 'Taux de Marge' },
            ],
            valueProp: 'id',
            labelProp: 'name',
          },
        },
        ruleType: {
          type: 'radio',
          label: 'Type de règle',
          required: true,
          noExport: true,
          noImport: true,
          props: {
            class: 'col-6',
            options: [
              { id: 1, name: 'Taux de marge vente globale' },
              { id: 4, name: 'Taux de reduction vente globale' },
              { id: 2, name: 'Taux de marge vente (FAS, ABO, RESIL)' },
              { id: 5, name: 'Taux de reduction vente (FAS, ABO, RESIL)' },
              { id: 3, name: 'Prix de vente fixés' },
              { id: 6, name: 'Prix de reduction fixés' },
            ],
            mapOptions: (options) => {
              return {
                id: options.id,
                name: options.name,
              };
            },
          },
          expressions: {
            'model.ruleType':
              'model.marginGlobal ? 1 : model.marginSetup ? 2 : model.marginRecur ? 2 : model.marginResil ? 2 : model.sellSetup ? 3 : model.sellRecur ? 3 : model.sellResil ? 3 : model.discountGlobal ? 4 : model.discountPercentSetup ? 5 : model.discountPercentRecur ? 5 : model.discountPercentResil ? 5 : model.discountSetup ? 6 : model.discountRecur ? 6 : model.discountResil ? 6 : model.ruleType',
            'props.options': (field) => {
              if (this.model.type === 'margin_rate') {
                field.props!.options = [
                  { id: 1, name: 'Taux de marge vente globale' },
                  { id: 4, name: 'Taux de reduction vente globale' },
                  { id: 2, name: 'Taux de marge vente (FAS, ABO, RESIL)' },
                  { id: 5, name: 'Taux de reduction vente (FAS, ABO, RESIL)' },
                  { id: 3, name: 'Prix de vente fixés' },
                  { id: 6, name: 'Prix de reduction fixés' },
                ];
                field.hide = true;
                field.hide = false;
                return;
              }
              field.props!.options = [
                { id: 1, name: 'Coefficient de marge vente globale' },
                { id: 4, name: 'Coefficient de reduction vente globale' },
                { id: 2, name: 'Coefficient de marge vente (FAS, ABO, RESIL)' },
                {
                  id: 5,
                  name: 'Coefficient de reduction vente (FAS, ABO, RESIL)',
                },
                { id: 3, name: 'Prix de vente fixés' },
                { id: 6, name: 'Prix de reduction fixés' },
              ];
              field.hide = true;
              field.hide = false;
            },
          },
        },
        marginGlobal: {
          type: 'input-slider',
          defaultValue: this.model.marginGlobal,
          required: true,
          props: {
            min: 1,
            label: 'Taux de marge vente globale',
            suffix: '%',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 1) ||
                field.form.get('ruleType')?.value !== 1
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 1;
              }
              return this.model.ruleType !== 1;
            },
            'props.label':
              'model.type === "margin_rate" ? "Taux de marge vente globale" : "Coefficient vente globale"',
            'props.type': 'model.type',
          },
        },
        marginSetup: {
          type: 'input-slider',
          defaultValue: this.model.marginSetup,
          required: true,
          class: 'col-6',
          props: {
            label: 'Taux de marge vente FAS',
            suffix: '%',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 2) ||
                field.form.get('ruleType')?.value !== 2
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 2;
              }
              return this.model.ruleType !== 2;
            },
            'props.label':
              'model.type === "margin_rate" ? "Taux de marge vente FAS" : "Coefficient de marge vente FAS"',
            'props.type': 'model.type',
          },
        },
        marginRecur: {
          type: 'input-slider',
          defaultValue: this.model.marginRecur,
          required: true,
          class: 'col-6',
          props: {
            label: 'Taux de marge vente ABO',
            suffix: '%',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 2) ||
                field.form.get('ruleType')?.value !== 2
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 2;
              }
              return this.model.ruleType !== 2;
            },
            'props.label':
              'model.type === "margin_rate" ? "Taux de marge vente ABO" : "Coefficient de marge vente ABO"',
            'props.type': 'model.type',
          },
        },
        marginResil: {
          type: 'input-slider',
          defaultValue: this.model.marginResil,
          required: true,
          props: {
            label: 'Taux de marge vente RESIL',
            suffix: '%',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 2) ||
                field.form.get('ruleType')?.value !== 2
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 2;
              }
              return this.model.ruleType !== 2;
            },
            'props.label':
              'model.type === "margin_rate" ? "Taux de marge vente RESIL" : "Coefficient de marge vente RESIL"',
            'props.type': 'model.type',
          },
        },
        sellSetup: {
          type: 'input',
          class: 'col-4 fs-6',
          required: true,
          defaultValue: this.model.sellSetup,
          props: {
            label: 'Prix de vente fixés (FAS)',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 3) ||
                field.form.get('ruleType')?.value !== 3
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 3;
              }
              return this.model.ruleType !== 3;
            },
          },
        },
        sellRecur: {
          type: 'input',
          class: 'col-4 fs-6',
          required: true,
          defaultValue: this.model.sellRecur,
          props: {
            label: 'Prix de vente fixés (ABO)',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 3) ||
                field.form.get('ruleType')?.value !== 3
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 3;
              }
              return this.model.ruleType !== 3;
            },
          },
        },
        sellResil: {
          type: 'input',
          class: 'col-4 fs-6',
          required: true,
          defaultValue: this.model.sellResil,
          props: {
            label: 'Prix de vente fixés (RESIL)',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 3) ||
                field.form.get('ruleType')?.value !== 3
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 3;
              }
              return this.model.ruleType !== 3;
            },
          },
        },
        discountGlobal: {
          type: 'input-slider',
          required: true,
          defaultValue: this.model.discountGlobal,
          props: {
            min: 1,
            label: 'Taux de reduction vente globale',
            suffix: '%',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 4) ||
                field.form.get('ruleType')?.value !== 4
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 4;
              }
              return this.model.ruleType !== 4;
            },
            'props.label':
              'model.type === "margin_rate" ? "Taux de reduction vente globale" : "Coefficient de reduction vente globale"',
            'props.type': 'model.type',
          },
        },
        discountPercentSetup: {
          type: 'input-slider',
          required: true,
          defaultValue: this.model.discountPercentSetup,
          class: 'col-6',
          props: {
            label: 'Taux de reduction vente FAS',
            suffix: '%',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 5) ||
                field.form.get('ruleType')?.value !== 5
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 5;
              }
              return this.model.ruleType !== 5;
            },
            'props.label':
              'model.type === "margin_rate" ? "Taux de reduction vente FAS" : "Coefficient de reduction vente FAS"',
            'props.type': 'model.type',
          },
        },
        discountPercentRecur: {
          type: 'input-slider',
          required: true,
          defaultValue: this.model.discountPercentRecur,
          class: 'col-6',
          props: {
            label: 'Taux de reduction vente ABO',
            suffix: '%',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 5) ||
                field.form.get('ruleType')?.value !== 5
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 5;
              }
              return this.model.ruleType !== 5;
            },
            'props.label':
              'model.type === "margin_rate" ? "Taux de reduction vente ABO" : "Coefficient de reduction vente ABO"',
            'props.type': 'model.type',
          },
        },
        discountPercentResil: {
          type: 'input-slider',
          required: true,
          defaultValue: this.model.discountPercentResil,
          props: {
            label: 'Taux de reduction vente RESIL',
            suffix: '%',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 5) ||
                field.form.get('ruleType')?.value !== 5
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 5;
              }
              return this.model.ruleType !== 5;
            },
            'props.label':
              'model.type === "margin_rate" ? "Taux de reduction vente RESIL" : "Coefficient de reduction vente RESIL"',
            'props.type': 'model.type',
          },
        },
        discountSetup: {
          type: 'input',
          class: 'col-4 fs-6',
          required: true,
          defaultValue: this.model.discountSetup,
          props: {
            label: 'Prix de reduction fixés (FAS)',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 6) ||
                field.form.get('ruleType')?.value !== 6
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 6;
              }
              return this.model.ruleType !== 6;
            },
          },
        },
        discountRecur: {
          type: 'input',
          class: 'col-4 fs-6',
          required: true,
          defaultValue: this.model.discountRecur,
          props: {
            label: 'Prix de reduction fixés (ABO)',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 6) ||
                field.form.get('ruleType')?.value !== 6
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 6;
              }
              return this.model.ruleType !== 6;
            },
          },
        },
        discountResil: {
          type: 'input',
          class: 'col-4 fs-6',
          required: true,
          defaultValue: this.model.discountResil,
          props: {
            label: 'Prix de reduction fixés (RESIL)',
          },
          expressions: {
            hide: (field: any) => {
              if (
                (this.model.ruleType && this.model.ruleType !== 6) ||
                field.form.get('ruleType')?.value !== 6
              ) {
                field.formControl.setValue(null);
              }
              if (!this.model.ruleType) {
                return field.form.get('ruleType')?.value !== 6;
              }
              return this.model.ruleType !== 6;
            },
          },
        },
        categories: {
          type: 'select',
          noImport: true,
          props: {
            label: 'Catégories',
            multiple: true,
            allowClear: true,
            resource: {
              model: 'category',
            },
            paramsBuilder: {
              join: ['tenant'],
              fields: ['id,name,tenant.name as groupName'],
            },
            mapOptions: (options) => {
              return {
                id: options,
                name: options.name,
              };
            },
            groupName: 'groupName',
            labelProp: 'name',
          },
        },
        offers: {
          type: 'select',
          noImport: true,
          class: 'col-6',
          props: {
            allowClear: true,
            resource: {
              model: 'offer',
            },
            paramsBuilder: {
              limit: 50,
            },
            label: 'Offres',
            multiple: true,
            mapOptions: (options) => {
              return {
                id: options,
                name: options.name,
              };
            },
            labelProp: 'name',
          },
        },
        providers: {
          type: 'select',
          noImport: true,
          class: 'col-6',
          props: {
            label: 'Fournisseurs',
            multiple: true,
            allowClear: true,
            options: this.eligibilityService
              .listProviders$Response(
                this.paramsBuilderService.build({
                  fullSearch: {
                    whereILike: {
                      label: this.model.whereLike ?? '%%',
                    },
                  },
                }),
              )
              .pipe(
                map((res: any) => {
                  res.body.providers.forEach((provider: any) => {
                    delete provider.isNew;
                    delete provider.status;
                  });
                  return res.body.providers;
                }),
              ),
            mapOptions: (options) => {
              return {
                id: options,
                name: options.label,
              };
            },
            labelProp: 'label',
          },
        },
        technos: {
          type: 'select',
          class: 'col-6',
          props: {
            label: 'Technos',
            multiple: true,
            allowClear: true,
            options: [
              {
                name: 'FTTO',
              },
              {
                name: 'FTTH',
              },
              {
                name: 'FTTE',
              },
              {
                name: 'SDSL',
              },
              {
                name: 'ADSL',
              },
              {
                name: 'VDSL',
              },
            ],
            labelProp: 'name',
            valueProp: 'name',
          },
        },
        tags: {
          type: 'select',
          class: 'col-6',
          props: {
            label: 'Tags',
            allowClear: true,
            resource: {
              model: 'tag',
            },
            paramsBuilder: {
              fields: ['*, tenant.name as groupName'],
              limit: 50,
              join: ['tenant'],
            },
            groupName: 'groupName',
            labelProp: 'name',
            multiple: true,
            valueProp: 'name',
          },
        },
        conditionMode: {
          type: 'radio',
          label: 'Mode de condition',
          required: true,
          defaultValue: 'or',
          class: 'col-6',
          props: {
            class: 'col-12',
            options: [
              { id: 'or', name: 'Ou' },
              { id: 'and', name: 'Et' },
            ],
          },
        },
        conditionTag: {
          type: 'radio',
          label: 'Mode de condition tag',
          required: true,
          defaultValue: 'or',
          class: 'col-6',
          props: {
            class: 'col-12',
            options: [
              { id: 'or', name: 'Ou' },
              { id: 'and', name: 'Et' },
            ],
          },
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    throw new Error('Method not implemented.');
  }
}
