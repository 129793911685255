/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countTag } from '../fn/tag/count-tag';
import { CountTag$Params } from '../fn/tag/count-tag';
import { createTag } from '../fn/tag/create-tag';
import { CreateTag$Params } from '../fn/tag/create-tag';
import { deleteTag } from '../fn/tag/delete-tag';
import { DeleteTag$Params } from '../fn/tag/delete-tag';
import { fakeTag } from '../fn/tag/fake-tag';
import { FakeTag$Params } from '../fn/tag/fake-tag';
import { listTag } from '../fn/tag/list-tag';
import { ListTag$Params } from '../fn/tag/list-tag';
import { patchTag } from '../fn/tag/patch-tag';
import { PatchTag$Params } from '../fn/tag/patch-tag';
import { restoreTag } from '../fn/tag/restore-tag';
import { RestoreTag$Params } from '../fn/tag/restore-tag';
import { showTag } from '../fn/tag/show-tag';
import { ShowTag$Params } from '../fn/tag/show-tag';
import { TagResponse } from '../models/tag-response';

@Injectable({ providedIn: 'root' })
export class TagService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listTag()` */
  static readonly ListTagPath = '/tags';

  /**
   * List tags.
   *
   * List tags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTag$Response(params?: ListTag$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<TagResponse>;
'total'?: number;
}>> {
    return listTag(this.http, this.rootUrl, params, context);
  }

  /**
   * List tags.
   *
   * List tags
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTag(params?: ListTag$Params, context?: HttpContext): Observable<{
'data'?: Array<TagResponse>;
'total'?: number;
}> {
    return this.listTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<TagResponse>;
'total'?: number;
}>): {
'data'?: Array<TagResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createTag()` */
  static readonly CreateTagPath = '/tags';

  /**
   * Create tag.
   *
   * Create one tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag$Response(params: CreateTag$Params, context?: HttpContext): Observable<StrictHttpResponse<TagResponse>> {
    return createTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Create tag.
   *
   * Create one tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag(params: CreateTag$Params, context?: HttpContext): Observable<TagResponse> {
    return this.createTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagResponse>): TagResponse => r.body)
    );
  }

  /** Path part for operation `countTag()` */
  static readonly CountTagPath = '/tags/count';

  /**
   * Count tags.
   *
   * Count tags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  countTag$Response(params?: CountTag$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Count tags.
   *
   * Count tags
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countTag(params?: CountTag$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showTag()` */
  static readonly ShowTagPath = '/tags/{id}';

  /**
   * Show tag.
   *
   * Show one tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTag$Response(params: ShowTag$Params, context?: HttpContext): Observable<StrictHttpResponse<TagResponse>> {
    return showTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Show tag.
   *
   * Show one tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTag(params: ShowTag$Params, context?: HttpContext): Observable<TagResponse> {
    return this.showTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagResponse>): TagResponse => r.body)
    );
  }

  /** Path part for operation `restoreTag()` */
  static readonly RestoreTagPath = '/tags/{id}';

  /**
   * Restore tag.
   *
   * Restore tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreTag$Response(params: RestoreTag$Params, context?: HttpContext): Observable<StrictHttpResponse<TagResponse>> {
    return restoreTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore tag.
   *
   * Restore tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreTag(params: RestoreTag$Params, context?: HttpContext): Observable<TagResponse> {
    return this.restoreTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagResponse>): TagResponse => r.body)
    );
  }

  /** Path part for operation `deleteTag()` */
  static readonly DeleteTagPath = '/tags/{id}';

  /**
   * Delete tag.
   *
   * Delete tag (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTag$Response(params: DeleteTag$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete tag.
   *
   * Delete tag (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTag(params: DeleteTag$Params, context?: HttpContext): Observable<void> {
    return this.deleteTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchTag()` */
  static readonly PatchTagPath = '/tags/{id}';

  /**
   * Patch tag.
   *
   * Patch one tag (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTag$Response(params: PatchTag$Params, context?: HttpContext): Observable<StrictHttpResponse<TagResponse>> {
    return patchTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch tag.
   *
   * Patch one tag (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTag(params: PatchTag$Params, context?: HttpContext): Observable<TagResponse> {
    return this.patchTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagResponse>): TagResponse => r.body)
    );
  }

  /** Path part for operation `fakeTag()` */
  static readonly FakeTagPath = '/tags/faker';

  /**
   * Fake tag.
   *
   * Generate fake tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeTag$Response(params?: FakeTag$Params, context?: HttpContext): Observable<StrictHttpResponse<TagResponse>> {
    return fakeTag(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake tag.
   *
   * Generate fake tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeTag(params?: FakeTag$Params, context?: HttpContext): Observable<TagResponse> {
    return this.fakeTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagResponse>): TagResponse => r.body)
    );
  }

}
