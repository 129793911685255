// import { Location as LocationAngular } from "@angular/common";
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LocalUserService } from '../services/local-user.service';
// import { AuthService } from "../services/auth.service";
// import { TokenStorageService } from "../services/token-storage.service";

@Injectable({
  providedIn: 'root',
})

/**
 * Guard that verify if user is logged in and redirect to home page (back to complete)
 */
export class IsSignInGuard {
  constructor(
    private routes: Router,
    // private authService: AuthService,
    // private location: LocationAngular,
    // private tokenStorageService: TokenStorageService,
    private localUserService: LocalUserService,
  ) {}

  //Renvoyer à la route d'avant s'il elle existe

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const userId = this.localUserService.getUserId();

    if (userId) {
      console.log('Already sign in !');

      //trouver comment rediriger vers l'ancienne page si ce n'est pas une nouvelle
      //this.location.back()
      this.routes.navigate(['users/list']);
      return false;
    } else {
      console.log('Not sign in.. its ok');
      return true;
    }
  }
}
