<div *ngIf="data">
  <!-- <code *ngIf="data.supplierRef" [innerHTML]="data.supplierRef"></code> -->
  <code class="me-2" *ngIf="data.code" [innerHTML]="data.code"></code>
  <span *ngIf="data.name" [innerHTML]="data.name"></span>

  <div class="d-flex">
    <div *ngIf="data.setup">
      <strong class="me-2">FAS</strong>
      <span class="me-2" [innerHTML]="data.setup | currency"></span>
    </div>

    <div *ngIf="data.recur">
      <strong class="me-2">RECUR</strong>
      <span class="me-2" [innerHTML]="data.recur | currency"></span>
    </div>

    <div *ngIf="data.resil">
      <strong class="me-2">RESIL</strong>
      <span class="me-2" [innerHTML]="data.resil | currency"></span>
    </div>
  </div>
</div>
