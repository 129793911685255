/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Filter } from '../../models/filter';

export interface CountCategory$Params {

/**
 * [Modify](https://vincit.github.io/objection.js/recipes/modifiers.html)
 */
  modifiers?: string;
  filter?: Filter;
}

export function countCategory(http: HttpClient, rootUrl: string, params?: CountCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
  const rb = new RequestBuilder(rootUrl, countCategory.PATH, 'get');
  if (params) {
    rb.query('modifiers', params.modifiers, {});
    rb.query('filter', params.filter, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'data'?: number;
      }>;
    })
  );
}

countCategory.PATH = '/categories/count';
