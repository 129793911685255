import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import {
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { RouterModule, TitleStrategy } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import {
  // TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { NotifierModule } from 'angular-notifier';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';
import localeFr from '@angular/common/locales/fr';
import { ApiModule } from '../api/api.module';
import { environment } from '../environments/environment';
import { DynamicTitleStrategy, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { AuthGuard } from './core/guards/auth.guard';
import { TokenInterceptorService } from './core/services/token-interceptor.service';
import { IsSignInGuard } from './core/guards/is-sign-in.guard';
import { AuthService } from './core/services/auth.service';
import { GravatarService } from './core/services/gravatar.service';
import { LocalUserService } from './core/services/local-user.service';
import { SignalInfoService } from './core/services/signal-info.service';
import { HttpErrorInterceptorService } from './core/services/http-error-interceptor.service';
import { SettingsService } from './core/services/settings.service';
import { GlobalErrorInterceptor } from './core/services/global-error-interceptor.service';
import { DirtyCheck as DirtyCheckGuard } from './core/guards/dirty-check.guard';
import { WebSocketService } from './core/services/web-socket.service';
import { UseTenantService } from './core/services/use-tenant.service';
import { LetDirective } from './core/directives/let.directive';
import { LockService } from './core/services/lock.service';
import { CapitalizePipe } from './shared/pipes/capitalize.pipe';
import { icons } from './app.icons';
import { UtilsService } from './shared/services/utils.service';
import { UrlEncodeInterceptor } from './core/services/url-encode-interceptor.service';
import { OrderTemplateService } from './core/services/order-template.service';

export type Environment = {
  debug?: boolean;
  production: boolean;
  apiUrl: string;
  apiPrefix: string;
  googleMapsKey: string;
  eligibiltyMaintenance: boolean;
  webSockets: boolean;
};

// import { GlobalService } from 'src/api/services';
// import { APP_CONFIG } from 'src/variables';

// TODO elsewhere?
// const enabledLocales = ['fr', 'en'];
registerLocaleData(localeFr);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// TODO replace perfect scrollbar config
// suppressScrollX: true,
// wheelSpeed: 1,
// wheelPropagation: true,
// minScrollbarLength: 20,

@NgModule({
  declarations: [AppComponent, LetDirective, SpinnerComponent],
  imports: [
    // AppComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    NotifierModule.withConfig({
      // behaviour: {
      //   autoHide: 5000,
      //   onClick: false,
      //   onMouseover: 'pauseAutoHide',
      //   showDismissButton: true,
      //   stacking: 4,
      // },
      position: {
        horizontal: {
          position: 'right',
          // distance: 12,
        },
        // vertical: {
        //   position: 'bottom',
        //   distance: 12,
        //   gap: 10,
        // },
      },
    }),
    FeatherModule.pick(icons),
    FeatherModule,
    RouterModule.forRoot(routes, {
      // enableTracing: true,
    }),
    TranslateModule.forRoot({
      // compiler: {
      //   provide: TranslateCompiler,
      //   useClass: TranslateMessageFormatCompiler,
      // },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    // NgxMaskModule.forRoot(),
    ApiModule.forRoot({
      // rootUrl: 'http://127.0.0.1:3000/api',
      rootUrl: `${environment.apiUrl}${environment.apiPrefix}`,
    }),
    AbilityModule,
  ],
  providers: [
    AuthGuard,
    DirtyCheckGuard,
    AuthService,
    IsSignInGuard,
    LocalUserService,
    TranslateService,
    SignalInfoService,
    UseTenantService,
    GravatarService,
    WebSocketService,
    OrderTemplateService,
    provideNgxMask(),
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlEncodeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    SettingsService,
    LockService,
    // {
    //   provide: APP_CONFIG,
    //   useFactory: initializeAppFactory,
    //   deps: [GlobalService]
    //   // multi: true
    // },
    { provide: TitleStrategy, useClass: DynamicTitleStrategy },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    // { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: enabledLocales }},
    UtilsService,
    CapitalizePipe,
  ],
  bootstrap: [AppComponent],
  exports: [LetDirective],
})
export class AppModule {}
