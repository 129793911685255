/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { geoSearch } from '../fn/geo-json/geo-search';
import { GeoSearch$Params } from '../fn/geo-json/geo-search';
import { geoSearchAddress } from '../fn/geo-json/geo-search-address';
import { GeoSearchAddress$Params } from '../fn/geo-json/geo-search-address';
import { geoSearchMall } from '../fn/geo-json/geo-search-mall';
import { GeoSearchMall$Params } from '../fn/geo-json/geo-search-mall';

@Injectable({ providedIn: 'root' })
export class GeoJsonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `geoSearchMall()` */
  static readonly GeoSearchMallPath = '/geoJSON/mall';

  /**
   * search mall.
   *
   * search geoJSON mall.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geoSearchMall()` instead.
   *
   * This method doesn't expect any request body.
   */
  geoSearchMall$Response(params: GeoSearchMall$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return geoSearchMall(this.http, this.rootUrl, params, context);
  }

  /**
   * search mall.
   *
   * search geoJSON mall.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geoSearchMall$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geoSearchMall(params: GeoSearchMall$Params, context?: HttpContext): Observable<{
}> {
    return this.geoSearchMall$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `geoSearchAddress()` */
  static readonly GeoSearchAddressPath = '/geoJSON/location';

  /**
   * search address.
   *
   * search geoJSON address.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geoSearchAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  geoSearchAddress$Response(params: GeoSearchAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return geoSearchAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * search address.
   *
   * search geoJSON address.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geoSearchAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geoSearchAddress(params: GeoSearchAddress$Params, context?: HttpContext): Observable<{
}> {
    return this.geoSearchAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `geoSearch()` */
  static readonly GeoSearchPath = '/geoJSON/search';

  /**
   * search points.
   *
   * search geoJSON points. buildings fiber & mobile site.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geoSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  geoSearch$Response(params: GeoSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return geoSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * search points.
   *
   * search geoJSON points. buildings fiber & mobile site.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geoSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geoSearch(params: GeoSearch$Params, context?: HttpContext): Observable<{
}> {
    return this.geoSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
