import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  /**
   * Theme color mode
   */
  mode = 'light';

  /**
   * Theme color icon
   */
  icon: string;

  constructor() {
    this.icon = this.getIcon();
  }

  /**
   * Toggle theme color mode
   */
  toggle() {
    // TODO: handle auto and persist in local storage
    this.mode = this.mode === 'dark' ? 'light' : 'dark';
    this.icon = this.getIcon();
  }

  getIcon() {
    return (this.icon = this.mode === 'dark' ? 'sun' : 'moon');
  }
}
