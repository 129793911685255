/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface Appointments$Params {
      body: {
'operator': string;
'data'?: {
} | null;
}
}

export function appointments(http: HttpClient, rootUrl: string, params: Appointments$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'appointments'?: {
'provider'?: string;
'slots'?: Array<{
'startDate'?: string;
'endDate'?: string;
'slotId'?: string;
}>;
};
}>> {
  const rb = new RequestBuilder(rootUrl, appointments.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'appointments'?: {
      'provider'?: string;
      'slots'?: Array<{
      'startDate'?: string;
      'endDate'?: string;
      'slotId'?: string;
      }>;
      };
      }>;
    })
  );
}

appointments.PATH = '/appointment/search';
