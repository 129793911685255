import { Type } from '@angular/core';
import { OrderTemplateComponent } from './order.template';

import { linkTemplate } from './link.template';
import { trunkTemplate } from './trunk.template';

export { linkTemplate } from './link.template';
export { trunkTemplate } from './trunk.template';

export const templates: { [key: string]: Type<OrderTemplateComponent> } = {
  linkTemplate,
  trunkTemplate,
};
