import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicModelFormRender } from '../models/dynamic-model-form-render';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { CurrencyTaxPipe } from '../pipes/currency-tax.pipe';
import { AnyAction } from '../models/actions/action';
import { LinkActionComponent } from '../components/actions/link/link.action.component';
import { BaseTemplateComponent } from './base.template';
import { OfferResponse } from 'src/api/models/offer-response';
// import { AnyAction } from '../models/actions/action';

// @Injectable()
@Component({
  templateUrl: './offer.template.html',
  providers: [CurrencyTaxPipe],
  imports: [CommonModule],
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OfferTemplate extends BaseTemplateComponent<OfferResponse> {
  override icon = ' mdi mdi-hexagon-outline';

  @ViewChild('optionsTemplate') optionsTemplate!: TemplateRef<any>;

  override defaultParamsBuilder = {
    fields: ['name'],
  };

  override defaultViewParams = {
    fetch: 'tenant',
  };

  currencyTax = (value: any): string | null => {
    if (!value) return 'Non vendu';
    return CurrencyTaxPipe.prototype.transform(value);
  };

  recur = (recur: any): string => {
    if (!recur) return '';
    return `<span class="badge text-bg-primary">${this.translate.instant(
      'RECUR.' + recur.toUpperCase(),
    )}</span>`;
  };

  engagement = (engagement: any): string => {
    if (!engagement) return "Pas d'engagement";
    return engagement + ' mois';
  };

  public override getForm(): DynamicModelFormRender {
    const isAdmin = false; // this.localUserService.isAdmin();
    return {
      model: 'Offer',
      action: this.action,
      join: ['tenant', 'category'],
      fields: {
        Offer: {
          type: 'tab-form',
          tabs: {
            Infos: {
              props: {
                title: 'Général',
              },
              fieldGroup: {
                tenantId: isAdmin
                  ? {
                      label: 'Instance',
                      type: 'select',
                      class: 'col-6',
                      required: true,
                      props: {
                        resource: {
                          model: 'category',
                        },
                        labelProp: 'name',
                        // paramsBuilder: defaultParams,
                      },
                    }
                  : {
                      type: 'input',
                      hide: true,
                    },
                categoryId: {
                  label: 'Catégorie',
                  type: 'select',
                  class: 'col-6',
                  required: true,
                  submitField: 'categoryId',
                  props: {
                    resource: {
                      model: 'category',
                    },
                    // paramsBuilder: defaultParams,
                  },
                },
                name: {
                  type: 'input',
                  class: 'col-6',
                  label: 'Nom',
                  required: true,
                },
                label: {
                  type: 'input',
                  class: 'col-6',
                  label: 'Label',
                },
                description: {
                  type: 'textarea',
                  class: 'col-12',
                  label: 'Description',
                  props: {
                    rows: 5,
                  },
                },
                terms: {
                  type: 'input',
                  class: 'col-4',
                  label: 'Conditions',
                },
                supplierRef: {
                  type: 'input',
                  class: 'col-4',
                  label: 'Référence fournisseur',
                },
                code: {
                  type: 'input',
                  class: 'col-4',
                  label: 'Code',
                  required: true,
                },
                startsAt: {
                  type: 'datepicker',
                  class: 'col-6',
                  label: 'Début',
                  required: true,
                  props: {
                    type: 'dateTime',
                  },
                },
                endsAt: {
                  type: 'datepicker',
                  class: 'col-6',
                  label: 'Fin',
                  props: {
                    type: 'dateTime',
                  },
                },
                setup: {
                  type: 'input',
                  class: 'col-3',
                  label: 'FAS',
                  required: true,
                  props: {
                    type: 'number',
                  },
                },
                recur: {
                  type: 'input',
                  class: 'col-3',
                  label: 'Abonnement',
                  required: true,
                  props: {
                    type: 'number',
                  },
                },
                resil: {
                  type: 'input',
                  class: 'col-3',
                  label: 'Frais de résiliation',
                  required: true,
                  props: {
                    type: 'number',
                  },
                },
                vat: {
                  type: 'select',
                  class: 'col-3',
                  required: true,
                  props: {
                    label: 'TVA',
                    options: [5.5, 10, 20],
                    mapOptions: (options) => {
                      return {
                        id: options,
                        name: options + '%',
                      };
                    },
                  },
                },
                engagement: {
                  type: 'select',
                  class: 'col-6',
                  label: 'Engagement (mois)',
                  props: {
                    options: [
                      {
                        id: null,
                        name: "Pas d'engagement",
                      },
                      {
                        id: 1,
                        name: '1',
                      },
                      {
                        id: 12,
                        name: '12',
                      },
                      {
                        id: 24,
                        name: '24',
                      },
                      {
                        id: 36,
                        name: '36',
                      },
                    ],
                    labelProp: 'name',
                    valueProp: 'id',
                  },
                },
                recurrenceSlug: {
                  type: 'select',
                  class: 'col-6',
                  label: 'Récurrence',
                  props: {
                    options: [
                      {
                        id: 'daily',
                        name: 'Quotidien',
                      },
                      {
                        id: 'weekly',
                        name: 'Hebdomadaire',
                      },
                      {
                        id: 'monthly',
                        name: 'Mensuel',
                      },
                      {
                        id: 'yearly',
                        name: 'Annuel',
                      },
                    ],
                    labelProp: 'name',
                    valueProp: 'id',
                  },
                },
                enabled: {
                  type: 'checkbox',
                  class: 'col-6',
                  label: 'Actif',
                },
                isRequired: {
                  type: 'checkbox',
                  class: 'col-6',
                  label: 'Obligatoire ?',
                },
                minQuantity: {
                  type: 'maskedInput',
                  class: 'col-6',
                  label: 'Quantité minimum',
                  props: {
                    type: 'number',
                    mask: '0000000000',
                  },
                },
                maxQuantity: {
                  type: 'maskedInput',
                  class: 'col-6 mb-4',
                  label: 'Quantité maximum',
                  props: {
                    type: 'number',
                    mask: '0000000000',
                  },
                },
              },
            },
          },
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    return {
      buttons: this.getButtons('Offer'),
      fetch: ['tenant'],

      dtFilters: {
        _all: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-6',
          title: 'Search All',
          filter: true,
        },
        supplierRef: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-6',
          title: 'Search Supplier Ref',
        },
      },
      columns: {
        id: {
          // type: 'input',
          title: 'ID',
          className: 'no-export',
          visible: false,
        },
        // companyId: {
        // type: 'input',
        // title: 'ID entreprise',
        // className: 'no-export',
        // visible: false,
        // },
        tenantName: {
          // type: 'input',
          title: 'Tenant',
          data: 'tenant.name',
          className: 'nowrap',
          visible: true,
        },
        name: {
          title: 'Nom',
        },
        description: {
          title: 'Description',
          className: 'none',
          orderable: false,
          searchable: false,
          visible: false,
        },
        nbOptions: {
          title: 'Options',
          data: 'options.length',
          visible: true,
          className: 'none',
          // TODO: not searchable by default (int)
          searchable: false,
        },
        terms: {
          title: 'Conditions',
          className: 'none',
          orderable: false,
          searchable: false,
          visible: true,
        },
        supplierRef: {
          title: 'Référence',
          type: 'tag',
          visible: true,
          // className: 'nowrap',
        },
        code: {
          title: 'Code',
          type: 'tag',
          visible: true,
          // className: 'nowrap',
        },
        startsAt: {
          title: 'Début',
          type: 'datetime',
          visible: true,
        },
        endsAt: {
          title: 'Fin',
          type: 'datetime',
          visible: true,
        },
        setup: {
          title: 'PA FAS',
          type: 'number',
          pipe: 'currencyTax',
          pipeArgs: ['EUR', 'fr-FR', '1.2-2'],
          searchable: false,
        },
        recur: {
          title: 'PA ABO',
          type: 'number',
          pipe: 'currencyTax',
          pipeArgs: ['EUR', 'fr-FR', '1.2-2'],
          searchable: false,
        },
        resil: {
          title: 'PA RESIL',
          type: 'number',
          pipe: 'currencyTax',
          pipeArgs: ['EUR', 'fr-FR', '1.2-2'],
          searchable: false,
        },
        sellSetup: {
          title: 'PV FAS',
          type: 'number',
          render: (val: any) => {
            return this.currencyTax(val);
          },
          searchable: false,
          visible: false,
        },
        sellRecur: {
          title: 'PV Abonnement',
          type: 'number',
          render: (val: any) => {
            return this.currencyTax(val);
          },
          searchable: false,
          visible: false,
        },
        sellResil: {
          title: 'PV Résiliation',
          type: 'number',
          render: (val: any) => {
            return this.currencyTax(val);
          },
          searchable: false,
          visible: false,
        },
        // auto: {},
        engagement: {
          title: 'Engagement',
          render: (val: any) => this.engagement(val),
          searchable: false,
          visible: true,
        },
        recurrenceSlug: {
          title: 'Récurrence',
          searchable: false,
          render: (val: any) => this.recur(val),
          visible: true,
        },
        enabled: {
          title: 'Actif',
          type: 'boolean',
          visible: true,
        },
        // isOption: {},
        // isRequired: {},
        // minQuantity: {},
        // maxQuantity: {},
        // quantity: {},
        // billingMode: {},
        // prorated: {},
        meta: {
          title: 'Meta',
          type: 'json',
          className: 'none',
          render: (val: any) => JSON.stringify(val),
          orderable: false,
          searchable: false,
          visible: true,
        },
        updatedAt: true,
        createdAt: true,
      },
    };
  }

  override getActions(
    modelName: string,
    resolve?: any,
    reject?: any,
  ): AnyAction[] {
    return [
      {
        message: '<i class="fa-solid fa-eye"></i>',
        type: 'link',
        title: 'Voir les options',
        onClick: (event: Event, action: LinkActionComponent) => {
          return this.openModalTable(
            event,
            'Offer',
            'show',
            action.data.id,
            'Options',
            'xl',
          )
            .then(resolve)
            .catch(reject);
        },
        hidden: (action: LinkActionComponent) => {
          return (
            !action.data.options ||
            action.data.options?.length <= 0 ||
            action.data.deletedAt
          );
        },
      },
      {
        message: '<i class="fa fa-fw fa-pencil"></i>',
        type: 'link',
        onClick: (event: Event, action: LinkActionComponent) => {
          return this.openModalForm(event, modelName, 'patch', action.data.id)
            .then(resolve)
            .catch(reject);
        },
        class: '',
        can: ['update', modelName],
      },
      {
        message: '<i class="fa fa-fw fa-trash"></i>',
        type: 'link',
        onClick: (event: Event, action: LinkActionComponent) => {
          return this.openModalForm(event, modelName, 'delete', action.data.id)
            .then(resolve)
            .catch(reject);
        },
        hidden: (action: LinkActionComponent) => {
          // Action.data is undefined when loading?
          return !action.data || action.data.deletedAt;
        },
        class: '',
        can: ['delete', modelName],
      },
      {
        message: '<i class="fa fa-fw fa-trash-arrow-up"></i>',
        type: 'link',
        onClick: (event: Event, action: LinkActionComponent) => {
          return this.openModalForm(event, modelName, 'restore', action.data.id)
            .then(resolve)
            .catch(reject);
        },
        class: '',
        hidden: (action: LinkActionComponent) => {
          // Action.data is undefined when loading?
          return !action.data || !action.data.deletedAt;
        },
        can: ['restore', modelName],
      },
    ];
  }
}
