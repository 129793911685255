/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressResponse } from '../models/address-response';
import { countAddress } from '../fn/address/count-address';
import { CountAddress$Params } from '../fn/address/count-address';
import { createAddress } from '../fn/address/create-address';
import { CreateAddress$Params } from '../fn/address/create-address';
import { deleteAddress } from '../fn/address/delete-address';
import { DeleteAddress$Params } from '../fn/address/delete-address';
import { fakeAddress } from '../fn/address/fake-address';
import { FakeAddress$Params } from '../fn/address/fake-address';
import { listAddress } from '../fn/address/list-address';
import { ListAddress$Params } from '../fn/address/list-address';
import { patchAddress } from '../fn/address/patch-address';
import { PatchAddress$Params } from '../fn/address/patch-address';
import { restoreAddress } from '../fn/address/restore-address';
import { RestoreAddress$Params } from '../fn/address/restore-address';
import { showAddress } from '../fn/address/show-address';
import { ShowAddress$Params } from '../fn/address/show-address';
import { updateAddress } from '../fn/address/update-address';
import { UpdateAddress$Params } from '../fn/address/update-address';


/**
 * Address resource
 */
@Injectable({ providedIn: 'root' })
export class AddressService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listAddress()` */
  static readonly ListAddressPath = '/addresses';

  /**
   * List addresses.
   *
   * List addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAddress$Response(params?: ListAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<AddressResponse>;
'total'?: number;
}>> {
    return listAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * List addresses.
   *
   * List addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAddress(params?: ListAddress$Params, context?: HttpContext): Observable<{
'data'?: Array<AddressResponse>;
'total'?: number;
}> {
    return this.listAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<AddressResponse>;
'total'?: number;
}>): {
'data'?: Array<AddressResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createAddress()` */
  static readonly CreateAddressPath = '/addresses';

  /**
   * Create address.
   *
   * Create one address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddress$Response(params: CreateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressResponse>> {
    return createAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Create address.
   *
   * Create one address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddress(params: CreateAddress$Params, context?: HttpContext): Observable<AddressResponse> {
    return this.createAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressResponse>): AddressResponse => r.body)
    );
  }

  /** Path part for operation `countAddress()` */
  static readonly CountAddressPath = '/addresses/count';

  /**
   * Count addresses.
   *
   * Count addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAddress$Response(params?: CountAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Count addresses.
   *
   * Count addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countAddress(params?: CountAddress$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showAddress()` */
  static readonly ShowAddressPath = '/addresses/{id}';

  /**
   * Show address.
   *
   * Show one address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  showAddress$Response(params: ShowAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressResponse>> {
    return showAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Show address.
   *
   * Show one address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showAddress(params: ShowAddress$Params, context?: HttpContext): Observable<AddressResponse> {
    return this.showAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressResponse>): AddressResponse => r.body)
    );
  }

  /** Path part for operation `updateAddress()` */
  static readonly UpdateAddressPath = '/addresses/{id}';

  /**
   * Update address.
   *
   * Update one address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAddress$Response(params: UpdateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressResponse>> {
    return updateAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Update address.
   *
   * Update one address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAddress(params: UpdateAddress$Params, context?: HttpContext): Observable<AddressResponse> {
    return this.updateAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressResponse>): AddressResponse => r.body)
    );
  }

  /** Path part for operation `restoreAddress()` */
  static readonly RestoreAddressPath = '/addresses/{id}';

  /**
   * Restore address.
   *
   * Restore address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreAddress$Response(params: RestoreAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressResponse>> {
    return restoreAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore address.
   *
   * Restore address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreAddress(params: RestoreAddress$Params, context?: HttpContext): Observable<AddressResponse> {
    return this.restoreAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressResponse>): AddressResponse => r.body)
    );
  }

  /** Path part for operation `deleteAddress()` */
  static readonly DeleteAddressPath = '/addresses/{id}';

  /**
   * Delete address.
   *
   * Delete address (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddress$Response(params: DeleteAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete address.
   *
   * Delete address (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddress(params: DeleteAddress$Params, context?: HttpContext): Observable<void> {
    return this.deleteAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchAddress()` */
  static readonly PatchAddressPath = '/addresses/{id}';

  /**
   * Patch address.
   *
   * Patch one address (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAddress$Response(params: PatchAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressResponse>> {
    return patchAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch address.
   *
   * Patch one address (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAddress(params: PatchAddress$Params, context?: HttpContext): Observable<AddressResponse> {
    return this.patchAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressResponse>): AddressResponse => r.body)
    );
  }

  /** Path part for operation `fakeAddress()` */
  static readonly FakeAddressPath = '/addresses/faker';

  /**
   * Fake address.
   *
   * Generate fake address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeAddress$Response(params?: FakeAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressResponse>> {
    return fakeAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake address.
   *
   * Generate fake address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeAddress(params?: FakeAddress$Params, context?: HttpContext): Observable<AddressResponse> {
    return this.fakeAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressResponse>): AddressResponse => r.body)
    );
  }

}
