/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface GetElig$Params {
      body: {
'lineNumber'?: string | null;
'endpoint'?: string | null;
'buildingCode'?: string | null;
}
}

export function getElig(http: HttpClient, rootUrl: string, params: GetElig$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'status'?: boolean;
'message'?: string;
'data'?: {
'provider'?: {
'status'?: boolean;
'provider'?: string;
'size'?: number;
'list'?: Array<{
'id'?: string;
'name'?: string;
'engagement'?: number;
'status'?: number;
'conditions'?: string;
'techno'?: string;
'supplierRef'?: string;
'code'?: string;
'speed'?: number;
'zone'?: string;
'sla'?: string;
'slaCode'?: string;
'deliveryInfo'?: string;
'setup'?: number;
'recur'?: number;
'resil'?: number;
'sellSetup'?: number;
'sellRecur'?: number;
'sellResil'?: number;
}>;
'time'?: number;
};
};
'time'?: number;
'params'?: {
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'address'?: string;
'lineNumber'?: string;
'activationType'?: string;
'companyName'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'websocket'?: {
'room'?: string;
};
'ts'?: string;
'providers'?: Array<string>;
};
}>> {
  const rb = new RequestBuilder(rootUrl, getElig.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'status'?: boolean;
      'message'?: string;
      'data'?: {
      'provider'?: {
      'status'?: boolean;
      'provider'?: string;
      'size'?: number;
      'list'?: Array<{
      'id'?: string;
      'name'?: string;
      'engagement'?: number;
      'status'?: number;
      'conditions'?: string;
      'techno'?: string;
      'supplierRef'?: string;
      'code'?: string;
      'speed'?: number;
      'zone'?: string;
      'sla'?: string;
      'slaCode'?: string;
      'deliveryInfo'?: string;
      'setup'?: number;
      'recur'?: number;
      'resil'?: number;
      'sellSetup'?: number;
      'sellRecur'?: number;
      'sellResil'?: number;
      }>;
      'time'?: number;
      };
      };
      'time'?: number;
      'params'?: {
      'coordinates'?: {
      'longitude'?: number;
      'latitude'?: number;
      };
      'address'?: string;
      'lineNumber'?: string;
      'activationType'?: string;
      'companyName'?: string;
      'streetNr'?: string;
      'streetNrSuffix'?: string;
      'streetType'?: string;
      'inseeCode'?: string;
      'rivoliCode'?: string;
      'streetName'?: string;
      'postcode'?: string;
      'city'?: string;
      'websocket'?: {
      'room'?: string;
      };
      'ts'?: string;
      'providers'?: Array<string>;
      };
      }>;
    })
  );
}

getElig.PATH = '/getEligibility';
