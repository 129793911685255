import { Injectable, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as templates from '../../features/orders/pages/templates';

@Injectable()
export class OrderTemplateService {
  constructor(private injector: Injector) {}

  getTemplateInstance(type: string, model?: object, form?: UntypedFormGroup) {
    try {
      form ??= new UntypedFormGroup({});

      const newInjector = Injector.create({
        providers: [(<any>templates)[type + 'Template']],
        parent: this.injector,
      });

      const template = newInjector.get<any>(
        (<any>templates)[type + 'Template'],
      );

      Object.assign(template, {
        model,
        form,
      });

      return template;
    } catch (error) {
      console.error(error);
      throw new Error('Invalid template for ' + type);
    }
  }
}
