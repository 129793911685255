/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countUserRole } from '../fn/user-role/count-user-role';
import { CountUserRole$Params } from '../fn/user-role/count-user-role';
import { createUserRole } from '../fn/user-role/create-user-role';
import { CreateUserRole$Params } from '../fn/user-role/create-user-role';
import { createUserRoleUser } from '../fn/user-role/create-user-role-user';
import { CreateUserRoleUser$Params } from '../fn/user-role/create-user-role-user';
import { deleteUserRole } from '../fn/user-role/delete-user-role';
import { DeleteUserRole$Params } from '../fn/user-role/delete-user-role';
import { fakeUserRole } from '../fn/user-role/fake-user-role';
import { FakeUserRole$Params } from '../fn/user-role/fake-user-role';
import { listUserRole } from '../fn/user-role/list-user-role';
import { ListUserRole$Params } from '../fn/user-role/list-user-role';
import { patchUserRole } from '../fn/user-role/patch-user-role';
import { PatchUserRole$Params } from '../fn/user-role/patch-user-role';
import { patchUserRoleUser } from '../fn/user-role/patch-user-role-user';
import { PatchUserRoleUser$Params } from '../fn/user-role/patch-user-role-user';
import { restoreUserRole } from '../fn/user-role/restore-user-role';
import { RestoreUserRole$Params } from '../fn/user-role/restore-user-role';
import { showUserRole } from '../fn/user-role/show-user-role';
import { ShowUserRole$Params } from '../fn/user-role/show-user-role';
import { showUserRoleUser } from '../fn/user-role/show-user-role-user';
import { ShowUserRoleUser$Params } from '../fn/user-role/show-user-role-user';
import { UserResponse } from '../models/user-response';
import { UserRoleResponse } from '../models/user-role-response';

@Injectable({ providedIn: 'root' })
export class UserRoleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listUserRole()` */
  static readonly ListUserRolePath = '/userRoles';

  /**
   * List userRoles.
   *
   * List userRoles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUserRole$Response(params?: ListUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<UserRoleResponse>;
'total'?: number;
}>> {
    return listUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * List userRoles.
   *
   * List userRoles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUserRole(params?: ListUserRole$Params, context?: HttpContext): Observable<{
'data'?: Array<UserRoleResponse>;
'total'?: number;
}> {
    return this.listUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<UserRoleResponse>;
'total'?: number;
}>): {
'data'?: Array<UserRoleResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createUserRole()` */
  static readonly CreateUserRolePath = '/userRoles';

  /**
   * Create userRole.
   *
   * Create one userRole
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserRole$Response(params: CreateUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<UserRoleResponse>> {
    return createUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Create userRole.
   *
   * Create one userRole
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUserRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserRole(params: CreateUserRole$Params, context?: HttpContext): Observable<UserRoleResponse> {
    return this.createUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserRoleResponse>): UserRoleResponse => r.body)
    );
  }

  /** Path part for operation `countUserRole()` */
  static readonly CountUserRolePath = '/userRoles/count';

  /**
   * Count userRoles.
   *
   * Count userRoles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  countUserRole$Response(params?: CountUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Count userRoles.
   *
   * Count userRoles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countUserRole(params?: CountUserRole$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showUserRole()` */
  static readonly ShowUserRolePath = '/userRoles/{id}';

  /**
   * Show userRole.
   *
   * Show one userRole
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserRole$Response(params: ShowUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<UserRoleResponse>> {
    return showUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Show userRole.
   *
   * Show one userRole
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserRole(params: ShowUserRole$Params, context?: HttpContext): Observable<UserRoleResponse> {
    return this.showUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserRoleResponse>): UserRoleResponse => r.body)
    );
  }

  /** Path part for operation `restoreUserRole()` */
  static readonly RestoreUserRolePath = '/userRoles/{id}';

  /**
   * Restore userRole.
   *
   * Restore userRole
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreUserRole$Response(params: RestoreUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<UserRoleResponse>> {
    return restoreUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore userRole.
   *
   * Restore userRole
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreUserRole(params: RestoreUserRole$Params, context?: HttpContext): Observable<UserRoleResponse> {
    return this.restoreUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserRoleResponse>): UserRoleResponse => r.body)
    );
  }

  /** Path part for operation `deleteUserRole()` */
  static readonly DeleteUserRolePath = '/userRoles/{id}';

  /**
   * Delete userRole.
   *
   * Delete userRole (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserRole$Response(params: DeleteUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete userRole.
   *
   * Delete userRole (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserRole(params: DeleteUserRole$Params, context?: HttpContext): Observable<void> {
    return this.deleteUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchUserRole()` */
  static readonly PatchUserRolePath = '/userRoles/{id}';

  /**
   * Patch userRole.
   *
   * Patch one userRole (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserRole$Response(params: PatchUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<UserRoleResponse>> {
    return patchUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch userRole.
   *
   * Patch one userRole (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserRole(params: PatchUserRole$Params, context?: HttpContext): Observable<UserRoleResponse> {
    return this.patchUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserRoleResponse>): UserRoleResponse => r.body)
    );
  }

  /** Path part for operation `fakeUserRole()` */
  static readonly FakeUserRolePath = '/userRoles/faker';

  /**
   * Fake userRole.
   *
   * Generate fake userRole
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeUserRole$Response(params?: FakeUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<UserRoleResponse>> {
    return fakeUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake userRole.
   *
   * Generate fake userRole
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeUserRole(params?: FakeUserRole$Params, context?: HttpContext): Observable<UserRoleResponse> {
    return this.fakeUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserRoleResponse>): UserRoleResponse => r.body)
    );
  }

  /** Path part for operation `showUserRoleUser()` */
  static readonly ShowUserRoleUserPath = '/userRoles/{id}/user';

  /**
   * Show userRole user.
   *
   * Show userRole user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showUserRoleUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserRoleUser$Response(params: ShowUserRoleUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return showUserRoleUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Show userRole user.
   *
   * Show userRole user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showUserRoleUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserRoleUser(params: ShowUserRoleUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.showUserRoleUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `createUserRoleUser()` */
  static readonly CreateUserRoleUserPath = '/userRoles/{id}/user';

  /**
   * Create userRole user.
   *
   * Create userRole user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserRoleUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserRoleUser$Response(params: CreateUserRoleUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createUserRoleUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create userRole user.
   *
   * Create userRole user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUserRoleUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserRoleUser(params: CreateUserRoleUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createUserRoleUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchUserRoleUser()` */
  static readonly PatchUserRoleUserPath = '/userRoles/{id}/user';

  /**
   * Patch userRole user.
   *
   * Patch userRole user (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserRoleUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserRoleUser$Response(params: PatchUserRoleUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchUserRoleUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch userRole user.
   *
   * Patch userRole user (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserRoleUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserRoleUser(params: PatchUserRoleUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchUserRoleUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

}
