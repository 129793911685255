/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { GlobalService } from './services/global.service';
import { InternalService } from './services/internal.service';
import { AppointmentsService } from './services/appointments.service';
import { AuthenticationService } from './services/authentication.service';
import { BulkEligService } from './services/bulk-elig.service';
import { ByZenService } from './services/by-zen.service';
import { CatalogService } from './services/catalog.service';
import { CityService } from './services/city.service';
import { EligibilityService } from './services/eligibility.service';
import { GeoJsonService } from './services/geo-json.service';
import { OrderService } from './services/order.service';
import { ExternalService } from './services/external.service';
import { CommandsService } from './services/commands.service';
import { PriceRuleService } from './services/price-rule.service';
import { TenantService } from './services/tenant.service';
import { VcenterService } from './services/vcenter.service';
import { VoipService } from './services/voip.service';
import { VrbService } from './services/vrb.service';
import { YeastarService } from './services/yeastar.service';
import { AuditService } from './services/audit.service';
import { AddressService } from './services/address.service';
import { CompanyAddressService } from './services/company-address.service';
import { CompanyService } from './services/company.service';
import { RoadService } from './services/road.service';
import { UserService } from './services/user.service';
import { RoleService } from './services/role.service';
import { UserRoleService } from './services/user-role.service';
import { TagService } from './services/tag.service';
import { OfferService } from './services/offer.service';
import { CategoryService } from './services/category.service';
import { PlanService } from './services/plan.service';
import { OfferRewriteService } from './services/offer-rewrite.service';
import { OrderLinkService } from './services/order-link.service';
import { OrderLinkOptionService } from './services/order-link-option.service';
import { ProviderService } from './services/provider.service';
import { OrderStatusService } from './services/order-status.service';
import { RefreshTokenService } from './services/refresh-token.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    GlobalService,
    InternalService,
    AppointmentsService,
    AuthenticationService,
    BulkEligService,
    ByZenService,
    CatalogService,
    CityService,
    EligibilityService,
    GeoJsonService,
    OrderService,
    ExternalService,
    CommandsService,
    PriceRuleService,
    TenantService,
    VcenterService,
    VoipService,
    VrbService,
    YeastarService,
    AuditService,
    AddressService,
    CompanyAddressService,
    CompanyService,
    RoadService,
    UserService,
    RoleService,
    UserRoleService,
    TagService,
    OfferService,
    CategoryService,
    PlanService,
    OfferRewriteService,
    OrderLinkService,
    OrderLinkOptionService,
    ProviderService,
    OrderStatusService,
    RefreshTokenService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
