import { Injectable } from '@angular/core';
import { RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthGuard } from './core/guards/auth.guard';
import { ConfigResolver } from './core/resolvers/config.resolver';
import { NpsModule } from './nps/nps.module';

// https://angular.io/guide/router#setting-the-page-title
@Injectable({ providedIn: 'root' })
export class DynamicTitleStrategy extends TitleStrategy {
  tenantName = 'Backend';

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      if (title !== 'nps') {
        this.title.setTitle(`${title} | ${this.tenantName}`);
      } else {
        this.title.setTitle(`${title}`);
      }
    }
  }
}

export const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./features/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'nps',
    loadChildren: () => import('./nps/nps.module').then((m) => m.NpsModule),
    title: 'Campagne NPS',
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/layout/layout.module').then((m) => m.LayoutModule),
    resolve: {
      config: ConfigResolver,
    },
  },
];
