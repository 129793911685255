/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CompanyResponse } from '../models/company-response';
import { countTenant } from '../fn/tenant/count-tenant';
import { CountTenant$Params } from '../fn/tenant/count-tenant';
import { createTenant } from '../fn/tenant/create-tenant';
import { CreateTenant$Params } from '../fn/tenant/create-tenant';
import { createTenantContact } from '../fn/tenant/create-tenant-contact';
import { CreateTenantContact$Params } from '../fn/tenant/create-tenant-contact';
import { createTenantUsers } from '../fn/tenant/create-tenant-users';
import { CreateTenantUsers$Params } from '../fn/tenant/create-tenant-users';
import { listTenant } from '../fn/tenant/list-tenant';
import { ListTenant$Params } from '../fn/tenant/list-tenant';
import { patchTenant } from '../fn/tenant/patch-tenant';
import { PatchTenant$Params } from '../fn/tenant/patch-tenant';
import { patchTenantContact } from '../fn/tenant/patch-tenant-contact';
import { PatchTenantContact$Params } from '../fn/tenant/patch-tenant-contact';
import { patchTenantUsers } from '../fn/tenant/patch-tenant-users';
import { PatchTenantUsers$Params } from '../fn/tenant/patch-tenant-users';
import { showTenant } from '../fn/tenant/show-tenant';
import { ShowTenant$Params } from '../fn/tenant/show-tenant';
import { showTenantCompanies } from '../fn/tenant/show-tenant-companies';
import { ShowTenantCompanies$Params } from '../fn/tenant/show-tenant-companies';
import { showTenantCompany } from '../fn/tenant/show-tenant-company';
import { ShowTenantCompany$Params } from '../fn/tenant/show-tenant-company';
import { showTenantContact } from '../fn/tenant/show-tenant-contact';
import { ShowTenantContact$Params } from '../fn/tenant/show-tenant-contact';
import { showTenantUsers } from '../fn/tenant/show-tenant-users';
import { ShowTenantUsers$Params } from '../fn/tenant/show-tenant-users';
import { TenantResponse } from '../models/tenant-response';
import { uploadImages } from '../fn/tenant/upload-images';
import { UploadImages$Params } from '../fn/tenant/upload-images';
import { UserResponse } from '../models/user-response';


/**
 * Tenant resource
 */
@Injectable({ providedIn: 'root' })
export class TenantService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadImages()` */
  static readonly UploadImagesPath = '/tenants/upload/images';

  /**
   * Upload images.
   *
   * Upload instance images
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadImages()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadImages$Response(params: UploadImages$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'faviconUrl'?: string;
'logoUrl'?: string;
}>> {
    return uploadImages(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload images.
   *
   * Upload instance images
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadImages$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadImages(params: UploadImages$Params, context?: HttpContext): Observable<{
'faviconUrl'?: string;
'logoUrl'?: string;
}> {
    return this.uploadImages$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'faviconUrl'?: string;
'logoUrl'?: string;
}>): {
'faviconUrl'?: string;
'logoUrl'?: string;
} => r.body)
    );
  }

  /** Path part for operation `listTenant()` */
  static readonly ListTenantPath = '/tenants';

  /**
   * List tenants.
   *
   * List tenants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTenant$Response(params?: ListTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<TenantResponse>;
'total'?: number;
}>> {
    return listTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * List tenants.
   *
   * List tenants
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTenant(params?: ListTenant$Params, context?: HttpContext): Observable<{
'data'?: Array<TenantResponse>;
'total'?: number;
}> {
    return this.listTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<TenantResponse>;
'total'?: number;
}>): {
'data'?: Array<TenantResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createTenant()` */
  static readonly CreateTenantPath = '/tenants';

  /**
   * Create tenant.
   *
   * Create one tenant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant$Response(params: CreateTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantResponse>> {
    return createTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Create tenant.
   *
   * Create one tenant
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant(params: CreateTenant$Params, context?: HttpContext): Observable<TenantResponse> {
    return this.createTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantResponse>): TenantResponse => r.body)
    );
  }

  /** Path part for operation `countTenant()` */
  static readonly CountTenantPath = '/tenants/count';

  /**
   * Count tenants.
   *
   * Count tenants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  countTenant$Response(params?: CountTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Count tenants.
   *
   * Count tenants
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countTenant(params?: CountTenant$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showTenant()` */
  static readonly ShowTenantPath = '/tenants/{id}';

  /**
   * Show tenant.
   *
   * Show one tenant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenant$Response(params: ShowTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantResponse>> {
    return showTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Show tenant.
   *
   * Show one tenant
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenant(params: ShowTenant$Params, context?: HttpContext): Observable<TenantResponse> {
    return this.showTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantResponse>): TenantResponse => r.body)
    );
  }

  /** Path part for operation `patchTenant()` */
  static readonly PatchTenantPath = '/tenants/{id}';

  /**
   * Patch tenant.
   *
   * Patch one tenant (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTenant$Response(params: PatchTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantResponse>> {
    return patchTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch tenant.
   *
   * Patch one tenant (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTenant(params: PatchTenant$Params, context?: HttpContext): Observable<TenantResponse> {
    return this.patchTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantResponse>): TenantResponse => r.body)
    );
  }

  /** Path part for operation `showTenantCompany()` */
  static readonly ShowTenantCompanyPath = '/tenants/{id}/company';

  /**
   * Show tenant company.
   *
   * Show tenant company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showTenantCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenantCompany$Response(params: ShowTenantCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return showTenantCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Show tenant company.
   *
   * Show tenant company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showTenantCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenantCompany(params: ShowTenantCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.showTenantCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `showTenantContact()` */
  static readonly ShowTenantContactPath = '/tenants/{id}/contact';

  /**
   * Show tenant contact.
   *
   * Show tenant contact
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showTenantContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenantContact$Response(params: ShowTenantContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return showTenantContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Show tenant contact.
   *
   * Show tenant contact
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showTenantContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenantContact(params: ShowTenantContact$Params, context?: HttpContext): Observable<UserResponse> {
    return this.showTenantContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `createTenantContact()` */
  static readonly CreateTenantContactPath = '/tenants/{id}/contact';

  /**
   * Create tenant contact.
   *
   * Create tenant contact
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenantContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenantContact$Response(params: CreateTenantContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createTenantContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Create tenant contact.
   *
   * Create tenant contact
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTenantContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenantContact(params: CreateTenantContact$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createTenantContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchTenantContact()` */
  static readonly PatchTenantContactPath = '/tenants/{id}/contact';

  /**
   * Patch tenant contact.
   *
   * Patch tenant contact (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTenantContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTenantContact$Response(params: PatchTenantContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchTenantContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch tenant contact.
   *
   * Patch tenant contact (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTenantContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTenantContact(params: PatchTenantContact$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchTenantContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `showTenantCompanies()` */
  static readonly ShowTenantCompaniesPath = '/tenants/{id}/companies';

  /**
   * Show tenant companies.
   *
   * Show tenant companies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showTenantCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenantCompanies$Response(params: ShowTenantCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<CompanyResponse>;
}>> {
    return showTenantCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * Show tenant companies.
   *
   * Show tenant companies
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showTenantCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenantCompanies(params: ShowTenantCompanies$Params, context?: HttpContext): Observable<{
'data'?: Array<CompanyResponse>;
}> {
    return this.showTenantCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<CompanyResponse>;
}>): {
'data'?: Array<CompanyResponse>;
} => r.body)
    );
  }

  /** Path part for operation `showTenantUsers()` */
  static readonly ShowTenantUsersPath = '/tenants/{id}/users';

  /**
   * Show tenant users.
   *
   * Show tenant users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showTenantUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenantUsers$Response(params: ShowTenantUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<UserResponse>;
}>> {
    return showTenantUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Show tenant users.
   *
   * Show tenant users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showTenantUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showTenantUsers(params: ShowTenantUsers$Params, context?: HttpContext): Observable<{
'data'?: Array<UserResponse>;
}> {
    return this.showTenantUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<UserResponse>;
}>): {
'data'?: Array<UserResponse>;
} => r.body)
    );
  }

  /** Path part for operation `createTenantUsers()` */
  static readonly CreateTenantUsersPath = '/tenants/{id}/users';

  /**
   * Create tenant users.
   *
   * Create tenant users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenantUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenantUsers$Response(params: CreateTenantUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createTenantUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Create tenant users.
   *
   * Create tenant users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTenantUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenantUsers(params: CreateTenantUsers$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createTenantUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchTenantUsers()` */
  static readonly PatchTenantUsersPath = '/tenants/{id}/users';

  /**
   * Patch tenant users.
   *
   * Patch tenant users (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTenantUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTenantUsers$Response(params: PatchTenantUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchTenantUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch tenant users.
   *
   * Patch tenant users (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTenantUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTenantUsers(params: PatchTenantUsers$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchTenantUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

}
