/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BulkEligResponse } from '../models/bulk-elig-response';
import { CompanyResponse } from '../models/company-response';
import { countBulkElig } from '../fn/bulk-elig/count-bulk-elig';
import { CountBulkElig$Params } from '../fn/bulk-elig/count-bulk-elig';
import { createBulkElig } from '../fn/bulk-elig/create-bulk-elig';
import { CreateBulkElig$Params } from '../fn/bulk-elig/create-bulk-elig';
import { createBulkEligUser } from '../fn/bulk-elig/create-bulk-elig-user';
import { CreateBulkEligUser$Params } from '../fn/bulk-elig/create-bulk-elig-user';
import { deleteBulkElig } from '../fn/bulk-elig/delete-bulk-elig';
import { DeleteBulkElig$Params } from '../fn/bulk-elig/delete-bulk-elig';
import { downloadBulk } from '../fn/bulk-elig/download-bulk';
import { DownloadBulk$Params } from '../fn/bulk-elig/download-bulk';
import { downloadBulkTemplate } from '../fn/bulk-elig/download-bulk-template';
import { DownloadBulkTemplate$Params } from '../fn/bulk-elig/download-bulk-template';
import { executeBulk } from '../fn/bulk-elig/execute-bulk';
import { ExecuteBulk$Params } from '../fn/bulk-elig/execute-bulk';
import { fakeBulkElig } from '../fn/bulk-elig/fake-bulk-elig';
import { FakeBulkElig$Params } from '../fn/bulk-elig/fake-bulk-elig';
import { listBulkElig } from '../fn/bulk-elig/list-bulk-elig';
import { ListBulkElig$Params } from '../fn/bulk-elig/list-bulk-elig';
import { patchBulkElig } from '../fn/bulk-elig/patch-bulk-elig';
import { PatchBulkElig$Params } from '../fn/bulk-elig/patch-bulk-elig';
import { patchBulkEligUser } from '../fn/bulk-elig/patch-bulk-elig-user';
import { PatchBulkEligUser$Params } from '../fn/bulk-elig/patch-bulk-elig-user';
import { restoreBulkElig } from '../fn/bulk-elig/restore-bulk-elig';
import { RestoreBulkElig$Params } from '../fn/bulk-elig/restore-bulk-elig';
import { showBulkElig } from '../fn/bulk-elig/show-bulk-elig';
import { ShowBulkElig$Params } from '../fn/bulk-elig/show-bulk-elig';
import { showBulkEligCompany } from '../fn/bulk-elig/show-bulk-elig-company';
import { ShowBulkEligCompany$Params } from '../fn/bulk-elig/show-bulk-elig-company';
import { showBulkEligUser } from '../fn/bulk-elig/show-bulk-elig-user';
import { ShowBulkEligUser$Params } from '../fn/bulk-elig/show-bulk-elig-user';
import { uploadBulk } from '../fn/bulk-elig/upload-bulk';
import { UploadBulk$Params } from '../fn/bulk-elig/upload-bulk';
import { UserResponse } from '../models/user-response';


/**
 * Bulk eligibility
 */
@Injectable({ providedIn: 'root' })
export class BulkEligService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadBulk()` */
  static readonly UploadBulkPath = '/bulkElig/upload';

  /**
   * Upload excel bulk.
   *
   * Upload excel bulk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadBulk()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadBulk$Response(params: UploadBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'fileUrl'?: string;
}>> {
    return uploadBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload excel bulk.
   *
   * Upload excel bulk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadBulk$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadBulk(params: UploadBulk$Params, context?: HttpContext): Observable<{
'fileUrl'?: string;
}> {
    return this.uploadBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'fileUrl'?: string;
}>): {
'fileUrl'?: string;
} => r.body)
    );
  }

  /** Path part for operation `downloadBulk()` */
  static readonly DownloadBulkPath = '/bulkElig/download';

  /**
   * Download excel bulk.
   *
   * Download excel bulk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadBulk()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadBulk$Response(params: DownloadBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return downloadBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * Download excel bulk.
   *
   * Download excel bulk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadBulk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadBulk(params: DownloadBulk$Params, context?: HttpContext): Observable<{
}> {
    return this.downloadBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `executeBulk()` */
  static readonly ExecuteBulkPath = '/bulkElig/execute';

  /**
   * Execute excel bulk.
   *
   * execute excel bulk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeBulk()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeBulk$Response(params: ExecuteBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'id'?: string;
'status'?: string;
}>> {
    return executeBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * Execute excel bulk.
   *
   * execute excel bulk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeBulk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  executeBulk(params: ExecuteBulk$Params, context?: HttpContext): Observable<{
'id'?: string;
'status'?: string;
}> {
    return this.executeBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'id'?: string;
'status'?: string;
}>): {
'id'?: string;
'status'?: string;
} => r.body)
    );
  }

  /** Path part for operation `downloadBulkTemplate()` */
  static readonly DownloadBulkTemplatePath = '/bulkElig/download/template';

  /**
   * Download template excel bulk.
   *
   * Download template excel bulk
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadBulkTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadBulkTemplate$Response(params: DownloadBulkTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return downloadBulkTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Download template excel bulk.
   *
   * Download template excel bulk
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadBulkTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadBulkTemplate(params: DownloadBulkTemplate$Params, context?: HttpContext): Observable<{
}> {
    return this.downloadBulkTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `listBulkElig()` */
  static readonly ListBulkEligPath = '/bulkElig';

  /**
   * List bulkElig.
   *
   * List bulkElig
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listBulkElig()` instead.
   *
   * This method doesn't expect any request body.
   */
  listBulkElig$Response(params?: ListBulkElig$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<BulkEligResponse>;
'total'?: number;
}>> {
    return listBulkElig(this.http, this.rootUrl, params, context);
  }

  /**
   * List bulkElig.
   *
   * List bulkElig
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listBulkElig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listBulkElig(params?: ListBulkElig$Params, context?: HttpContext): Observable<{
'data'?: Array<BulkEligResponse>;
'total'?: number;
}> {
    return this.listBulkElig$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<BulkEligResponse>;
'total'?: number;
}>): {
'data'?: Array<BulkEligResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createBulkElig()` */
  static readonly CreateBulkEligPath = '/bulkElig';

  /**
   * Create bulkElig.
   *
   * Create one bulkElig
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBulkElig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBulkElig$Response(params: CreateBulkElig$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkEligResponse>> {
    return createBulkElig(this.http, this.rootUrl, params, context);
  }

  /**
   * Create bulkElig.
   *
   * Create one bulkElig
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBulkElig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBulkElig(params: CreateBulkElig$Params, context?: HttpContext): Observable<BulkEligResponse> {
    return this.createBulkElig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkEligResponse>): BulkEligResponse => r.body)
    );
  }

  /** Path part for operation `countBulkElig()` */
  static readonly CountBulkEligPath = '/bulkElig/count';

  /**
   * Count bulkElig.
   *
   * Count bulkElig
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countBulkElig()` instead.
   *
   * This method doesn't expect any request body.
   */
  countBulkElig$Response(params?: CountBulkElig$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countBulkElig(this.http, this.rootUrl, params, context);
  }

  /**
   * Count bulkElig.
   *
   * Count bulkElig
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countBulkElig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countBulkElig(params?: CountBulkElig$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countBulkElig$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showBulkElig()` */
  static readonly ShowBulkEligPath = '/bulkElig/{id}';

  /**
   * Show bulkElig.
   *
   * Show one bulkElig
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showBulkElig()` instead.
   *
   * This method doesn't expect any request body.
   */
  showBulkElig$Response(params: ShowBulkElig$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkEligResponse>> {
    return showBulkElig(this.http, this.rootUrl, params, context);
  }

  /**
   * Show bulkElig.
   *
   * Show one bulkElig
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showBulkElig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showBulkElig(params: ShowBulkElig$Params, context?: HttpContext): Observable<BulkEligResponse> {
    return this.showBulkElig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkEligResponse>): BulkEligResponse => r.body)
    );
  }

  /** Path part for operation `restoreBulkElig()` */
  static readonly RestoreBulkEligPath = '/bulkElig/{id}';

  /**
   * Restore bulkElig.
   *
   * Restore bulkElig
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreBulkElig()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreBulkElig$Response(params: RestoreBulkElig$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkEligResponse>> {
    return restoreBulkElig(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore bulkElig.
   *
   * Restore bulkElig
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreBulkElig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreBulkElig(params: RestoreBulkElig$Params, context?: HttpContext): Observable<BulkEligResponse> {
    return this.restoreBulkElig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkEligResponse>): BulkEligResponse => r.body)
    );
  }

  /** Path part for operation `deleteBulkElig()` */
  static readonly DeleteBulkEligPath = '/bulkElig/{id}';

  /**
   * Delete bulkElig.
   *
   * Delete bulkElig (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBulkElig()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBulkElig$Response(params: DeleteBulkElig$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteBulkElig(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete bulkElig.
   *
   * Delete bulkElig (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBulkElig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBulkElig(params: DeleteBulkElig$Params, context?: HttpContext): Observable<void> {
    return this.deleteBulkElig$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchBulkElig()` */
  static readonly PatchBulkEligPath = '/bulkElig/{id}';

  /**
   * Patch bulkElig.
   *
   * Patch one bulkElig (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBulkElig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBulkElig$Response(params: PatchBulkElig$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkEligResponse>> {
    return patchBulkElig(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch bulkElig.
   *
   * Patch one bulkElig (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBulkElig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBulkElig(params: PatchBulkElig$Params, context?: HttpContext): Observable<BulkEligResponse> {
    return this.patchBulkElig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkEligResponse>): BulkEligResponse => r.body)
    );
  }

  /** Path part for operation `fakeBulkElig()` */
  static readonly FakeBulkEligPath = '/bulkElig/faker';

  /**
   * Fake bulkElig.
   *
   * Generate fake bulkElig
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeBulkElig()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeBulkElig$Response(params?: FakeBulkElig$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkEligResponse>> {
    return fakeBulkElig(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake bulkElig.
   *
   * Generate fake bulkElig
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeBulkElig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeBulkElig(params?: FakeBulkElig$Params, context?: HttpContext): Observable<BulkEligResponse> {
    return this.fakeBulkElig$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkEligResponse>): BulkEligResponse => r.body)
    );
  }

  /** Path part for operation `showBulkEligUser()` */
  static readonly ShowBulkEligUserPath = '/bulkElig/{id}/user';

  /**
   * Show bulkElig user.
   *
   * Show bulkElig user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showBulkEligUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  showBulkEligUser$Response(params: ShowBulkEligUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return showBulkEligUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Show bulkElig user.
   *
   * Show bulkElig user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showBulkEligUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showBulkEligUser(params: ShowBulkEligUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.showBulkEligUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `createBulkEligUser()` */
  static readonly CreateBulkEligUserPath = '/bulkElig/{id}/user';

  /**
   * Create bulkElig user.
   *
   * Create bulkElig user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBulkEligUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBulkEligUser$Response(params: CreateBulkEligUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createBulkEligUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create bulkElig user.
   *
   * Create bulkElig user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBulkEligUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBulkEligUser(params: CreateBulkEligUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createBulkEligUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchBulkEligUser()` */
  static readonly PatchBulkEligUserPath = '/bulkElig/{id}/user';

  /**
   * Patch bulkElig user.
   *
   * Patch bulkElig user (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBulkEligUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBulkEligUser$Response(params: PatchBulkEligUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchBulkEligUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch bulkElig user.
   *
   * Patch bulkElig user (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBulkEligUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBulkEligUser(params: PatchBulkEligUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchBulkEligUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `showBulkEligCompany()` */
  static readonly ShowBulkEligCompanyPath = '/bulkElig/{id}/company';

  /**
   * Show bulkElig company.
   *
   * Show bulkElig company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showBulkEligCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  showBulkEligCompany$Response(params: ShowBulkEligCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return showBulkEligCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Show bulkElig company.
   *
   * Show bulkElig company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showBulkEligCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showBulkEligCompany(params: ShowBulkEligCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.showBulkEligCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

}
