import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-form-template',
  templateUrl: './modal-table-template.component.html',
})
export class ModalTableTemplateComponent {
  @Input() model: string = '';
  @Input() id?: string;
  @Input() related?: string;
  @Input() action: string = 'list';
  @Input() title?: string;
  color = 'primary';

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
  ) {
    router.events.subscribe(() => {
      activeModal.close();
    });
  }
}
