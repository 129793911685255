/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BaseOrder } from '../models/base-order';
import { cancelOrder } from '../fn/order/cancel-order';
import { CancelOrder$Params } from '../fn/order/cancel-order';
import { confirmOrder } from '../fn/order/confirm-order';
import { ConfirmOrder$Params } from '../fn/order/confirm-order';
import { initiateOrder } from '../fn/order/initiate-order';
import { InitiateOrder$Params } from '../fn/order/initiate-order';
import { linkShow } from '../fn/order/link-show';
import { LinkShow$Params } from '../fn/order/link-show';
import { listOrder } from '../fn/order/list-order';
import { ListOrder$Params } from '../fn/order/list-order';
import { listOrderPost } from '../fn/order/list-order-post';
import { ListOrderPost$Params } from '../fn/order/list-order-post';
import { listOrderUnitedway } from '../fn/order/list-order-unitedway';
import { ListOrderUnitedway$Params } from '../fn/order/list-order-unitedway';
import { orderSync } from '../fn/order/order-sync';
import { OrderSync$Params } from '../fn/order/order-sync';
import { patchOrder } from '../fn/order/patch-order';
import { PatchOrder$Params } from '../fn/order/patch-order';
import { productSearch } from '../fn/order/product-search';
import { ProductSearch$Params } from '../fn/order/product-search';
import { showOrder } from '../fn/order/show-order';
import { ShowOrder$Params } from '../fn/order/show-order';
import { showOrderUnitedway } from '../fn/order/show-order-unitedway';
import { ShowOrderUnitedway$Params } from '../fn/order/show-order-unitedway';
import { taoTesting } from '../fn/order/tao-testing';
import { TaoTesting$Params } from '../fn/order/tao-testing';
import { unitedwayTesting } from '../fn/order/unitedway-testing';
import { UnitedwayTesting$Params } from '../fn/order/unitedway-testing';
import { validOrder } from '../fn/order/valid-order';
import { ValidOrder$Params } from '../fn/order/valid-order';


/**
 * Orders API
 */
@Injectable({ providedIn: 'root' })
export class OrderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `initiateOrder()` */
  static readonly InitiateOrderPath = '/order/initiate';

  /**
   * initiate order.
   *
   * order init.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initiateOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initiateOrder$Response(params: InitiateOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return initiateOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * initiate order.
   *
   * order init.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `initiateOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initiateOrder(params: InitiateOrder$Params, context?: HttpContext): Observable<{
}> {
    return this.initiateOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `confirmOrder()` */
  static readonly ConfirmOrderPath = '/order/confirm';

  /**
   * Confirm order.
   *
   * Confirm order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmOrder$Response(params: ConfirmOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return confirmOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Confirm order.
   *
   * Confirm order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmOrder(params: ConfirmOrder$Params, context?: HttpContext): Observable<{
}> {
    return this.confirmOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `validOrder()` */
  static readonly ValidOrderPath = '/order/valid';

  /**
   * Valid order.
   *
   * order validation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validOrder$Response(params: ValidOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return validOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Valid order.
   *
   * order validation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validOrder(params: ValidOrder$Params, context?: HttpContext): Observable<{
}> {
    return this.validOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `patchOrder()` */
  static readonly PatchOrderPath = '/order/update';

  /**
   * Patch Order.
   *
   * order updating.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrder$Response(params: PatchOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return patchOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch Order.
   *
   * order updating.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrder(params: PatchOrder$Params, context?: HttpContext): Observable<{
}> {
    return this.patchOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `showOrder()` */
  static readonly ShowOrderPath = '/order/show';

  /**
   * Show order.
   *
   * Show order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrder$Response(params: ShowOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: {
'id'?: string;
'code'?: string;
'name'?: string;
'label'?: string;
'description'?: string;
'reference'?: string;
'v1Id'?: number;
'type'?: string;
'status'?: string;
'ndi'?: string;
'enabled'?: boolean;
'startsAt'?: string;
'endsAt'?: string;
'vlan'?: string;
'meta'?: {
};
'createdAt'?: string;
'updatedAt'?: string;
'deletedAt'?: string;
'provider'?: {
'name'?: string;
};
'category'?: {
'name'?: string;
'typeSlug'?: string;
};
'building'?: {
'code'?: string;
'name'?: string;
'stairs'?: string;
'floor'?: string;
};
'company'?: {
'id'?: string;
'name'?: string;
'v1Id'?: number;
'siret'?: string;
'tenant'?: {
'name'?: string;
};
};
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
'product'?: {
'name'?: string;
'label'?: string;
'type'?: string;
'techno'?: string;
};
'address'?: {
'houseNumber'?: string;
'roadType'?: string;
'road'?: string;
'postcode'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'city'?: string;
'hexacle'?: string;
};
'contact'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'phone'?: string;
};
'appointment'?: {
'name'?: string;
'tel'?: string;
'site'?: string;
'code'?: string;
'startDate'?: string;
'endDate'?: string;
};
'options'?: Array<{
'name'?: string;
'code'?: string;
}>;
'comments'?: Array<{
'name'?: string;
'content'?: string;
'createdAt'?: string;
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
}>;
'realm'?: string;
};
}>> {
    return showOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * Show order.
   *
   * Show order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrder(params: ShowOrder$Params, context?: HttpContext): Observable<{
'data'?: {
'id'?: string;
'code'?: string;
'name'?: string;
'label'?: string;
'description'?: string;
'reference'?: string;
'v1Id'?: number;
'type'?: string;
'status'?: string;
'ndi'?: string;
'enabled'?: boolean;
'startsAt'?: string;
'endsAt'?: string;
'vlan'?: string;
'meta'?: {
};
'createdAt'?: string;
'updatedAt'?: string;
'deletedAt'?: string;
'provider'?: {
'name'?: string;
};
'category'?: {
'name'?: string;
'typeSlug'?: string;
};
'building'?: {
'code'?: string;
'name'?: string;
'stairs'?: string;
'floor'?: string;
};
'company'?: {
'id'?: string;
'name'?: string;
'v1Id'?: number;
'siret'?: string;
'tenant'?: {
'name'?: string;
};
};
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
'product'?: {
'name'?: string;
'label'?: string;
'type'?: string;
'techno'?: string;
};
'address'?: {
'houseNumber'?: string;
'roadType'?: string;
'road'?: string;
'postcode'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'city'?: string;
'hexacle'?: string;
};
'contact'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'phone'?: string;
};
'appointment'?: {
'name'?: string;
'tel'?: string;
'site'?: string;
'code'?: string;
'startDate'?: string;
'endDate'?: string;
};
'options'?: Array<{
'name'?: string;
'code'?: string;
}>;
'comments'?: Array<{
'name'?: string;
'content'?: string;
'createdAt'?: string;
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
}>;
'realm'?: string;
};
}> {
    return this.showOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: {
'id'?: string;
'code'?: string;
'name'?: string;
'label'?: string;
'description'?: string;
'reference'?: string;
'v1Id'?: number;
'type'?: string;
'status'?: string;
'ndi'?: string;
'enabled'?: boolean;
'startsAt'?: string;
'endsAt'?: string;
'vlan'?: string;
'meta'?: {
};
'createdAt'?: string;
'updatedAt'?: string;
'deletedAt'?: string;
'provider'?: {
'name'?: string;
};
'category'?: {
'name'?: string;
'typeSlug'?: string;
};
'building'?: {
'code'?: string;
'name'?: string;
'stairs'?: string;
'floor'?: string;
};
'company'?: {
'id'?: string;
'name'?: string;
'v1Id'?: number;
'siret'?: string;
'tenant'?: {
'name'?: string;
};
};
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
'product'?: {
'name'?: string;
'label'?: string;
'type'?: string;
'techno'?: string;
};
'address'?: {
'houseNumber'?: string;
'roadType'?: string;
'road'?: string;
'postcode'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'city'?: string;
'hexacle'?: string;
};
'contact'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'phone'?: string;
};
'appointment'?: {
'name'?: string;
'tel'?: string;
'site'?: string;
'code'?: string;
'startDate'?: string;
'endDate'?: string;
};
'options'?: Array<{
'name'?: string;
'code'?: string;
}>;
'comments'?: Array<{
'name'?: string;
'content'?: string;
'createdAt'?: string;
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
}>;
'realm'?: string;
};
}>): {
'data'?: {
'id'?: string;
'code'?: string;
'name'?: string;
'label'?: string;
'description'?: string;
'reference'?: string;
'v1Id'?: number;
'type'?: string;
'status'?: string;
'ndi'?: string;
'enabled'?: boolean;
'startsAt'?: string;
'endsAt'?: string;
'vlan'?: string;
'meta'?: {
};
'createdAt'?: string;
'updatedAt'?: string;
'deletedAt'?: string;
'provider'?: {
'name'?: string;
};
'category'?: {
'name'?: string;
'typeSlug'?: string;
};
'building'?: {
'code'?: string;
'name'?: string;
'stairs'?: string;
'floor'?: string;
};
'company'?: {
'id'?: string;
'name'?: string;
'v1Id'?: number;
'siret'?: string;
'tenant'?: {
'name'?: string;
};
};
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
'product'?: {
'name'?: string;
'label'?: string;
'type'?: string;
'techno'?: string;
};
'address'?: {
'houseNumber'?: string;
'roadType'?: string;
'road'?: string;
'postcode'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'city'?: string;
'hexacle'?: string;
};
'contact'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'phone'?: string;
};
'appointment'?: {
'name'?: string;
'tel'?: string;
'site'?: string;
'code'?: string;
'startDate'?: string;
'endDate'?: string;
};
'options'?: Array<{
'name'?: string;
'code'?: string;
}>;
'comments'?: Array<{
'name'?: string;
'content'?: string;
'createdAt'?: string;
'user'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
'v1Id'?: number;
};
}>;
'realm'?: string;
};
} => r.body)
    );
  }

  /** Path part for operation `listOrder()` */
  static readonly ListOrderPath = '/orders/list';

  /**
   * List orders.
   *
   * List orders
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrder$Response(params?: ListOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<BaseOrder>;
'total'?: number;
}>> {
    return listOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * List orders.
   *
   * List orders
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrder(params?: ListOrder$Params, context?: HttpContext): Observable<{
'data'?: Array<BaseOrder>;
'total'?: number;
}> {
    return this.listOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<BaseOrder>;
'total'?: number;
}>): {
'data'?: Array<BaseOrder>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `listOrderPost()` */
  static readonly ListOrderPostPath = '/order/list';

  /**
   * List order POST.
   *
   * list order POST
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrderPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listOrderPost$Response(params: ListOrderPost$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<{
'id'?: string;
'v1Id'?: string;
'type'?: string;
'code'?: string;
'name'?: string;
'label'?: string;
'description'?: string;
'reference'?: string;
'providerRef'?: string;
'status'?: string;
'ndi'?: string;
'vlan'?: string;
'enabled'?: boolean;
'startsAt'?: string;
'endsAt'?: string;
'meta'?: {
};
'addressId'?: string;
'buildingId'?: string;
'contactId'?: string;
'productId'?: string;
'providerId'?: string;
'categoryId'?: string;
'companyId'?: string;
'userId'?: string;
'createdAt'?: string;
'updatedAt'?: string;
'deletedAt'?: string;
'realm'?: string;
}>;
'total'?: number;
}>> {
    return listOrderPost(this.http, this.rootUrl, params, context);
  }

  /**
   * List order POST.
   *
   * list order POST
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrderPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listOrderPost(params: ListOrderPost$Params, context?: HttpContext): Observable<{
'data'?: Array<{
'id'?: string;
'v1Id'?: string;
'type'?: string;
'code'?: string;
'name'?: string;
'label'?: string;
'description'?: string;
'reference'?: string;
'providerRef'?: string;
'status'?: string;
'ndi'?: string;
'vlan'?: string;
'enabled'?: boolean;
'startsAt'?: string;
'endsAt'?: string;
'meta'?: {
};
'addressId'?: string;
'buildingId'?: string;
'contactId'?: string;
'productId'?: string;
'providerId'?: string;
'categoryId'?: string;
'companyId'?: string;
'userId'?: string;
'createdAt'?: string;
'updatedAt'?: string;
'deletedAt'?: string;
'realm'?: string;
}>;
'total'?: number;
}> {
    return this.listOrderPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<{
'id'?: string;
'v1Id'?: string;
'type'?: string;
'code'?: string;
'name'?: string;
'label'?: string;
'description'?: string;
'reference'?: string;
'providerRef'?: string;
'status'?: string;
'ndi'?: string;
'vlan'?: string;
'enabled'?: boolean;
'startsAt'?: string;
'endsAt'?: string;
'meta'?: {
};
'addressId'?: string;
'buildingId'?: string;
'contactId'?: string;
'productId'?: string;
'providerId'?: string;
'categoryId'?: string;
'companyId'?: string;
'userId'?: string;
'createdAt'?: string;
'updatedAt'?: string;
'deletedAt'?: string;
'realm'?: string;
}>;
'total'?: number;
}>): {
'data'?: Array<{
'id'?: string;
'v1Id'?: string;
'type'?: string;
'code'?: string;
'name'?: string;
'label'?: string;
'description'?: string;
'reference'?: string;
'providerRef'?: string;
'status'?: string;
'ndi'?: string;
'vlan'?: string;
'enabled'?: boolean;
'startsAt'?: string;
'endsAt'?: string;
'meta'?: {
};
'addressId'?: string;
'buildingId'?: string;
'contactId'?: string;
'productId'?: string;
'providerId'?: string;
'categoryId'?: string;
'companyId'?: string;
'userId'?: string;
'createdAt'?: string;
'updatedAt'?: string;
'deletedAt'?: string;
'realm'?: string;
}>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `cancelOrder()` */
  static readonly CancelOrderPath = '/order/cancel';

  /**
   * cancel order.
   *
   * cancel order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelOrder$Response(params: CancelOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return cancelOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * cancel order.
   *
   * cancel order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelOrder(params: CancelOrder$Params, context?: HttpContext): Observable<{
}> {
    return this.cancelOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `orderSync()` */
  static readonly OrderSyncPath = '/order/sync';

  /**
   * Synchronize commands.
   *
   * Synchronize commands
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderSync()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderSync$Response(params: OrderSync$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return orderSync(this.http, this.rootUrl, params, context);
  }

  /**
   * Synchronize commands.
   *
   * Synchronize commands
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderSync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orderSync(params: OrderSync$Params, context?: HttpContext): Observable<{
}> {
    return this.orderSync$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `linkShow()` */
  static readonly LinkShowPath = '/link/show';

  /**
   * Show link.
   *
   * Show link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkShow$Response(params: LinkShow$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return linkShow(this.http, this.rootUrl, params, context);
  }

  /**
   * Show link.
   *
   * Show link
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  linkShow(params: LinkShow$Params, context?: HttpContext): Observable<{
}> {
    return this.linkShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `productSearch()` */
  static readonly ProductSearchPath = '/product/search';

  /**
   * search products.
   *
   * search products
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  productSearch$Response(params: ProductSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return productSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * search products.
   *
   * search products
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productSearch(params: ProductSearch$Params, context?: HttpContext): Observable<{
}> {
    return this.productSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `unitedwayTesting()` */
  static readonly UnitedwayTestingPath = '/testing/unitedway';

  /**
   * Testing.
   *
   * Testing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unitedwayTesting()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitedwayTesting$Response(params?: UnitedwayTesting$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return unitedwayTesting(this.http, this.rootUrl, params, context);
  }

  /**
   * Testing.
   *
   * Testing
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unitedwayTesting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unitedwayTesting(params?: UnitedwayTesting$Params, context?: HttpContext): Observable<{
}> {
    return this.unitedwayTesting$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `taoTesting()` */
  static readonly TaoTestingPath = '/tao/testing';

  /**
   * Testing.
   *
   * Testing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taoTesting()` instead.
   *
   * This method doesn't expect any request body.
   */
  taoTesting$Response(params?: TaoTesting$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return taoTesting(this.http, this.rootUrl, params, context);
  }

  /**
   * Testing.
   *
   * Testing
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taoTesting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taoTesting(params?: TaoTesting$Params, context?: HttpContext): Observable<{
}> {
    return this.taoTesting$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `listOrderUnitedway()` */
  static readonly ListOrderUnitedwayPath = '/order/unitedway';

  /**
   * List order unitedway.
   *
   * List order unitedway
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrderUnitedway()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrderUnitedway$Response(params?: ListOrderUnitedway$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return listOrderUnitedway(this.http, this.rootUrl, params, context);
  }

  /**
   * List order unitedway.
   *
   * List order unitedway
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrderUnitedway$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrderUnitedway(params?: ListOrderUnitedway$Params, context?: HttpContext): Observable<{
}> {
    return this.listOrderUnitedway$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `showOrderUnitedway()` */
  static readonly ShowOrderUnitedwayPath = '/order/unitedway/{id}';

  /**
   * Show order unitedway.
   *
   * Show order unitedway
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderUnitedway()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderUnitedway$Response(params: ShowOrderUnitedway$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return showOrderUnitedway(this.http, this.rootUrl, params, context);
  }

  /**
   * Show order unitedway.
   *
   * Show order unitedway
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderUnitedway$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderUnitedway(params: ShowOrderUnitedway$Params, context?: HttpContext): Observable<{
}> {
    return this.showOrderUnitedway$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
