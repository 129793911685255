import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GlobalService } from 'src/api/services';

@Injectable({
  providedIn: 'root',
})
export class ConfigResolver {
  constructor(private service: GlobalService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): ReturnType<GlobalService['globalConfig']> {
    return this.service.globalConfig();
  }
}
