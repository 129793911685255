import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import CryptoJS from 'crypto-js';
import { AuthenticationService } from '../../../api/services/authentication.service';
import { LoginParams } from '../../../api/models/login-params';
import { TokenStorageService } from './token-storage.service';
import { LocalUserService } from './local-user.service';
import { SignalInfoService } from './signal-info.service';
import { WebSocketService } from './web-socket.service';
import { UserRoleService } from 'src/api/services';
import {
  LoginPayload,
  RecoverParams,
  RefreshParams,
  ResetParams,
  User,
} from 'src/api/models';
@Injectable()
/**
 * Allows to manage user auth
 */
export class AuthService {
  constructor(
    private authenticationService: AuthenticationService,
    private tokenStorage: TokenStorageService,
    private localUserService: LocalUserService,
    private routes: Router,
    private signalError: SignalInfoService,
    private webSocketService: WebSocketService,
    private userRoleService: UserRoleService,
  ) {}

  /**
   * Observable which is trying to login the user
   * @param loginParams
   * @returns
   */
  login(loginParams: LoginParams, after2FA = false): Observable<User> {
    this.localUserService.disconnectUser();
    return new Observable<User>((subscriber) => {
      this.authenticationService.login$Json({ body: loginParams }).subscribe(
        (resp: LoginPayload) => {
          const { token, refreshToken } = resp;

          if (!token || !refreshToken) {
            console.log('Missing token or refresh token', resp);
            subscriber.error({
              message: 'Token or Refresh Token missing',
            });
          }
          const key = 'R7AHWVALUBLYI3GX4VDL5VSRTRCOVJ4U';
          this.tokenStorage.setToken(token);
          this.tokenStorage.setRefreshToken(refreshToken!);
          if (!after2FA) {
            this.localUserService.has2FA().subscribe((resp) => {
              const { status } = resp.body;
              if (status === true) {
                const encrypted = CryptoJS.AES.encrypt(
                  JSON.stringify({
                    valid: true,
                    token: token,
                    refreshToken: refreshToken,
                    user: loginParams,
                  }),
                  key,
                ).toString();
                this.routes.navigate(['/account/2FA'], {
                  queryParams: { hash: encrypted },
                });
              } else {
                this.generate().subscribe((resp) => {
                  const { secret, uri, qr } = resp.body;
                  const encrypted = CryptoJS.AES.encrypt(
                    JSON.stringify({
                      valid: false,
                      token: token,
                      refreshToken: refreshToken,
                      user: loginParams,
                      secret: secret,
                      uri: uri,
                      qr: qr,
                    }),
                    key,
                  ).toString();
                  this.routes.navigate(['/account/2FA'], {
                    queryParams: { hash: encrypted },
                  });
                });
              }
            });
          } else {
            this.localUserService
              .checkAndGetUser()
              .then((user: User) => {
                this.routes.navigate(['/']);
                subscriber.next(user);
                subscriber.complete();
              })
              .catch((error) => {
                subscriber.error(error);
              });
          }
        },
        (error: HttpErrorResponse) => {
          subscriber.error(error);
        },
      );
    });
  }

  /**
   * Logout the user and redirect to login page
   */
  logout() {
    this.signalError.setError('AUTH.DISCONNECTED');
    const refreshToken = this.tokenStorage.getRefreshToken() as string;

    this.authenticationService.revoke({ refreshToken }).subscribe({
      next: () => {
        console.log('refreshToken Revoked');
        this.finalizeLogout();
      },
      error: (error) => {
        console.log(error);
        this.finalizeLogout();
      },
    });
  }

  /**
   * Delete the tokens and user local storage and redirect to login page
   */
  private finalizeLogout(): void {
    this.tokenStorage.deleteTokens();
    this.localUserService.disconnectUser();
    this.webSocketService.disconnect();
    this.routes.navigate(['/account/login']);
  }

  /**
   * Observable that is trying to get a new token form a refreshToken
   * @param refreshToken
   * @param userId
   * @returns
   */
  refreshToken(refreshToken: string, userId: string): Observable<LoginPayload> {
    if (!(userId || refreshToken)) {
      return throwError('UserId or Refreshtoken Invalid');
    }

    const refreshParam = {
      refreshToken,
      userId: userId.toString(),
    } as RefreshParams;

    return this.authenticationService.refresh$Json({ body: refreshParam });
  }

  /**
   * Observable which tries to send an email to recover the user's password
   * @param email
   * @returns
   */
  recover(email: string) {
    const recoverParam: RecoverParams = {
      email,
    };
    return this.authenticationService.recover$Json({ body: recoverParam });
  }

  /**
   * Observable which tries to reset the user's password
   * @param token
   * @param password
   * @param confirmPassword
   * @returns
   */
  reset(token: string, password: string, confirmPassword: string) {
    const resetParams: ResetParams = {
      recoverToken: token,
      password,
      confirmPassword,
    };
    return this.authenticationService.reset$Json({ body: resetParams });
  }

  /**
   * Observable which generates QR code for 2FA
   * @returns
   */
  generate(): Observable<{
    body: {
      secret?: string | undefined;
      uri?: string | undefined;
      qr?: string | undefined;
    };
  }> {
    return new Observable<{
      body: {
        secret?: string | undefined;
        uri?: string | undefined;
        qr?: string | undefined;
      };
    }>((subscriber) => {
      this.authenticationService.otpGenerate$Response().subscribe({
        next: (resp: any) => {
          subscriber.next(resp);
          subscriber.complete();
        },
        error: (error: any) => {
          subscriber.error(error);
        },
      });
    });
  }

  /**
   * Observable which validates the 2FA code
   * @param code
   * @returns
   */
  validate(code: string): Observable<{
    body: {
      valid: boolean;
    };
  }> {
    return new Observable<{ body: { valid: boolean } }>((subscriber) => {
      this.authenticationService.otpVerify$Response({ token: code }).subscribe({
        next: (resp: any) => {
          subscriber.next(resp);
          subscriber.complete();
        },
        error: (error: any) => {
          subscriber.error(error);
        },
      });
    });
  }
}
