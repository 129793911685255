/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { appointments } from '../fn/appointments/appointments';
import { Appointments$Params } from '../fn/appointments/appointments';

@Injectable({ providedIn: 'root' })
export class AppointmentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `appointments()` */
  static readonly AppointmentsPath = '/appointment/search';

  /**
   * search appointments.
   *
   * technician appointment search. required operator.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appointments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointments$Response(params: Appointments$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'appointments'?: {
'provider'?: string;
'slots'?: Array<{
'startDate'?: string;
'endDate'?: string;
'slotId'?: string;
}>;
};
}>> {
    return appointments(this.http, this.rootUrl, params, context);
  }

  /**
   * search appointments.
   *
   * technician appointment search. required operator.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appointments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  appointments(params: Appointments$Params, context?: HttpContext): Observable<{
'appointments'?: {
'provider'?: string;
'slots'?: Array<{
'startDate'?: string;
'endDate'?: string;
'slotId'?: string;
}>;
};
}> {
    return this.appointments$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'appointments'?: {
'provider'?: string;
'slots'?: Array<{
'startDate'?: string;
'endDate'?: string;
'slotId'?: string;
}>;
};
}>): {
'appointments'?: {
'provider'?: string;
'slots'?: Array<{
'startDate'?: string;
'endDate'?: string;
'slotId'?: string;
}>;
};
} => r.body)
    );
  }

}
