import {
  DEFAULT_CURRENCY_CODE,
  Inject,
  LOCALE_ID,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
  name: 'currencyTax',
})
export class CurrencyTaxPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD',
  ) {}

  transform(
    value: number,
    currencyCode = this._defaultCurrencyCode,
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
    digitsInfo = '1.2-2',
    locale = this._locale,
    withTax = false,
  ): string | null {
    if (typeof value !== 'number') {
      return null;
    }
    if (!withTax) {
      return (
        formatCurrency(
          value,
          locale,
          getCurrencySymbol(currencyCode, 'wide'),
          currencyCode,
          digitsInfo,
        ) + '<sup>HT</sup>'
      );
    }
    return formatCurrency(
      value,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    );
  }
}
