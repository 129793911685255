/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CategoryResponse } from '../models/category-response';
import { getCatalogCategories } from '../fn/catalog/get-catalog-categories';
import { GetCatalogCategories$Params } from '../fn/catalog/get-catalog-categories';
import { getCatalogOffer } from '../fn/catalog/get-catalog-offer';
import { GetCatalogOffer$Params } from '../fn/catalog/get-catalog-offer';
import { listCatalogOffers } from '../fn/catalog/list-catalog-offers';
import { ListCatalogOffers$Params } from '../fn/catalog/list-catalog-offers';
import { OfferResponse } from '../models/offer-response';
import { patchCatalogOffers } from '../fn/catalog/patch-catalog-offers';
import { PatchCatalogOffers$Params } from '../fn/catalog/patch-catalog-offers';
import { showCatalogOffers } from '../fn/catalog/show-catalog-offers';
import { ShowCatalogOffers$Params } from '../fn/catalog/show-catalog-offers';
import { showCatalogOffersOptions } from '../fn/catalog/show-catalog-offers-options';
import { ShowCatalogOffersOptions$Params } from '../fn/catalog/show-catalog-offers-options';
import { staticPrices } from '../fn/catalog/static-prices';
import { StaticPrices$Params } from '../fn/catalog/static-prices';

@Injectable({ providedIn: 'root' })
export class CatalogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCatalogOffer()` */
  static readonly GetCatalogOfferPath = '/catalog/prices';

  /**
   * Get offer prices from V1.
   *
   * Get offer from V1.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCatalogOffer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalogOffer$Response(params: GetCatalogOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getCatalogOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get offer prices from V1.
   *
   * Get offer from V1.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCatalogOffer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalogOffer(params: GetCatalogOffer$Params, context?: HttpContext): Observable<{
}> {
    return this.getCatalogOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `staticPrices()` */
  static readonly StaticPricesPath = '/catalog/static';

  /**
   * Apply static prices.
   *
   * Apply static prices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `staticPrices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staticPrices$Response(params: StaticPrices$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OfferResponse>;
'total'?: number;
}>> {
    return staticPrices(this.http, this.rootUrl, params, context);
  }

  /**
   * Apply static prices.
   *
   * Apply static prices.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `staticPrices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  staticPrices(params: StaticPrices$Params, context?: HttpContext): Observable<{
'data'?: Array<OfferResponse>;
'total'?: number;
}> {
    return this.staticPrices$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OfferResponse>;
'total'?: number;
}>): {
'data'?: Array<OfferResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `listCatalogOffers()` */
  static readonly ListCatalogOffersPath = '/catalog/offers';

  /**
   * List catalog offers.
   *
   * List all offers with prices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCatalogOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCatalogOffers$Response(params?: ListCatalogOffers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OfferResponse>;
'total'?: number;
}>> {
    return listCatalogOffers(this.http, this.rootUrl, params, context);
  }

  /**
   * List catalog offers.
   *
   * List all offers with prices.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listCatalogOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCatalogOffers(params?: ListCatalogOffers$Params, context?: HttpContext): Observable<{
'data'?: Array<OfferResponse>;
'total'?: number;
}> {
    return this.listCatalogOffers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OfferResponse>;
'total'?: number;
}>): {
'data'?: Array<OfferResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showCatalogOffers()` */
  static readonly ShowCatalogOffersPath = '/catalog/offers/{id}';

  /**
   * show catalog offers.
   *
   * List an offers with prices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCatalogOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCatalogOffers$Response(params: ShowCatalogOffers$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return showCatalogOffers(this.http, this.rootUrl, params, context);
  }

  /**
   * show catalog offers.
   *
   * List an offers with prices.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCatalogOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCatalogOffers(params: ShowCatalogOffers$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.showCatalogOffers$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `patchCatalogOffers()` */
  static readonly PatchCatalogOffersPath = '/catalog/offers/{id}';

  /**
   * patch offer.
   *
   * patch offer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCatalogOffers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCatalogOffers$Response(params: PatchCatalogOffers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OfferResponse>;
'total'?: number;
}>> {
    return patchCatalogOffers(this.http, this.rootUrl, params, context);
  }

  /**
   * patch offer.
   *
   * patch offer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCatalogOffers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCatalogOffers(params: PatchCatalogOffers$Params, context?: HttpContext): Observable<{
'data'?: Array<OfferResponse>;
'total'?: number;
}> {
    return this.patchCatalogOffers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OfferResponse>;
'total'?: number;
}>): {
'data'?: Array<OfferResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showCatalogOffersOptions()` */
  static readonly ShowCatalogOffersOptionsPath = '/catalog/offers/{id}/options';

  /**
   * show catalog offer options.
   *
   * List options with prices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCatalogOffersOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCatalogOffersOptions$Response(params: ShowCatalogOffersOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return showCatalogOffersOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * show catalog offer options.
   *
   * List options with prices.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCatalogOffersOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCatalogOffersOptions(params: ShowCatalogOffersOptions$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.showCatalogOffersOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `getCatalogCategories()` */
  static readonly GetCatalogCategoriesPath = '/catalog/categories';

  /**
   * Get offer categories.
   *
   * Get offer categories.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCatalogCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalogCategories$Response(params?: GetCatalogCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryResponse>> {
    return getCatalogCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * Get offer categories.
   *
   * Get offer categories.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCatalogCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalogCategories(params?: GetCatalogCategories$Params, context?: HttpContext): Observable<CategoryResponse> {
    return this.getCatalogCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryResponse>): CategoryResponse => r.body)
    );
  }

}
