import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParameterCodec,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UrlEncodeInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const params = new HttpParams({
      encoder: new CustomEncoder(),
      fromString: req.params.toString(),
    });
    return next.handle(req.clone({ params }));
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    let newKey = encodeURIComponent(key);
    //Enocde url parenthesis
    newKey = newKey.replace('(', '%28');
    newKey = newKey.replace(')', '%29');
    return newKey;
  }

  encodeValue(value: string): string {
    let newValue = encodeURIComponent(value);
    //Enocde url parenthesis
    newValue = newValue.replace('(', '%28');
    newValue = newValue.replace(')', '%29');
    return newValue;
  }

  decodeKey(key: string): string {
    let decodeKey = decodeURIComponent(key);
    //Decode url parenthesis
    decodeKey = decodeKey.replace(/%28/g, '(');
    decodeKey = decodeKey.replace(/%29/g, ')');
    return decodeKey;
  }

  decodeValue(value: string): string {
    let decodeValue = decodeURIComponent(value);
    //Decode url parenthesis
    decodeValue = decodeValue.replace(/%28/g, '(');
    decodeValue = decodeValue.replace(/%29/g, ')');
    return decodeValue;
  }
}
