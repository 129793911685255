/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Offer } from '../../models/offer';
import { OfferResponse } from '../../models/offer-response';

export interface UpdateOffer$Params {

/**
 * ID of the model
 */
  id: string;
  
    /**
     * Offer attributes
     */
    body: Offer
}

export function updateOffer(http: HttpClient, rootUrl: string, params: UpdateOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
  const rb = new RequestBuilder(rootUrl, updateOffer.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OfferResponse>;
    })
  );
}

updateOffer.PATH = '/offers/{id}';
