/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countProvider } from '../fn/provider/count-provider';
import { CountProvider$Params } from '../fn/provider/count-provider';
import { createProvider } from '../fn/provider/create-provider';
import { CreateProvider$Params } from '../fn/provider/create-provider';
import { deleteProvider } from '../fn/provider/delete-provider';
import { DeleteProvider$Params } from '../fn/provider/delete-provider';
import { fakeProvider } from '../fn/provider/fake-provider';
import { FakeProvider$Params } from '../fn/provider/fake-provider';
import { listProvider } from '../fn/provider/list-provider';
import { ListProvider$Params } from '../fn/provider/list-provider';
import { patchProvider } from '../fn/provider/patch-provider';
import { PatchProvider$Params } from '../fn/provider/patch-provider';
import { ProviderResponse } from '../models/provider-response';
import { restoreProvider } from '../fn/provider/restore-provider';
import { RestoreProvider$Params } from '../fn/provider/restore-provider';
import { showProvider } from '../fn/provider/show-provider';
import { ShowProvider$Params } from '../fn/provider/show-provider';

@Injectable({ providedIn: 'root' })
export class ProviderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listProvider()` */
  static readonly ListProviderPath = '/providers';

  /**
   * List providers.
   *
   * List providers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProvider$Response(params?: ListProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<ProviderResponse>;
'total'?: number;
}>> {
    return listProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * List providers.
   *
   * List providers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProvider(params?: ListProvider$Params, context?: HttpContext): Observable<{
'data'?: Array<ProviderResponse>;
'total'?: number;
}> {
    return this.listProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<ProviderResponse>;
'total'?: number;
}>): {
'data'?: Array<ProviderResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createProvider()` */
  static readonly CreateProviderPath = '/providers';

  /**
   * Create provider.
   *
   * Create one provider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProvider()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProvider$Response(params: CreateProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<ProviderResponse>> {
    return createProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * Create provider.
   *
   * Create one provider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createProvider$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProvider(params: CreateProvider$Params, context?: HttpContext): Observable<ProviderResponse> {
    return this.createProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProviderResponse>): ProviderResponse => r.body)
    );
  }

  /** Path part for operation `countProvider()` */
  static readonly CountProviderPath = '/providers/count';

  /**
   * Count providers.
   *
   * Count providers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  countProvider$Response(params?: CountProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * Count providers.
   *
   * Count providers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countProvider(params?: CountProvider$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showProvider()` */
  static readonly ShowProviderPath = '/providers/{id}';

  /**
   * Show provider.
   *
   * Show one provider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  showProvider$Response(params: ShowProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<ProviderResponse>> {
    return showProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * Show provider.
   *
   * Show one provider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showProvider(params: ShowProvider$Params, context?: HttpContext): Observable<ProviderResponse> {
    return this.showProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProviderResponse>): ProviderResponse => r.body)
    );
  }

  /** Path part for operation `restoreProvider()` */
  static readonly RestoreProviderPath = '/providers/{id}';

  /**
   * Restore provider.
   *
   * Restore provider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreProvider$Response(params: RestoreProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<ProviderResponse>> {
    return restoreProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore provider.
   *
   * Restore provider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreProvider(params: RestoreProvider$Params, context?: HttpContext): Observable<ProviderResponse> {
    return this.restoreProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProviderResponse>): ProviderResponse => r.body)
    );
  }

  /** Path part for operation `deleteProvider()` */
  static readonly DeleteProviderPath = '/providers/{id}';

  /**
   * Delete provider.
   *
   * Delete provider (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProvider$Response(params: DeleteProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete provider.
   *
   * Delete provider (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProvider(params: DeleteProvider$Params, context?: HttpContext): Observable<void> {
    return this.deleteProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchProvider()` */
  static readonly PatchProviderPath = '/providers/{id}';

  /**
   * Patch provider.
   *
   * Patch one provider (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchProvider()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchProvider$Response(params: PatchProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<ProviderResponse>> {
    return patchProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch provider.
   *
   * Patch one provider (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchProvider$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchProvider(params: PatchProvider$Params, context?: HttpContext): Observable<ProviderResponse> {
    return this.patchProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProviderResponse>): ProviderResponse => r.body)
    );
  }

  /** Path part for operation `fakeProvider()` */
  static readonly FakeProviderPath = '/providers/faker';

  /**
   * Fake provider.
   *
   * Generate fake provider
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeProvider$Response(params?: FakeProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<ProviderResponse>> {
    return fakeProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake provider.
   *
   * Generate fake provider
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeProvider(params?: FakeProvider$Params, context?: HttpContext): Observable<ProviderResponse> {
    return this.fakeProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProviderResponse>): ProviderResponse => r.body)
    );
  }

}
