/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface GetCatalogOffer$Params {

/**
 * Company ID V1
 */
  companyId: number;

/**
 * Offer code
 */
  code: string;

/**
 * Offer supplier reference
 */
  supplierRef: string;

/**
 * Offer is an option
 */
  isOption: boolean;
}

export function getCatalogOffer(http: HttpClient, rootUrl: string, params: GetCatalogOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, getCatalogOffer.PATH, 'get');
  if (params) {
    rb.query('companyId', params.companyId, {});
    rb.query('code', params.code, {});
    rb.query('supplierRef', params.supplierRef, {});
    rb.query('isOption', params.isOption, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

getCatalogOffer.PATH = '/catalog/prices';
