/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressResponse } from '../models/address-response';
import { CategoryResponse } from '../models/category-response';
import { CompanyResponse } from '../models/company-response';
import { countOrderLink } from '../fn/order-link/count-order-link';
import { CountOrderLink$Params } from '../fn/order-link/count-order-link';
import { createOrderLink } from '../fn/order-link/create-order-link';
import { CreateOrderLink$Params } from '../fn/order-link/create-order-link';
import { createOrderLinkContact } from '../fn/order-link/create-order-link-contact';
import { CreateOrderLinkContact$Params } from '../fn/order-link/create-order-link-contact';
import { createOrderLinkUser } from '../fn/order-link/create-order-link-user';
import { CreateOrderLinkUser$Params } from '../fn/order-link/create-order-link-user';
import { deleteOrderLink } from '../fn/order-link/delete-order-link';
import { DeleteOrderLink$Params } from '../fn/order-link/delete-order-link';
import { fakeOrderLink } from '../fn/order-link/fake-order-link';
import { FakeOrderLink$Params } from '../fn/order-link/fake-order-link';
import { listOrderLink } from '../fn/order-link/list-order-link';
import { ListOrderLink$Params } from '../fn/order-link/list-order-link';
import { OrderLinkResponse } from '../models/order-link-response';
import { patchOrderLink } from '../fn/order-link/patch-order-link';
import { PatchOrderLink$Params } from '../fn/order-link/patch-order-link';
import { patchOrderLinkContact } from '../fn/order-link/patch-order-link-contact';
import { PatchOrderLinkContact$Params } from '../fn/order-link/patch-order-link-contact';
import { patchOrderLinkUser } from '../fn/order-link/patch-order-link-user';
import { PatchOrderLinkUser$Params } from '../fn/order-link/patch-order-link-user';
import { restoreOrderLink } from '../fn/order-link/restore-order-link';
import { RestoreOrderLink$Params } from '../fn/order-link/restore-order-link';
import { showOrderLink } from '../fn/order-link/show-order-link';
import { ShowOrderLink$Params } from '../fn/order-link/show-order-link';
import { showOrderLinkAddress } from '../fn/order-link/show-order-link-address';
import { ShowOrderLinkAddress$Params } from '../fn/order-link/show-order-link-address';
import { showOrderLinkCategory } from '../fn/order-link/show-order-link-category';
import { ShowOrderLinkCategory$Params } from '../fn/order-link/show-order-link-category';
import { showOrderLinkCompany } from '../fn/order-link/show-order-link-company';
import { ShowOrderLinkCompany$Params } from '../fn/order-link/show-order-link-company';
import { showOrderLinkContact } from '../fn/order-link/show-order-link-contact';
import { ShowOrderLinkContact$Params } from '../fn/order-link/show-order-link-contact';
import { showOrderLinkUser } from '../fn/order-link/show-order-link-user';
import { ShowOrderLinkUser$Params } from '../fn/order-link/show-order-link-user';
import { UserResponse } from '../models/user-response';


/**
 * Connectivity orders
 */
@Injectable({ providedIn: 'root' })
export class OrderLinkService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listOrderLink()` */
  static readonly ListOrderLinkPath = '/links';

  /**
   * List links.
   *
   * List links
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrderLink$Response(params?: ListOrderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OrderLinkResponse>;
'total'?: number;
}>> {
    return listOrderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * List links.
   *
   * List links
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrderLink(params?: ListOrderLink$Params, context?: HttpContext): Observable<{
'data'?: Array<OrderLinkResponse>;
'total'?: number;
}> {
    return this.listOrderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OrderLinkResponse>;
'total'?: number;
}>): {
'data'?: Array<OrderLinkResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createOrderLink()` */
  static readonly CreateOrderLinkPath = '/links';

  /**
   * Create orderLink.
   *
   * Create one orderLink
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrderLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLink$Response(params: CreateOrderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkResponse>> {
    return createOrderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Create orderLink.
   *
   * Create one orderLink
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrderLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLink(params: CreateOrderLink$Params, context?: HttpContext): Observable<OrderLinkResponse> {
    return this.createOrderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkResponse>): OrderLinkResponse => r.body)
    );
  }

  /** Path part for operation `countOrderLink()` */
  static readonly CountOrderLinkPath = '/links/count';

  /**
   * Count links.
   *
   * Count links
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countOrderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOrderLink$Response(params?: CountOrderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countOrderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Count links.
   *
   * Count links
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countOrderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOrderLink(params?: CountOrderLink$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countOrderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showOrderLink()` */
  static readonly ShowOrderLinkPath = '/links/{id}';

  /**
   * Show orderLink.
   *
   * Show one orderLink
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLink$Response(params: ShowOrderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkResponse>> {
    return showOrderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLink.
   *
   * Show one orderLink
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLink(params: ShowOrderLink$Params, context?: HttpContext): Observable<OrderLinkResponse> {
    return this.showOrderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkResponse>): OrderLinkResponse => r.body)
    );
  }

  /** Path part for operation `restoreOrderLink()` */
  static readonly RestoreOrderLinkPath = '/links/{id}';

  /**
   * Restore orderLink.
   *
   * Restore orderLink
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreOrderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOrderLink$Response(params: RestoreOrderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkResponse>> {
    return restoreOrderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore orderLink.
   *
   * Restore orderLink
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreOrderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOrderLink(params: RestoreOrderLink$Params, context?: HttpContext): Observable<OrderLinkResponse> {
    return this.restoreOrderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkResponse>): OrderLinkResponse => r.body)
    );
  }

  /** Path part for operation `deleteOrderLink()` */
  static readonly DeleteOrderLinkPath = '/links/{id}';

  /**
   * Delete orderLink.
   *
   * Delete orderLink (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderLink$Response(params: DeleteOrderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOrderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete orderLink.
   *
   * Delete orderLink (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOrderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderLink(params: DeleteOrderLink$Params, context?: HttpContext): Observable<void> {
    return this.deleteOrderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchOrderLink()` */
  static readonly PatchOrderLinkPath = '/links/{id}';

  /**
   * Patch orderLink.
   *
   * Patch one orderLink (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrderLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLink$Response(params: PatchOrderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkResponse>> {
    return patchOrderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch orderLink.
   *
   * Patch one orderLink (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrderLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLink(params: PatchOrderLink$Params, context?: HttpContext): Observable<OrderLinkResponse> {
    return this.patchOrderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkResponse>): OrderLinkResponse => r.body)
    );
  }

  /** Path part for operation `fakeOrderLink()` */
  static readonly FakeOrderLinkPath = '/links/faker';

  /**
   * Fake orderLink.
   *
   * Generate fake orderLink
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeOrderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOrderLink$Response(params?: FakeOrderLink$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkResponse>> {
    return fakeOrderLink(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake orderLink.
   *
   * Generate fake orderLink
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeOrderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOrderLink(params?: FakeOrderLink$Params, context?: HttpContext): Observable<OrderLinkResponse> {
    return this.fakeOrderLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkResponse>): OrderLinkResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkAddress()` */
  static readonly ShowOrderLinkAddressPath = '/links/{id}/address';

  /**
   * Show orderLink address.
   *
   * Show orderLink address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkAddress$Response(params: ShowOrderLinkAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressResponse>> {
    return showOrderLinkAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLink address.
   *
   * Show orderLink address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkAddress(params: ShowOrderLinkAddress$Params, context?: HttpContext): Observable<AddressResponse> {
    return this.showOrderLinkAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressResponse>): AddressResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkContact()` */
  static readonly ShowOrderLinkContactPath = '/links/{id}/contact';

  /**
   * Show orderLink contact.
   *
   * Show orderLink contact
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkContact$Response(params: ShowOrderLinkContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return showOrderLinkContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLink contact.
   *
   * Show orderLink contact
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkContact(params: ShowOrderLinkContact$Params, context?: HttpContext): Observable<UserResponse> {
    return this.showOrderLinkContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `createOrderLinkContact()` */
  static readonly CreateOrderLinkContactPath = '/links/{id}/contact';

  /**
   * Create orderLink contact.
   *
   * Create orderLink contact
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrderLinkContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkContact$Response(params: CreateOrderLinkContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createOrderLinkContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Create orderLink contact.
   *
   * Create orderLink contact
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrderLinkContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkContact(params: CreateOrderLinkContact$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createOrderLinkContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchOrderLinkContact()` */
  static readonly PatchOrderLinkContactPath = '/links/{id}/contact';

  /**
   * Patch orderLink contact.
   *
   * Patch orderLink contact (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrderLinkContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkContact$Response(params: PatchOrderLinkContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchOrderLinkContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch orderLink contact.
   *
   * Patch orderLink contact (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrderLinkContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkContact(params: PatchOrderLinkContact$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchOrderLinkContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkCompany()` */
  static readonly ShowOrderLinkCompanyPath = '/links/{id}/company';

  /**
   * Show orderLink company.
   *
   * Show orderLink company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkCompany$Response(params: ShowOrderLinkCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return showOrderLinkCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLink company.
   *
   * Show orderLink company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkCompany(params: ShowOrderLinkCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.showOrderLinkCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkUser()` */
  static readonly ShowOrderLinkUserPath = '/links/{id}/user';

  /**
   * Show orderLink user.
   *
   * Show orderLink user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkUser$Response(params: ShowOrderLinkUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return showOrderLinkUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLink user.
   *
   * Show orderLink user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkUser(params: ShowOrderLinkUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.showOrderLinkUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `createOrderLinkUser()` */
  static readonly CreateOrderLinkUserPath = '/links/{id}/user';

  /**
   * Create orderLink user.
   *
   * Create orderLink user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrderLinkUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkUser$Response(params: CreateOrderLinkUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createOrderLinkUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create orderLink user.
   *
   * Create orderLink user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrderLinkUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkUser(params: CreateOrderLinkUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createOrderLinkUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchOrderLinkUser()` */
  static readonly PatchOrderLinkUserPath = '/links/{id}/user';

  /**
   * Patch orderLink user.
   *
   * Patch orderLink user (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrderLinkUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkUser$Response(params: PatchOrderLinkUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchOrderLinkUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch orderLink user.
   *
   * Patch orderLink user (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrderLinkUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkUser(params: PatchOrderLinkUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchOrderLinkUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkCategory()` */
  static readonly ShowOrderLinkCategoryPath = '/links/{id}/category';

  /**
   * Show orderLink category.
   *
   * Show orderLink category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkCategory$Response(params: ShowOrderLinkCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryResponse>> {
    return showOrderLinkCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLink category.
   *
   * Show orderLink category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkCategory(params: ShowOrderLinkCategory$Params, context?: HttpContext): Observable<CategoryResponse> {
    return this.showOrderLinkCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryResponse>): CategoryResponse => r.body)
    );
  }

}
