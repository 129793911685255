/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CategoryResponse } from '../models/category-response';
import { countOffer } from '../fn/offer/count-offer';
import { CountOffer$Params } from '../fn/offer/count-offer';
import { createOffer } from '../fn/offer/create-offer';
import { CreateOffer$Params } from '../fn/offer/create-offer';
import { createOfferOptions } from '../fn/offer/create-offer-options';
import { CreateOfferOptions$Params } from '../fn/offer/create-offer-options';
import { deleteOffer } from '../fn/offer/delete-offer';
import { DeleteOffer$Params } from '../fn/offer/delete-offer';
import { fakeOffer } from '../fn/offer/fake-offer';
import { FakeOffer$Params } from '../fn/offer/fake-offer';
import { listOffer } from '../fn/offer/list-offer';
import { ListOffer$Params } from '../fn/offer/list-offer';
import { OfferResponse } from '../models/offer-response';
import { patchOffer } from '../fn/offer/patch-offer';
import { PatchOffer$Params } from '../fn/offer/patch-offer';
import { restoreOffer } from '../fn/offer/restore-offer';
import { RestoreOffer$Params } from '../fn/offer/restore-offer';
import { showOffer } from '../fn/offer/show-offer';
import { ShowOffer$Params } from '../fn/offer/show-offer';
import { showOfferCategory } from '../fn/offer/show-offer-category';
import { ShowOfferCategory$Params } from '../fn/offer/show-offer-category';
import { showOfferOptions } from '../fn/offer/show-offer-options';
import { ShowOfferOptions$Params } from '../fn/offer/show-offer-options';
import { updateOffer } from '../fn/offer/update-offer';
import { UpdateOffer$Params } from '../fn/offer/update-offer';

@Injectable({ providedIn: 'root' })
export class OfferService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listOffer()` */
  static readonly ListOfferPath = '/offers';

  /**
   * List offers.
   *
   * List offers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOffer()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOffer$Response(params?: ListOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OfferResponse>;
'total'?: number;
}>> {
    return listOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * List offers.
   *
   * List offers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOffer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOffer(params?: ListOffer$Params, context?: HttpContext): Observable<{
'data'?: Array<OfferResponse>;
'total'?: number;
}> {
    return this.listOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OfferResponse>;
'total'?: number;
}>): {
'data'?: Array<OfferResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createOffer()` */
  static readonly CreateOfferPath = '/offers';

  /**
   * Create offer.
   *
   * Create one offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOffer$Response(params: CreateOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return createOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Create offer.
   *
   * Create one offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOffer(params: CreateOffer$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.createOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `countOffer()` */
  static readonly CountOfferPath = '/offers/count';

  /**
   * Count offers.
   *
   * Count offers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countOffer()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOffer$Response(params?: CountOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Count offers.
   *
   * Count offers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countOffer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOffer(params?: CountOffer$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showOffer()` */
  static readonly ShowOfferPath = '/offers/{id}';

  /**
   * Show offer.
   *
   * Show one offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOffer()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOffer$Response(params: ShowOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return showOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Show offer.
   *
   * Show one offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOffer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOffer(params: ShowOffer$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.showOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `updateOffer()` */
  static readonly UpdateOfferPath = '/offers/{id}';

  /**
   * Update offer.
   *
   * Update one offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOffer$Response(params: UpdateOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return updateOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Update offer.
   *
   * Update one offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOffer(params: UpdateOffer$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.updateOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `restoreOffer()` */
  static readonly RestoreOfferPath = '/offers/{id}';

  /**
   * Restore offer.
   *
   * Restore offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreOffer()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOffer$Response(params: RestoreOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return restoreOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore offer.
   *
   * Restore offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreOffer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOffer(params: RestoreOffer$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.restoreOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `deleteOffer()` */
  static readonly DeleteOfferPath = '/offers/{id}';

  /**
   * Delete offer.
   *
   * Delete offer (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOffer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOffer$Response(params: DeleteOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete offer.
   *
   * Delete offer (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOffer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOffer(params: DeleteOffer$Params, context?: HttpContext): Observable<void> {
    return this.deleteOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchOffer()` */
  static readonly PatchOfferPath = '/offers/{id}';

  /**
   * Patch offer.
   *
   * Patch one offer (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOffer$Response(params: PatchOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return patchOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch offer.
   *
   * Patch one offer (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOffer(params: PatchOffer$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.patchOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `fakeOffer()` */
  static readonly FakeOfferPath = '/offers/faker';

  /**
   * Fake offer.
   *
   * Generate fake offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeOffer()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOffer$Response(params?: FakeOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return fakeOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake offer.
   *
   * Generate fake offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeOffer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOffer(params?: FakeOffer$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.fakeOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `showOfferCategory()` */
  static readonly ShowOfferCategoryPath = '/offers/{id}/category';

  /**
   * Show offer category.
   *
   * Show offer category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOfferCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferCategory$Response(params: ShowOfferCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryResponse>> {
    return showOfferCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Show offer category.
   *
   * Show offer category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOfferCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferCategory(params: ShowOfferCategory$Params, context?: HttpContext): Observable<CategoryResponse> {
    return this.showOfferCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryResponse>): CategoryResponse => r.body)
    );
  }

  /** Path part for operation `showOfferOptions()` */
  static readonly ShowOfferOptionsPath = '/offers/{id}/options';

  /**
   * Show offer options.
   *
   * Show offer options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOfferOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferOptions$Response(params: ShowOfferOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OfferResponse>;
}>> {
    return showOfferOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * Show offer options.
   *
   * Show offer options
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOfferOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferOptions(params: ShowOfferOptions$Params, context?: HttpContext): Observable<{
'data'?: Array<OfferResponse>;
}> {
    return this.showOfferOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OfferResponse>;
}>): {
'data'?: Array<OfferResponse>;
} => r.body)
    );
  }

  /** Path part for operation `createOfferOptions()` */
  static readonly CreateOfferOptionsPath = '/offers/{id}/options';

  /**
   * Create offer options.
   *
   * Create offer options
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOfferOptions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOfferOptions$Response(params: CreateOfferOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return createOfferOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * Create offer options.
   *
   * Create offer options
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOfferOptions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOfferOptions(params: CreateOfferOptions$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.createOfferOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

}
