import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LocalUserService } from '../services/local-user.service';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable({
  providedIn: 'root',
})

/**
 * Guard that blocks and redirect to login the user if he's not logged in
 */
export class AuthGuard {
  constructor(
    private routes: Router,
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private localUserService: LocalUserService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.localUserService
        .checkAndGetUser()
        .then(() => {
          subscriber.next(true);
        })
        .catch((error) => {
          console.log(error);
          this.localUserService.disconnectUser();
          this.routes.navigate(['account/login']);
          subscriber.next(false);
        });
    });
  }
}
