/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface FunnelEligibility$Params {

/**
 * Setiar referential
 */
  setiar?: boolean;

/**
 * Fulltext
 */
  q?: string;

/**
 * no list places
 */
  noPlaces?: string;

/**
 * Ndi
 */
  nd?: string;

/**
 * Building
 */
  imb?: string;

/**
 * Longitude
 */
  long?: number;

/**
 * Latitude
 */
  lat?: number;

/**
 * House number
 */
  houseNumber?: string;

/**
 * Street name
 */
  road?: string;

/**
 * Postal code
 */
  postalCode?: string;

/**
 * City name
 */
  city?: string;

/**
 * Use WebSockets
 */
  socket?: boolean;
}

export function funnelEligibility(http: HttpClient, rootUrl: string, params?: FunnelEligibility$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'q'?: string;
'address'?: string;
'inseeCode'?: string;
'rivoliCode'?: string;
'streetNr'?: string;
'streetNrSuffix'?: string;
'streetType'?: string;
'streetName'?: string;
'postcode'?: string;
'city'?: string;
'coordinates'?: {
'longitude'?: number;
'latitude'?: number;
};
'buildings'?: {
'label'?: string;
'buildingCodeOC'?: string;
'buildingCodeOI'?: string;
};
'endpoints'?: Array<{
'ndi'?: string;
'label'?: string;
'status'?: string;
}>;
'characteristics'?: {
};
}>> {
  const rb = new RequestBuilder(rootUrl, funnelEligibility.PATH, 'get');
  if (params) {
    rb.query('setiar', params.setiar, {});
    rb.query('q', params.q, {});
    rb.query('noPlaces', params.noPlaces, {});
    rb.query('nd', params.nd, {});
    rb.query('imb', params.imb, {});
    rb.query('long', params.long, {});
    rb.query('lat', params.lat, {});
    rb.query('houseNumber', params.houseNumber, {});
    rb.query('road', params.road, {});
    rb.query('postalCode', params.postalCode, {});
    rb.query('city', params.city, {});
    rb.query('socket', params.socket, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'q'?: string;
      'address'?: string;
      'inseeCode'?: string;
      'rivoliCode'?: string;
      'streetNr'?: string;
      'streetNrSuffix'?: string;
      'streetType'?: string;
      'streetName'?: string;
      'postcode'?: string;
      'city'?: string;
      'coordinates'?: {
      'longitude'?: number;
      'latitude'?: number;
      };
      'buildings'?: {
      'label'?: string;
      'buildingCodeOC'?: string;
      'buildingCodeOI'?: string;
      };
      'endpoints'?: Array<{
      'ndi'?: string;
      'label'?: string;
      'status'?: string;
      }>;
      'characteristics'?: {
      };
      }>;
    })
  );
}

funnelEligibility.PATH = '/eligibility/funnel';
