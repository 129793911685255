/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countOfferRewrite } from '../fn/offer-rewrite/count-offer-rewrite';
import { CountOfferRewrite$Params } from '../fn/offer-rewrite/count-offer-rewrite';
import { createOfferRewrite } from '../fn/offer-rewrite/create-offer-rewrite';
import { CreateOfferRewrite$Params } from '../fn/offer-rewrite/create-offer-rewrite';
import { createOfferRewriteOffer } from '../fn/offer-rewrite/create-offer-rewrite-offer';
import { CreateOfferRewriteOffer$Params } from '../fn/offer-rewrite/create-offer-rewrite-offer';
import { createOfferRewritePlan } from '../fn/offer-rewrite/create-offer-rewrite-plan';
import { CreateOfferRewritePlan$Params } from '../fn/offer-rewrite/create-offer-rewrite-plan';
import { deleteOfferRewrite } from '../fn/offer-rewrite/delete-offer-rewrite';
import { DeleteOfferRewrite$Params } from '../fn/offer-rewrite/delete-offer-rewrite';
import { fakeOfferRewrite } from '../fn/offer-rewrite/fake-offer-rewrite';
import { FakeOfferRewrite$Params } from '../fn/offer-rewrite/fake-offer-rewrite';
import { listOfferRewrite } from '../fn/offer-rewrite/list-offer-rewrite';
import { ListOfferRewrite$Params } from '../fn/offer-rewrite/list-offer-rewrite';
import { OfferResponse } from '../models/offer-response';
import { OfferRewriteResponse } from '../models/offer-rewrite-response';
import { patchOfferRewrite } from '../fn/offer-rewrite/patch-offer-rewrite';
import { PatchOfferRewrite$Params } from '../fn/offer-rewrite/patch-offer-rewrite';
import { PlanResponse } from '../models/plan-response';
import { restoreOfferRewrite } from '../fn/offer-rewrite/restore-offer-rewrite';
import { RestoreOfferRewrite$Params } from '../fn/offer-rewrite/restore-offer-rewrite';
import { showOfferRewrite } from '../fn/offer-rewrite/show-offer-rewrite';
import { ShowOfferRewrite$Params } from '../fn/offer-rewrite/show-offer-rewrite';
import { showOfferRewriteOffer } from '../fn/offer-rewrite/show-offer-rewrite-offer';
import { ShowOfferRewriteOffer$Params } from '../fn/offer-rewrite/show-offer-rewrite-offer';
import { showOfferRewritePlan } from '../fn/offer-rewrite/show-offer-rewrite-plan';
import { ShowOfferRewritePlan$Params } from '../fn/offer-rewrite/show-offer-rewrite-plan';
import { updateOfferRewrite } from '../fn/offer-rewrite/update-offer-rewrite';
import { UpdateOfferRewrite$Params } from '../fn/offer-rewrite/update-offer-rewrite';

@Injectable({ providedIn: 'root' })
export class OfferRewriteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listOfferRewrite()` */
  static readonly ListOfferRewritePath = '/offerRewrites';

  /**
   * List offerRewrites.
   *
   * List offerRewrites
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOfferRewrite()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOfferRewrite$Response(params?: ListOfferRewrite$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OfferRewriteResponse>;
'total'?: number;
}>> {
    return listOfferRewrite(this.http, this.rootUrl, params, context);
  }

  /**
   * List offerRewrites.
   *
   * List offerRewrites
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOfferRewrite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOfferRewrite(params?: ListOfferRewrite$Params, context?: HttpContext): Observable<{
'data'?: Array<OfferRewriteResponse>;
'total'?: number;
}> {
    return this.listOfferRewrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OfferRewriteResponse>;
'total'?: number;
}>): {
'data'?: Array<OfferRewriteResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createOfferRewrite()` */
  static readonly CreateOfferRewritePath = '/offerRewrites';

  /**
   * Create offerRewrite.
   *
   * Create one offerRewrite
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOfferRewrite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOfferRewrite$Response(params: CreateOfferRewrite$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferRewriteResponse>> {
    return createOfferRewrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Create offerRewrite.
   *
   * Create one offerRewrite
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOfferRewrite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOfferRewrite(params: CreateOfferRewrite$Params, context?: HttpContext): Observable<OfferRewriteResponse> {
    return this.createOfferRewrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferRewriteResponse>): OfferRewriteResponse => r.body)
    );
  }

  /** Path part for operation `countOfferRewrite()` */
  static readonly CountOfferRewritePath = '/offerRewrites/count';

  /**
   * Count offerRewrites.
   *
   * Count offerRewrites
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countOfferRewrite()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOfferRewrite$Response(params?: CountOfferRewrite$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countOfferRewrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Count offerRewrites.
   *
   * Count offerRewrites
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countOfferRewrite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOfferRewrite(params?: CountOfferRewrite$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countOfferRewrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showOfferRewrite()` */
  static readonly ShowOfferRewritePath = '/offerRewrites/{id}';

  /**
   * Show offerRewrite.
   *
   * Show one offerRewrite
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOfferRewrite()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferRewrite$Response(params: ShowOfferRewrite$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferRewriteResponse>> {
    return showOfferRewrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Show offerRewrite.
   *
   * Show one offerRewrite
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOfferRewrite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferRewrite(params: ShowOfferRewrite$Params, context?: HttpContext): Observable<OfferRewriteResponse> {
    return this.showOfferRewrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferRewriteResponse>): OfferRewriteResponse => r.body)
    );
  }

  /** Path part for operation `updateOfferRewrite()` */
  static readonly UpdateOfferRewritePath = '/offerRewrites/{id}';

  /**
   * Update offerRewrite.
   *
   * Update one offerRewrite
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOfferRewrite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOfferRewrite$Response(params: UpdateOfferRewrite$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferRewriteResponse>> {
    return updateOfferRewrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Update offerRewrite.
   *
   * Update one offerRewrite
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOfferRewrite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOfferRewrite(params: UpdateOfferRewrite$Params, context?: HttpContext): Observable<OfferRewriteResponse> {
    return this.updateOfferRewrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferRewriteResponse>): OfferRewriteResponse => r.body)
    );
  }

  /** Path part for operation `restoreOfferRewrite()` */
  static readonly RestoreOfferRewritePath = '/offerRewrites/{id}';

  /**
   * Restore offerRewrite.
   *
   * Restore offerRewrite
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreOfferRewrite()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOfferRewrite$Response(params: RestoreOfferRewrite$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferRewriteResponse>> {
    return restoreOfferRewrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore offerRewrite.
   *
   * Restore offerRewrite
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreOfferRewrite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOfferRewrite(params: RestoreOfferRewrite$Params, context?: HttpContext): Observable<OfferRewriteResponse> {
    return this.restoreOfferRewrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferRewriteResponse>): OfferRewriteResponse => r.body)
    );
  }

  /** Path part for operation `deleteOfferRewrite()` */
  static readonly DeleteOfferRewritePath = '/offerRewrites/{id}';

  /**
   * Delete offerRewrite.
   *
   * Delete offerRewrite (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOfferRewrite()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOfferRewrite$Response(params: DeleteOfferRewrite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOfferRewrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete offerRewrite.
   *
   * Delete offerRewrite (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOfferRewrite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOfferRewrite(params: DeleteOfferRewrite$Params, context?: HttpContext): Observable<void> {
    return this.deleteOfferRewrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchOfferRewrite()` */
  static readonly PatchOfferRewritePath = '/offerRewrites/{id}';

  /**
   * Patch offerRewrite.
   *
   * Patch one offerRewrite (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOfferRewrite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOfferRewrite$Response(params: PatchOfferRewrite$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferRewriteResponse>> {
    return patchOfferRewrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch offerRewrite.
   *
   * Patch one offerRewrite (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOfferRewrite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOfferRewrite(params: PatchOfferRewrite$Params, context?: HttpContext): Observable<OfferRewriteResponse> {
    return this.patchOfferRewrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferRewriteResponse>): OfferRewriteResponse => r.body)
    );
  }

  /** Path part for operation `fakeOfferRewrite()` */
  static readonly FakeOfferRewritePath = '/offerRewrites/faker';

  /**
   * Fake offerRewrite.
   *
   * Generate fake offerRewrite
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeOfferRewrite()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOfferRewrite$Response(params?: FakeOfferRewrite$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferRewriteResponse>> {
    return fakeOfferRewrite(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake offerRewrite.
   *
   * Generate fake offerRewrite
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeOfferRewrite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOfferRewrite(params?: FakeOfferRewrite$Params, context?: HttpContext): Observable<OfferRewriteResponse> {
    return this.fakeOfferRewrite$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferRewriteResponse>): OfferRewriteResponse => r.body)
    );
  }

  /** Path part for operation `showOfferRewritePlan()` */
  static readonly ShowOfferRewritePlanPath = '/offerRewrites/{id}/plan';

  /**
   * Show offerRewrite plan.
   *
   * Show offerRewrite plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOfferRewritePlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferRewritePlan$Response(params: ShowOfferRewritePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return showOfferRewritePlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Show offerRewrite plan.
   *
   * Show offerRewrite plan
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOfferRewritePlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferRewritePlan(params: ShowOfferRewritePlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.showOfferRewritePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

  /** Path part for operation `createOfferRewritePlan()` */
  static readonly CreateOfferRewritePlanPath = '/offerRewrites/{id}/plan';

  /**
   * Create offerRewrite plan.
   *
   * Create offerRewrite plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOfferRewritePlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOfferRewritePlan$Response(params: CreateOfferRewritePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return createOfferRewritePlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Create offerRewrite plan.
   *
   * Create offerRewrite plan
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOfferRewritePlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOfferRewritePlan(params: CreateOfferRewritePlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.createOfferRewritePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

  /** Path part for operation `showOfferRewriteOffer()` */
  static readonly ShowOfferRewriteOfferPath = '/offerRewrites/{id}/offer';

  /**
   * Show offerRewrite offer.
   *
   * Show offerRewrite offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOfferRewriteOffer()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferRewriteOffer$Response(params: ShowOfferRewriteOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return showOfferRewriteOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Show offerRewrite offer.
   *
   * Show offerRewrite offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOfferRewriteOffer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOfferRewriteOffer(params: ShowOfferRewriteOffer$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.showOfferRewriteOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `createOfferRewriteOffer()` */
  static readonly CreateOfferRewriteOfferPath = '/offerRewrites/{id}/offer';

  /**
   * Create offerRewrite offer.
   *
   * Create offerRewrite offer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOfferRewriteOffer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOfferRewriteOffer$Response(params: CreateOfferRewriteOffer$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return createOfferRewriteOffer(this.http, this.rootUrl, params, context);
  }

  /**
   * Create offerRewrite offer.
   *
   * Create offerRewrite offer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOfferRewriteOffer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOfferRewriteOffer(params: CreateOfferRewriteOffer$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.createOfferRewriteOffer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

}
