/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countPlan } from '../fn/plan/count-plan';
import { CountPlan$Params } from '../fn/plan/count-plan';
import { createPlan } from '../fn/plan/create-plan';
import { CreatePlan$Params } from '../fn/plan/create-plan';
import { createPlanPrices } from '../fn/plan/create-plan-prices';
import { CreatePlanPrices$Params } from '../fn/plan/create-plan-prices';
import { deletePlan } from '../fn/plan/delete-plan';
import { DeletePlan$Params } from '../fn/plan/delete-plan';
import { fakePlan } from '../fn/plan/fake-plan';
import { FakePlan$Params } from '../fn/plan/fake-plan';
import { listPlan } from '../fn/plan/list-plan';
import { ListPlan$Params } from '../fn/plan/list-plan';
import { patchPlan } from '../fn/plan/patch-plan';
import { PatchPlan$Params } from '../fn/plan/patch-plan';
import { PlanResponse } from '../models/plan-response';
import { PriceRuleResponse } from '../models/price-rule-response';
import { restorePlan } from '../fn/plan/restore-plan';
import { RestorePlan$Params } from '../fn/plan/restore-plan';
import { showPlan } from '../fn/plan/show-plan';
import { ShowPlan$Params } from '../fn/plan/show-plan';
import { showPlanPrices } from '../fn/plan/show-plan-prices';
import { ShowPlanPrices$Params } from '../fn/plan/show-plan-prices';
import { updatePlan } from '../fn/plan/update-plan';
import { UpdatePlan$Params } from '../fn/plan/update-plan';

@Injectable({ providedIn: 'root' })
export class PlanService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listPlan()` */
  static readonly ListPlanPath = '/plans';

  /**
   * List plans.
   *
   * List plans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPlan$Response(params?: ListPlan$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<PlanResponse>;
'total'?: number;
}>> {
    return listPlan(this.http, this.rootUrl, params, context);
  }

  /**
   * List plans.
   *
   * List plans
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPlan(params?: ListPlan$Params, context?: HttpContext): Observable<{
'data'?: Array<PlanResponse>;
'total'?: number;
}> {
    return this.listPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<PlanResponse>;
'total'?: number;
}>): {
'data'?: Array<PlanResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createPlan()` */
  static readonly CreatePlanPath = '/plans';

  /**
   * Create plan.
   *
   * Create one plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlan$Response(params: CreatePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return createPlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Create plan.
   *
   * Create one plan
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlan(params: CreatePlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.createPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

  /** Path part for operation `countPlan()` */
  static readonly CountPlanPath = '/plans/count';

  /**
   * Count plans.
   *
   * Count plans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  countPlan$Response(params?: CountPlan$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countPlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Count plans.
   *
   * Count plans
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countPlan(params?: CountPlan$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showPlan()` */
  static readonly ShowPlanPath = '/plans/{id}';

  /**
   * Show plan.
   *
   * Show one plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPlan$Response(params: ShowPlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return showPlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Show plan.
   *
   * Show one plan
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPlan(params: ShowPlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.showPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

  /** Path part for operation `updatePlan()` */
  static readonly UpdatePlanPath = '/plans/{id}';

  /**
   * Update plan.
   *
   * Update one plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlan$Response(params: UpdatePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return updatePlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Update plan.
   *
   * Update one plan
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlan(params: UpdatePlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.updatePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

  /** Path part for operation `restorePlan()` */
  static readonly RestorePlanPath = '/plans/{id}';

  /**
   * Restore plan.
   *
   * Restore plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restorePlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  restorePlan$Response(params: RestorePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return restorePlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore plan.
   *
   * Restore plan
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restorePlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restorePlan(params: RestorePlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.restorePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

  /** Path part for operation `deletePlan()` */
  static readonly DeletePlanPath = '/plans/{id}';

  /**
   * Delete plan.
   *
   * Delete plan (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePlan$Response(params: DeletePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete plan.
   *
   * Delete plan (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePlan(params: DeletePlan$Params, context?: HttpContext): Observable<void> {
    return this.deletePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchPlan()` */
  static readonly PatchPlanPath = '/plans/{id}';

  /**
   * Patch plan.
   *
   * Patch one plan (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPlan$Response(params: PatchPlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return patchPlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch plan.
   *
   * Patch one plan (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPlan(params: PatchPlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.patchPlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

  /** Path part for operation `fakePlan()` */
  static readonly FakePlanPath = '/plans/faker';

  /**
   * Fake plan.
   *
   * Generate fake plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakePlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakePlan$Response(params?: FakePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return fakePlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake plan.
   *
   * Generate fake plan
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakePlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakePlan(params?: FakePlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.fakePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

  /** Path part for operation `showPlanPrices()` */
  static readonly ShowPlanPricesPath = '/plans/{id}/prices';

  /**
   * Show plan prices.
   *
   * Show plan prices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showPlanPrices()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPlanPrices$Response(params: ShowPlanPrices$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<PriceRuleResponse>;
}>> {
    return showPlanPrices(this.http, this.rootUrl, params, context);
  }

  /**
   * Show plan prices.
   *
   * Show plan prices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showPlanPrices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPlanPrices(params: ShowPlanPrices$Params, context?: HttpContext): Observable<{
'data'?: Array<PriceRuleResponse>;
}> {
    return this.showPlanPrices$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<PriceRuleResponse>;
}>): {
'data'?: Array<PriceRuleResponse>;
} => r.body)
    );
  }

  /** Path part for operation `createPlanPrices()` */
  static readonly CreatePlanPricesPath = '/plans/{id}/prices';

  /**
   * Create plan prices.
   *
   * Create plan prices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlanPrices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlanPrices$Response(params: CreatePlanPrices$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceRuleResponse>> {
    return createPlanPrices(this.http, this.rootUrl, params, context);
  }

  /**
   * Create plan prices.
   *
   * Create plan prices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlanPrices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlanPrices(params: CreatePlanPrices$Params, context?: HttpContext): Observable<PriceRuleResponse> {
    return this.createPlanPrices$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceRuleResponse>): PriceRuleResponse => r.body)
    );
  }

}
