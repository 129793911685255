/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { sfrEAccessNotificationOrder } from '../fn/external/sfr-e-access-notification-order';
import { SfrEAccessNotificationOrder$Params } from '../fn/external/sfr-e-access-notification-order';

@Injectable({ providedIn: 'root' })
export class ExternalService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sfrEAccessNotificationOrder()` */
  static readonly SfrEAccessNotificationOrderPath = '/external/notification/order/eaccess';

  /**
   * SFR E-Access notification order.
   *
   * SFR E-Access notification order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sfrEAccessNotificationOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sfrEAccessNotificationOrder$Response(params: SfrEAccessNotificationOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'status': 'OK';
}>> {
    return sfrEAccessNotificationOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * SFR E-Access notification order.
   *
   * SFR E-Access notification order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sfrEAccessNotificationOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sfrEAccessNotificationOrder(params: SfrEAccessNotificationOrder$Params, context?: HttpContext): Observable<{
'status': 'OK';
}> {
    return this.sfrEAccessNotificationOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'status': 'OK';
}>): {
'status': 'OK';
} => r.body)
    );
  }

}
