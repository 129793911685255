/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface InitiateOrder$Params {
      body: {
'type': string;
'data'?: {
'operator'?: string;
'offerId'?: string;
'operatorData'?: {
'options'?: Array<{
'offerId'?: string;
'quantity'?: number;
}>;
'building'?: {
'code'?: string;
'name'?: string;
'stairs'?: string;
'floor'?: string;
'coordinates'?: {
'latitude'?: string;
'longitude'?: string;
};
};
'company'?: {
'name'?: string;
'siren'?: string;
'company'?: string;
};
'delivery'?: {
'address'?: {
'houseNumber'?: string;
'inseeCode'?: string;
'postcode'?: string;
'road'?: string;
'city'?: string;
};
};
'contact'?: {
'firstName'?: string;
'lastName'?: string;
'email'?: string;
};
'eligId'?: string;
'supplierId'?: string;
'nro'?: string;
};
};
}
}

export function initiateOrder(http: HttpClient, rootUrl: string, params: InitiateOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, initiateOrder.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

initiateOrder.PATH = '/order/initiate';
