/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getVrbConfig } from '../fn/vrb/get-vrb-config';
import { GetVrbConfig$Params } from '../fn/vrb/get-vrb-config';
import { setVrbConfig } from '../fn/vrb/set-vrb-config';
import { SetVrbConfig$Params } from '../fn/vrb/set-vrb-config';

@Injectable({ providedIn: 'root' })
export class VrbService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVrbConfig()` */
  static readonly GetVrbConfigPath = '/vrb/{cluster}/config';

  /**
   * Get VRB Config.
   *
   * Get VRB Config
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVrbConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVrbConfig$Response(params: GetVrbConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getVrbConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Get VRB Config.
   *
   * Get VRB Config
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVrbConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVrbConfig(params: GetVrbConfig$Params, context?: HttpContext): Observable<{
}> {
    return this.getVrbConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `setVrbConfig()` */
  static readonly SetVrbConfigPath = '/vrb/{cluster}/config';

  /**
   * Set VRB Config.
   *
   * Set VRB Config
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setVrbConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setVrbConfig$Response(params: SetVrbConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return setVrbConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * Set VRB Config.
   *
   * Set VRB Config
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setVrbConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setVrbConfig(params: SetVrbConfig$Params, context?: HttpContext): Observable<{
}> {
    return this.setVrbConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
