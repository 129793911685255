/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface EligQuantic$Params {

/**
 * INSEE code
 */
  inseeCode?: string;

/**
 * RIVOLI code
 */
  rivoliCode?: string;

/**
 * Street number
 */
  streetNr?: string;

/**
 * latitude
 */
  latitude?: number;

/**
 * longitude
 */
  longitude?: number;

/**
 * Fulltext
 */
  address?: string;

/**
 * Postal code
 */
  postcode?: string;

/**
 * City name
 */
  city?: string;

/**
 * Line number
 */
  lineNumber?: string;

/**
 * endpoint
 */
  endpoint?: string;

/**
 * Use WebSockets
 */
  socket?: boolean;
}

export function eligQuantic(http: HttpClient, rootUrl: string, params?: EligQuantic$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, eligQuantic.PATH, 'get');
  if (params) {
    rb.query('inseeCode', params.inseeCode, {});
    rb.query('rivoliCode', params.rivoliCode, {});
    rb.query('streetNr', params.streetNr, {});
    rb.query('latitude', params.latitude, {});
    rb.query('longitude', params.longitude, {});
    rb.query('address', params.address, {});
    rb.query('postcode', params.postcode, {});
    rb.query('city', params.city, {});
    rb.query('lineNumber', params.lineNumber, {});
    rb.query('endpoint', params.endpoint, {});
    rb.query('socket', params.socket, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

eligQuantic.PATH = '/eligibility/quantic';
