import { UntypedFormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Ability } from '@casl/ability';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldType } from '@ngx-formly/core';
import { DynamicModelFormRender } from '../models/dynamic-model-form-render';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { ParamsBuilder } from '../../core/models/params-builder';
import { LinkActionComponent } from '../components/actions/link/link.action.component';
import { DynamicTableComponent } from '../components/dynamic-table/dynamic-table.component';
import { AnyAction } from '../models/actions/action';
import { CreateModalService } from '../services/create-modal.service';
import { UtilsService } from '../services/utils.service';
import { ParamsBuilderService } from '../services/params-builder.service';
import {
  CatalogService,
  CompanyService,
  EligibilityService,
  PriceRuleService,
  TenantService,
  UserService,
} from 'src/api/services';

export type ModelView = {
  params?: { [key: string]: unknown };
  [key: string]: unknown;
};

@Component({
  template: '',
})
export abstract class BaseTemplateComponent<T = unknown> extends FieldType {
  title: string;
  icon = 'question';
  nameProperty = 'name';

  @Input() buttons: AnyAction[] = [];
  @Input() action = 'list';
  @Input() render: (data: any) => string = (data) => data.name;
  @Input()
  private _model!: any;

  @Input()
  private _form: UntypedFormGroup = new UntypedFormGroup({});

  @Input() defaultViewParams: ParamsBuilder = {
    fields: ['id', 'name'],
  };

  @Input() defaultParamsBuilder: ParamsBuilder = {
    fields: ['name'],
  };

  // Used to store data when viewing model
  data!: T;

  constructor(
    public translate: TranslateService,
    public createModalService: CreateModalService,
    public ability: Ability,
    private route: ActivatedRoute,
    private router: Router,
    public companyService: CompanyService,
    public tenantService: TenantService,
    // public contactService: ContactService,
    public catalogService: CatalogService,
    public priceRuleService: PriceRuleService,
    public userService: UserService,
    public utilsService: UtilsService,
    public eligibilityService: EligibilityService,
    public paramsBuilderService: ParamsBuilderService,
  ) {
    super();
    this.title = this.constructor.name.replace(/Template$/, '');
  }

  protected _dynamicTableComponent?: DynamicTableComponent;

  public getDynamicTableComponent(): DynamicTableComponent {
    return this._dynamicTableComponent!;
  }

  public setDynamicTableComponent(table: DynamicTableComponent): void {
    this._dynamicTableComponent = table;
  }

  public getOptions(): DynamicModelFormRender {
    return {
      model: this.model,
      action: this.action,
      fields: {},
    };
  }

  /**
   * Return an object to create the form
   * @returns
   */
  public abstract getForm(): DynamicModelFormRender;

  /**
   * Return an object to create the table
   * @returns
   */
  public abstract getTable(): DynamicTableRender;

  public override get form(): UntypedFormGroup {
    return this._form;
  }

  public override set form(value: UntypedFormGroup) {
    this._form = value;
  }

  public override get model(): any {
    return this._model;
  }

  public override set model(value: any) {
    this._model = value;
  }

  /**
   * It returns an array of actions that will be displayed in the table and the view.
   * @param {string} modelName - The name of the model that you want to use.
   * @returns returns an array of actions.
   */
  public getActions(
    modelName: string,
    resolve?: any,
    reject?: any,
  ): AnyAction[] {
    return [
      {
        message: '<i class="fa fa-fw fa-pencil"></i>',
        type: 'link',
        onClick: (event: Event, action: LinkActionComponent) => {
          return this.openModalForm(event, modelName, 'patch', action.data.id)
            .then(resolve)
            .catch(reject);
        },
        class: '',
        can: ['update', modelName],
      },
      {
        message: '<i class="fa fa-fw fa-times"></i>',
        type: 'link',
        onClick: (event: Event, action: LinkActionComponent) => {
          return this.openModalForm(event, modelName, 'delete', action.data.id)
            .then(resolve)
            .catch(reject);
        },
        hidden: (action: LinkActionComponent) => {
          // Action.data is undefined when loading?
          return !action.data || action.data.deletedAt;
        },
        class: '',
        can: ['delete', modelName],
      },
      {
        message: '<i class="fa-solid fa-trash-arrow-up"></i>',
        type: 'link',
        onClick: (event: Event, action: LinkActionComponent) => {
          return this.openModalForm(event, modelName, 'restore', action.data.id)
            .then(resolve)
            .catch(reject);
        },
        class: '',
        hidden: (action: LinkActionComponent) => {
          // Action.data is undefined when loading?
          return !action.data || !action.data.deletedAt;
        },
        can: ['restore', modelName],
      },
    ];
  }

  /**
   * It creates the buttons that will be displayed in the table.
   * @param {string} modelName - The name of the model that you want to use.
   * @returns all the buttons
   */
  protected getButtons(modelName: string) {
    return (table: DynamicTableComponent) => {
      const self = this;

      const buttons: any[] = [
        {
          extend: 'pageLength',
        },
        {
          extend: 'colvis',
          text:
            '<i class="fa fa-eye me-2"></i>' +
            this.translate?.instant('DATATABLES.buttons.colvis'),
          columns: ':not(.group,.ignore)',
          background: false,
          fade: 300,
          // attr: {
          //   class: 'btn btn-sm btn-secondary dropdown-toggle',
          // }
        },
        {
          // extend: 'copy',
          extend: 'copyHtml5',
          text:
            '<i class="fa fa-clipboard me-2"></i>' +
            this.translate?.instant('DATATABLES.buttons.copy'),
          exportOptions: {
            columns: ':visible :not(.no-export)',
          },
          // attr: {
          //   class: 'btn btn-sm btn-secondary',
          // }
        },
        {
          // extend: 'csv',
          extend: 'csvHtml5',
          text: '<i class="fa fa-file-csv me-2"></i>CSV',
          exportOptions: {
            columns: ':visible :not(.no-export)',
          },
          // split: ['pdf', 'excel'],
          titleAttr: 'Export table',
          // attr: {
          //   class: 'btn btn-sm btn-secondary',
          // }
        },
        {
          text:
            '<i class="fa fa-times me-2"></i>' +
            this.translate?.instant('Rerender'),
          action: (/*e, dt, node, config*/) => {
            table.rerender();
          },
          titleAttr: 'Rerender table',
          attr: {
            class: 'btn btn-outline-light',
          },
        },
        {
          text:
            '<i class="fa fa-refresh me-2"></i>' +
            this.translate?.instant('Refresh'),
          action: (/*e, dt, node, config*/) => {
            table.ajaxRefresh();
          },
          // className: 'btn btn-outline btn-primary',
          titleAttr: 'Refresh table',
          attr: {
            // class: 'btn btn-sm btn-outline-secondary',
            class: 'btn btn-outline-secondary',
          },
        },
      ];
      //FIXME

      //SHOW DELETE BUTTON

      // if (this.ability.can('delete', modelName)) {
      //   buttons.push({
      //     text: this.translate?.instant('Voir supprimées'),
      //     action: function (e: any, dt: any, node: any, config: any) {
      //       if (!table.paramsBuilder.modifiers?.includes('deleted')) {
      //         node[0].innerText = self.translate?.instant('Voir actives');
      //         table.paramsBuilder.modifiers?.push('deleted');

      //         ////Find deleteAt col and show it
      //         //const deleteCol = table.columns.findIndex((col) => col.data == 'deletedAt');
      //         //if (deleteCol != -1) {
      //         //  dt.columns(deleteCol).visible(true);
      //         //}

      //         table.ajaxRefresh();
      //       } else {
      //         node[0].innerText = self.translate?.instant('Voir supprimées');

      //         ////Find deleteAt col and hide it
      //         //const deleteCol = table.columns.findIndex((col) => col.data == 'deletedAt');
      //         //if (deleteCol != -1) {
      //         //  dt.columns(deleteCol).visible(false);
      //         //}

      //         table.paramsBuilder.modifiers = table.paramsBuilder.modifiers?.filter((val) => val != 'deleted');
      //         table.ajaxRefresh();
      //       }
      //     },
      //   });
      // }

      //SHOW NEW BUTTON
      if (this.ability?.can('create', modelName)) {
        buttons.push({
          text: this.translate?.instant('Nouveau') + ' ' + modelName,
          action: function (e: any, dt: any, node: any, config: any) {
            //console.log(selected.column(1).checkboxes.selected)
            self.createModalService
              .newModalForm({
                action: 'create',
                model: modelName,
                onSuccess: (event, activeModal) => activeModal.close(event),
              })
              .result.then(() => {
                dt.ajax.reload();
              })
              .catch(() => {
                dt.ajax.reload();
              });
          },
        });
      }

      if (this.ability?.can('update', modelName)) {
        buttons.push({
          text: this.translate?.instant('Mode Bulk'),
          action: function (e: any, dt: any, node: any, config: any) {
            table.toggleBulkMode();
          },
        });
      }

      return buttons;
    };
  }

  getRenderRow() {
    return (row: HTMLElement, data: any, index: number) => {
      if (data.deletedAt) row.className = 'table-danger';
      return row;
    };
  }

  /**
   * Create a model modal from an action event, which will resolve after closed
   * @param event
   * @param model
   * @param action
   * @param id
   * @return Promise<void>
   */
  protected async openModalForm(
    event: Event,
    model: string,
    action: string,
    id: string,
  ): Promise<void> {
    event.stopPropagation();
    return this.createModalService.newModalForm({
      action: action,
      model,
      id,
      size: 'xl',
    }).result;
  }

  protected async openModalTable(
    event: Event,
    model: string,
    action: string,
    id?: string,
    related?: string,
    size?: 'sm' | 'lg' | 'xl',
  ): Promise<void> {
    event.stopPropagation();
    return this.createModalService.newModalTable({
      model,
      action,
      id,
      related,
      size,
    }).result;
  }
}
