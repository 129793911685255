import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { AuthService } from './auth.service';
import { SignalInfoService } from './signal-info.service';
import { LocalUserService } from './local-user.service';

@Injectable()
/**
 * Intercept all the HttpClient Request and handle error
 */
export class HttpErrorInterceptorService implements HttpInterceptor {
  constructor(
    private signalInfoService: SignalInfoService,
    private authService: AuthService,
    private router: Router,
    private localUserService: LocalUserService,
    private notifierService: NotifierService,
  ) {}

  /**
   * Interceptor of the HttpClient Request, catch error and notify the user
   * @param req
   * @param next
   * @returns
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const newReq: HttpRequest<any> = req;

    return next.handle(newReq).pipe(
      catchError((error) => {
        console.error(error);
        const appError = error.error;
        let msg = error.error.message || 'Une erreur inconnue est survenue';

        if (appError.debug) {
          console.error('DEBUG', appError.debug);
        }

        if (error.status) {
          msg += ` (code ${error.status})`;
        }

        // if (!environment.production) {
        //   if (!isEmpty(appError.data)) {
        //     msg += ' [DATA] ' + JSON.stringify(appError.data);
        //   }
        //   if (appError.debug && appError.debug.message) {
        //     msg += ' [DEBUG] ' + appError.debug.message;
        //   }
        // }

        // if (
        //   error instanceof HttpErrorResponse &&
        //   (error.status === 0 || error.status === 500)
        // ) {
        this.notifierService.notify('error', msg);
        // }

        // this.notifierService.notify({
        //   type: 'error',
        //   message: msg,
        //   id: `http-error-${error.status}`,
        // });

        return throwError(error);
      }),
    );
  }
}
