import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from './shared/services/theme.service';
// import { SharedModule } from './shared/shared.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // providers: [SharedModule]
})
export class AppComponent {
  public app = {
    test: 'ok',
    console,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    public theme: ThemeService,
  ) {
    //useTenantService.getTenantConfig().subscribe(val => console.log(val))
    // useTenantService.generateCSS().subscribe(() => {
    // });
  }
}
