import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { TOKEN_NAME, TokenStorageService } from './token-storage.service';
import { AuthService } from './auth.service';
import { SignalInfoService } from './signal-info.service';
import { LocalUserService } from './local-user.service';
import { LockService } from './lock.service';
import { LoginPayload } from 'src/api/models';

/**
 * Intercept all the HttpClient Request and add the token to the header. Moreover, if he received an error token expired, he will try to recover it and retry the request
 */
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private tokenStorage: TokenStorageService,
    private signalErrorService: SignalInfoService,
    private authService: AuthService,
    private router: Router,
    private localUserService: LocalUserService,
    private injector: Injector,
  ) {}

  /**
   * Interceptor of the HttpClient Request, check token and try to get another one if invalid
   * @param req
   * @param next
   * @returns
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    //If header contains 'skip', then don't add the token
    if (req.headers.get('skip')) {
      req = req.clone({
        headers: req.headers.delete('skip'),
      });
      return next.handle(req);
    }

    if (req.url.includes('/assets/i18n')) return next.handle(req);

    const lockService = this.injector.get(LockService);
    //if Locked, don't add the token
    if (lockService.getLock()) return next.handle(req);

    const token = localStorage.getItem(TOKEN_NAME);
    let newReq: HttpRequest<any> = req;

    // if request send, refresh lock timer
    lockService.refreshLockTime();

    if (!this.tokenStorage.isTokenExpired(token)) {
      console.log('add Token to header');
      newReq = this.addTokenHeader(req, token!);
    }

    return next.handle(newReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          error.error.code == 'MissingToken'
        ) {
          return this.handle401Error(newReq, next).pipe(
            catchError((error) => {
              this.authService.logout();
              this.signalErrorService.setError('Veuillez vous reconnecter');
              this.router.navigate(['account/login']);

              return throwError(error);
            }),
          );
        } else {
          return throwError(error);
        }
      }),
    );
  }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );

  /**
   * handle the missingToken error and try to get a new token with the refreshToken
   * @param request
   * @param next
   * @returns
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const idUser = this.localUserService.getUserId();
      const refreshToken = this.tokenStorage.getRefreshToken();

      console.log('Try refreshToken');

      if (refreshToken && idUser) {
        return this.authService
          .refreshToken(refreshToken, idUser.toString())
          .pipe(
            switchMap((loginPayload: LoginPayload) => {
              //console.log(loginPayload)
              this.isRefreshing = false;
              this.refreshTokenSubject.next(loginPayload.token);

              this.tokenStorage.setToken(loginPayload.token);
              if (loginPayload.refreshToken) {
                this.tokenStorage.setRefreshToken(loginPayload.refreshToken);
              }

              return next.handle(
                this.addTokenHeader(request, loginPayload.token),
              );
            }),
          );
      } else {
        return throwError('Invalid Token or UderId');
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addTokenHeader(request, jwt));
        }),
      );
    }
  }

  /**
   * Add the token to the header of the request
   * @param request
   * @param token
   * @returns
   */
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
