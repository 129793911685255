/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { customerCreate } from '../fn/yeastar/customer-create';
import { CustomerCreate$Params } from '../fn/yeastar/customer-create';
import { customerDelete } from '../fn/yeastar/customer-delete';
import { CustomerDelete$Params } from '../fn/yeastar/customer-delete';
import { customerList } from '../fn/yeastar/customer-list';
import { CustomerList$Params } from '../fn/yeastar/customer-list';
import { customerShow } from '../fn/yeastar/customer-show';
import { CustomerShow$Params } from '../fn/yeastar/customer-show';
import { customerUpdate } from '../fn/yeastar/customer-update';
import { CustomerUpdate$Params } from '../fn/yeastar/customer-update';
import { pbxCreate } from '../fn/yeastar/pbx-create';
import { PbxCreate$Params } from '../fn/yeastar/pbx-create';
import { pbxDelete } from '../fn/yeastar/pbx-delete';
import { PbxDelete$Params } from '../fn/yeastar/pbx-delete';
import { pbxList } from '../fn/yeastar/pbx-list';
import { PbxList$Params } from '../fn/yeastar/pbx-list';
import { pbxResize } from '../fn/yeastar/pbx-resize';
import { PbxResize$Params } from '../fn/yeastar/pbx-resize';
import { pbxShow } from '../fn/yeastar/pbx-show';
import { PbxShow$Params } from '../fn/yeastar/pbx-show';
import { systemCapacities } from '../fn/yeastar/system-capacities';
import { SystemCapacities$Params } from '../fn/yeastar/system-capacities';
import { userCreate } from '../fn/yeastar/user-create';
import { UserCreate$Params } from '../fn/yeastar/user-create';
import { userDelete } from '../fn/yeastar/user-delete';
import { UserDelete$Params } from '../fn/yeastar/user-delete';
import { userList } from '../fn/yeastar/user-list';
import { UserList$Params } from '../fn/yeastar/user-list';
import { userResize } from '../fn/yeastar/user-resize';
import { UserResize$Params } from '../fn/yeastar/user-resize';
import { userShow } from '../fn/yeastar/user-show';
import { UserShow$Params } from '../fn/yeastar/user-show';

@Injectable({ providedIn: 'root' })
export class YeastarService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pbxList()` */
  static readonly PbxListPath = '/yeastar/pbx/list';

  /**
   * List PBX.
   *
   * Get list of PBX
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxList()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxList$Response(params?: PbxList$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return pbxList(this.http, this.rootUrl, params, context);
  }

  /**
   * List PBX.
   *
   * Get list of PBX
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxList(params?: PbxList$Params, context?: HttpContext): Observable<{
}> {
    return this.pbxList$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `pbxCreate()` */
  static readonly PbxCreatePath = '/yeastar/pbx/create';

  /**
   * Create PBX.
   *
   * Create new PBX
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxCreate$Response(params: PbxCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return pbxCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create PBX.
   *
   * Create new PBX
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxCreate(params: PbxCreate$Params, context?: HttpContext): Observable<{
}> {
    return this.pbxCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `pbxResize()` */
  static readonly PbxResizePath = '/yeastar/pbx/resize';

  /**
   * Resize PBX Capacities.
   *
   * Reisze PBX Capacities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxResize()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxResize$Response(params: PbxResize$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return pbxResize(this.http, this.rootUrl, params, context);
  }

  /**
   * Resize PBX Capacities.
   *
   * Reisze PBX Capacities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxResize$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxResize(params: PbxResize$Params, context?: HttpContext): Observable<{
}> {
    return this.pbxResize$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `pbxShow()` */
  static readonly PbxShowPath = '/yeastar/pbx/show';

  /**
   * Show PBX.
   *
   * Show PBX
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxShow$Response(params: PbxShow$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return pbxShow(this.http, this.rootUrl, params, context);
  }

  /**
   * Show PBX.
   *
   * Show PBX
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxShow(params: PbxShow$Params, context?: HttpContext): Observable<{
}> {
    return this.pbxShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `pbxDelete()` */
  static readonly PbxDeletePath = '/yeastar/pbx/delete';

  /**
   * Delete PBX.
   *
   * Detete PBX
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxDelete$Response(params: PbxDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return pbxDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete PBX.
   *
   * Detete PBX
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxDelete(params: PbxDelete$Params, context?: HttpContext): Observable<{
}> {
    return this.pbxDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `userShow()` */
  static readonly UserShowPath = '/yeastar/user/show';

  /**
   * Show User.
   *
   * Show User
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  userShow$Response(params: UserShow$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return userShow(this.http, this.rootUrl, params, context);
  }

  /**
   * Show User.
   *
   * Show User
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userShow(params: UserShow$Params, context?: HttpContext): Observable<{
}> {
    return this.userShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `userResize()` */
  static readonly UserResizePath = '/yeastar/user/resize';

  /**
   * Resize User Capacities.
   *
   * Reisze User Capacities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userResize()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userResize$Response(params: UserResize$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return userResize(this.http, this.rootUrl, params, context);
  }

  /**
   * Resize User Capacities.
   *
   * Reisze User Capacities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userResize$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userResize(params: UserResize$Params, context?: HttpContext): Observable<{
}> {
    return this.userResize$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `userList()` */
  static readonly UserListPath = '/yeastar/user/list';

  /**
   * List PBX.
   *
   * Get list of Users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userList()` instead.
   *
   * This method doesn't expect any request body.
   */
  userList$Response(params?: UserList$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return userList(this.http, this.rootUrl, params, context);
  }

  /**
   * List PBX.
   *
   * Get list of Users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userList(params?: UserList$Params, context?: HttpContext): Observable<{
}> {
    return this.userList$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `userCreate()` */
  static readonly UserCreatePath = '/yeastar/user/create';

  /**
   * Create User.
   *
   * Create new User
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreate$Response(params: UserCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return userCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create User.
   *
   * Create new User
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreate(params: UserCreate$Params, context?: HttpContext): Observable<{
}> {
    return this.userCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `userDelete()` */
  static readonly UserDeletePath = '/yeastar/user/delete';

  /**
   * Delete User.
   *
   * Delete User
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDelete$Response(params: UserDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return userDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete User.
   *
   * Delete User
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDelete(params: UserDelete$Params, context?: HttpContext): Observable<{
}> {
    return this.userDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `systemCapacities()` */
  static readonly SystemCapacitiesPath = '/yeastar/system/capacities';

  /**
   * System Capacities.
   *
   * Display System Capacities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemCapacities()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemCapacities$Response(params?: SystemCapacities$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return systemCapacities(this.http, this.rootUrl, params, context);
  }

  /**
   * System Capacities.
   *
   * Display System Capacities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemCapacities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemCapacities(params?: SystemCapacities$Params, context?: HttpContext): Observable<{
}> {
    return this.systemCapacities$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `customerCreate()` */
  static readonly CustomerCreatePath = '/yeastar/customer/create';

  /**
   * Create Customer.
   *
   * Create new Customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customerCreate$Response(params: CustomerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return customerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Customer.
   *
   * Create new Customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customerCreate(params: CustomerCreate$Params, context?: HttpContext): Observable<{
}> {
    return this.customerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `customerList()` */
  static readonly CustomerListPath = '/yeastar/customer/list';

  /**
   * List Customers.
   *
   * Get list of Customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerList$Response(params?: CustomerList$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return customerList(this.http, this.rootUrl, params, context);
  }

  /**
   * List Customers.
   *
   * Get list of Customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerList(params?: CustomerList$Params, context?: HttpContext): Observable<{
}> {
    return this.customerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `customerDelete()` */
  static readonly CustomerDeletePath = '/yeastar/customer/delete';

  /**
   * Delete Customer.
   *
   * Delete Customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerDelete$Response(params: CustomerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return customerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Customer.
   *
   * Delete Customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerDelete(params: CustomerDelete$Params, context?: HttpContext): Observable<{
}> {
    return this.customerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `customerShow()` */
  static readonly CustomerShowPath = '/yeastar/customer/show';

  /**
   * Show Customer.
   *
   * Show Customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerShow$Response(params: CustomerShow$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return customerShow(this.http, this.rootUrl, params, context);
  }

  /**
   * Show Customer.
   *
   * Show Customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customerShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customerShow(params: CustomerShow$Params, context?: HttpContext): Observable<{
}> {
    return this.customerShow$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `customerUpdate()` */
  static readonly CustomerUpdatePath = '/yeastar/customer/update';

  /**
   * Update Customer.
   *
   * Update Customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customerUpdate$Response(params: CustomerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return customerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Customer.
   *
   * Update Customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customerUpdate(params: CustomerUpdate$Params, context?: HttpContext): Observable<{
}> {
    return this.customerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
