/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressResponse } from '../models/address-response';
import { CompanyAddressResponse } from '../models/company-address-response';
import { CompanyResponse } from '../models/company-response';
import { countCompanyAddress } from '../fn/company-address/count-company-address';
import { CountCompanyAddress$Params } from '../fn/company-address/count-company-address';
import { createCompanyAddress } from '../fn/company-address/create-company-address';
import { CreateCompanyAddress$Params } from '../fn/company-address/create-company-address';
import { deleteCompanyAddress } from '../fn/company-address/delete-company-address';
import { DeleteCompanyAddress$Params } from '../fn/company-address/delete-company-address';
import { fakeCompanyAddress } from '../fn/company-address/fake-company-address';
import { FakeCompanyAddress$Params } from '../fn/company-address/fake-company-address';
import { listCompanyAddress } from '../fn/company-address/list-company-address';
import { ListCompanyAddress$Params } from '../fn/company-address/list-company-address';
import { patchCompanyAddress } from '../fn/company-address/patch-company-address';
import { PatchCompanyAddress$Params } from '../fn/company-address/patch-company-address';
import { restoreCompanyAddress } from '../fn/company-address/restore-company-address';
import { RestoreCompanyAddress$Params } from '../fn/company-address/restore-company-address';
import { showCompanyAddress } from '../fn/company-address/show-company-address';
import { ShowCompanyAddress$Params } from '../fn/company-address/show-company-address';
import { showCompanyAddressAddress } from '../fn/company-address/show-company-address-address';
import { ShowCompanyAddressAddress$Params } from '../fn/company-address/show-company-address-address';
import { showCompanyAddressCompany } from '../fn/company-address/show-company-address-company';
import { ShowCompanyAddressCompany$Params } from '../fn/company-address/show-company-address-company';


/**
 * Company address resource
 */
@Injectable({ providedIn: 'root' })
export class CompanyAddressService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listCompanyAddress()` */
  static readonly ListCompanyAddressPath = '/companyAddresses';

  /**
   * List companyAddresses.
   *
   * List companyAddresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCompanyAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCompanyAddress$Response(params?: ListCompanyAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<CompanyAddressResponse>;
'total'?: number;
}>> {
    return listCompanyAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * List companyAddresses.
   *
   * List companyAddresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listCompanyAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCompanyAddress(params?: ListCompanyAddress$Params, context?: HttpContext): Observable<{
'data'?: Array<CompanyAddressResponse>;
'total'?: number;
}> {
    return this.listCompanyAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<CompanyAddressResponse>;
'total'?: number;
}>): {
'data'?: Array<CompanyAddressResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createCompanyAddress()` */
  static readonly CreateCompanyAddressPath = '/companyAddresses';

  /**
   * Create companyAddress.
   *
   * Create one companyAddress
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompanyAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyAddress$Response(params: CreateCompanyAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyAddressResponse>> {
    return createCompanyAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Create companyAddress.
   *
   * Create one companyAddress
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompanyAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyAddress(params: CreateCompanyAddress$Params, context?: HttpContext): Observable<CompanyAddressResponse> {
    return this.createCompanyAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyAddressResponse>): CompanyAddressResponse => r.body)
    );
  }

  /** Path part for operation `countCompanyAddress()` */
  static readonly CountCompanyAddressPath = '/companyAddresses/count';

  /**
   * Count companyAddresses.
   *
   * Count companyAddresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countCompanyAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  countCompanyAddress$Response(params?: CountCompanyAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countCompanyAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Count companyAddresses.
   *
   * Count companyAddresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countCompanyAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countCompanyAddress(params?: CountCompanyAddress$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countCompanyAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showCompanyAddress()` */
  static readonly ShowCompanyAddressPath = '/companyAddresses/{id}';

  /**
   * Show companyAddress.
   *
   * Show one companyAddress
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCompanyAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyAddress$Response(params: ShowCompanyAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyAddressResponse>> {
    return showCompanyAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Show companyAddress.
   *
   * Show one companyAddress
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCompanyAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyAddress(params: ShowCompanyAddress$Params, context?: HttpContext): Observable<CompanyAddressResponse> {
    return this.showCompanyAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyAddressResponse>): CompanyAddressResponse => r.body)
    );
  }

  /** Path part for operation `restoreCompanyAddress()` */
  static readonly RestoreCompanyAddressPath = '/companyAddresses/{id}';

  /**
   * Restore companyAddress.
   *
   * Restore companyAddress
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreCompanyAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreCompanyAddress$Response(params: RestoreCompanyAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyAddressResponse>> {
    return restoreCompanyAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore companyAddress.
   *
   * Restore companyAddress
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreCompanyAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreCompanyAddress(params: RestoreCompanyAddress$Params, context?: HttpContext): Observable<CompanyAddressResponse> {
    return this.restoreCompanyAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyAddressResponse>): CompanyAddressResponse => r.body)
    );
  }

  /** Path part for operation `deleteCompanyAddress()` */
  static readonly DeleteCompanyAddressPath = '/companyAddresses/{id}';

  /**
   * Delete companyAddress.
   *
   * Delete companyAddress (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyAddress$Response(params: DeleteCompanyAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCompanyAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete companyAddress.
   *
   * Delete companyAddress (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyAddress(params: DeleteCompanyAddress$Params, context?: HttpContext): Observable<void> {
    return this.deleteCompanyAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchCompanyAddress()` */
  static readonly PatchCompanyAddressPath = '/companyAddresses/{id}';

  /**
   * Patch companyAddress.
   *
   * Patch one companyAddress (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCompanyAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompanyAddress$Response(params: PatchCompanyAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyAddressResponse>> {
    return patchCompanyAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch companyAddress.
   *
   * Patch one companyAddress (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCompanyAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompanyAddress(params: PatchCompanyAddress$Params, context?: HttpContext): Observable<CompanyAddressResponse> {
    return this.patchCompanyAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyAddressResponse>): CompanyAddressResponse => r.body)
    );
  }

  /** Path part for operation `fakeCompanyAddress()` */
  static readonly FakeCompanyAddressPath = '/companyAddresses/faker';

  /**
   * Fake companyAddress.
   *
   * Generate fake companyAddress
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeCompanyAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeCompanyAddress$Response(params?: FakeCompanyAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyAddressResponse>> {
    return fakeCompanyAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake companyAddress.
   *
   * Generate fake companyAddress
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeCompanyAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeCompanyAddress(params?: FakeCompanyAddress$Params, context?: HttpContext): Observable<CompanyAddressResponse> {
    return this.fakeCompanyAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyAddressResponse>): CompanyAddressResponse => r.body)
    );
  }

  /** Path part for operation `showCompanyAddressCompany()` */
  static readonly ShowCompanyAddressCompanyPath = '/companyAddresses/{id}/company';

  /**
   * Show companyAddress company.
   *
   * Show companyAddress company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCompanyAddressCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyAddressCompany$Response(params: ShowCompanyAddressCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return showCompanyAddressCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Show companyAddress company.
   *
   * Show companyAddress company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCompanyAddressCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyAddressCompany(params: ShowCompanyAddressCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.showCompanyAddressCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `showCompanyAddressAddress()` */
  static readonly ShowCompanyAddressAddressPath = '/companyAddresses/{id}/address';

  /**
   * Show companyAddress address.
   *
   * Show companyAddress address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCompanyAddressAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyAddressAddress$Response(params: ShowCompanyAddressAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressResponse>> {
    return showCompanyAddressAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Show companyAddress address.
   *
   * Show companyAddress address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCompanyAddressAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyAddressAddress(params: ShowCompanyAddressAddress$Params, context?: HttpContext): Observable<AddressResponse> {
    return this.showCompanyAddressAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressResponse>): AddressResponse => r.body)
    );
  }

}
