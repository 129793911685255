import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 * Allows to send messages between components
 */
export class SignalInfoService {
  private error?: string;
  public isSearching = false;
  /**
   * Set the error message
   * @param val
   */
  public setError(val: string): void {
    this.error = val;
  }

  /**
   * Get the error message and delete it
   * @returns
   */
  public getLastError(): string | undefined {
    const tempString = this.error;
    this.error = undefined;
    return tempString;
  }
}
