import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

export const TOKEN_NAME = 'jwt_token';
export const REFRESH_TOKEN_NAME = 'refresh_jwt_token';

@Injectable({
  providedIn: 'root',
})
/**
 * Manage the token storage
 */
export class TokenStorageService {
  constructor() {}
  /**
   * Get the current token
   * @returns
   */
  getToken(): string | null {
    const token = localStorage.getItem(TOKEN_NAME);
    if (token) {
      return token;
    }

    return null;
  }

  /**
   * get the current refreshToken
   * @returns
   */
  getRefreshToken(): string | null {
    const token = localStorage.getItem(REFRESH_TOKEN_NAME);
    if (token) {
      return token;
    }

    return null;
  }

  /**
   * Set the token
   * @param token
   */
  setToken(token: string): void {
    if (token) {
      localStorage.setItem(TOKEN_NAME, token);
    }
  }

  /**
   * Set the refreshToken
   * @param token
   */
  setRefreshToken(token: string): void {
    if (token) {
      localStorage.setItem(REFRESH_TOKEN_NAME, token);
    }
  }

  /**
   * Delete token and refreshToken
   */
  deleteTokens() {
    localStorage.removeItem(REFRESH_TOKEN_NAME);
    localStorage.removeItem(TOKEN_NAME);
  }

  /**
   * Return the expiration date if token is valid
   * @param token
   * @returns
   */
  getTokenExpirationDate(token: string): Date | null {
    try {
      const decoded: any = jwtDecode(token);
      const date = new Date(0);
      date.setUTCSeconds(decoded.exp);
      return date;
    } catch {
      return null;
    }
  }

  /**
   * Check if token is valid
   * @param token
   * @returns
   */
  isTokenExpired(token?: string | null): boolean | null {
    if (!token) {
      token = this.getToken();
    }

    if (token) {
      const date = this.getTokenExpirationDate(token);
      if (date) {
        return !(date.valueOf() > new Date().valueOf());
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
