import { Injectable, Injector, Type } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseTemplateComponent } from '../model-templates/base.template';
import { templates } from '../model-templates';

@Injectable()
export class ModelTemplateService {
  constructor(private injector: Injector) {}

  /**
   * Get template provider
   *
   * @param name
   */
  getTemplateProvider(name: string): Type<BaseTemplateComponent> {
    const key = `${name}Template`;
    const provider = templates[key];

    return provider;
  }

  /**
   * Allows to check if template exist
   * @param model
   * @returns
   */
  checkTemplateName(model: string): boolean {
    const provider = this.getTemplateProvider(model);

    return typeof provider !== 'undefined';
  }

  /**
   * Get the template class from '../model-templates/' folder
   * @param model
   * @param action
   * @returns
   */
  getTemplateInstance(
    model: string,
    action: string,
    form?: UntypedFormGroup,
    data?: unknown,
  ): BaseTemplateComponent {
    const provider = this.getTemplateProvider(model);

    if (typeof provider === 'undefined') {
      console.error('getTemplateInstance', model, action);
      throw new Error('Undefined template: ' + model);
    }

    try {
      form ??= new UntypedFormGroup({});

      //Create a custom injector, to only create our template and set services
      const newInjector = Injector.create({
        providers: [provider],
        parent: this.injector,
      });
      //Use it to create our template and add attributes
      const template = newInjector.get(provider);
      Object.assign(template, {
        action,
        form,
        data,
        model: data,
      });
      return template;
    } catch (error) {
      console.error('getTemplateInstance', error);
      throw new Error('Invalid template: ' + model);
    }
  }
}
