/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface GeoSearch$Params {

/**
 * latitude point A
 */
  latA?: string;

/**
 * longitude point A
 */
  lngA?: string;

/**
 * latitude point B
 */
  latB?: string;

/**
 * longitude point B
 */
  lngB?: string;

/**
 * filter (building or relay)
 */
  filter: 'building' | 'relay';
}

export function geoSearch(http: HttpClient, rootUrl: string, params: GeoSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, geoSearch.PATH, 'get');
  if (params) {
    rb.query('latA', params.latA, {});
    rb.query('lngA', params.lngA, {});
    rb.query('latB', params.latB, {});
    rb.query('lngB', params.lngB, {});
    rb.query('filter', params.filter, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

geoSearch.PATH = '/geoJSON/search';
