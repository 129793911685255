/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserRoleResponse } from '../../models/user-role-response';

export interface ShowUserRole$Params {

/**
 * ID of the model
 */
  id: string;

/**
 * Select field
 */
  fields?: string;

/**
 * [Modify](https://vincit.github.io/objection.js/recipes/modifiers.html)
 */
  modifiers?: string;

/**
 * [Fetch graph](https://vincit.github.io/objection.js/api/query-builder/eager-methods.html#withgraphfetched)
 */
  fetch?: string;

/**
 * [Join graph](https://vincit.github.io/objection.js/api/query-builder/eager-methods.html#withgraphjoined)
 */
  join?: string;

/**
 * Debug (TODO: admin only)
 */
  debug?: boolean;
}

export function showUserRole(http: HttpClient, rootUrl: string, params: ShowUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<UserRoleResponse>> {
  const rb = new RequestBuilder(rootUrl, showUserRole.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('fields', params.fields, {});
    rb.query('modifiers', params.modifiers, {});
    rb.query('fetch', params.fetch, {});
    rb.query('join', params.join, {});
    rb.query('debug', params.debug, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UserRoleResponse>;
    })
  );
}

showUserRole.PATH = '/userRoles/{id}';
