/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressResponse } from '../models/address-response';
import { CompanyResponse } from '../models/company-response';
import { countCompany } from '../fn/company/count-company';
import { CountCompany$Params } from '../fn/company/count-company';
import { createCompany } from '../fn/company/create-company';
import { CreateCompany$Params } from '../fn/company/create-company';
import { createCompanyUsers } from '../fn/company/create-company-users';
import { CreateCompanyUsers$Params } from '../fn/company/create-company-users';
import { deleteCompany } from '../fn/company/delete-company';
import { DeleteCompany$Params } from '../fn/company/delete-company';
import { fakeCompany } from '../fn/company/fake-company';
import { FakeCompany$Params } from '../fn/company/fake-company';
import { listCompany } from '../fn/company/list-company';
import { ListCompany$Params } from '../fn/company/list-company';
import { patchCompany } from '../fn/company/patch-company';
import { PatchCompany$Params } from '../fn/company/patch-company';
import { patchCompanyUsers } from '../fn/company/patch-company-users';
import { PatchCompanyUsers$Params } from '../fn/company/patch-company-users';
import { restoreCompany } from '../fn/company/restore-company';
import { RestoreCompany$Params } from '../fn/company/restore-company';
import { showCompany } from '../fn/company/show-company';
import { ShowCompany$Params } from '../fn/company/show-company';
import { showCompanyAddresses } from '../fn/company/show-company-addresses';
import { ShowCompanyAddresses$Params } from '../fn/company/show-company-addresses';
import { showCompanyUsers } from '../fn/company/show-company-users';
import { ShowCompanyUsers$Params } from '../fn/company/show-company-users';
import { updateCompany } from '../fn/company/update-company';
import { UpdateCompany$Params } from '../fn/company/update-company';
import { UserResponse } from '../models/user-response';


/**
 * Company resource
 */
@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listCompany()` */
  static readonly ListCompanyPath = '/companies';

  /**
   * List companies.
   *
   * List companies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCompany$Response(params?: ListCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<CompanyResponse>;
'total'?: number;
}>> {
    return listCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * List companies.
   *
   * List companies
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCompany(params?: ListCompany$Params, context?: HttpContext): Observable<{
'data'?: Array<CompanyResponse>;
'total'?: number;
}> {
    return this.listCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<CompanyResponse>;
'total'?: number;
}>): {
'data'?: Array<CompanyResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createCompany()` */
  static readonly CreateCompanyPath = '/companies';

  /**
   * Create company.
   *
   * Create one company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompany$Response(params: CreateCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return createCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Create company.
   *
   * Create one company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompany(params: CreateCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.createCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `countCompany()` */
  static readonly CountCompanyPath = '/companies/count';

  /**
   * Count companies.
   *
   * Count companies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  countCompany$Response(params?: CountCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Count companies.
   *
   * Count companies
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countCompany(params?: CountCompany$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showCompany()` */
  static readonly ShowCompanyPath = '/companies/{id}';

  /**
   * Show company.
   *
   * Show one company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompany$Response(params: ShowCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return showCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Show company.
   *
   * Show one company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompany(params: ShowCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.showCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `updateCompany()` */
  static readonly UpdateCompanyPath = '/companies/{id}';

  /**
   * Update company.
   *
   * Update one company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompany$Response(params: UpdateCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return updateCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Update company.
   *
   * Update one company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompany(params: UpdateCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.updateCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `restoreCompany()` */
  static readonly RestoreCompanyPath = '/companies/{id}';

  /**
   * Restore company.
   *
   * Restore company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreCompany$Response(params: RestoreCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return restoreCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore company.
   *
   * Restore company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreCompany(params: RestoreCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.restoreCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `deleteCompany()` */
  static readonly DeleteCompanyPath = '/companies/{id}';

  /**
   * Delete company.
   *
   * Delete company (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompany$Response(params: DeleteCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete company.
   *
   * Delete company (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompany(params: DeleteCompany$Params, context?: HttpContext): Observable<void> {
    return this.deleteCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchCompany()` */
  static readonly PatchCompanyPath = '/companies/{id}';

  /**
   * Patch company.
   *
   * Patch one company (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompany$Response(params: PatchCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return patchCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch company.
   *
   * Patch one company (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompany(params: PatchCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.patchCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `fakeCompany()` */
  static readonly FakeCompanyPath = '/companies/faker';

  /**
   * Fake company.
   *
   * Generate fake company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeCompany$Response(params?: FakeCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return fakeCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake company.
   *
   * Generate fake company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeCompany(params?: FakeCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.fakeCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `showCompanyUsers()` */
  static readonly ShowCompanyUsersPath = '/companies/{id}/users';

  /**
   * Show company users.
   *
   * Show company users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCompanyUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyUsers$Response(params: ShowCompanyUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<UserResponse>;
}>> {
    return showCompanyUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Show company users.
   *
   * Show company users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCompanyUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyUsers(params: ShowCompanyUsers$Params, context?: HttpContext): Observable<{
'data'?: Array<UserResponse>;
}> {
    return this.showCompanyUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<UserResponse>;
}>): {
'data'?: Array<UserResponse>;
} => r.body)
    );
  }

  /** Path part for operation `createCompanyUsers()` */
  static readonly CreateCompanyUsersPath = '/companies/{id}/users';

  /**
   * Create company users.
   *
   * Create company users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompanyUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyUsers$Response(params: CreateCompanyUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createCompanyUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Create company users.
   *
   * Create company users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompanyUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyUsers(params: CreateCompanyUsers$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createCompanyUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchCompanyUsers()` */
  static readonly PatchCompanyUsersPath = '/companies/{id}/users';

  /**
   * Patch company users.
   *
   * Patch company users (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCompanyUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompanyUsers$Response(params: PatchCompanyUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchCompanyUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch company users.
   *
   * Patch company users (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCompanyUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompanyUsers(params: PatchCompanyUsers$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchCompanyUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `showCompanyAddresses()` */
  static readonly ShowCompanyAddressesPath = '/companies/{id}/addresses';

  /**
   * Show company addresses.
   *
   * Show company addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCompanyAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyAddresses$Response(params: ShowCompanyAddresses$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<AddressResponse>;
}>> {
    return showCompanyAddresses(this.http, this.rootUrl, params, context);
  }

  /**
   * Show company addresses.
   *
   * Show company addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCompanyAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCompanyAddresses(params: ShowCompanyAddresses$Params, context?: HttpContext): Observable<{
'data'?: Array<AddressResponse>;
}> {
    return this.showCompanyAddresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<AddressResponse>;
}>): {
'data'?: Array<AddressResponse>;
} => r.body)
    );
  }

}
