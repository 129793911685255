/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CategoryResponse } from '../models/category-response';
import { CompanyResponse } from '../models/company-response';
import { countPriceRule } from '../fn/price-rule/count-price-rule';
import { CountPriceRule$Params } from '../fn/price-rule/count-price-rule';
import { createPriceRule } from '../fn/price-rule/create-price-rule';
import { CreatePriceRule$Params } from '../fn/price-rule/create-price-rule';
import { createPriceRuleOffers } from '../fn/price-rule/create-price-rule-offers';
import { CreatePriceRuleOffers$Params } from '../fn/price-rule/create-price-rule-offers';
import { createPriceRulePlan } from '../fn/price-rule/create-price-rule-plan';
import { CreatePriceRulePlan$Params } from '../fn/price-rule/create-price-rule-plan';
import { deletePriceRule } from '../fn/price-rule/delete-price-rule';
import { DeletePriceRule$Params } from '../fn/price-rule/delete-price-rule';
import { fakePriceRule } from '../fn/price-rule/fake-price-rule';
import { FakePriceRule$Params } from '../fn/price-rule/fake-price-rule';
import { listPriceRule } from '../fn/price-rule/list-price-rule';
import { ListPriceRule$Params } from '../fn/price-rule/list-price-rule';
import { OfferResponse } from '../models/offer-response';
import { patchPosition } from '../fn/price-rule/patch-position';
import { PatchPosition$Params } from '../fn/price-rule/patch-position';
import { patchPriceRule } from '../fn/price-rule/patch-price-rule';
import { PatchPriceRule$Params } from '../fn/price-rule/patch-price-rule';
import { PlanResponse } from '../models/plan-response';
import { PriceRuleResponse } from '../models/price-rule-response';
import { restorePriceRule } from '../fn/price-rule/restore-price-rule';
import { RestorePriceRule$Params } from '../fn/price-rule/restore-price-rule';
import { showPriceRule } from '../fn/price-rule/show-price-rule';
import { ShowPriceRule$Params } from '../fn/price-rule/show-price-rule';
import { showPriceRuleCategories } from '../fn/price-rule/show-price-rule-categories';
import { ShowPriceRuleCategories$Params } from '../fn/price-rule/show-price-rule-categories';
import { showPriceRuleCustomers } from '../fn/price-rule/show-price-rule-customers';
import { ShowPriceRuleCustomers$Params } from '../fn/price-rule/show-price-rule-customers';
import { showPriceRuleOffers } from '../fn/price-rule/show-price-rule-offers';
import { ShowPriceRuleOffers$Params } from '../fn/price-rule/show-price-rule-offers';
import { showPriceRulePlan } from '../fn/price-rule/show-price-rule-plan';
import { ShowPriceRulePlan$Params } from '../fn/price-rule/show-price-rule-plan';
import { updatePriceRule } from '../fn/price-rule/update-price-rule';
import { UpdatePriceRule$Params } from '../fn/price-rule/update-price-rule';

@Injectable({ providedIn: 'root' })
export class PriceRuleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `patchPosition()` */
  static readonly PatchPositionPath = '/priceRules/positionReorder';

  /**
   * Reorder position.
   *
   * Reorder position
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPosition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPosition$Response(params: PatchPosition$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return patchPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * Reorder position.
   *
   * Reorder position
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPosition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPosition(params: PatchPosition$Params, context?: HttpContext): Observable<{
}> {
    return this.patchPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `listPriceRule()` */
  static readonly ListPriceRulePath = '/priceRules';

  /**
   * List priceRules.
   *
   * List priceRules
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPriceRule()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPriceRule$Response(params?: ListPriceRule$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<PriceRuleResponse>;
'total'?: number;
}>> {
    return listPriceRule(this.http, this.rootUrl, params, context);
  }

  /**
   * List priceRules.
   *
   * List priceRules
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listPriceRule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPriceRule(params?: ListPriceRule$Params, context?: HttpContext): Observable<{
'data'?: Array<PriceRuleResponse>;
'total'?: number;
}> {
    return this.listPriceRule$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<PriceRuleResponse>;
'total'?: number;
}>): {
'data'?: Array<PriceRuleResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createPriceRule()` */
  static readonly CreatePriceRulePath = '/priceRules';

  /**
   * Create priceRule.
   *
   * Create one priceRule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPriceRule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPriceRule$Response(params: CreatePriceRule$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceRuleResponse>> {
    return createPriceRule(this.http, this.rootUrl, params, context);
  }

  /**
   * Create priceRule.
   *
   * Create one priceRule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPriceRule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPriceRule(params: CreatePriceRule$Params, context?: HttpContext): Observable<PriceRuleResponse> {
    return this.createPriceRule$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceRuleResponse>): PriceRuleResponse => r.body)
    );
  }

  /** Path part for operation `countPriceRule()` */
  static readonly CountPriceRulePath = '/priceRules/count';

  /**
   * Count priceRules.
   *
   * Count priceRules
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countPriceRule()` instead.
   *
   * This method doesn't expect any request body.
   */
  countPriceRule$Response(params?: CountPriceRule$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countPriceRule(this.http, this.rootUrl, params, context);
  }

  /**
   * Count priceRules.
   *
   * Count priceRules
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countPriceRule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countPriceRule(params?: CountPriceRule$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countPriceRule$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showPriceRule()` */
  static readonly ShowPriceRulePath = '/priceRules/{id}';

  /**
   * Show priceRule.
   *
   * Show one priceRule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showPriceRule()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRule$Response(params: ShowPriceRule$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceRuleResponse>> {
    return showPriceRule(this.http, this.rootUrl, params, context);
  }

  /**
   * Show priceRule.
   *
   * Show one priceRule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showPriceRule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRule(params: ShowPriceRule$Params, context?: HttpContext): Observable<PriceRuleResponse> {
    return this.showPriceRule$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceRuleResponse>): PriceRuleResponse => r.body)
    );
  }

  /** Path part for operation `updatePriceRule()` */
  static readonly UpdatePriceRulePath = '/priceRules/{id}';

  /**
   * Update priceRule.
   *
   * Update one priceRule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePriceRule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePriceRule$Response(params: UpdatePriceRule$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceRuleResponse>> {
    return updatePriceRule(this.http, this.rootUrl, params, context);
  }

  /**
   * Update priceRule.
   *
   * Update one priceRule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePriceRule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePriceRule(params: UpdatePriceRule$Params, context?: HttpContext): Observable<PriceRuleResponse> {
    return this.updatePriceRule$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceRuleResponse>): PriceRuleResponse => r.body)
    );
  }

  /** Path part for operation `restorePriceRule()` */
  static readonly RestorePriceRulePath = '/priceRules/{id}';

  /**
   * Restore priceRule.
   *
   * Restore priceRule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restorePriceRule()` instead.
   *
   * This method doesn't expect any request body.
   */
  restorePriceRule$Response(params: RestorePriceRule$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceRuleResponse>> {
    return restorePriceRule(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore priceRule.
   *
   * Restore priceRule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restorePriceRule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restorePriceRule(params: RestorePriceRule$Params, context?: HttpContext): Observable<PriceRuleResponse> {
    return this.restorePriceRule$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceRuleResponse>): PriceRuleResponse => r.body)
    );
  }

  /** Path part for operation `deletePriceRule()` */
  static readonly DeletePriceRulePath = '/priceRules/{id}';

  /**
   * Delete priceRule.
   *
   * Delete priceRule (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePriceRule()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePriceRule$Response(params: DeletePriceRule$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deletePriceRule(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete priceRule.
   *
   * Delete priceRule (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePriceRule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePriceRule(params: DeletePriceRule$Params, context?: HttpContext): Observable<void> {
    return this.deletePriceRule$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchPriceRule()` */
  static readonly PatchPriceRulePath = '/priceRules/{id}';

  /**
   * Patch priceRule.
   *
   * Patch one priceRule (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPriceRule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPriceRule$Response(params: PatchPriceRule$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceRuleResponse>> {
    return patchPriceRule(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch priceRule.
   *
   * Patch one priceRule (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPriceRule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPriceRule(params: PatchPriceRule$Params, context?: HttpContext): Observable<PriceRuleResponse> {
    return this.patchPriceRule$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceRuleResponse>): PriceRuleResponse => r.body)
    );
  }

  /** Path part for operation `fakePriceRule()` */
  static readonly FakePriceRulePath = '/priceRules/faker';

  /**
   * Fake priceRule.
   *
   * Generate fake priceRule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakePriceRule()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakePriceRule$Response(params?: FakePriceRule$Params, context?: HttpContext): Observable<StrictHttpResponse<PriceRuleResponse>> {
    return fakePriceRule(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake priceRule.
   *
   * Generate fake priceRule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakePriceRule$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakePriceRule(params?: FakePriceRule$Params, context?: HttpContext): Observable<PriceRuleResponse> {
    return this.fakePriceRule$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriceRuleResponse>): PriceRuleResponse => r.body)
    );
  }

  /** Path part for operation `showPriceRuleCategories()` */
  static readonly ShowPriceRuleCategoriesPath = '/priceRules/{id}/categories';

  /**
   * Show priceRule categories.
   *
   * Show priceRule categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showPriceRuleCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRuleCategories$Response(params: ShowPriceRuleCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<CategoryResponse>;
}>> {
    return showPriceRuleCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * Show priceRule categories.
   *
   * Show priceRule categories
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showPriceRuleCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRuleCategories(params: ShowPriceRuleCategories$Params, context?: HttpContext): Observable<{
'data'?: Array<CategoryResponse>;
}> {
    return this.showPriceRuleCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<CategoryResponse>;
}>): {
'data'?: Array<CategoryResponse>;
} => r.body)
    );
  }

  /** Path part for operation `showPriceRuleOffers()` */
  static readonly ShowPriceRuleOffersPath = '/priceRules/{id}/offers';

  /**
   * Show priceRule offers.
   *
   * Show priceRule offers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showPriceRuleOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRuleOffers$Response(params: ShowPriceRuleOffers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OfferResponse>;
}>> {
    return showPriceRuleOffers(this.http, this.rootUrl, params, context);
  }

  /**
   * Show priceRule offers.
   *
   * Show priceRule offers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showPriceRuleOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRuleOffers(params: ShowPriceRuleOffers$Params, context?: HttpContext): Observable<{
'data'?: Array<OfferResponse>;
}> {
    return this.showPriceRuleOffers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OfferResponse>;
}>): {
'data'?: Array<OfferResponse>;
} => r.body)
    );
  }

  /** Path part for operation `createPriceRuleOffers()` */
  static readonly CreatePriceRuleOffersPath = '/priceRules/{id}/offers';

  /**
   * Create priceRule offers.
   *
   * Create priceRule offers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPriceRuleOffers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPriceRuleOffers$Response(params: CreatePriceRuleOffers$Params, context?: HttpContext): Observable<StrictHttpResponse<OfferResponse>> {
    return createPriceRuleOffers(this.http, this.rootUrl, params, context);
  }

  /**
   * Create priceRule offers.
   *
   * Create priceRule offers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPriceRuleOffers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPriceRuleOffers(params: CreatePriceRuleOffers$Params, context?: HttpContext): Observable<OfferResponse> {
    return this.createPriceRuleOffers$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfferResponse>): OfferResponse => r.body)
    );
  }

  /** Path part for operation `showPriceRuleCustomers()` */
  static readonly ShowPriceRuleCustomersPath = '/priceRules/{id}/customers';

  /**
   * Show priceRule customers.
   *
   * Show priceRule customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showPriceRuleCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRuleCustomers$Response(params: ShowPriceRuleCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<CompanyResponse>;
}>> {
    return showPriceRuleCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Show priceRule customers.
   *
   * Show priceRule customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showPriceRuleCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRuleCustomers(params: ShowPriceRuleCustomers$Params, context?: HttpContext): Observable<{
'data'?: Array<CompanyResponse>;
}> {
    return this.showPriceRuleCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<CompanyResponse>;
}>): {
'data'?: Array<CompanyResponse>;
} => r.body)
    );
  }

  /** Path part for operation `showPriceRulePlan()` */
  static readonly ShowPriceRulePlanPath = '/priceRules/{id}/plan';

  /**
   * Show priceRule plan.
   *
   * Show priceRule plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showPriceRulePlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRulePlan$Response(params: ShowPriceRulePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return showPriceRulePlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Show priceRule plan.
   *
   * Show priceRule plan
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showPriceRulePlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showPriceRulePlan(params: ShowPriceRulePlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.showPriceRulePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

  /** Path part for operation `createPriceRulePlan()` */
  static readonly CreatePriceRulePlanPath = '/priceRules/{id}/plan';

  /**
   * Create priceRule plan.
   *
   * Create priceRule plan
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPriceRulePlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPriceRulePlan$Response(params: CreatePriceRulePlan$Params, context?: HttpContext): Observable<StrictHttpResponse<PlanResponse>> {
    return createPriceRulePlan(this.http, this.rootUrl, params, context);
  }

  /**
   * Create priceRule plan.
   *
   * Create priceRule plan
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPriceRulePlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPriceRulePlan(params: CreatePriceRulePlan$Params, context?: HttpContext): Observable<PlanResponse> {
    return this.createPriceRulePlan$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanResponse>): PlanResponse => r.body)
    );
  }

}
