/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface PbxCreate$Params {
      body: {
'name': string;
'domain': string;
'versionId': number;
'customerIds': Array<number>;
'extension': number;
'concurrentCall': number;
'recordingCapacity'?: number;
'callRecording'?: boolean;
'type': string;
'expireTime'?: string;
'pbxApiAvailable'?: boolean;
}
}

export function pbxCreate(http: HttpClient, rootUrl: string, params: PbxCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, pbxCreate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

pbxCreate.PATH = '/yeastar/pbx/create';
