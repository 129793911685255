import { Injectable, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalFormTemplateComponent } from '../components/modal-template/modal-form-template.component';
import { ModalTableTemplateComponent } from '../components/modal-template/modal-table-template.component';
import { UtilsService } from './utils.service';
// import { templates } from '../model-templates';

/**
 * Allow the creation of modals
 */
@Injectable()
export class CreateModalService {
  constructor(
    public modalService: NgbModal,
    private translate: TranslateService,
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.modalService.activeInstances.subscribe((list) => {
      //console.log(list.length);
    });
  }

  /**
   * Create a new modal with dynamic model form inside
   * @param properties
   * @returns
   */
  newModalForm(properties: {
    action: string;
    model: string;
    id?: string;
    title?: string;
    form?: UntypedFormGroup;
    onSuccess?: (event: Event, activeModel: NgbActiveModal) => void;
    beforeDismissMessage?: string;
    modalOptions?: NgbModalOptions;
    size?: 'sm' | 'lg' | 'xl';
  }): NgbModalRef {
    // const template = new templates[properties.model + 'Template'](this.injector);
    properties.title ??= this.utils.capitalize(
      this.translate.instant(`ACTION.${properties.action}`) +
        ' ' +
        this.translate.instant(`MODEL.${properties.model}`),
    );

    //Create the formGroup here to get the ref
    properties.form ??= new UntypedFormGroup({});

    let optionsModal: NgbModalOptions = {
      size: properties.size ?? 'lg',
      beforeDismiss: () => {
        if (properties.form!.dirty) {
          return confirm(
            this.translate.instant(
              properties.beforeDismissMessage ?? 'ALERT.DIRTY_CHECK',
            ),
          );
        }
        return true;
      },
    };

    optionsModal = Object.assign(optionsModal, properties.modalOptions);
    const modalRef = this.modalService.open(
      ModalFormTemplateComponent,
      optionsModal,
    );

    const modalComponent =
      modalRef.componentInstance as ModalFormTemplateComponent;
    Object.assign(modalComponent, properties);

    return modalRef;
  }

  newModalTable(properties: {
    model: string;
    action: string;
    id?: string;
    related?: string;
    size?: 'sm' | 'lg' | 'xl';
  }): NgbModalRef {
    const optionsModal: NgbModalOptions = {
      size: properties.size ?? 'lg',
    };
    const modalRef = this.modalService.open(
      ModalTableTemplateComponent,
      optionsModal,
    );

    const modalComponent =
      modalRef.componentInstance as ModalFormTemplateComponent;
    Object.assign(modalComponent, properties);

    return modalRef;
  }
}
