import { Component } from '@angular/core';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { BaseTemplateComponent } from './base.template';
import { TenantResponse } from 'src/api/models/tenant-response';
import { DynamicModelFormRender } from 'src/app/shared/models/dynamic-model-form-render';
// import { TenantService } from 'src/api/services';

@Component({
  selector: 'tenant-template',
  templateUrl: './tenant.template.html',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TenantTemplate extends BaseTemplateComponent<TenantResponse> {
  override icon = 'globe';
  // public tenantService = this.injector.get(TenantService);

  // constructor(override injector: Injector,
  //   public tenantService: TenantService) {
  //   super(injector);
  // }

  public getForm(): DynamicModelFormRender {
    return {
      model: 'Tenant',
      action: this.action,
      fields: {
        name: {
          label: 'Nom',
          type: 'input',
          class: 'col-9 col-sm-8 col-md-6',
          required: true,
        },
        enabled: {
          type: 'checkbox',
          class:
            'col-3 col-sm-3 offset-sm-1 col-md-4 offset-md-2 d-flex align-items-end mb-2',
          props: {
            label: 'Actif',
          },
        },
        parentId: {
          label: 'Instance parent',
          type: 'select',
          class: 'col-6',
          required: true,
          props: {
            resource: {
              model: 'tenant',
            },
          },
        },
        logo: {
          type: 'file',
          label: 'logo',
          ignore: true,
          props: {
            editorOptions: {
              aspectRatio: 16 / 9,
              viewMode: 3,
              resizeToWidth: 1000,
              modalSize: 'lg',
            },
          },
          onChange: (field, event) => {
            console.log('onChange', { field, event });
            const logo = event as any; // FIXME
            if (logo) {
              console.warn('TODO: upload image');
              // this.tenantService.uploadImages({ body: { logo } }).subscribe({
              //   next: val => {
              //     console.log(val.logoUrl);
              //     (<any>input).config.controls.logoUrl.setValue(val.logoUrl);
              //   }
              // });
            }
          },
        },
        hostnames: {
          label: 'Domains',
          type: 'repeat',
          // hideExpression: (model:any, formState:any, field:any) => {
          //   return field.parent.model.name
          // },
          fieldArray: {
            type: 'input',
            onChange(field, event) {
              // if (!field.parent.parent.model.name)
              //   field.form.controls.forEach((c: any) => {
              //     c.setValue('');
              //   })
              // console.log(field.form)
            },
            // expressionProperties: {
            //   'templateOptions.label': '"Numéro " + field.key'
            // }
          },
        },
        config: {
          wrappers: ['panel'],
          label: 'Configuration',
          fieldGroup: {
            logoUrl: {
              noImport: true,
              type: 'input',
              label: 'Logo',
              onChange: (field) => {},
            },
            mail: {
              noImport: true,
              fieldGroup: {
                host: {
                  type: 'input',
                  noImport: true,
                },
                port: {
                  type: 'input',
                  noImport: true,
                },
                secure: {
                  type: 'checkbox',
                  noImport: true,
                },
                user: {
                  type: 'input',
                  noImport: true,
                  // validators: {
                  //   validation: ['email'],
                  // },
                },
                pass: {
                  noImport: true,
                  hide: this.action != 'create',
                  type: 'input',
                },
                // 'from' : {

                // },
                // 'replyTo' : {

                // },
                // 'to' : {

                // },
                subjectPrefix: {
                  noImport: true,
                  type: 'input',
                  placeholder: '[EXAMPLE] ',
                },
              },
            },
            resetPasswordUrl: {
              label: 'Password URL',
              noImport: true,
              type: 'input',
              // required: true
            },
          },
        },
      },
    };
  }

  public getTable(): DynamicTableRender {
    return {
      buttons: this.getButtons('Tenant'),
      actionsCol: this.getActions('Tenant'),
      columns: {
        id: {
          type: 'input',
          className: 'no-export',
        },
        name: {
          title: 'Nom',
          type: 'input',
        },
        enabled: {
          title: 'Actif',
          type: 'boolean',
          width: '1%',
        },
        // clientId: {
        //   title: 'API client ID',
        //   className: 'no-export',
        // },
        updatedAt: true,
        createdAt: true,
      },
    } as DynamicTableRender;
  }
}
