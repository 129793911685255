/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countOrderStatus } from '../fn/order-status/count-order-status';
import { CountOrderStatus$Params } from '../fn/order-status/count-order-status';
import { createOrderStatus } from '../fn/order-status/create-order-status';
import { CreateOrderStatus$Params } from '../fn/order-status/create-order-status';
import { deleteOrderStatus } from '../fn/order-status/delete-order-status';
import { DeleteOrderStatus$Params } from '../fn/order-status/delete-order-status';
import { fakeOrderStatus } from '../fn/order-status/fake-order-status';
import { FakeOrderStatus$Params } from '../fn/order-status/fake-order-status';
import { listOrderStatus } from '../fn/order-status/list-order-status';
import { ListOrderStatus$Params } from '../fn/order-status/list-order-status';
import { OrderStatusResponse } from '../models/order-status-response';
import { patchOrderStatus } from '../fn/order-status/patch-order-status';
import { PatchOrderStatus$Params } from '../fn/order-status/patch-order-status';
import { restoreOrderStatus } from '../fn/order-status/restore-order-status';
import { RestoreOrderStatus$Params } from '../fn/order-status/restore-order-status';
import { showOrderStatus } from '../fn/order-status/show-order-status';
import { ShowOrderStatus$Params } from '../fn/order-status/show-order-status';

@Injectable({ providedIn: 'root' })
export class OrderStatusService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listOrderStatus()` */
  static readonly ListOrderStatusPath = '/statuses';

  /**
   * List statuses.
   *
   * List statuses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrderStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrderStatus$Response(params?: ListOrderStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OrderStatusResponse>;
'total'?: number;
}>> {
    return listOrderStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * List statuses.
   *
   * List statuses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrderStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrderStatus(params?: ListOrderStatus$Params, context?: HttpContext): Observable<{
'data'?: Array<OrderStatusResponse>;
'total'?: number;
}> {
    return this.listOrderStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OrderStatusResponse>;
'total'?: number;
}>): {
'data'?: Array<OrderStatusResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createOrderStatus()` */
  static readonly CreateOrderStatusPath = '/statuses';

  /**
   * Create orderStatus.
   *
   * Create one orderStatus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrderStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderStatus$Response(params: CreateOrderStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderStatusResponse>> {
    return createOrderStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Create orderStatus.
   *
   * Create one orderStatus
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrderStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderStatus(params: CreateOrderStatus$Params, context?: HttpContext): Observable<OrderStatusResponse> {
    return this.createOrderStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderStatusResponse>): OrderStatusResponse => r.body)
    );
  }

  /** Path part for operation `countOrderStatus()` */
  static readonly CountOrderStatusPath = '/statuses/count';

  /**
   * Count statuses.
   *
   * Count statuses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countOrderStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOrderStatus$Response(params?: CountOrderStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countOrderStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Count statuses.
   *
   * Count statuses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countOrderStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOrderStatus(params?: CountOrderStatus$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countOrderStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showOrderStatus()` */
  static readonly ShowOrderStatusPath = '/statuses/{id}';

  /**
   * Show orderStatus.
   *
   * Show one orderStatus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderStatus$Response(params: ShowOrderStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderStatusResponse>> {
    return showOrderStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderStatus.
   *
   * Show one orderStatus
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderStatus(params: ShowOrderStatus$Params, context?: HttpContext): Observable<OrderStatusResponse> {
    return this.showOrderStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderStatusResponse>): OrderStatusResponse => r.body)
    );
  }

  /** Path part for operation `restoreOrderStatus()` */
  static readonly RestoreOrderStatusPath = '/statuses/{id}';

  /**
   * Restore orderStatus.
   *
   * Restore orderStatus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreOrderStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOrderStatus$Response(params: RestoreOrderStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderStatusResponse>> {
    return restoreOrderStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore orderStatus.
   *
   * Restore orderStatus
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreOrderStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOrderStatus(params: RestoreOrderStatus$Params, context?: HttpContext): Observable<OrderStatusResponse> {
    return this.restoreOrderStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderStatusResponse>): OrderStatusResponse => r.body)
    );
  }

  /** Path part for operation `deleteOrderStatus()` */
  static readonly DeleteOrderStatusPath = '/statuses/{id}';

  /**
   * Delete orderStatus.
   *
   * Delete orderStatus (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrderStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderStatus$Response(params: DeleteOrderStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOrderStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete orderStatus.
   *
   * Delete orderStatus (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOrderStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderStatus(params: DeleteOrderStatus$Params, context?: HttpContext): Observable<void> {
    return this.deleteOrderStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchOrderStatus()` */
  static readonly PatchOrderStatusPath = '/statuses/{id}';

  /**
   * Patch orderStatus.
   *
   * Patch one orderStatus (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrderStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderStatus$Response(params: PatchOrderStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderStatusResponse>> {
    return patchOrderStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch orderStatus.
   *
   * Patch one orderStatus (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrderStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderStatus(params: PatchOrderStatus$Params, context?: HttpContext): Observable<OrderStatusResponse> {
    return this.patchOrderStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderStatusResponse>): OrderStatusResponse => r.body)
    );
  }

  /** Path part for operation `fakeOrderStatus()` */
  static readonly FakeOrderStatusPath = '/statuses/faker';

  /**
   * Fake orderStatus.
   *
   * Generate fake orderStatus
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeOrderStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOrderStatus$Response(params?: FakeOrderStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderStatusResponse>> {
    return fakeOrderStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake orderStatus.
   *
   * Generate fake orderStatus
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeOrderStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOrderStatus(params?: FakeOrderStatus$Params, context?: HttpContext): Observable<OrderStatusResponse> {
    return this.fakeOrderStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderStatusResponse>): OrderStatusResponse => r.body)
    );
  }

}
