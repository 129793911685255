/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressResponse } from '../models/address-response';
import { CategoryResponse } from '../models/category-response';
import { CompanyResponse } from '../models/company-response';
import { countOrderLinkOption } from '../fn/order-link-option/count-order-link-option';
import { CountOrderLinkOption$Params } from '../fn/order-link-option/count-order-link-option';
import { createOrderLinkOption } from '../fn/order-link-option/create-order-link-option';
import { CreateOrderLinkOption$Params } from '../fn/order-link-option/create-order-link-option';
import { createOrderLinkOptionContact } from '../fn/order-link-option/create-order-link-option-contact';
import { CreateOrderLinkOptionContact$Params } from '../fn/order-link-option/create-order-link-option-contact';
import { createOrderLinkOptionUser } from '../fn/order-link-option/create-order-link-option-user';
import { CreateOrderLinkOptionUser$Params } from '../fn/order-link-option/create-order-link-option-user';
import { deleteOrderLinkOption } from '../fn/order-link-option/delete-order-link-option';
import { DeleteOrderLinkOption$Params } from '../fn/order-link-option/delete-order-link-option';
import { fakeOrderLinkOption } from '../fn/order-link-option/fake-order-link-option';
import { FakeOrderLinkOption$Params } from '../fn/order-link-option/fake-order-link-option';
import { listOrderLinkOption } from '../fn/order-link-option/list-order-link-option';
import { ListOrderLinkOption$Params } from '../fn/order-link-option/list-order-link-option';
import { OrderLinkOptionResponse } from '../models/order-link-option-response';
import { patchOrderLinkOption } from '../fn/order-link-option/patch-order-link-option';
import { PatchOrderLinkOption$Params } from '../fn/order-link-option/patch-order-link-option';
import { patchOrderLinkOptionContact } from '../fn/order-link-option/patch-order-link-option-contact';
import { PatchOrderLinkOptionContact$Params } from '../fn/order-link-option/patch-order-link-option-contact';
import { patchOrderLinkOptionUser } from '../fn/order-link-option/patch-order-link-option-user';
import { PatchOrderLinkOptionUser$Params } from '../fn/order-link-option/patch-order-link-option-user';
import { restoreOrderLinkOption } from '../fn/order-link-option/restore-order-link-option';
import { RestoreOrderLinkOption$Params } from '../fn/order-link-option/restore-order-link-option';
import { showOrderLinkOption } from '../fn/order-link-option/show-order-link-option';
import { ShowOrderLinkOption$Params } from '../fn/order-link-option/show-order-link-option';
import { showOrderLinkOptionAddress } from '../fn/order-link-option/show-order-link-option-address';
import { ShowOrderLinkOptionAddress$Params } from '../fn/order-link-option/show-order-link-option-address';
import { showOrderLinkOptionCategory } from '../fn/order-link-option/show-order-link-option-category';
import { ShowOrderLinkOptionCategory$Params } from '../fn/order-link-option/show-order-link-option-category';
import { showOrderLinkOptionCompany } from '../fn/order-link-option/show-order-link-option-company';
import { ShowOrderLinkOptionCompany$Params } from '../fn/order-link-option/show-order-link-option-company';
import { showOrderLinkOptionContact } from '../fn/order-link-option/show-order-link-option-contact';
import { ShowOrderLinkOptionContact$Params } from '../fn/order-link-option/show-order-link-option-contact';
import { showOrderLinkOptionUser } from '../fn/order-link-option/show-order-link-option-user';
import { ShowOrderLinkOptionUser$Params } from '../fn/order-link-option/show-order-link-option-user';
import { UserResponse } from '../models/user-response';


/**
 * Connectivity options
 */
@Injectable({ providedIn: 'root' })
export class OrderLinkOptionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listOrderLinkOption()` */
  static readonly ListOrderLinkOptionPath = '/linkOptions';

  /**
   * List linkOptions.
   *
   * List linkOptions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listOrderLinkOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrderLinkOption$Response(params?: ListOrderLinkOption$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<OrderLinkOptionResponse>;
'total'?: number;
}>> {
    return listOrderLinkOption(this.http, this.rootUrl, params, context);
  }

  /**
   * List linkOptions.
   *
   * List linkOptions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listOrderLinkOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listOrderLinkOption(params?: ListOrderLinkOption$Params, context?: HttpContext): Observable<{
'data'?: Array<OrderLinkOptionResponse>;
'total'?: number;
}> {
    return this.listOrderLinkOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<OrderLinkOptionResponse>;
'total'?: number;
}>): {
'data'?: Array<OrderLinkOptionResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createOrderLinkOption()` */
  static readonly CreateOrderLinkOptionPath = '/linkOptions';

  /**
   * Create orderLinkOption.
   *
   * Create one orderLinkOption
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrderLinkOption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkOption$Response(params: CreateOrderLinkOption$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkOptionResponse>> {
    return createOrderLinkOption(this.http, this.rootUrl, params, context);
  }

  /**
   * Create orderLinkOption.
   *
   * Create one orderLinkOption
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrderLinkOption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkOption(params: CreateOrderLinkOption$Params, context?: HttpContext): Observable<OrderLinkOptionResponse> {
    return this.createOrderLinkOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkOptionResponse>): OrderLinkOptionResponse => r.body)
    );
  }

  /** Path part for operation `countOrderLinkOption()` */
  static readonly CountOrderLinkOptionPath = '/linkOptions/count';

  /**
   * Count linkOptions.
   *
   * Count linkOptions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countOrderLinkOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOrderLinkOption$Response(params?: CountOrderLinkOption$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countOrderLinkOption(this.http, this.rootUrl, params, context);
  }

  /**
   * Count linkOptions.
   *
   * Count linkOptions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countOrderLinkOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countOrderLinkOption(params?: CountOrderLinkOption$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countOrderLinkOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showOrderLinkOption()` */
  static readonly ShowOrderLinkOptionPath = '/linkOptions/{id}';

  /**
   * Show orderLinkOption.
   *
   * Show one orderLinkOption
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOption$Response(params: ShowOrderLinkOption$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkOptionResponse>> {
    return showOrderLinkOption(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLinkOption.
   *
   * Show one orderLinkOption
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOption(params: ShowOrderLinkOption$Params, context?: HttpContext): Observable<OrderLinkOptionResponse> {
    return this.showOrderLinkOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkOptionResponse>): OrderLinkOptionResponse => r.body)
    );
  }

  /** Path part for operation `restoreOrderLinkOption()` */
  static readonly RestoreOrderLinkOptionPath = '/linkOptions/{id}';

  /**
   * Restore orderLinkOption.
   *
   * Restore orderLinkOption
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreOrderLinkOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOrderLinkOption$Response(params: RestoreOrderLinkOption$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkOptionResponse>> {
    return restoreOrderLinkOption(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore orderLinkOption.
   *
   * Restore orderLinkOption
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreOrderLinkOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreOrderLinkOption(params: RestoreOrderLinkOption$Params, context?: HttpContext): Observable<OrderLinkOptionResponse> {
    return this.restoreOrderLinkOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkOptionResponse>): OrderLinkOptionResponse => r.body)
    );
  }

  /** Path part for operation `deleteOrderLinkOption()` */
  static readonly DeleteOrderLinkOptionPath = '/linkOptions/{id}';

  /**
   * Delete orderLinkOption.
   *
   * Delete orderLinkOption (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOrderLinkOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderLinkOption$Response(params: DeleteOrderLinkOption$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOrderLinkOption(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete orderLinkOption.
   *
   * Delete orderLinkOption (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOrderLinkOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOrderLinkOption(params: DeleteOrderLinkOption$Params, context?: HttpContext): Observable<void> {
    return this.deleteOrderLinkOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchOrderLinkOption()` */
  static readonly PatchOrderLinkOptionPath = '/linkOptions/{id}';

  /**
   * Patch orderLinkOption.
   *
   * Patch one orderLinkOption (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrderLinkOption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkOption$Response(params: PatchOrderLinkOption$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkOptionResponse>> {
    return patchOrderLinkOption(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch orderLinkOption.
   *
   * Patch one orderLinkOption (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrderLinkOption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkOption(params: PatchOrderLinkOption$Params, context?: HttpContext): Observable<OrderLinkOptionResponse> {
    return this.patchOrderLinkOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkOptionResponse>): OrderLinkOptionResponse => r.body)
    );
  }

  /** Path part for operation `fakeOrderLinkOption()` */
  static readonly FakeOrderLinkOptionPath = '/linkOptions/faker';

  /**
   * Fake orderLinkOption.
   *
   * Generate fake orderLinkOption
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeOrderLinkOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOrderLinkOption$Response(params?: FakeOrderLinkOption$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderLinkOptionResponse>> {
    return fakeOrderLinkOption(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake orderLinkOption.
   *
   * Generate fake orderLinkOption
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeOrderLinkOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeOrderLinkOption(params?: FakeOrderLinkOption$Params, context?: HttpContext): Observable<OrderLinkOptionResponse> {
    return this.fakeOrderLinkOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderLinkOptionResponse>): OrderLinkOptionResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkOptionAddress()` */
  static readonly ShowOrderLinkOptionAddressPath = '/linkOptions/{id}/address';

  /**
   * Show orderLinkOption address.
   *
   * Show orderLinkOption address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkOptionAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionAddress$Response(params: ShowOrderLinkOptionAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressResponse>> {
    return showOrderLinkOptionAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLinkOption address.
   *
   * Show orderLinkOption address
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkOptionAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionAddress(params: ShowOrderLinkOptionAddress$Params, context?: HttpContext): Observable<AddressResponse> {
    return this.showOrderLinkOptionAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressResponse>): AddressResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkOptionContact()` */
  static readonly ShowOrderLinkOptionContactPath = '/linkOptions/{id}/contact';

  /**
   * Show orderLinkOption contact.
   *
   * Show orderLinkOption contact
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkOptionContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionContact$Response(params: ShowOrderLinkOptionContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return showOrderLinkOptionContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLinkOption contact.
   *
   * Show orderLinkOption contact
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkOptionContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionContact(params: ShowOrderLinkOptionContact$Params, context?: HttpContext): Observable<UserResponse> {
    return this.showOrderLinkOptionContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `createOrderLinkOptionContact()` */
  static readonly CreateOrderLinkOptionContactPath = '/linkOptions/{id}/contact';

  /**
   * Create orderLinkOption contact.
   *
   * Create orderLinkOption contact
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrderLinkOptionContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkOptionContact$Response(params: CreateOrderLinkOptionContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createOrderLinkOptionContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Create orderLinkOption contact.
   *
   * Create orderLinkOption contact
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrderLinkOptionContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkOptionContact(params: CreateOrderLinkOptionContact$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createOrderLinkOptionContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchOrderLinkOptionContact()` */
  static readonly PatchOrderLinkOptionContactPath = '/linkOptions/{id}/contact';

  /**
   * Patch orderLinkOption contact.
   *
   * Patch orderLinkOption contact (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrderLinkOptionContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkOptionContact$Response(params: PatchOrderLinkOptionContact$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchOrderLinkOptionContact(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch orderLinkOption contact.
   *
   * Patch orderLinkOption contact (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrderLinkOptionContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkOptionContact(params: PatchOrderLinkOptionContact$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchOrderLinkOptionContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkOptionCompany()` */
  static readonly ShowOrderLinkOptionCompanyPath = '/linkOptions/{id}/company';

  /**
   * Show orderLinkOption company.
   *
   * Show orderLinkOption company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkOptionCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionCompany$Response(params: ShowOrderLinkOptionCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return showOrderLinkOptionCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLinkOption company.
   *
   * Show orderLinkOption company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkOptionCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionCompany(params: ShowOrderLinkOptionCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.showOrderLinkOptionCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkOptionUser()` */
  static readonly ShowOrderLinkOptionUserPath = '/linkOptions/{id}/user';

  /**
   * Show orderLinkOption user.
   *
   * Show orderLinkOption user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkOptionUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionUser$Response(params: ShowOrderLinkOptionUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return showOrderLinkOptionUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLinkOption user.
   *
   * Show orderLinkOption user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkOptionUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionUser(params: ShowOrderLinkOptionUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.showOrderLinkOptionUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `createOrderLinkOptionUser()` */
  static readonly CreateOrderLinkOptionUserPath = '/linkOptions/{id}/user';

  /**
   * Create orderLinkOption user.
   *
   * Create orderLinkOption user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrderLinkOptionUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkOptionUser$Response(params: CreateOrderLinkOptionUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createOrderLinkOptionUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create orderLinkOption user.
   *
   * Create orderLinkOption user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrderLinkOptionUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrderLinkOptionUser(params: CreateOrderLinkOptionUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createOrderLinkOptionUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchOrderLinkOptionUser()` */
  static readonly PatchOrderLinkOptionUserPath = '/linkOptions/{id}/user';

  /**
   * Patch orderLinkOption user.
   *
   * Patch orderLinkOption user (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOrderLinkOptionUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkOptionUser$Response(params: PatchOrderLinkOptionUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchOrderLinkOptionUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch orderLinkOption user.
   *
   * Patch orderLinkOption user (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchOrderLinkOptionUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchOrderLinkOptionUser(params: PatchOrderLinkOptionUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchOrderLinkOptionUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `showOrderLinkOptionCategory()` */
  static readonly ShowOrderLinkOptionCategoryPath = '/linkOptions/{id}/category';

  /**
   * Show orderLinkOption category.
   *
   * Show orderLinkOption category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showOrderLinkOptionCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionCategory$Response(params: ShowOrderLinkOptionCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryResponse>> {
    return showOrderLinkOptionCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Show orderLinkOption category.
   *
   * Show orderLinkOption category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showOrderLinkOptionCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showOrderLinkOptionCategory(params: ShowOrderLinkOptionCategory$Params, context?: HttpContext): Observable<CategoryResponse> {
    return this.showOrderLinkOptionCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryResponse>): CategoryResponse => r.body)
    );
  }

}
