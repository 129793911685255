import { Component } from '@angular/core';
import { map } from 'rxjs';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { DynamicModelFormRender } from '../models/dynamic-model-form-render';
import { BaseTemplateComponent } from './base.template';

@Component({
  selector: 'app-static-plan-template',
  templateUrl: './static-plan.template.html',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class StaticPlanTemplate extends BaseTemplateComponent<unknown> {
  public override getForm(): DynamicModelFormRender {
    return {
      model: 'Offer',
      action: this.action,
      fields: {
        type: {
          type: 'select',
          class: 'col-6',
          required: true,
          defaultValue: 'margin_rate',
          props: {
            label: 'Type',
            options: [
              { id: 'multiplier', name: 'Coefficient' },
              { id: 'margin_rate', name: 'Taux de Marge' },
            ],
            valueProp: 'id',
            labelProp: 'name',
          },
        },
        margin: {
          type: 'input-slider',
          required: true,
          defaultValue: 1,
          props: {
            suffix: '%',
            label: 'Marge',
            min: 1,
            max: 100,
          },
          expressions: {
            'props.label':
              'model.type == "margin_rate" ? "Taux de Marge" : "Coefficient"',
            'props.type': 'model.type',
          },
        },
        categories: {
          type: 'select',
          noImport: true,
          props: {
            label: 'Catégories',
            multiple: true,
            resource: {
              model: 'category',
            },
            labelProp: 'tenantName',
          },
        },
        offers: {
          type: 'select',
          noImport: true,
          class: 'col-6',
          props: {
            label: 'Offres',
            multiple: true,
            resource: {
              model: 'offer',
            },
            paramsBuilder: {
              limit: 50,
            },
            labelProp: 'name',
          },
        },
        providers: {
          type: 'select',
          noImport: true,
          class: 'col-6',
          props: {
            label: 'Fournisseurs',
            multiple: true,
            options: this.eligibilityService
              .listProviders$Response(
                this.paramsBuilderService.build({
                  fullSearch: {
                    whereILike: {
                      label: this.model.whereLike ?? '%%',
                    },
                  },
                }),
              )
              .pipe(
                map((res: any) => {
                  res.body.providers.forEach((provider: any) => {
                    delete provider.isNew;
                    delete provider.status;
                  });
                  return res.body.providers;
                }),
              ),
            labelProp: 'label',
          },
        },
        simulate: {
          type: 'checkbox',
          defaultValue: false,
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    throw new Error('Method not implemented.');
  }
}
