import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import 'moment/locale/fr';
import { SettingsModel } from '../models/settings';

const SETTINGS_STORAGE = 'settings_storage';

@Injectable({
  providedIn: 'root',
})
/**
 * Store all the settings of the user
 */
export class SettingsService {
  private _settings: SettingsModel;
  public getSettings() {
    return this._settings;
  }

  public settingsEmitter: Subject<SettingsModel>;
  constructor(private translate: TranslateService) {
    this.settingsEmitter = new Subject<SettingsModel>();

    const browserLang = translate.getBrowserLang() as string;
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

    const localSettings = localStorage.getItem(SETTINGS_STORAGE);
    this._settings = JSON.parse(
      localSettings && localSettings != '' ? localSettings : '{}',
    );

    if (this._settings && this._settings.language) {
      translate.use(this._settings.language);
      moment.locale(this._settings.language);
    }

    // Detect locale change and update moment config
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(event.lang);
    });

    // Translations not loaded on first start workaround:
    // https://github.com/ngx-translate/core/issues/1178#issuecomment-591869841
    translate.setDefaultLang('fr');
    translate.setDefaultLang('en');

    const defaultSettings: SettingsModel = {
      limitRequest: 10,
      formLock: false,
      formLockType: 'pin',
      language: translate.currentLang,
      pin: 1234,
      timeBeforeLock: 300000, //5 min
    };
    this._settings = Object.assign(defaultSettings, this._settings);

    translate.onLangChange.subscribe((val) => {
      // translate.setDefaultLang(this.translate.currentLang);
      this.setSettings('language', val.lang);
    });
  }

  /**
   * Set a specific value, and notify subscriber
   * @param key
   * @param value
   */
  setSettings(key: string, value: any) {
    try {
      (<any>this._settings)[key] = value;
    } catch {
      console.error('invalid key settings !');
    }

    localStorage.setItem(SETTINGS_STORAGE, JSON.stringify(this._settings));
    this.settingsEmitter.next(this._settings);
    return this._settings;
  }
}
