/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { globalConfig } from '../fn/global/global-config';
import { GlobalConfig$Params } from '../fn/global/global-config';
import { index } from '../fn/global/index';
import { Index$Params } from '../fn/global/index';


/**
 * Global routes
 */
@Injectable({ providedIn: 'root' })
export class GlobalService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `index()` */
  static readonly IndexPath = '/';

  /**
   * API index
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `index()` instead.
   *
   * This method doesn't expect any request body.
   */
  index$Response(params?: Index$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'name'?: string;
'version'?: string;
'description'?: string;
'documentation'?: string;
'resources'?: Array<{
}>;
'data'?: Array<{
'model'?: string;
'resource'?: string;
}>;
}>> {
    return index(this.http, this.rootUrl, params, context);
  }

  /**
   * API index
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `index$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  index(params?: Index$Params, context?: HttpContext): Observable<{
'name'?: string;
'version'?: string;
'description'?: string;
'documentation'?: string;
'resources'?: Array<{
}>;
'data'?: Array<{
'model'?: string;
'resource'?: string;
}>;
}> {
    return this.index$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'name'?: string;
'version'?: string;
'description'?: string;
'documentation'?: string;
'resources'?: Array<{
}>;
'data'?: Array<{
'model'?: string;
'resource'?: string;
}>;
}>): {
'name'?: string;
'version'?: string;
'description'?: string;
'documentation'?: string;
'resources'?: Array<{
}>;
'data'?: Array<{
'model'?: string;
'resource'?: string;
}>;
} => r.body)
    );
  }

  /** Path part for operation `globalConfig()` */
  static readonly GlobalConfigPath = '/config';

  /**
   * API public configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `globalConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  globalConfig$Response(params?: GlobalConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'logoUrl'?: string;
'resetPasswordUrl'?: string;
}>> {
    return globalConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * API public configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `globalConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  globalConfig(params?: GlobalConfig$Params, context?: HttpContext): Observable<{
'logoUrl'?: string;
'resetPasswordUrl'?: string;
}> {
    return this.globalConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'logoUrl'?: string;
'resetPasswordUrl'?: string;
}>): {
'logoUrl'?: string;
'resetPasswordUrl'?: string;
} => r.body)
    );
  }

}
