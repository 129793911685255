<div class="d-flex justify-content-between"></div>

<!-- <span class="badge bg-secondary">  ${data.name} </span>
    <div class="icon">
      <div class="arrow"></div>
    </div> -->

<div>
  <div class="d-flex justify-content-center mb-5">Regle Marge</div>
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <div class="arrowA"></div>
      Setup : +50%
    </div>
    <div class="d-flex">
      <div class="arrowA"></div>
      Resil : +75%
    </div>
    <div class="d-flex">
      <div class="arrowA"></div>
      Abo : +5%
    </div>
  </div>
</div>
