/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AddressResponse } from '../models/address-response';
import { CompanyResponse } from '../models/company-response';
import { countUser } from '../fn/user/count-user';
import { CountUser$Params } from '../fn/user/count-user';
import { createUser } from '../fn/user/create-user';
import { CreateUser$Params } from '../fn/user/create-user';
import { deleteUser } from '../fn/user/delete-user';
import { DeleteUser$Params } from '../fn/user/delete-user';
import { fakeUser } from '../fn/user/fake-user';
import { FakeUser$Params } from '../fn/user/fake-user';
import { listUser } from '../fn/user/list-user';
import { ListUser$Params } from '../fn/user/list-user';
import { patchUser } from '../fn/user/patch-user';
import { PatchUser$Params } from '../fn/user/patch-user';
import { RefreshTokenResponse } from '../models/refresh-token-response';
import { restoreUser } from '../fn/user/restore-user';
import { RestoreUser$Params } from '../fn/user/restore-user';
import { showUser } from '../fn/user/show-user';
import { ShowUser$Params } from '../fn/user/show-user';
import { showUserAddresses } from '../fn/user/show-user-addresses';
import { ShowUserAddresses$Params } from '../fn/user/show-user-addresses';
import { showUserCompany } from '../fn/user/show-user-company';
import { ShowUserCompany$Params } from '../fn/user/show-user-company';
import { showUserRefreshTokens } from '../fn/user/show-user-refresh-tokens';
import { ShowUserRefreshTokens$Params } from '../fn/user/show-user-refresh-tokens';
import { showUserTenant } from '../fn/user/show-user-tenant';
import { ShowUserTenant$Params } from '../fn/user/show-user-tenant';
import { TenantResponse } from '../models/tenant-response';
import { updateUser } from '../fn/user/update-user';
import { UpdateUser$Params } from '../fn/user/update-user';
import { UserResponse } from '../models/user-response';


/**
 * User resource
 */
@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listUser()` */
  static readonly ListUserPath = '/users';

  /**
   * List users.
   *
   * List users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUser$Response(params?: ListUser$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<UserResponse>;
'total'?: number;
}>> {
    return listUser(this.http, this.rootUrl, params, context);
  }

  /**
   * List users.
   *
   * List users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUser(params?: ListUser$Params, context?: HttpContext): Observable<{
'data'?: Array<UserResponse>;
'total'?: number;
}> {
    return this.listUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<UserResponse>;
'total'?: number;
}>): {
'data'?: Array<UserResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createUser()` */
  static readonly CreateUserPath = '/users';

  /**
   * Create user.
   *
   * Create one user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: CreateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Create user.
   *
   * Create one user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: CreateUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `countUser()` */
  static readonly CountUserPath = '/users/count';

  /**
   * Count users.
   *
   * Count users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  countUser$Response(params?: CountUser$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Count users.
   *
   * Count users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countUser(params?: CountUser$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showUser()` */
  static readonly ShowUserPath = '/users/{id}';

  /**
   * Show user.
   *
   * Show one user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUser$Response(params: ShowUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return showUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Show user.
   *
   * Show one user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUser(params: ShowUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.showUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/users/{id}';

  /**
   * Update user.
   *
   * Update one user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: UpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return updateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Update user.
   *
   * Update one user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: UpdateUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.updateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `restoreUser()` */
  static readonly RestoreUserPath = '/users/{id}';

  /**
   * Restore user.
   *
   * Restore user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreUser$Response(params: RestoreUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return restoreUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore user.
   *
   * Restore user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreUser(params: RestoreUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.restoreUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `deleteUser()` */
  static readonly DeleteUserPath = '/users/{id}';

  /**
   * Delete user.
   *
   * Delete user (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: DeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete user.
   *
   * Delete user (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: DeleteUser$Params, context?: HttpContext): Observable<void> {
    return this.deleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchUser()` */
  static readonly PatchUserPath = '/users/{id}';

  /**
   * Patch user.
   *
   * Patch one user (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUser$Response(params: PatchUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch user.
   *
   * Patch one user (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUser(params: PatchUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `fakeUser()` */
  static readonly FakeUserPath = '/users/faker';

  /**
   * Fake user.
   *
   * Generate fake user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeUser$Response(params?: FakeUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return fakeUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake user.
   *
   * Generate fake user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeUser(params?: FakeUser$Params, context?: HttpContext): Observable<UserResponse> {
    return this.fakeUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `showUserCompany()` */
  static readonly ShowUserCompanyPath = '/users/{id}/company';

  /**
   * Show user company.
   *
   * Show user company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showUserCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserCompany$Response(params: ShowUserCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyResponse>> {
    return showUserCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Show user company.
   *
   * Show user company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showUserCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserCompany(params: ShowUserCompany$Params, context?: HttpContext): Observable<CompanyResponse> {
    return this.showUserCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyResponse>): CompanyResponse => r.body)
    );
  }

  /** Path part for operation `showUserAddresses()` */
  static readonly ShowUserAddressesPath = '/users/{id}/addresses';

  /**
   * Show user addresses.
   *
   * Show user addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showUserAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserAddresses$Response(params: ShowUserAddresses$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<AddressResponse>;
}>> {
    return showUserAddresses(this.http, this.rootUrl, params, context);
  }

  /**
   * Show user addresses.
   *
   * Show user addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showUserAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserAddresses(params: ShowUserAddresses$Params, context?: HttpContext): Observable<{
'data'?: Array<AddressResponse>;
}> {
    return this.showUserAddresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<AddressResponse>;
}>): {
'data'?: Array<AddressResponse>;
} => r.body)
    );
  }

  /** Path part for operation `showUserTenant()` */
  static readonly ShowUserTenantPath = '/users/{id}/tenant';

  /**
   * Show user tenant.
   *
   * Show user tenant
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showUserTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserTenant$Response(params: ShowUserTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantResponse>> {
    return showUserTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Show user tenant.
   *
   * Show user tenant
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showUserTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserTenant(params: ShowUserTenant$Params, context?: HttpContext): Observable<TenantResponse> {
    return this.showUserTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantResponse>): TenantResponse => r.body)
    );
  }

  /** Path part for operation `showUserRefreshTokens()` */
  static readonly ShowUserRefreshTokensPath = '/users/{id}/refreshTokens';

  /**
   * Show user refreshTokens.
   *
   * Show user refreshTokens
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showUserRefreshTokens()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserRefreshTokens$Response(params: ShowUserRefreshTokens$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<RefreshTokenResponse>;
}>> {
    return showUserRefreshTokens(this.http, this.rootUrl, params, context);
  }

  /**
   * Show user refreshTokens.
   *
   * Show user refreshTokens
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showUserRefreshTokens$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showUserRefreshTokens(params: ShowUserRefreshTokens$Params, context?: HttpContext): Observable<{
'data'?: Array<RefreshTokenResponse>;
}> {
    return this.showUserRefreshTokens$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<RefreshTokenResponse>;
}>): {
'data'?: Array<RefreshTokenResponse>;
} => r.body)
    );
  }

}
