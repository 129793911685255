/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface VCenterVmPower$Params {

/**
 * Node ID
 */
  node: string;
      body: {
'vmId': string;
'action': 'powerOn' | 'powerOff' | 'suspend' | 'reset';
}
}

export function vCenterVmPower(http: HttpClient, rootUrl: string, params: VCenterVmPower$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, vCenterVmPower.PATH, 'post');
  if (params) {
    rb.path('node', params.node, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

vCenterVmPower.PATH = '/vcenter/{node}/vm/power';
