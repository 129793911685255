import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { BaseTemplateComponent } from './base.template';
import { DynamicModelFormRender } from 'src/app/shared/models/dynamic-model-form-render';

@Component({
  standalone: true,
  imports: [
    // CommonModule,
    RouterModule,
    TranslateModule,
  ],
  templateUrl: './order.template.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OrderTemplate extends BaseTemplateComponent<{
  id?: string;
  code?: string;
  name?: string;
  label?: string;
  description?: string;
  reference?: string;
  v1Id?: number;
  type?: string;
  status?: string;
  ndi?: string;
  enabled?: boolean;
  startsAt?: string;
  endsAt?: string;
  // vlan?: string;
  meta?: any;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  provider?: {
    name?: string;
  };
  category?: {
    name?: string;
    typeSlug?: string;
  };
  building?: {
    code?: string;
    name?: string;
    stairs?: string;
    floor?: string;
  };
  company?: {
    id?: string;
    name?: string;
    v1Id?: number;
    siret?: string;
    tenant?: {
      name?: string;
    };
  };
  tenant?: {
    id: string;
    name: string;
  };
  user?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    name?: string;
    v1Id?: number;
  };
  product?: {
    name?: string;
    label?: string;
    type?: string;
    techno?: string;
  };
  address?: {
    houseNumber?: string;
    roadType?: string;
    road?: string;
    postcode?: string;
    inseeCode?: string;
    rivoliCode?: string;
    city?: string;
    hexacle?: string;
    fullAddress?: string;
  };
  contact?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  };
  appointment?: {
    name?: string;
    tel?: string;
    site?: string;
    code?: string;
    startDate?: string;
    endDate?: string;
  };
  options?: Array<{
    name?: string;
    code?: string;
  }>;
  comments?: Array<{
    name?: string;
    content?: string;
    createdAt?: string;
    user?: {
      firstName?: string;
      lastName?: string;
      email?: string;
      v1Id?: number;
    };
  }>;
  realm?: string;
}> {
  override render = (data: any) => {
    return data;
  };

  status = (status: any) => {
    let color = 'primary';
    const statusTranslated = this.translate.instant('STATUS.' + status);
    switch (status) {
      case 'IN_PRODUCTION':
      case 'CONFIRMED':
      case 'VALIDATED':
        color = 'success';
        break;
      case 'CLOSING':
      case 'CANCELED':
        color = 'danger';
        break;
      case 'IN_PROGRESS':
        color = 'warning';
        break;
      case 'TERMINATED':
      case 'WAITING':
        color = 'info';
        break;
    }
    return `<span class="badge text-bg-${color}">${statusTranslated}</span>`;
  };

  public getForm(): DynamicModelFormRender {
    const self = this;
    return {
      model: 'Order',
      action: this.action,
      fields: {
        Order: {
          type: 'tab-form',
          tabs: {
            General: {
              props: {
                title: 'Général',
              },
              fieldGroup: {
                id: {
                  key: 'id',
                  type: 'input',
                  noExport: true,
                  props: {
                    type: 'hidden',
                  },
                },
                status: {
                  // 19/09/2023 : status can't be send/set in orders
                  type: 'select',
                  label: 'Status',
                  class: 'col-6',
                  required: true,
                  props: {
                    options: [
                      { value: 'IN_PROGRESS', label: 'En cours' },
                      { value: 'IN_PRODUCTION', label: 'En production' },
                      { value: 'CLOSING', label: 'En clôture' },
                      { value: 'TERMINATED', label: 'Terminé' },
                      { value: 'CANCELED', label: 'Annulé' },
                      { value: 'WAITING', label: 'En attente' },
                      { value: 'CONFIRMED', label: 'Confirmé' },
                      { value: 'VALIDATED', label: 'Validé' },
                      { value: 'ALL', label: 'Tous' },
                      { value: 'INITIAL', label: 'Initial' },
                    ],
                    labelProp: 'name',
                    mapOptions: (data: any) => {
                      return {
                        id: data.value,
                        name: this.translate.instant('STATUS.' + data.value),
                      };
                    },
                  },
                  hooks: {
                    onInit: (field: any) => {
                      setTimeout(() => {
                        //Wait for the field to be initialized
                        field.formControl.setValue(
                          this.translate.instant(
                            'STATUS.' + this.data.status ?? 'INITIAL',
                          ),
                        );
                      });
                    },
                  },
                },
                tenant: {
                  type: 'select',
                  label: "Entreprise de l'offre",
                  class: 'col-6',
                  props: {
                    resource: {
                      model: 'tenant',
                    },
                    mapOptions: (data: any) => {
                      return {
                        id: data.id,
                        name: data.name,
                      };
                    },
                    labelProp: 'name',
                  },
                },
                category: {
                  type: 'select',
                  label: 'Catégorie',
                  class: 'col-6',
                  props: {
                    resource: {
                      model: 'category',
                    },
                    labelProp: 'name',
                  },
                },
                code: {
                  type: 'input',
                  label: 'Code',
                  class: 'col-6',
                  required: true,
                },
                reference: {
                  type: 'input',
                  label: 'Référence',
                  class: 'col-6',
                },
                startsAt: {
                  type: 'datepicker',
                  label: 'Date de début',
                  class: 'col-6',
                  props: {
                    type: 'date',
                    defaultValue: this.data.startsAt ?? new Date(),
                  },
                },
                endsAt: {
                  type: 'datepicker',
                  label: 'Date de fin',
                  class: 'col-6 mb-2',
                  props: {
                    type: 'date',
                    defaultValue: this.data.endsAt,
                    disabled: this.data.endsAt == null,
                  },
                },
                enabled: {
                  type: 'checkbox',
                  label: 'Actif',
                  class:
                    'col-6 d-flex align-items-center justify-content-center mb-2 mt-25',
                  props: {
                    defaultValue: this.data.enabled ?? false,
                  },
                },
                label: {
                  type: 'input',
                  label: 'Libellé client',
                  class: 'col-6',
                  hooks: {
                    onInit: (field: any) => {
                      setTimeout(() => {
                        //Wait for the field to be initialized
                        field.formControl.setValue(
                          this.data.label ?? this.data.name ?? '',
                        );
                      });
                    },
                  },
                },
                name: {
                  type: 'input',
                  label: 'Offre',
                  class: 'col-6',
                  name: 'Offre',
                  required: true,
                },
                description: {
                  type: 'textarea',
                  label: 'Description',
                  class: 'col-12',
                  props: {
                    rows: 4,
                  },
                },
                provider: {
                  type: 'select',
                  label: 'Fournisseur',
                  class: 'col-6',
                  props: {
                    options: this.eligibilityService
                      .listProviders$Response()
                      .pipe(
                        map((data) => {
                          data.body.providers?.map((provider: any) => {
                            provider.name = this.utilsService.capitalize(
                              provider.name,
                            );
                          });
                          return data.body.providers ?? [];
                        }),
                      ),
                    labelProp: 'name',
                    mapOptions: (data: any) => {
                      return {
                        id: data.id,
                        name: data.name,
                      };
                    },
                  },
                },
                //--------------- Début partie facturation ---------------
                pannelInfo: {
                  type: 'alert',
                  props: {
                    color: 'danger',
                  },
                  expressions: {
                    'props.message': () => {
                      return this.model.editPrice == 'Oui'
                        ? "Les valeurs ci-dessous représente le prix de vente de l'entreprise FLEX NETWORK"
                        : `Les valeurs ci-dessous représente le prix de vente de l'entreprise ${this.model.company.tenant.name}`;
                    },
                  },
                },
                editPrice: {
                  type: 'radio',
                  class: 'col-4',
                  defaultValue: 'Oui',
                  props: {
                    options: [
                      { value: 'Oui', label: 'Oui' },
                      { value: 'Non', label: 'Non' },
                    ],
                    label: 'Éditer le prix de vente de FLEX NETWORK',
                    mapOptions: (data: any) => {
                      return {
                        id: data.value,
                        name: data.label,
                      };
                    },
                  },
                },
                blank: {
                  type: 'access-panel',
                  class: 'col-2',
                },
                isFas: {
                  type: 'checkbox',
                  class:
                    'col-2 d-flex align-items-center justify-content-center mt-25',
                  label: 'Frais de mise en service',
                  defaultValue: true,
                },
                recur: {
                  type: 'select',
                  class: 'col-2 mt-2',
                  label: 'Récurrence',
                  defaultValue: 'Mensuelle',
                  props: {
                    options: [
                      { value: 'Mensuelle', label: 'Mensuelle' },
                      { value: 'Trimestrielle', label: 'Trimestrielle' },
                      { value: 'Annuelle', label: 'Annuelle' },
                    ],
                    mapOptions: (data: any) => {
                      return {
                        id: data.value,
                        name: data.label,
                      };
                    },
                  },
                },
                terminanceFee: {
                  type: 'checkbox',
                  class:
                    'col-2 d-flex align-items-center justify-content-center mt-25',
                  label: 'Frais de résiliation',
                  defaultValue: true,
                },
                quantity: {
                  type: 'input',
                  class: 'col-2 mt-2',
                  label: 'Quantité',
                  defaultValue: 1,
                  props: {
                    type: 'number',
                  },
                },
                prorata: {
                  type: 'checkbox',
                  class:
                    'col-2 d-flex align-items-center justify-content-center mt-25',
                  label: 'Au prorata',
                  defaultValue: true,
                },
                blank2: {
                  type: 'access-panel',
                  class: 'col-2',
                },
                priceFas: {
                  type: 'input',
                  class:
                    'col-2 d-flex align-items-center justify-content-center mt-25',
                  defaultValue: 0,
                },
                priceRecur: {
                  type: 'input',
                  class:
                    'col-2 d-flex align-items-center justify-content-center mt-25',
                  defaultValue: 0,
                },
                priceResil: {
                  type: 'input',
                  class:
                    'col-2 d-flex align-items-center justify-content-center mt-25',
                  defaultValue: 0,
                },
                vat: {
                  type: 'input',
                  class: 'col-2',
                  label: 'TVA',
                  defaultValue: 20,
                },
                autoliquidation: {
                  type: 'radio',
                  class: 'col-2',
                  defaultValue: 'Non',
                  props: {
                    options: [
                      { value: 'Oui', label: 'Oui' },
                      { value: 'Non', label: 'Non' },
                    ],
                    label: 'Autoliquidation',
                    mapOptions: (data: any) => {
                      return {
                        id: data.value,
                        name: data.label,
                      };
                    },
                  },
                },
                blank3: {
                  type: 'access-panel',
                  class: 'col-4',
                },
                engagement: {
                  type: 'select',
                  class: 'col-2',
                  label: 'Engagement (mois)',
                  props: {
                    options: [
                      {
                        id: null,
                        name: "Pas d'engagement",
                      },
                      {
                        id: 1,
                        name: '1',
                      },
                      {
                        id: 12,
                        name: '12',
                      },
                      {
                        id: 24,
                        name: '24',
                      },
                      {
                        id: 36,
                        name: '36',
                      },
                    ],
                    labelProp: 'name',
                    valueProp: 'id',
                  },
                },
                facturationMode: {
                  type: 'radio',
                  class: 'col-2',
                  defaultValue: 'Avant',
                  props: {
                    options: [
                      { value: 'Avant', label: 'Avant' },
                      { value: 'Après', label: 'Après' },
                    ],
                    mapOptions: (data: any) => {
                      return {
                        id: data.value,
                        name: data.label,
                      };
                    },
                    label: 'Mode de facturation',
                  },
                },
                //--------------- Fin partie facturation ---------------
                userDeploy: {
                  type: 'select',
                  label: 'Deploiement traité par',
                  class: 'col-6',
                  props: {
                    resource: {
                      model: 'user',
                    },
                    paramsBuilder: {
                      fields: ['firstName', 'lastName', 'email'],
                      fullSearch: {
                        where: {
                          companyId: this.data.company?.id,
                        },
                      },
                    },
                    labelProp: 'name',
                    mapOptions: (data: any) => {
                      return {
                        id: data.id,
                        name: data.lastName + ' ' + data.firstName,
                      };
                    },
                  },
                },
                // 'data.vlan': {
                //   type: 'input',
                //   label: 'VLAN',
                //   class: 'col-6',
                // },
              },
            },
            Details: {
              props: {
                title: 'Détails',
              },
              fieldGroup: {},
            },
            Gestion: {
              props: {
                title: 'Gestion',
              },
              fieldGroup: {
                revendeur: {
                  type: 'select',
                  label: 'Revendeur',
                  class: 'col-6',
                  defaultValue: this.data.tenant,
                  props: {
                    resource: {
                      model: 'tenant',
                    },
                    mapOptions: (data: any) => {
                      return {
                        id: data.id,
                        name: data.name,
                      };
                    },
                    labelProp: 'name',
                    required: true,
                    actions: [
                      {
                        class: 'btn btn-primary',
                        type: 'button',
                        title: "Modifier l'entreprise",
                        icon: 'pencil',
                        onClick(event: Event, action) {
                          if (action.data.value) {
                            self.createModalService.newModalForm({
                              action: 'patch',
                              model: 'tenant',
                              id: action.data.value.id,
                            });
                          }
                        },
                        hidden: (data) => !data.data.value,
                        role: 'manager',
                      },
                      {
                        type: 'button',
                        class: 'btn btn-success',
                        onClick(event: Event, data: any) {
                          self.createModalService.newModalForm({
                            action: 'create',
                            model: 'tenant',
                          });
                        },
                        title: 'Nouveaux tenant',
                        icon: 'plus',
                      },
                    ],
                  },
                },
                utilisateur: {
                  type: 'select',
                  label: 'Utilisateur',
                  class: 'col-6',
                  defaultValue: this.data.user,
                  props: {
                    resource: {
                      model: 'user',
                    },
                    paramsBuilder: {
                      fields: ['firstName', 'lastName', 'email'],
                      fullSearch: {
                        where: {
                          companyId: this.data.company?.id,
                        },
                      },
                    },
                    mapOptions: (data: any) => {
                      return {
                        id: data,
                        name: data.email,
                      };
                    },
                    labelProp: 'email',
                    actions: [
                      {
                        class: 'btn btn-primary',
                        type: 'button',
                        title: "Modifier l'utilisateur",
                        icon: 'pencil',
                        onClick(event: Event, action) {
                          if (action.data.value) {
                            self.createModalService.newModalForm({
                              action: 'patch',
                              model: 'user',
                              id: action.data.value.id,
                            });
                          }
                        },
                        hidden: (data) => !data.data.value,
                        role: 'manager',
                      },
                      {
                        type: 'button',
                        class: 'btn btn-success',
                        onClick(event: Event, data: any) {
                          self.createModalService.newModalForm({
                            action: 'create',
                            model: 'user',
                          });
                        },
                        title: 'Nouvel utilisateur',
                        icon: 'plus',
                      },
                    ],
                  },
                },

                client: {
                  type: 'select',
                  label: 'Client',
                  class: 'col-6',
                  defaultValue: this.data.company,
                  props: {
                    resource: {
                      model: 'company',
                    },
                    paramsBuilder: {
                      fullSearch: {
                        where: {
                          tenantId: this.data.tenant?.id,
                        },
                      },
                    },
                    mapOptions: (data: any) => {
                      return {
                        id: data,
                        name: data.name,
                      };
                    },
                    labelProp: 'name',
                    required: true,
                    actions: [
                      {
                        class: 'btn btn-primary',
                        type: 'button',
                        title: "Modifier l'entreprise",
                        icon: 'pencil',
                        onClick(event: Event, action) {
                          if (action.data.value) {
                            self.createModalService.newModalForm({
                              action: 'patch',
                              model: 'company',
                              id: action.data.value.id,
                            });
                          }
                        },
                        hidden: (data) => !data.data.value,
                        role: 'manager',
                      },
                      {
                        type: 'button',
                        class: 'btn btn-success',
                        onClick(event: Event, data: any) {
                          self.createModalService.newModalForm({
                            action: 'create',
                            model: 'company',
                          });
                        },
                        title: 'Nouvelle entreprise',
                        icon: 'plus',
                      },
                    ],
                  },
                },
                // TODO:
                // clientId: {
                //   label: 'Client',
                //   // translate: true,
                //   type: 'select',
                //   class: 'col-6',
                //   defaultValue: this.data?.company?.name,
                //   required: true,
                //   props: {
                //     resource: 'company',
                //   },
                // },

                site: {
                  type: 'select',
                  label: 'Site',
                  class: 'col-6',
                  defaultValue: this.data.address,
                  props: {
                    resource: {
                      model: 'company',
                      related: 'Addresses',
                      method: 'show',
                      params: { id: this.data.company?.id },
                    },
                    paramsBuilder: {
                      fields: [
                        'id',
                        'houseNumber',
                        'roadType',
                        'road',
                        'postcode',
                        'inseeCode',
                        'rivoliCode',
                        'city',
                        'hexacle',
                        'country',
                        'countryCode',
                      ],
                    },
                    labelProp: 'fullAddress',
                    mapOptions: (data: any) => {
                      return {
                        id: data,
                        name: data.fullAddress,
                      };
                    },
                    actions: [
                      {
                        class: 'btn btn-primary',
                        type: 'button',
                        title: "Modifier l'adresse",
                        icon: 'pencil',
                        onClick(event: Event, action) {
                          if (action.data.value) {
                            self.createModalService.newModalForm({
                              action: 'patch',
                              model: 'address',
                              id: action.data.value.id,
                            });
                          }
                        },
                        hidden: (data) => !data.data.value,
                        role: 'manager',
                      },
                      {
                        type: 'button',
                        class: 'btn btn-success',
                        onClick(event: Event, data: any) {
                          self.createModalService.newModalForm({
                            action: 'create',
                            model: 'companyAddresss',
                          });
                        },
                        title: 'Nouvelle adresse',
                        icon: 'plus',
                      },
                    ],
                  },
                  expressions: {
                    'props.defaultValue': () => {
                      if (this.data?.address) {
                        return `${this.data?.address?.houseNumber ?? ''} ${
                          this.data?.address?.road ?? ''
                        } ${this.data?.address?.postcode ?? ''} ${
                          this.data?.address?.city ?? ''
                        }`;
                      }
                      return null;
                    },
                  },
                },
                // TODO:
                // siteId: {
                //   label: 'Site',
                //   // translate: true,
                //   type: 'select',
                //   class: 'col-6',
                //   // defaultValue: this.data?.address?.id,
                //   required: true,
                //   props: {
                //     resource: 'companyAddress',
                //   },
                // },

                contact: {
                  type: 'select',
                  label: 'Contact',
                  class: 'col-12',
                  props: {
                    resource: {
                      model: 'contact',
                    },
                    paramsBuilder: {
                      fields: ['*'],
                    },
                    mapOptions: (data: any) => {
                      return {
                        id: data,
                        name: data.fullName,
                      };
                    },
                    labelProp: 'fullName',
                    actions: [
                      {
                        class: 'btn btn-primary',
                        type: 'button',
                        title: 'Modifier le contact',
                        icon: 'pencil',
                        onClick(event: Event, action) {
                          if (action.data.value) {
                            self.createModalService.newModalForm({
                              action: 'patch',
                              model: 'contact',
                              id: action.data.value.id,
                            });
                          }
                        },
                        hidden: (data) => !data.data.value,
                        role: 'manager',
                      },
                      {
                        type: 'button',
                        class: 'btn btn-success',
                        onClick(event: Event, data: any) {
                          self.createModalService.newModalForm({
                            action: 'create',
                            model: 'contact',
                          });
                        },
                        title: 'Nouveau contact',
                        icon: 'plus',
                      },
                    ],
                  },
                  expressions: {
                    'props.defaultValue': () => {
                      if (this.data.contact) {
                        return `${this.data.contact?.firstName} ${this.data.contact?.lastName}`;
                      }
                      return null;
                    },
                  },
                },
              },
            },
            Administration: {
              props: {
                title: 'Administration',
              },
              fieldGroup: {
                ndi: {
                  type: 'input',
                  label: 'N° de référence (NDI)',
                  class: 'col-3',
                  defaultValue: this.data.ndi,
                },
                siret: {
                  type: 'input',
                  label: 'Numéro de SIRET',
                  class: 'col-3',
                  defaultValue: this.data.company?.siret,
                },
                codeProjet: {
                  type: 'input',
                  label: 'Code projet',
                  class: 'col-3',
                },
                commandDate: {
                  type: 'datepicker',
                  label: 'Date de commande',
                  class: 'col-3',
                  props: {
                    type: 'date',
                    defaultValue: this.data.createdAt,
                  },
                },
                appointmentWanted: {
                  type: 'datepicker',
                  label: 'Date de RDV souhaitée',
                  class: 'col-6',
                  props: {
                    type: 'dateTime',
                    // defaultValue: this.data?.order?,
                  },
                },
                appointment: {
                  type: 'datepicker',
                  label: 'Date de RDV',
                  class: 'col-6',
                  props: {
                    type: 'dateTime',
                    defaultValue: this.data.appointment?.startDate,
                  },
                },
                blank: {
                  type: 'access-panel',
                  class: 'col-6',
                },
                appointmentEnd: {
                  type: 'datepicker',
                  label: 'Date de fin de RDV',
                  class: 'col-6',
                  props: {
                    type: 'dateTime',
                    defaultValue: this.data.appointment?.endDate,
                  },
                },
                officeAvailability: {
                  type: 'datepicker',
                  label: 'Date de mise à disposition des locaux',
                  class: 'col-6',
                  props: {
                    type: 'dateTime',
                  },
                },
                crAvailibility: {
                  type: 'datepicker',
                  label: 'Date de mise à disposition (CR)',
                  class: 'col-6',
                  props: {
                    type: 'dateTime',
                  },
                },
                addressLink: {
                  type: 'section',
                  label: 'Adresse du lien',
                  fieldGroup: {
                    corporateName: {
                      type: 'input',
                      label: 'Nom / Raison sociale',
                      class: 'col-12',
                      defaultValue: this.data.company?.name,
                    },
                    houseNumber: {
                      type: 'input',
                      label: 'Numéro',
                      class: 'col-3',
                      defaultValue: this.data.address?.houseNumber,
                    },
                    road: {
                      type: 'input',
                      label: 'Voie',
                      class: 'col-4',
                      defaultValue: this.data.address?.road,
                    },
                    supplementAddress: {
                      type: 'input',
                      label: "Complément d'adresse",
                      class: 'col-5',
                      // defaultValue: this.data?.order?.address?,
                    },
                    postcode: {
                      type: 'input',
                      label: 'Code postal',
                      class: 'col-3',
                      defaultValue: this.data.address?.postcode,
                    },
                    city: {
                      type: 'input',
                      label: 'Ville',
                      class: 'col-3',
                      defaultValue: this.data.address?.city,
                    },
                    stairs: {
                      type: 'input',
                      label: 'Escalier',
                      class: 'col-3',
                      defaultValue: this.data.building?.stairs,
                    },
                    floor: {
                      type: 'input',
                      label: 'Etage',
                      class: 'col-3',
                      defaultValue: this.data.building?.floor,
                    },
                    inseeCode: {
                      type: 'input',
                      label: 'Code INSEE',
                      class: 'col-6',
                      defaultValue: this.data.address?.inseeCode,
                    },
                    rivoliCode: {
                      type: 'input',
                      label: 'Code RIVOLI',
                      class: 'col-6',
                      defaultValue: this.data.address?.rivoliCode,
                    },
                    buildingName: {
                      type: 'input',
                      label: 'Référence bâtiment',
                      class: 'col-6',
                      defaultValue:
                        this.data.building?.name == '_NA_'
                          ? ''
                          : this.data.building?.name,
                    },
                  },
                },
                techContact: {
                  type: 'section',
                  label: 'Contact technique sur site',
                  fieldGroup: {
                    lastName: {
                      type: 'input',
                      label: 'Nom',
                      class: 'col-6',
                      defaultValue: this.data.contact?.lastName,
                    },
                    firstName: {
                      type: 'input',
                      label: 'Prénom',
                      class: 'col-6',
                      defaultValue: this.data.contact?.firstName,
                    },
                    email: {
                      type: 'input',
                      label: 'Email',
                      class: 'col-6',
                      defaultValue: this.data.contact?.email,
                    },
                    phone: {
                      type: 'input',
                      label: 'Téléphone',
                      class: 'col-6',
                      defaultValue: this.data.contact?.phone,
                    },
                  },
                },
                techInfo: {
                  type: 'section',
                  label: 'Informations techniques',
                  fieldGroup: {
                    modemType: {
                      type: 'input',
                      label: 'Modèle du modem',
                      class: 'col-6',
                    },
                    modemPort: {
                      type: 'input',
                      label: 'Port du modem',
                      class: 'col-6',
                      defaultValue: this.data.meta?.modem_port,
                      props: {
                        type: 'number',
                      },
                    },
                    modemNegotiation: {
                      type: 'select',
                      label: 'Négociation du modem',
                      class: 'col-6',
                    },
                    dnsPrimary: {
                      type: 'input',
                      label: 'Serveur DNS primaire',
                      class: 'col-6',
                    },
                    dnsSecondary: {
                      type: 'input',
                      label: 'Serveur DNS secondaire',
                      class: 'col-6',
                    },
                    smtp: {
                      type: 'input',
                      label: 'Serveur SMTP',
                      class: 'col-6',
                    },
                    idPort: {
                      type: 'input',
                      label: 'Port ID',
                      class: 'col-6',
                    },
                    subnetMask: {
                      type: 'input',
                      label: 'Masque de sous-réseau',
                      class: 'col-6',
                    },
                    gateway: {
                      type: 'input',
                      label: 'Passerelle',
                      class: 'col-6',
                    },
                    ipAddress: {
                      type: 'input',
                      label: 'Adresse IP',
                      class: 'col-6',
                    },
                    radiusLogin: {
                      type: 'input',
                      label: 'Login Radius',
                      class: 'col-6',
                    },
                    radiusLoginPremium: {
                      type: 'input',
                      label: 'Login Radius Premium',
                      class: 'col-6',
                    },
                    radiusPassword: {
                      type: 'input',
                      label: 'Mot de passe Radius',
                      class: 'col-6',
                    },
                    radiusPasswordPremium: {
                      type: 'input',
                      label: 'Mot de passe Radius Premium',
                      class: 'col-6',
                    },
                    complementaryInfo: {
                      type: 'textarea',
                      label: 'Informations complémentaires',
                      props: {
                        rows: 5,
                      },
                    },
                    routing: {
                      type: 'select',
                      label: 'Routage / Transit',
                    },
                    // vlan: {
                    //   type: 'input',
                    //   label: 'VLAN',
                    //   defaultValue: this.data?.vlan,
                    // },
                    supplierRef: {
                      type: 'input',
                      label: 'Référence fournisseur',
                      class: 'col-6',
                    },
                    boxRef: {
                      type: 'input',
                      label: 'Référence box',
                      class: 'col-6',
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }

  public getTable(): DynamicTableRender {
    return {
      dtFilters: {
        _all: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-4',
          title: 'Search all',
          filter: true,
          where: 'where',
          showDelete: true,
        },
        orderType: {
          type: 'select',
          icon: 'search',
          class: 'col-sm-4',
          title: 'Search Type',
          filter: true,
          allowClear: true,
          mapOptions: (data: any) => {
            return {
              id: data.id,
              name: data.name,
            };
          },
          options: [
            { id: 'link', name: 'Lien data' },
            { id: 'sda', name: 'SDA' },
            { id: 'vga', name: 'VGA' },
          ],
        },

        companyId: {
          type: 'select',
          icon: 'search',
          class: 'col-sm-4',
          title: 'Search Company',
          filter: true,
          where: 'where',
          allowClear: true,
          mapOptions: (data: any) => {
            return {
              id: data.id,
              name: data.name,
            };
          },
          options: this.companyService?.listCompany$Response().pipe(
            map((response: any) => {
              return response.body.data;
            }),
          ),
        },
        // TODO:
        // companyId: {
        //   type: 'select',
        //   icon: 'search',
        //   class: 'col-sm-4',
        //   title: 'Search Company',
        //   filter: true,
        //   where: 'where',
        //   allowClear: true,
        //   resource: 'company',
        // },

        userId: {
          type: 'select',
          icon: 'search',
          class: 'col-sm-4',
          title: 'Search User',
          filter: true,
          where: 'where',
          allowClear: true,
          mapOptions: (data: any) => {
            return {
              id: data.id,
              name: data.fullName,
            };
          },
          options: this.userService?.listUser$Response().pipe(
            map((response: any) => {
              response.body?.data.map((user: any) => {
                user.fullName = `${user.firstName} ${user.lastName}`;
              });
              return response.body.data;
            }),
          ),
        },
        status: {
          type: 'select',
          icon: 'search',
          class: 'col-sm-4',
          title: 'Search Status',
          where: 'whereIn',
          filter: true,
          allowClear: true,
          mapOptions: (data: any) => {
            return {
              id: data.value,
              name: data.label,
            };
          },
          options: [
            { value: 'IN_PROGRESS', label: 'En cours' },
            { value: 'IN_PRODUCTION', label: 'En production' },
            { value: 'CLOSING', label: 'En clôture' },
            { value: 'TERMINATED', label: 'Terminé' },
            { value: 'CANCELED', label: 'Annulé' },
            { value: 'WAITING', label: 'En attente' },
            { value: 'CONFIRMED', label: 'Confirmé' },
            { value: 'VALIDATED', label: 'Validé' },
            { value: 'ALL', label: 'Tous' },
            { value: 'INITIAL', label: 'Initial' },
          ],
          multiple: true,
        },
        reference: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-4',
          title: 'Search Reference',
          where: 'whereILike',
          filter: true,
        },
      },
      join: ['user', 'contact', 'company', 'address', 'tenant'],
      columns: {
        id: {
          title: 'ID',
          type: 'number',
          className: 'no-export',
          visible: true,
        },
        // supplier_name: {
        // type: 'input',
        // title: 'Fournisseur'
        // visible: true
        // },
        companyName: {
          fields: ['company.name as companyName'],
          type: 'input',
          title: 'Entreprise',
          visible: false,
        },
        fullName: {
          fields: ['user.firstName as firstName', 'user.lastName as lastName'],
          data: (data: any) => {
            const firstName = data.firstName;
            const lastName = data.lastName;
            if (!firstName || !lastName) return this.render('');
            return this.render(firstName + ' ' + lastName);
          },
          title: 'Utilisateur',
          visible: false,
        },
        // client: {
        // type: 'input',
        // title: 'Client',
        // visible: false
        // },
        tenantName: {
          fields: ['tenant.name as tenantName'],
          type: 'input',
          title: 'Tenant',
          visible: true,
        },
        address: {
          fields: [
            'address.road as road',
            'address.city as city',
            'address.postcode as postcode',
            'address.houseNumber as houseNumber',
          ],
          data: (data: any) => {
            const road = data.road;
            const city = data.city;
            const postcode = data.postcode;
            const houseNumber = data.houseNumber;
            if (!road || !city || !postcode || !houseNumber) {
              return this.render('');
            }
            return this.render(
              houseNumber + ' ' + road + ', ' + postcode + ' ' + city,
            );
          },
          title: 'Adresse',
          visible: false,
        },
        contactName: {
          fields: [
            'contact.firstName as contactfirstName',
            'contact.lastName as contactlastName',
          ],
          data: (data: any) => {
            const firstName = data.contactfirstName;
            const lastName = data.contactlastName;
            if (!firstName || !lastName) return this.render('');
            return this.render(firstName + ' ' + lastName);
          },
          title: 'Contact',
          visible: false,
        },

        reference: {
          type: 'input',
          title: 'Reference',
          visible: true,
        },
        name: {
          type: 'input',
          title: 'Nom',
          visible: true,
        },
        // quantity: {
        // type: 'input',
        // title: 'Quantité'
        // visible: true
        // },
        // FAS: {
        // type: 'input',
        // title: 'FAS',
        // visible: true
        // },
        // ABO: {
        // type: 'input',
        // title: 'ABO',
        // visible: true
        // },
        // RESIL: {
        // type: 'input',
        // title: 'RESIL',
        // visible: true
        // },
        // recurence : {
        // type: 'input',
        // title: 'Recurence'
        // visible: false
        // },
        // engagement: {
        // type: 'input',
        // title: 'Engagement'
        // visible: false
        // },
        // facturation: {
        // type: 'input',
        // title: 'Facturation'
        // visible: false
        // },
        // vlan: {
        //   type: 'input',
        //   title: 'Vlan',
        //   visible: false,
        // },
        // portability: {
        // type: 'input',
        // title: 'Portabilité'
        // visible: false
        // },
        startsAt: {
          type: 'date',
          title: 'Date de début',
          visible: true,
        },
        endsAt: {
          type: 'date',
          title: 'Date de fin',
          visible: false,
        },
        dateCommand: {
          fields: ['createdAt as dateCommand'],
          type: 'date',
          title: 'Date de commande',
          visible: true,
        },
        // appointmentDate: {
        // type: 'date',
        // title: 'Date de rendez-vous technique',
        // visible: false
        // },
        status: {
          type: 'input',
          title: 'Status',
          render: (status: any) => this.status(status),
          visible: true,
        },
        updatedAt: true,
        createdAt: true,
        deletedAt: true,
      },
      renderRow: this.getRenderRow(),
      buttons: this.getButtons('Order'),
      // actionsCol: this.getActions('Order')
    };
  }
}
