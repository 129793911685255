/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuthPayload } from '../models/auth-payload';
import { decode } from '../fn/authentication/decode';
import { Decode$Params } from '../fn/authentication/decode';
import { login$Json } from '../fn/authentication/login-json';
import { Login$Json$Params } from '../fn/authentication/login-json';
import { login$XWwwFormUrlencoded } from '../fn/authentication/login-x-www-form-urlencoded';
import { Login$XWwwFormUrlencoded$Params } from '../fn/authentication/login-x-www-form-urlencoded';
import { LoginPayload } from '../models/login-payload';
import { otpGenerate } from '../fn/authentication/otp-generate';
import { OtpGenerate$Params } from '../fn/authentication/otp-generate';
import { otpStatus } from '../fn/authentication/otp-status';
import { OtpStatus$Params } from '../fn/authentication/otp-status';
import { otpVerify } from '../fn/authentication/otp-verify';
import { OtpVerify$Params } from '../fn/authentication/otp-verify';
import { recover$Json } from '../fn/authentication/recover-json';
import { Recover$Json$Params } from '../fn/authentication/recover-json';
import { recover$XWwwFormUrlencoded } from '../fn/authentication/recover-x-www-form-urlencoded';
import { Recover$XWwwFormUrlencoded$Params } from '../fn/authentication/recover-x-www-form-urlencoded';
import { refresh$Json } from '../fn/authentication/refresh-json';
import { Refresh$Json$Params } from '../fn/authentication/refresh-json';
import { refresh$XWwwFormUrlencoded } from '../fn/authentication/refresh-x-www-form-urlencoded';
import { Refresh$XWwwFormUrlencoded$Params } from '../fn/authentication/refresh-x-www-form-urlencoded';
import { reset$Json } from '../fn/authentication/reset-json';
import { Reset$Json$Params } from '../fn/authentication/reset-json';
import { reset$XWwwFormUrlencoded } from '../fn/authentication/reset-x-www-form-urlencoded';
import { Reset$XWwwFormUrlencoded$Params } from '../fn/authentication/reset-x-www-form-urlencoded';
import { revoke } from '../fn/authentication/revoke';
import { Revoke$Params } from '../fn/authentication/revoke';


/**
 * Access, refresh and password recovery tokens
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `login()` */
  static readonly LoginPath = '/auth/login';

  /**
   * User login.
   *
   * Login to the application
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Json$Response(params: Login$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginPayload>> {
    return login$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * User login.
   *
   * Login to the application
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Json(params: Login$Json$Params, context?: HttpContext): Observable<LoginPayload> {
    return this.login$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginPayload>): LoginPayload => r.body)
    );
  }

  /**
   * User login.
   *
   * Login to the application
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  login$XWwwFormUrlencoded$Response(params: Login$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginPayload>> {
    return login$XWwwFormUrlencoded(this.http, this.rootUrl, params, context);
  }

  /**
   * User login.
   *
   * Login to the application
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  login$XWwwFormUrlencoded(params: Login$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<LoginPayload> {
    return this.login$XWwwFormUrlencoded$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginPayload>): LoginPayload => r.body)
    );
  }

  /** Path part for operation `refresh()` */
  static readonly RefreshPath = '/auth/token/refresh';

  /**
   * Refresh an access token.
   *
   * Creates a new access token and consumes the refresh token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refresh$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refresh$Json$Response(params: Refresh$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginPayload>> {
    return refresh$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Refresh an access token.
   *
   * Creates a new access token and consumes the refresh token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refresh$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refresh$Json(params: Refresh$Json$Params, context?: HttpContext): Observable<LoginPayload> {
    return this.refresh$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginPayload>): LoginPayload => r.body)
    );
  }

  /**
   * Refresh an access token.
   *
   * Creates a new access token and consumes the refresh token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refresh$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  refresh$XWwwFormUrlencoded$Response(params: Refresh$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginPayload>> {
    return refresh$XWwwFormUrlencoded(this.http, this.rootUrl, params, context);
  }

  /**
   * Refresh an access token.
   *
   * Creates a new access token and consumes the refresh token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refresh$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  refresh$XWwwFormUrlencoded(params: Refresh$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<LoginPayload> {
    return this.refresh$XWwwFormUrlencoded$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginPayload>): LoginPayload => r.body)
    );
  }

  /** Path part for operation `recover()` */
  static readonly RecoverPath = '/auth/recover';

  /**
   * Recover user's password.
   *
   * Initiate recover password process by sending a mail to the user with
   * the tenant config reset password url and the recover token appended
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recover$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recover$Json$Response(params: Recover$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'message'?: string;
}>> {
    return recover$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Recover user's password.
   *
   * Initiate recover password process by sending a mail to the user with
   * the tenant config reset password url and the recover token appended
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recover$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  recover$Json(params: Recover$Json$Params, context?: HttpContext): Observable<{
'message'?: string;
}> {
    return this.recover$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>): {
'message'?: string;
} => r.body)
    );
  }

  /**
   * Recover user's password.
   *
   * Initiate recover password process by sending a mail to the user with
   * the tenant config reset password url and the recover token appended
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recover$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recover$XWwwFormUrlencoded$Response(params: Recover$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'message'?: string;
}>> {
    return recover$XWwwFormUrlencoded(this.http, this.rootUrl, params, context);
  }

  /**
   * Recover user's password.
   *
   * Initiate recover password process by sending a mail to the user with
   * the tenant config reset password url and the recover token appended
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recover$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  recover$XWwwFormUrlencoded(params: Recover$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<{
'message'?: string;
}> {
    return this.recover$XWwwFormUrlencoded$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>): {
'message'?: string;
} => r.body)
    );
  }

  /** Path part for operation `reset()` */
  static readonly ResetPath = '/auth/reset';

  /**
   * Reset user's password.
   *
   * Provide new password with confirmation and the recover token received by mail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reset$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reset$Json$Response(params: Reset$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'message'?: string;
}>> {
    return reset$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Reset user's password.
   *
   * Provide new password with confirmation and the recover token received by mail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reset$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reset$Json(params: Reset$Json$Params, context?: HttpContext): Observable<{
'message'?: string;
}> {
    return this.reset$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>): {
'message'?: string;
} => r.body)
    );
  }

  /**
   * Reset user's password.
   *
   * Provide new password with confirmation and the recover token received by mail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reset$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  reset$XWwwFormUrlencoded$Response(params: Reset$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'message'?: string;
}>> {
    return reset$XWwwFormUrlencoded(this.http, this.rootUrl, params, context);
  }

  /**
   * Reset user's password.
   *
   * Provide new password with confirmation and the recover token received by mail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reset$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  reset$XWwwFormUrlencoded(params: Reset$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<{
'message'?: string;
}> {
    return this.reset$XWwwFormUrlencoded$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
}>): {
'message'?: string;
} => r.body)
    );
  }

  /** Path part for operation `decode()` */
  static readonly DecodePath = '/auth/token/decode';

  /**
   * Decode an access token.
   *
   * Decode a JSON Web Token payload data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `decode()` instead.
   *
   * This method doesn't expect any request body.
   */
  decode$Response(params: Decode$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthPayload>> {
    return decode(this.http, this.rootUrl, params, context);
  }

  /**
   * Decode an access token.
   *
   * Decode a JSON Web Token payload data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `decode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  decode(params: Decode$Params, context?: HttpContext): Observable<AuthPayload> {
    return this.decode$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthPayload>): AuthPayload => r.body)
    );
  }

  /** Path part for operation `revoke()` */
  static readonly RevokePath = '/auth/token/revoke';

  /**
   * Revoke a refresh token.
   *
   * Revoke a refresh token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revoke()` instead.
   *
   * This method doesn't expect any request body.
   */
  revoke$Response(params: Revoke$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revoke(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke a refresh token.
   *
   * Revoke a refresh token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revoke$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  revoke(params: Revoke$Params, context?: HttpContext): Observable<void> {
    return this.revoke$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `otpGenerate()` */
  static readonly OtpGeneratePath = '/auth/otp/generate';

  /**
   * Generate secret.
   *
   * Generate OTP secret
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `otpGenerate()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpGenerate$Response(params?: OtpGenerate$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'secret'?: string;
'uri'?: string;
'qr'?: string;
}>> {
    return otpGenerate(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate secret.
   *
   * Generate OTP secret
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `otpGenerate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpGenerate(params?: OtpGenerate$Params, context?: HttpContext): Observable<{
'secret'?: string;
'uri'?: string;
'qr'?: string;
}> {
    return this.otpGenerate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'secret'?: string;
'uri'?: string;
'qr'?: string;
}>): {
'secret'?: string;
'uri'?: string;
'qr'?: string;
} => r.body)
    );
  }

  /** Path part for operation `otpVerify()` */
  static readonly OtpVerifyPath = '/auth/otp/verify';

  /**
   * Verify token.
   *
   * Verify OTP token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `otpVerify()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpVerify$Response(params: OtpVerify$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'valid'?: boolean;
}>> {
    return otpVerify(this.http, this.rootUrl, params, context);
  }

  /**
   * Verify token.
   *
   * Verify OTP token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `otpVerify$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpVerify(params: OtpVerify$Params, context?: HttpContext): Observable<{
'valid'?: boolean;
}> {
    return this.otpVerify$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'valid'?: boolean;
}>): {
'valid'?: boolean;
} => r.body)
    );
  }

  /** Path part for operation `otpStatus()` */
  static readonly OtpStatusPath = '/auth/otp/status';

  /**
   * Check otp status.
   *
   * Check otp status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `otpStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpStatus$Response(params?: OtpStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'status'?: boolean;
}>> {
    return otpStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Check otp status.
   *
   * Check otp status
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `otpStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  otpStatus(params?: OtpStatus$Params, context?: HttpContext): Observable<{
'status'?: boolean;
}> {
    return this.otpStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'status'?: boolean;
}>): {
'status'?: boolean;
} => r.body)
    );
  }

}
