import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
// eslint-disable-next-line import/no-named-as-default
import io, { ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';

export type SocketStatus = {
  online?: boolean;
  // error?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  socketOptions?: Partial<ManagerOptions & SocketOptions>;
  socket?: Socket;

  private status$ = new BehaviorSubject<SocketStatus>({});
  currentStatus$ = this.status$.asObservable();

  constructor(
    private tokenService: TokenStorageService,
    private notifierService: NotifierService,
  ) {}

  /**
   * Connect to the servor web socket
   */
  public connect() {
    if (!this.socket || this.socket.disconnected) {
      // const token = this.tokenService.getToken();
      this.socketOptions = {
        // transports: ['websocket'],

        //path: '/socket.io',
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5,
        timeout: 20000,
        autoConnect: true,
        query: {},

        // Options for Node.js / React Native
        extraHeaders: {
          //"Access-Control-Allow-Headers":"*",
          //"Referrer Policy": "strict-origin-when-cross-origin",
          //Authorization: `Bearer ${String(token)}`,
        },
      };

      this.socket = io(environment.apiUrl, this.socketOptions);
      //localStorage.debug = 'socket.io-client:socket';

      this.socket
        .on('connect', () => {
          console.log('socket connected');
          this.status$.next({ online: true });
          //this.notifierService.notify('success','SOCKET CONNECTED')
        })
        .on('reconnect_attempt', (attempt: number) => {
          console.log('RECONNECT ATTEMPT', attempt);
        })
        .on('reconnect_error', (err: Error) => {
          console.error('RECONNECT ERROR', err);
        })
        .on('reconnect_failed', () => {
          console.error('RECONNECT FAILED');
        })
        .on('connect_error', (err: Error) => {
          console.error('CONNECT ERROR', err);
          // this.notifierService.notify('error', 'SOCKET CONNECT ERROR');
        })
        .on('error', (err: Error) => {
          console.error('ERROR', err);
        })
        .on('disconnect', (reason: string) => {
          console.log('disconnected');
          this.status$.next({ online: false });
        })
        .on('authenticated', (client: any) => {
          console.log(client);
        })
        .on('eligibility', (data: any) => {
          //console.log(data);
        });
    }
  }

  public disconnect() {
    if (this.socket) this.socket?.disconnect();
  }
}
