import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DynamicModelFormRender } from '../models/dynamic-model-form-render';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { BaseTemplateComponent } from './base.template';
import { CategoryResponse } from 'src/api/models/category-response';

// @Injectable()
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  // selector: 'app-view-model-template',
  // templateUrl: './category.template.html',
  template: `{{ data | json }}`,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CategoryTemplate extends BaseTemplateComponent<CategoryResponse> {
  override icon = 'folder-tree';
  override nameProperty = 'email';

  // override defaultParamsBuilder = {
  //   fields: ['firstName', 'lastName', 'companyId', 'email'],
  // };

  // override defaultViewParams = {
  //   fetch: 'company',
  // };

  // constructor() {
  //   super();
  // }

  // override render = (data: any) => data.firstName + ' ' + data.lastName;

  public override getForm(): DynamicModelFormRender {
    // const input = this.form.controls;
    const self = this;

    return {
      model: 'Category',
      action: this.action,
      fields: {
        Category: {
          type: 'tab-form',
          tabs: {
            Infos: {
              props: {
                title: 'Informations',
              },
              fieldGroup: {
                id: {
                  key: 'id',
                  type: 'input',
                  noExport: true,
                  hide: true,
                },
                tenantId: {
                  label: 'MODEL.Tenant',
                  translate: true,
                  type: 'select',
                  class: 'col-lg-6 col-sm-12',
                  required: true,
                  row: true,
                  props: {
                    resource: {
                      model: 'tenant',
                    },
                    allowClear: true,
                    // multiple : true,
                    actions: [
                      {
                        class: 'btn btn-primary',
                        type: 'button',
                        title: "Modifier l'entreprise",
                        icon: 'pencil',
                        onClick(event: Event, action) {
                          if (action.data.value) {
                            self.createModalService.newModalForm({
                              action: 'patch',
                              model: 'tenant',
                              id: action.data.value,
                            });
                          }
                        },
                        hidden: (data) => !data.data.value,
                        role: 'manager',
                      },
                      {
                        type: 'button',
                        class: 'btn btn-success',
                        onClick(event: Event, data: any) {
                          self.createModalService.newModalForm({
                            action: 'create',
                            model: 'tenant',
                          });
                        },
                        title: 'Nouvelle entreprise',
                        icon: 'plus',
                      },
                    ],
                  },
                },
                name: {
                  type: 'input',
                  label: 'FIELD.name',
                  translate: true,
                  class: 'col-lg-6 col-sm-12',
                  required: true,
                },
                // enabled: {
                //   type: 'checkbox',
                //   class:
                //     'col-3 col-sm-3 offset-sm-1 col-md-4 offset-md-2 d-flex align-items-end mb-2',
                //   props: {
                //     label: 'Actif',
                //   },
                // },
              },
            },
          },
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    // const input = this.form.controls;
    // const requestId = input.id;
    // const requestModel = this.action;
    // const companyId = input.companyId;

    // const hideCompanyId = requestModel === 'company' && !(companyId) && companyId == requestId;

    // const isAdmin = this.localUserService.isAdmin();

    return {
      buttons: this.getButtons('Category'),
      join: [],
      dtFilters: {
        _all: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-6',
          title: 'Search all categories',
          filter: true,
          // showDelete: isAdmin,
        },
        name: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-6',
          title: 'Search by name',
        },
      },
      columns: {
        id: {
          type: 'input',
          title: 'ID contact',
          className: 'no-export',
          visible: false,
        },
        name: {
          title: 'Nom',
          searchable: true,
        },
        typeSlug: {
          title: 'Type',
          searchable: true,
        },
        description: {
          title: 'Description',
          searchable: true,
        },
        // tenantId: {
        //   type: 'input',
        //   title: 'ID entreprise',
        //   className: 'no-export',
        //   visible: false,
        // },
        // tenantName: {
        //   fields: ['tenant.name as tenantName'],
        //   type: 'input',
        //   title: 'Nom entreprise',
        // },
        // enabled: {
        //   title: 'Activé',
        //   type: 'boolean',
        //   width: '1%',
        //   searchable: false,
        // },
        updatedAt: true,
        createdAt: true,
        // deletedAt: isAdmin,
      },
    };
  }
}
