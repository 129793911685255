import { FormlyFieldConfig } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { map, of } from 'rxjs';
import { OrderTemplateComponent } from './order.template';
import {
  AppointmentsService,
  CompanyService,
  EligibilityService,
  UserService,
} from 'src/api/services';
import { ParamsBuilderService } from 'src/app/shared/services/params-builder.service';
import { LocalUserService } from 'src/app/core/services/local-user.service';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class linkTemplate extends OrderTemplateComponent {
  constructor(
    companyService: CompanyService,
    userService: UserService,
    paramsBuilderService: ParamsBuilderService,
    localUserService: LocalUserService,
    private appointmentsService: AppointmentsService,
    private eligibilityService: EligibilityService,
    private datePipe: DatePipe,
  ) {
    super(companyService, userService, paramsBuilderService, localUserService);
  }

  public override getForm(): FormlyFieldConfig[] {
    return [
      {
        type: 'stepper',
        fieldGroup: [
          {
            props: {
              title: 'Informations',
              icon: 'user',
              subTitle: 'Détails du client',
            },
            fieldGroup: [
              {
                type: 'section',
                props: {
                  label: 'Revendeur',
                },
                fieldGroup: [
                  {
                    key: 'company',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      label: 'MODEL.Company',
                      translate: true,
                      required: true,
                      placeholder: 'Sélectionner...',
                      click: () =>
                        this.companyService
                          .listCompany$Response(
                            this.paramsBuilderService.build({
                              limit: 50,
                              fullSearch: {
                                whereILike: { name: this.model.whereLike },
                              },
                            }),
                          )
                          .pipe(map((r) => r.body.data!)),
                      defaultValue: this.companyService
                        .showCompany$Response({
                          id: this.localUserService.getUser().companyId,
                        })
                        .pipe(
                          map((r) => {
                            return [r.body];
                          }),
                        ),
                      labelProp: 'name',
                    },
                  },
                  {
                    key: 'commercial',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      label: 'Commercial',
                      required: false,
                      click: () =>
                        this.userService
                          .listUser$Response(
                            this.paramsBuilderService.build({
                              limit: 50,
                              fullSearch: {
                                whereILike: [
                                  { firstName: this.model.whereLike },
                                ],
                                where: { companyId: this.model.company?.id },
                              },
                            }),
                          )
                          .pipe(
                            map((r) => {
                              r.body.data!.forEach((u: any) => {
                                u.fullName = `${u.firstName} ${u.lastName} (${u.email})`;
                              });
                              return r.body.data!;
                            }),
                          ),
                      labelProp: 'fullName',
                    },
                    expressions: {
                      'props.disabled': '!model.company',
                    },
                  },
                ],
              },
              {
                type: 'section',
                props: {
                  label: 'Client Final',
                },
                fieldGroup: [
                  {
                    key: 'client',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      label: 'Client',
                      required: true,
                      add: true,
                      modify: true,
                      templateName: 'company',
                      click: () =>
                        this.companyService
                          .listCompany$Response(
                            this.paramsBuilderService.build({
                              fullSearch: {
                                whereILike: { name: this.model.whereLike },
                                where: {
                                  tenantId: this.model.company?.tenantId,
                                },
                              },
                            }),
                          )
                          .pipe(map((r) => r.body.data!)),
                      labelProp: 'name',
                    },
                    expressions: {
                      'props.disabled': '!model.company',
                    },
                  },
                  {
                    key: 'clientSite',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      label: 'Site client',
                      required: false,
                      add: true,
                      templateName: 'companyAddresss',
                      click: () =>
                        this.companyService
                          .showCompanyAddresses$Response({
                            id: this.model.client?.id,
                          })
                          .pipe(
                            map((r) => {
                              r.body.data!.forEach((u: any) => {
                                u.fullAddress = `${u.houseNumber} ${u.road}, ${u.postcode} ${u.city}`;
                              });
                              return r.body.data!;
                            }),
                          ),
                      labelProp: 'fullAddress',
                    },
                    expressions: {
                      'props.disabled': '!model.client',
                      'props.defaultClient': 'model.client?.id',
                    },
                  },
                  {
                    key: 'contact',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      label: 'Contact',
                      required: true,
                      add: true,
                      modify: true,
                      templateName: 'user',
                      click: () =>
                        this.userService
                          .listUser$Response(
                            this.paramsBuilderService.build({
                              fullSearch: {
                                whereILike: [
                                  { firstName: this.model.whereLike },
                                ],
                                where: { companyId: this.model.client?.id },
                              },
                            }),
                          )
                          .pipe(
                            map((r) => {
                              r.body.data!.forEach((u: any) => {
                                u.fullName = `${u.firstName} ${u.lastName} (${u.email})`;
                              });
                              return r.body.data!;
                            }),
                          ),
                      labelProp: 'fullName',
                    },
                    expressions: {
                      'props.disabled': '!model.client',
                    },
                  },
                ],
              },
              {
                type: 'section',
                props: {
                  label: 'Détails de la commande',
                },
                fieldGroup: [
                  {
                    key: 'orgName',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Raison sociale',
                      required: true,
                    },
                    expressions: {
                      'model.orgName': 'model.client?.name',
                    },
                  },
                  {
                    key: 'orgSiret',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Numéro SIRET',
                      required: true,
                    },
                    expressions: {
                      'model.orgSiret': 'model.client?.siret',
                    },
                  },
                  {
                    key: 'refNb',
                    type: 'maskedInput',
                    props: {
                      type: 'text',
                      label: 'Numéro de référence',
                      required: false,
                      disabled: true,
                      mask: '00 00 00 00 00',
                    },
                    expressions: {
                      'props.value': 'model.data.params.lineNumber',
                      'model.refNb': 'model.data.params.lineNumber',
                    },
                  },
                  {
                    key: 'houseNumber',
                    type: 'input',
                    className: 'col-md-4',
                    props: {
                      type: 'text',
                      label: 'Numéro',
                      required: true,
                      disabled: true,
                    },
                    expressions: {
                      'model.houseNumber': 'model.data.params.streetNr',
                    },
                  },
                  {
                    key: 'road',
                    type: 'input',
                    className: 'col-md-4',
                    props: {
                      type: 'text',
                      label: 'Voie',
                      required: false,
                      disabled: true,
                    },
                    expressions: {
                      'model.road':
                        'model.data.params.streetType + " " + model.data.params.streetName',
                    },
                  },
                  {
                    key: 'addressComplement',
                    type: 'input',
                    className: 'col-md-4',
                    props: {
                      type: 'text',
                      label: "Complément d'adresse",
                      required: false,
                      disabled: true,
                    },
                    expressions: {
                      'model.addressComplement':
                        'model.data.params.streetNrSuffix',
                    },
                  },
                  {
                    key: 'cp',
                    type: 'input',
                    className: 'col-md-4',
                    props: {
                      type: 'text',
                      label: 'Code postal',
                      required: false,
                      disabled: true,
                    },
                    expressions: {
                      'model.cp': 'model.data.params.postcode',
                    },
                  },
                  {
                    key: 'city',
                    type: 'input',
                    className: 'col-md-8',
                    props: {
                      type: 'text',
                      label: 'Ville',
                      required: false,
                      disabled: true,
                    },
                    expressions: {
                      'model.city': 'model.data.params.city',
                    },
                  },
                  {
                    key: 'inseeCode',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Code INSEE',
                      required: false,
                      disabled: true,
                    },
                    expressions: {
                      'model.inseeCode': 'model.data.params.inseeCode',
                    },
                  },
                  {
                    key: 'rivoliCode',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Code Rivoli',
                      required: false,
                      disabled: true,
                    },
                    expressions: {
                      'model.rivoliCode': 'model.data.params.rivoliCode',
                    },
                  },
                  {
                    key: 'buildingCode',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Code batiment',
                      required: false,
                      click: () =>
                        of(this.model.data.params.buildings).pipe(
                          map((r) => {
                            return r;
                          }),
                        ),
                      labelProp: 'buildingCodeOC',
                    },
                  },
                  {
                    key: 'buildingName',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Nom du batiment',
                      required: false,
                      disabled: true,
                      value: '_NA_',
                    },
                    expressions: {
                      'model.buildingName':
                        'model.buildingCode?.label !== "" ? model.buildingCode?.label : "_NA_"',
                    },
                  },
                  {
                    key: 'stairs',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Escalier',
                      required: false,
                      disabled: true,
                      click: () =>
                        this.eligibilityService
                          .buildingStructure$Response({
                            imb: this.model.buildingCode.buildingCodeOC,
                          })
                          .pipe(
                            map((r: any) => {
                              return r.body.building?.stair;
                            }),
                          ),
                      labelProp: 'id',
                    },
                    expressions: {
                      'props.disabled': '!model.buildingCode',
                    },
                  },
                  {
                    key: 'floor',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Étage',
                      required: false,
                      disabled: true,
                      click: () =>
                        of(this.model.stairs).pipe(
                          map((r: any) => {
                            return r.floor;
                          }),
                        ),
                      labelProp: 'id',
                    },
                    expressions: {
                      'props.disabled': '!model.buildingCode || !model.stairs',
                    },
                  },
                ],
              },
              {
                type: 'section',
                props: {
                  label: 'Information concernant la livraison du boîtier ONT',
                },
                expressions: {
                  hide: 'model.data.operatorMin != "BYG"',
                },
                fieldGroup: [
                  {
                    key: 'ONT_companyName',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: "Nom de l'entreprise",
                      required: false,
                    },
                  },
                  {
                    key: 'ONT_fullName',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Nom et prénom',
                      required: false,
                    },
                  },
                  {
                    key: 'ONT_phoneNumber',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Numéro de téléphone',
                      required: false,
                    },
                  },
                  {
                    key: 'ONT_email',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Adresse email',
                      required: false,
                    },
                  },
                  {
                    key: 'ONT_houseNumber',
                    type: 'input',
                    className: 'col-md-3',
                    props: {
                      type: 'text',
                      label: 'Numéro',
                      required: false,
                    },
                  },
                  {
                    key: 'ONT_road',
                    type: 'input',
                    className: 'col-md-3',
                    props: {
                      type: 'text',
                      label: 'Voie',
                      required: false,
                    },
                  },
                  {
                    key: 'ONT_cp',
                    type: 'input',
                    className: 'col-md-3',
                    props: {
                      type: 'text',
                      label: 'Code postal',
                      required: false,
                    },
                  },
                  {
                    key: 'ONT_city',
                    type: 'input',
                    className: 'col-md-3',
                    props: {
                      type: 'text',
                      label: 'Ville',
                      required: false,
                    },
                  },
                ],
              },
            ],
          },
          {
            props: {
              title: 'Coordonnées',
              icon: 'location-dot',
              subTitle: 'Contact technique',
            },
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                type: 'section',
                props: {
                  label: '',
                },
                fieldGroup: [
                  {
                    key: 'firstName',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Prénom',
                      required: true,
                    },
                    expressions: {
                      'model.firstName': 'model.contact?.firstName',
                    },
                  },
                  {
                    key: 'lastName',
                    type: 'input',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Nom',
                      required: true,
                    },
                    expressions: {
                      'model.lastName': 'model.contact?.lastName',
                    },
                  },
                  {
                    key: 'fixedPhone',
                    type: 'maskedInput',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Numéro de téléphone',
                      required: true,
                      mask: '00 00 00 00 00',
                    },
                  },
                  {
                    key: 'mobilePhone',
                    type: 'maskedInput',
                    className: 'col-md-6',
                    props: {
                      type: 'text',
                      label: 'Mobile',
                      required: true,
                      mask: '00 00 00 00 00',
                    },
                  },
                  {
                    key: 'email',
                    type: 'input',
                    props: {
                      type: 'text',
                      label: 'Email',
                      required: true,
                    },
                    expressions: {
                      'model.email': 'model.contact?.email',
                    },
                  },
                ],
              },
            ],
          },
          {
            props: {
              title: 'Services',
              icon: 'wrench',
              subTitle: 'Informations techniques',
            },
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                type: 'section',
                props: {
                  label: '',
                },
                fieldGroup: [
                  {
                    key: 'techAppointmentDate',
                    type: 'datepicker',
                    className: 'col-md-6 ps-5',
                    props: {
                      type: 'dateCalendar',
                      label: 'Choix de la date de rendez-vous technique',
                      required: true,
                      data: this.appointmentsService
                        .appointments$Response({
                          body: {
                            operator: this.model.data?.operator,
                            data: { item: this.model.data?.appointment },
                          },
                        })
                        .pipe(
                          map((r) => {
                            return r.body.appointments!.slots!;
                          }),
                        ),
                    },
                  },
                  {
                    key: 'techAppointmentTimeSlot',
                    type: 'input-select',
                    className: 'col-md-6 ps-5',
                    props: {
                      label: 'Créneau horaire',
                      required: true,
                      click: () =>
                        of(this.model.techAppointmentDate).pipe(
                          map((r) => {
                            r.forEach((element: any) => {
                              element.inputDate = `De ${this.transformDate(
                                element.startDate,
                                'H:mm',
                              )?.replace(':', 'h')} à ${this.transformDate(
                                element.endDate,
                                'H:mm',
                              )?.replace(':', 'h')}`;
                            });
                            return r;
                          }),
                        ),
                      labelProp: 'inputDate',
                    },
                    expressions: {
                      'props.disabled': '!model.techAppointmentDate',
                    },
                  },
                  {
                    key: 'officeAvailability',
                    type: 'datepicker',
                    className: 'col-md-6 ps-5',
                    props: {
                      type: 'dateTime',
                      label: 'Date de mise à disposition des locaux',
                      required: true,
                    },
                  },
                  {
                    key: 'appointmentDesired',
                    type: 'datepicker',
                    className: 'col-md-6 pe-5',
                    props: {
                      type: 'dateTime',
                      label: 'Date de rendez-vous souhaitée',
                      required: true,
                      disabled: true,
                      related: 'techAppointmentTimeSlot',
                    },
                  },
                ],
              },
              {
                type: 'section',
                props: {
                  label: 'Options',
                  icon: 'fa-solid fa-gears',
                },
                fieldGroup: [
                  {
                    key: 'optionCommand',
                    type: 'optionCommand',
                    className: 'col-md-12',
                    props: {
                      label: 'Options',
                      options: this.model.data?.offer.options,
                    },
                  },
                ],
              },
            ],
          },
          {
            props: {
              title: 'Confirmation',
              icon: 'check',
              subTitle: 'Récapitulatif de la commande',
            },
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                type: 'section',
                props: {
                  label: 'Informations',
                },
                fieldGroup: [
                  {
                    type: 'summary',
                    props: {
                      label: 'Client',
                    },
                    expressions: {
                      'props.value': 'model.client?.name',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Site client',
                    },
                    expressions: {
                      'props.value': 'model.clientSite?.fullAddress',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Contact',
                    },
                    expressions: {
                      'props.value': 'model.contact?.fullName',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Engagement',
                    },
                    expressions: {
                      'props.value': 'model.data.offer.engagement + " mois"',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Raison sociale',
                    },
                    expressions: {
                      'props.value': 'model.client?.name',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Numéro SIRET',
                    },
                    expressions: {
                      'props.value': 'model.client?.siret',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Numéro de référence',
                    },
                    expressions: {
                      'props.value': 'model.refNb',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Numéro',
                    },
                    expressions: {
                      'props.value': 'model.houseNumber',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Voie',
                    },
                    expressions: {
                      'props.value': 'model.road',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Code postal',
                    },
                    expressions: {
                      'props.value': 'model.cp',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Ville',
                    },
                    expressions: {
                      'props.value': 'model.city',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Code INSEE',
                    },
                    expressions: {
                      'props.value': 'model.inseeCode',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Code Rivoli',
                    },
                    expressions: {
                      'props.value': 'model.rivoliCode',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Code batiment',
                    },
                    expressions: {
                      'props.value': 'model.buildingCode?.buildingCodeOC',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Escalier',
                    },
                    expressions: {
                      'props.value': 'model.stairs?.id',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Étage',
                    },
                    expressions: {
                      'props.value': 'model.floor?.id',
                    },
                  },
                ],
              },
              {
                type: 'section',
                props: {
                  label: 'Coordonnées',
                },
                fieldGroup: [
                  {
                    type: 'summary',
                    props: {
                      label: 'Prenom',
                    },
                    expressions: {
                      'props.value': 'model.firstName',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Nom',
                    },
                    expressions: {
                      'props.value': 'model.lastName',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Téléphone',
                    },
                    expressions: {
                      'props.value': 'model.fixedPhone',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Mobile',
                    },
                    expressions: {
                      'props.value': 'model.mobilePhone',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Adresse Electronique',
                    },
                    expressions: {
                      'props.value': 'model.email',
                    },
                  },
                ],
              },
              {
                type: 'section',
                props: {
                  label: 'Services',
                },
                fieldGroup: [
                  {
                    type: 'summary',
                    props: {
                      label: 'Réference fournisseur',
                    },
                    expressions: {
                      'props.value': 'model.data?.supplierRef',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Date de mise à disposition des locaux',
                      type: 'dateCalendar',
                    },
                    expressions: {
                      'props.value': 'model.officeAvailability?.date',
                      'props.value2': 'model.officeAvailability?.time',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Date de rendez- vous souhaitée',
                      type: 'dateCalendar',
                    },
                    expressions: {
                      'props.value': 'model.appointmentDesired?.date',
                      'props.value2': 'model.appointmentDesired?.time',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
  }

  public override getTable(): DataTables.Settings {
    return {
      serverSide: true, // Set the flag
      ajax: (dataTablesParameters: any, callback) => {
        this.userService.listUser$Response().subscribe((resp) => {
          callback({
            recordsTotal: resp.body.data?.length,
            recordsFiltered: resp.body.data?.length,
            data: resp.body.data,
          });
        });
      },
      columns: [
        {
          title: 'ID',
          data: 'id',
        },
        {
          title: 'First name',
          data: 'firstName',
        },
        {
          title: 'Last name',
          data: 'lastName',
        },
      ],
    };
  }

  transformDate(date: string, format?: string) {
    return this.datePipe.transform(date, format);
  }
}
