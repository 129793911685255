/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CategoryResponse } from '../models/category-response';
import { countCategory } from '../fn/category/count-category';
import { CountCategory$Params } from '../fn/category/count-category';
import { createCategory } from '../fn/category/create-category';
import { CreateCategory$Params } from '../fn/category/create-category';
import { deleteCategory } from '../fn/category/delete-category';
import { DeleteCategory$Params } from '../fn/category/delete-category';
import { fakeCategory } from '../fn/category/fake-category';
import { FakeCategory$Params } from '../fn/category/fake-category';
import { listCategory } from '../fn/category/list-category';
import { ListCategory$Params } from '../fn/category/list-category';
import { patchCategory } from '../fn/category/patch-category';
import { PatchCategory$Params } from '../fn/category/patch-category';
import { restoreCategory } from '../fn/category/restore-category';
import { RestoreCategory$Params } from '../fn/category/restore-category';
import { showCategory } from '../fn/category/show-category';
import { ShowCategory$Params } from '../fn/category/show-category';

@Injectable({ providedIn: 'root' })
export class CategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listCategory()` */
  static readonly ListCategoryPath = '/categories';

  /**
   * List categories.
   *
   * List categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCategory$Response(params?: ListCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<CategoryResponse>;
'total'?: number;
}>> {
    return listCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * List categories.
   *
   * List categories
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCategory(params?: ListCategory$Params, context?: HttpContext): Observable<{
'data'?: Array<CategoryResponse>;
'total'?: number;
}> {
    return this.listCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<CategoryResponse>;
'total'?: number;
}>): {
'data'?: Array<CategoryResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createCategory()` */
  static readonly CreateCategoryPath = '/categories';

  /**
   * Create category.
   *
   * Create one category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCategory$Response(params: CreateCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryResponse>> {
    return createCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Create category.
   *
   * Create one category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCategory(params: CreateCategory$Params, context?: HttpContext): Observable<CategoryResponse> {
    return this.createCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryResponse>): CategoryResponse => r.body)
    );
  }

  /** Path part for operation `countCategory()` */
  static readonly CountCategoryPath = '/categories/count';

  /**
   * Count categories.
   *
   * Count categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  countCategory$Response(params?: CountCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Count categories.
   *
   * Count categories
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countCategory(params?: CountCategory$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showCategory()` */
  static readonly ShowCategoryPath = '/categories/{id}';

  /**
   * Show category.
   *
   * Show one category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCategory$Response(params: ShowCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryResponse>> {
    return showCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Show category.
   *
   * Show one category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCategory(params: ShowCategory$Params, context?: HttpContext): Observable<CategoryResponse> {
    return this.showCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryResponse>): CategoryResponse => r.body)
    );
  }

  /** Path part for operation `restoreCategory()` */
  static readonly RestoreCategoryPath = '/categories/{id}';

  /**
   * Restore category.
   *
   * Restore category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreCategory$Response(params: RestoreCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryResponse>> {
    return restoreCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore category.
   *
   * Restore category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreCategory(params: RestoreCategory$Params, context?: HttpContext): Observable<CategoryResponse> {
    return this.restoreCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryResponse>): CategoryResponse => r.body)
    );
  }

  /** Path part for operation `deleteCategory()` */
  static readonly DeleteCategoryPath = '/categories/{id}';

  /**
   * Delete category.
   *
   * Delete category (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCategory$Response(params: DeleteCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete category.
   *
   * Delete category (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCategory(params: DeleteCategory$Params, context?: HttpContext): Observable<void> {
    return this.deleteCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchCategory()` */
  static readonly PatchCategoryPath = '/categories/{id}';

  /**
   * Patch category.
   *
   * Patch one category (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCategory$Response(params: PatchCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryResponse>> {
    return patchCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch category.
   *
   * Patch one category (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCategory(params: PatchCategory$Params, context?: HttpContext): Observable<CategoryResponse> {
    return this.patchCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryResponse>): CategoryResponse => r.body)
    );
  }

  /** Path part for operation `fakeCategory()` */
  static readonly FakeCategoryPath = '/categories/faker';

  /**
   * Fake category.
   *
   * Generate fake category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeCategory$Response(params?: FakeCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryResponse>> {
    return fakeCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake category.
   *
   * Generate fake category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeCategory(params?: FakeCategory$Params, context?: HttpContext): Observable<CategoryResponse> {
    return this.fakeCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryResponse>): CategoryResponse => r.body)
    );
  }

}
