/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countRole } from '../fn/role/count-role';
import { CountRole$Params } from '../fn/role/count-role';
import { createRole } from '../fn/role/create-role';
import { CreateRole$Params } from '../fn/role/create-role';
import { createRoleUsers } from '../fn/role/create-role-users';
import { CreateRoleUsers$Params } from '../fn/role/create-role-users';
import { deleteRole } from '../fn/role/delete-role';
import { DeleteRole$Params } from '../fn/role/delete-role';
import { fakeRole } from '../fn/role/fake-role';
import { FakeRole$Params } from '../fn/role/fake-role';
import { listRole } from '../fn/role/list-role';
import { ListRole$Params } from '../fn/role/list-role';
import { patchRole } from '../fn/role/patch-role';
import { PatchRole$Params } from '../fn/role/patch-role';
import { patchRoleUsers } from '../fn/role/patch-role-users';
import { PatchRoleUsers$Params } from '../fn/role/patch-role-users';
import { restoreRole } from '../fn/role/restore-role';
import { RestoreRole$Params } from '../fn/role/restore-role';
import { RoleResponse } from '../models/role-response';
import { showRole } from '../fn/role/show-role';
import { ShowRole$Params } from '../fn/role/show-role';
import { showRoleUsers } from '../fn/role/show-role-users';
import { ShowRoleUsers$Params } from '../fn/role/show-role-users';
import { UserResponse } from '../models/user-response';

@Injectable({ providedIn: 'root' })
export class RoleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listRole()` */
  static readonly ListRolePath = '/roles';

  /**
   * List roles.
   *
   * List roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRole$Response(params?: ListRole$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<RoleResponse>;
'total'?: number;
}>> {
    return listRole(this.http, this.rootUrl, params, context);
  }

  /**
   * List roles.
   *
   * List roles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRole(params?: ListRole$Params, context?: HttpContext): Observable<{
'data'?: Array<RoleResponse>;
'total'?: number;
}> {
    return this.listRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<RoleResponse>;
'total'?: number;
}>): {
'data'?: Array<RoleResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createRole()` */
  static readonly CreateRolePath = '/roles';

  /**
   * Create role.
   *
   * Create one role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRole$Response(params: CreateRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleResponse>> {
    return createRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Create role.
   *
   * Create one role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRole(params: CreateRole$Params, context?: HttpContext): Observable<RoleResponse> {
    return this.createRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleResponse>): RoleResponse => r.body)
    );
  }

  /** Path part for operation `countRole()` */
  static readonly CountRolePath = '/roles/count';

  /**
   * Count roles.
   *
   * Count roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  countRole$Response(params?: CountRole$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Count roles.
   *
   * Count roles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countRole(params?: CountRole$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showRole()` */
  static readonly ShowRolePath = '/roles/{id}';

  /**
   * Show role.
   *
   * Show one role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  showRole$Response(params: ShowRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleResponse>> {
    return showRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Show role.
   *
   * Show one role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showRole(params: ShowRole$Params, context?: HttpContext): Observable<RoleResponse> {
    return this.showRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleResponse>): RoleResponse => r.body)
    );
  }

  /** Path part for operation `restoreRole()` */
  static readonly RestoreRolePath = '/roles/{id}';

  /**
   * Restore role.
   *
   * Restore role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreRole$Response(params: RestoreRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleResponse>> {
    return restoreRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore role.
   *
   * Restore role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreRole(params: RestoreRole$Params, context?: HttpContext): Observable<RoleResponse> {
    return this.restoreRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleResponse>): RoleResponse => r.body)
    );
  }

  /** Path part for operation `deleteRole()` */
  static readonly DeleteRolePath = '/roles/{id}';

  /**
   * Delete role.
   *
   * Delete role (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRole$Response(params: DeleteRole$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete role.
   *
   * Delete role (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRole(params: DeleteRole$Params, context?: HttpContext): Observable<void> {
    return this.deleteRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchRole()` */
  static readonly PatchRolePath = '/roles/{id}';

  /**
   * Patch role.
   *
   * Patch one role (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchRole$Response(params: PatchRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleResponse>> {
    return patchRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch role.
   *
   * Patch one role (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchRole(params: PatchRole$Params, context?: HttpContext): Observable<RoleResponse> {
    return this.patchRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleResponse>): RoleResponse => r.body)
    );
  }

  /** Path part for operation `fakeRole()` */
  static readonly FakeRolePath = '/roles/faker';

  /**
   * Fake role.
   *
   * Generate fake role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeRole$Response(params?: FakeRole$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleResponse>> {
    return fakeRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake role.
   *
   * Generate fake role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeRole(params?: FakeRole$Params, context?: HttpContext): Observable<RoleResponse> {
    return this.fakeRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleResponse>): RoleResponse => r.body)
    );
  }

  /** Path part for operation `showRoleUsers()` */
  static readonly ShowRoleUsersPath = '/roles/{id}/users';

  /**
   * Show role users.
   *
   * Show role users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showRoleUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  showRoleUsers$Response(params: ShowRoleUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<UserResponse>;
}>> {
    return showRoleUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Show role users.
   *
   * Show role users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showRoleUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showRoleUsers(params: ShowRoleUsers$Params, context?: HttpContext): Observable<{
'data'?: Array<UserResponse>;
}> {
    return this.showRoleUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<UserResponse>;
}>): {
'data'?: Array<UserResponse>;
} => r.body)
    );
  }

  /** Path part for operation `createRoleUsers()` */
  static readonly CreateRoleUsersPath = '/roles/{id}/users';

  /**
   * Create role users.
   *
   * Create role users
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRoleUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoleUsers$Response(params: CreateRoleUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return createRoleUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Create role users.
   *
   * Create role users
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRoleUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoleUsers(params: CreateRoleUsers$Params, context?: HttpContext): Observable<UserResponse> {
    return this.createRoleUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `patchRoleUsers()` */
  static readonly PatchRoleUsersPath = '/roles/{id}/users';

  /**
   * Patch role users.
   *
   * Patch role users (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRoleUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchRoleUsers$Response(params: PatchRoleUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserResponse>> {
    return patchRoleUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch role users.
   *
   * Patch role users (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRoleUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchRoleUsers(params: PatchRoleUsers$Params, context?: HttpContext): Observable<UserResponse> {
    return this.patchRoleUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

}
