/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { eventList } from '../fn/internal/event-list';
import { EventList$Params } from '../fn/internal/event-list';
import { eventSend } from '../fn/internal/event-send';
import { EventSend$Params } from '../fn/internal/event-send';
import { navigation } from '../fn/internal/navigation';
import { Navigation } from '../models/navigation';
import { Navigation$Params } from '../fn/internal/navigation';
import { Task } from '../models/task';
import { taskList } from '../fn/internal/task-list';
import { TaskList$Params } from '../fn/internal/task-list';

@Injectable({ providedIn: 'root' })
export class InternalService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `navigation()` */
  static readonly NavigationPath = '/internal/navigation';

  /**
   * Frontend navigation.
   *
   * Frontend navigation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `navigation()` instead.
   *
   * This method doesn't expect any request body.
   */
  navigation$Response(params?: Navigation$Params, context?: HttpContext): Observable<StrictHttpResponse<Navigation>> {
    return navigation(this.http, this.rootUrl, params, context);
  }

  /**
   * Frontend navigation.
   *
   * Frontend navigation
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `navigation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  navigation(params?: Navigation$Params, context?: HttpContext): Observable<Navigation> {
    return this.navigation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Navigation>): Navigation => r.body)
    );
  }

  /** Path part for operation `eventList()` */
  static readonly EventListPath = '/internal/events';

  /**
   * Backend events.
   *
   * Backend events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventList()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventList$Response(params?: EventList$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return eventList(this.http, this.rootUrl, params, context);
  }

  /**
   * Backend events.
   *
   * Backend events
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventList(params?: EventList$Params, context?: HttpContext): Observable<void> {
    return this.eventList$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `eventSend()` */
  static readonly EventSendPath = '/internal/events';

  /**
   * Send event (debug).
   *
   * Send event (debug)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSend()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSend$Response(params?: EventSend$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return eventSend(this.http, this.rootUrl, params, context);
  }

  /**
   * Send event (debug).
   *
   * Send event (debug)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventSend$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSend(params?: EventSend$Params, context?: HttpContext): Observable<void> {
    return this.eventSend$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `taskList()` */
  static readonly TaskListPath = '/internal/tasks';

  /**
   * List tasks.
   *
   * List tasks
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `taskList()` instead.
   *
   * This method doesn't expect any request body.
   */
  taskList$Response(params?: TaskList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Task>>> {
    return taskList(this.http, this.rootUrl, params, context);
  }

  /**
   * List tasks.
   *
   * List tasks
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `taskList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  taskList(params?: TaskList$Params, context?: HttpContext): Observable<Array<Task>> {
    return this.taskList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Task>>): Array<Task> => r.body)
    );
  }

}
