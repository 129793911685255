import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../models/component-can-deactivate';

@Injectable({
  providedIn: 'root',
})
/**
 * Guard that verify if the component can be change if not dirty. Only works when navigating elsewhere within your angular app.
 * To check navigator action (close, refresh...), use this following function :
 * ```
 * //Listen if the browser try to exit the page
 * @HostListener('window:beforeunload', ['$event'])
 * //The function tell if we use the event or not (use preventDefault to cancel the event by Angular)
 * unloadNotification(event: Event) {
 *  if (!this.canDeactivate()) {
 *   event.returnValue = NEW MESSAGE;
 *  }
 * }
 * ```
 */
export class DirtyCheck {
  constructor(private translate: TranslateService) {}
  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate()
      ? true
      : // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // when navigating away from your angular app, the browser will show a generic warning message
        // see http://stackoverflow.com/a/42207299/7307355
        confirm(this.translate.instant('ALERT.DIRTY_CHECK'));
  }
}
