import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { filter, map } from 'lodash';

/**
 * Contain some useful functions
 */
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  /**
   * Try to find an object inside another with the key (recursively)
   * @param object
   * @param key
   * @returns
   */
  public findValInObject(object: any, key: string): any | undefined {
    let value;
    Object.keys(object).some((k) => {
      if (k === key) {
        value = object[k];
        return true;
      }
      if (object[k] === key) {
        value = object[k];
        value = object;
        return true;
      }
      if (object[k] && typeof object[k] === 'object') {
        value = this.findValInObject(object[k], key);
        return value !== undefined;
      }
      return false;
    });
    return value;
  }

  /**
   * Find all object inside another one
   * @param object
   * @param key
   * @returns
   */
  public findAllValInObject(object: any, key: string, stage = 0) {
    let value = new Array<any>();

    Object.keys(object).some((k) => {
      if (k === key) {
        value.push(object[k]);
        return true;
      }
      if (object[k] === key) {
        //value = object[k];
        value.push(object);
        return true;
      }
      if (object[k] && typeof object[k] === 'object' && stage < 20) {
        stage++;
        value = value.concat(this.findAllValInObject(object[k], key, stage));
        return false; //value !== undefined;
      }
      return false;
    });
    return value;
  }

  /**
   * Create a string from the object values with a table of key
   * @param obj
   * @param render
   * @returns
   */
  getStringRenderFromObject(obj: any, render: string[]) {
    let strTemp = '';

    render.forEach((str, i) => {
      strTemp += obj[str] ? (i != 0 ? ' ' : '') + (obj[str] as string) : '';
    });

    return strTemp;
  }

  /**
   * Transform string from camelCase format to snake_format
   * @param str
   * @returns
   */
  camelToUnderscore(str: string) {
    const result = str.replace(/([A-Z])/g, ' $1');
    return result.split(' ').join('_').toLowerCase();
  }

  /**
   * Transform model name to respect modelName convention
   * @param value
   */
  transformModelName(value: string) {
    return this.capitalize(this.singular(value));
  }

  /**
   * Transform name into is correct form if exist
   */
  transformModelActionName(actionName: string) {
    const list: { [key: string]: string } = {
      uploadlogo: 'uploadLogo',
    };
    for (const name in list) {
      if (actionName == name) return list[name];
    }

    return actionName;
  }

  /**
   * Merge multiple arrays without duplicate
   * @param arrays
   * @returns
   */
  mergeArraysNoDuplicate(...arrays: unknown[]) {
    let jointArray: unknown[] = [];

    arrays.forEach((array) => {
      if (Array.isArray(array)) jointArray = [...jointArray, ...array];
    });
    const uniqueArray = jointArray.filter(
      (item, index) => jointArray.indexOf(item) === index,
    );
    return uniqueArray;
  }

  /**
   * Get the name of an AbstractControl
   * @param c
   * @returns
   */
  getAbstractControlName(c: AbstractControl): string | undefined {
    if (c.parent) {
      const formGroup = c.parent.controls;
      return (
        Object.keys(formGroup).find((name) => c === (<any>formGroup)[name]) ||
        undefined
      );
    }
    return undefined;
  }

  /**
   * Change ngSelect search function to search in an object
   * @param term
   * @param item
   * @returns
   */
  multipleKeysSearchFn(term: string, items: any[]) {
    items = map(items, (item) => {
      if (typeof item === 'string') {
        item = item.toLowerCase();
      }

      return item;
    });
    const results = filter(items, function (item) {
      return item.indexOf(term) > -1;
    });
    return results.length > 0;
  }

  /**
   * Set all the properties of an array of object with the same value
   * @param object
   * @param key
   * @param value
   */
  setAllProp(object: any[] | Record<string, any>, key: string, value: any) {
    if (Array.isArray(object)) {
      object.forEach((o: any) => {
        o[key] = value;
      });
    } else {
      for (const k in object) {
        object[k][key] = value;
      }
    }
  }

  /**
   * Performs a deep merge of `source` into `target`.
   * Mutates `target` only but not its objects and arrays.
   *
   * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
   */
  mergeDeep(target: any, source: any) {
    const isObject = (obj: any) => obj && typeof obj === 'object';

    if (!isObject(target) || !isObject(source)) {
      return source;
    }

    Object.keys(source).forEach((key) => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = this.mergeDeep(
          Object.assign({}, targetValue),
          sourceValue,
        );
      } else {
        target[key] = sourceValue;
      }
    });

    return target;
  }

  capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  /**
   * Returns the singular of an English word.
   *
   * @export
   * @param {string} word
   * @param {number} [amount]
   * @returns {string}
   */
  singular(word: string, amount?: number): string {
    if (amount !== undefined && amount !== 1) {
      return word;
    }

    const singular: { [key: string]: string } = {
      '(quiz)zes$': '$1',
      '(matr)ices$': '$1ix',
      '(vert|ind)ices$': '$1ex',
      '^(ox)en$': '$1',
      '(alias)es$': '$1',
      '(octop|vir)i$': '$1us',
      '(cris|ax|test)es$': '$1is',
      '(shoe)s$': '$1',
      '(o)es$': '$1',
      '(bus)es$': '$1',
      '([m|l])ice$': '$1ouse',
      '(x|ch|ss|sh)es$': '$1',
      '(m)ovies$': '$1ovie',
      '(s)eries$': '$1eries',
      '([^aeiouy]|qu)ies$': '$1y',
      '([lr])ves$': '$1f',
      '(tive)s$': '$1',
      '(hive)s$': '$1',
      '(li|wi|kni)ves$': '$1fe',
      '(shea|loa|lea|thie)ves$': '$1f',
      '(^analy)ses$': '$1sis',
      '((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$':
        '$1$2sis',
      '([ti])a$': '$1um',
      '(n)ews$': '$1ews',
      '(h|bl)ouses$': '$1ouse',
      '(corpse)s$': '$1',
      '(us)es$': '$1',
      s$: '',
    };
    const irregular: { [key: string]: string } = {
      move: 'moves',
      foot: 'feet',
      goose: 'geese',
      sex: 'sexes',
      child: 'children',
      man: 'men',
      tooth: 'teeth',
      person: 'people',
      address: 'addresses',
    };
    const uncountable: string[] = [
      'sheep',
      'fish',
      'deer',
      'moose',
      'series',
      'species',
      'money',
      'rice',
      'information',
      'equipment',
      'bison',
      'cod',
      'offspring',
      'pike',
      'salmon',
      'shrimp',
      'swine',
      'trout',
      'aircraft',
      'hovercraft',
      'spacecraft',
      'sugar',
      'tuna',
      'you',
      'wood',
      'address',
    ];
    // save some time in the case that singular and plural are the same
    if (uncountable.indexOf(word.toLowerCase()) >= 0) {
      return word;
    }

    // check for irregular forms
    for (const w in irregular) {
      const pattern = new RegExp(`${irregular[w]}$`, 'i');
      const replace = w;
      if (pattern.test(word)) {
        return word.replace(pattern, replace);
      }
    }
    // check for matches using regular expressions
    for (const reg in singular) {
      const pattern = new RegExp(reg, 'i');
      if (pattern.test(word)) {
        return word.replace(pattern, singular[reg]);
      }
    }
    return word;
  }

  /**
   * Returns the plural of an English word.
   *
   * @export
   * @param {string} word
   * @param {number} [amount]
   * @returns {string}
   */
  plural(word: string, amount?: number): string {
    if (amount !== undefined && amount === 1) {
      return word;
    }

    const plural: { [key: string]: string } = {
      '(quiz)$': '$1zes',
      '^(ox)$': '$1en',
      '([m|l])ouse$': '$1ice',
      '(matr|vert|ind)ix|ex$': '$1ices',
      '(x|ch|ss|sh)$': '$1es',
      '([^aeiouy]|qu)y$': '$1ies',
      '(hive)$': '$1s',
      '(?:([^f])fe|([lr])f)$': '$1$2ves',
      '(shea|lea|loa|thie)f$': '$1ves',
      sis$: 'ses',
      '([ti])um$': '$1a',
      '(tomat|potat|ech|her|vet)o$': '$1oes',
      '(bu)s$': '$1ses',
      '(alias)$': '$1es',
      '(octop)us$': '$1i',
      '(ax|test)is$': '$1es',
      '(us)$': '$1es',
      '([^s]+)$': '$1s',
    };
    const irregular: { [key: string]: string } = {
      move: 'moves',
      foot: 'feet',
      goose: 'geese',
      sex: 'sexes',
      child: 'children',
      man: 'men',
      tooth: 'teeth',
      person: 'people',
      address: 'addresses',
    };
    const uncountable: string[] = [
      'sheep',
      'fish',
      'deer',
      'moose',
      'series',
      'species',
      'money',
      'rice',
      'information',
      'equipment',
      'bison',
      'cod',
      'offspring',
      'pike',
      'salmon',
      'shrimp',
      'swine',
      'trout',
      'aircraft',
      'hovercraft',
      'spacecraft',
      'sugar',
      'tuna',
      'you',
      'wood',
    ];
    // save some time in the case that singular and plural are the same
    if (uncountable.indexOf(word.toLowerCase()) >= 0) {
      return word;
    }

    // check for irregular forms
    for (const w in irregular) {
      const pattern = new RegExp(`${w}$`, 'i');
      const replace = irregular[w];
      if (pattern.test(word)) {
        return word.replace(pattern, replace);
      }
    }
    // check for matches using regular expressions
    for (const reg in plural) {
      const pattern = new RegExp(reg, 'i');
      if (pattern.test(word)) {
        return word.replace(pattern, plural[reg]);
      }
    }
    return word;
  }
}
