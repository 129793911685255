/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RoadResponse } from '../../models/road-response';

export interface ShowRoad$Params {

/**
 * ID of the model
 */
  id: string;

/**
 * Select field
 */
  fields?: string;

/**
 * [Modify](https://vincit.github.io/objection.js/recipes/modifiers.html)
 */
  modifiers?: string;

/**
 * [Fetch graph](https://vincit.github.io/objection.js/api/query-builder/eager-methods.html#withgraphfetched)
 */
  fetch?: string;

/**
 * [Join graph](https://vincit.github.io/objection.js/api/query-builder/eager-methods.html#withgraphjoined)
 */
  join?: string;

/**
 * Debug (TODO: admin only)
 */
  debug?: boolean;
}

export function showRoad(http: HttpClient, rootUrl: string, params: ShowRoad$Params, context?: HttpContext): Observable<StrictHttpResponse<RoadResponse>> {
  const rb = new RequestBuilder(rootUrl, showRoad.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('fields', params.fields, {});
    rb.query('modifiers', params.modifiers, {});
    rb.query('fetch', params.fetch, {});
    rb.query('join', params.join, {});
    rb.query('debug', params.debug, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RoadResponse>;
    })
  );
}

showRoad.PATH = '/road/{id}';
