/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { countRoad } from '../fn/road/count-road';
import { CountRoad$Params } from '../fn/road/count-road';
import { createRoad } from '../fn/road/create-road';
import { CreateRoad$Params } from '../fn/road/create-road';
import { deleteRoad } from '../fn/road/delete-road';
import { DeleteRoad$Params } from '../fn/road/delete-road';
import { fakeRoad } from '../fn/road/fake-road';
import { FakeRoad$Params } from '../fn/road/fake-road';
import { listRoad } from '../fn/road/list-road';
import { ListRoad$Params } from '../fn/road/list-road';
import { patchRoad } from '../fn/road/patch-road';
import { PatchRoad$Params } from '../fn/road/patch-road';
import { restoreRoad } from '../fn/road/restore-road';
import { RestoreRoad$Params } from '../fn/road/restore-road';
import { RoadResponse } from '../models/road-response';
import { showRoad } from '../fn/road/show-road';
import { ShowRoad$Params } from '../fn/road/show-road';

@Injectable({ providedIn: 'root' })
export class RoadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listRoad()` */
  static readonly ListRoadPath = '/road';

  /**
   * List road.
   *
   * List road
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRoad()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRoad$Response(params?: ListRoad$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<RoadResponse>;
'total'?: number;
}>> {
    return listRoad(this.http, this.rootUrl, params, context);
  }

  /**
   * List road.
   *
   * List road
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRoad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRoad(params?: ListRoad$Params, context?: HttpContext): Observable<{
'data'?: Array<RoadResponse>;
'total'?: number;
}> {
    return this.listRoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<RoadResponse>;
'total'?: number;
}>): {
'data'?: Array<RoadResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createRoad()` */
  static readonly CreateRoadPath = '/road';

  /**
   * Create road.
   *
   * Create one road
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRoad()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoad$Response(params: CreateRoad$Params, context?: HttpContext): Observable<StrictHttpResponse<RoadResponse>> {
    return createRoad(this.http, this.rootUrl, params, context);
  }

  /**
   * Create road.
   *
   * Create one road
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRoad$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoad(params: CreateRoad$Params, context?: HttpContext): Observable<RoadResponse> {
    return this.createRoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoadResponse>): RoadResponse => r.body)
    );
  }

  /** Path part for operation `countRoad()` */
  static readonly CountRoadPath = '/road/count';

  /**
   * Count road.
   *
   * Count road
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countRoad()` instead.
   *
   * This method doesn't expect any request body.
   */
  countRoad$Response(params?: CountRoad$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countRoad(this.http, this.rootUrl, params, context);
  }

  /**
   * Count road.
   *
   * Count road
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countRoad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countRoad(params?: CountRoad$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countRoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showRoad()` */
  static readonly ShowRoadPath = '/road/{id}';

  /**
   * Show road.
   *
   * Show one road
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showRoad()` instead.
   *
   * This method doesn't expect any request body.
   */
  showRoad$Response(params: ShowRoad$Params, context?: HttpContext): Observable<StrictHttpResponse<RoadResponse>> {
    return showRoad(this.http, this.rootUrl, params, context);
  }

  /**
   * Show road.
   *
   * Show one road
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showRoad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showRoad(params: ShowRoad$Params, context?: HttpContext): Observable<RoadResponse> {
    return this.showRoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoadResponse>): RoadResponse => r.body)
    );
  }

  /** Path part for operation `restoreRoad()` */
  static readonly RestoreRoadPath = '/road/{id}';

  /**
   * Restore road.
   *
   * Restore road
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreRoad()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreRoad$Response(params: RestoreRoad$Params, context?: HttpContext): Observable<StrictHttpResponse<RoadResponse>> {
    return restoreRoad(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore road.
   *
   * Restore road
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreRoad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreRoad(params: RestoreRoad$Params, context?: HttpContext): Observable<RoadResponse> {
    return this.restoreRoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoadResponse>): RoadResponse => r.body)
    );
  }

  /** Path part for operation `deleteRoad()` */
  static readonly DeleteRoadPath = '/road/{id}';

  /**
   * Delete road.
   *
   * Delete road (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRoad()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRoad$Response(params: DeleteRoad$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteRoad(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete road.
   *
   * Delete road (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRoad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRoad(params: DeleteRoad$Params, context?: HttpContext): Observable<void> {
    return this.deleteRoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchRoad()` */
  static readonly PatchRoadPath = '/road/{id}';

  /**
   * Patch road.
   *
   * Patch one road (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRoad()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchRoad$Response(params: PatchRoad$Params, context?: HttpContext): Observable<StrictHttpResponse<RoadResponse>> {
    return patchRoad(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch road.
   *
   * Patch one road (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRoad$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchRoad(params: PatchRoad$Params, context?: HttpContext): Observable<RoadResponse> {
    return this.patchRoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoadResponse>): RoadResponse => r.body)
    );
  }

  /** Path part for operation `fakeRoad()` */
  static readonly FakeRoadPath = '/road/faker';

  /**
   * Fake road.
   *
   * Generate fake road
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeRoad()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeRoad$Response(params?: FakeRoad$Params, context?: HttpContext): Observable<StrictHttpResponse<RoadResponse>> {
    return fakeRoad(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake road.
   *
   * Generate fake road
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeRoad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeRoad(params?: FakeRoad$Params, context?: HttpContext): Observable<RoadResponse> {
    return this.fakeRoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoadResponse>): RoadResponse => r.body)
    );
  }

}
