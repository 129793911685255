/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CityResponse } from '../models/city-response';
import { countCity } from '../fn/city/count-city';
import { CountCity$Params } from '../fn/city/count-city';
import { createCity } from '../fn/city/create-city';
import { CreateCity$Params } from '../fn/city/create-city';
import { deleteCity } from '../fn/city/delete-city';
import { DeleteCity$Params } from '../fn/city/delete-city';
import { fakeCity } from '../fn/city/fake-city';
import { FakeCity$Params } from '../fn/city/fake-city';
import { listCity } from '../fn/city/list-city';
import { ListCity$Params } from '../fn/city/list-city';
import { patchCity } from '../fn/city/patch-city';
import { PatchCity$Params } from '../fn/city/patch-city';
import { restoreCity } from '../fn/city/restore-city';
import { RestoreCity$Params } from '../fn/city/restore-city';
import { searchInseeCode } from '../fn/city/search-insee-code';
import { SearchInseeCode$Params } from '../fn/city/search-insee-code';
import { showCity } from '../fn/city/show-city';
import { ShowCity$Params } from '../fn/city/show-city';

@Injectable({ providedIn: 'root' })
export class CityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchInseeCode()` */
  static readonly SearchInseeCodePath = '/city/inseeCode';

  /**
   * search InseeCode.
   *
   * search InseeCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchInseeCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchInseeCode$Response(params?: SearchInseeCode$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return searchInseeCode(this.http, this.rootUrl, params, context);
  }

  /**
   * search InseeCode.
   *
   * search InseeCode
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchInseeCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchInseeCode(params?: SearchInseeCode$Params, context?: HttpContext): Observable<string> {
    return this.searchInseeCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `listCity()` */
  static readonly ListCityPath = '/city';

  /**
   * List city.
   *
   * List city
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCity$Response(params?: ListCity$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<CityResponse>;
'total'?: number;
}>> {
    return listCity(this.http, this.rootUrl, params, context);
  }

  /**
   * List city.
   *
   * List city
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCity(params?: ListCity$Params, context?: HttpContext): Observable<{
'data'?: Array<CityResponse>;
'total'?: number;
}> {
    return this.listCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<CityResponse>;
'total'?: number;
}>): {
'data'?: Array<CityResponse>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createCity()` */
  static readonly CreateCityPath = '/city';

  /**
   * Create city.
   *
   * Create one city
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCity$Response(params: CreateCity$Params, context?: HttpContext): Observable<StrictHttpResponse<CityResponse>> {
    return createCity(this.http, this.rootUrl, params, context);
  }

  /**
   * Create city.
   *
   * Create one city
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCity(params: CreateCity$Params, context?: HttpContext): Observable<CityResponse> {
    return this.createCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<CityResponse>): CityResponse => r.body)
    );
  }

  /** Path part for operation `countCity()` */
  static readonly CountCityPath = '/city/count';

  /**
   * Count city.
   *
   * Count city
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  countCity$Response(params?: CountCity$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: number;
}>> {
    return countCity(this.http, this.rootUrl, params, context);
  }

  /**
   * Count city.
   *
   * Count city
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countCity(params?: CountCity$Params, context?: HttpContext): Observable<{
'data'?: number;
}> {
    return this.countCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: number;
}>): {
'data'?: number;
} => r.body)
    );
  }

  /** Path part for operation `showCity()` */
  static readonly ShowCityPath = '/city/{id}';

  /**
   * Show city.
   *
   * Show one city
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `showCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCity$Response(params: ShowCity$Params, context?: HttpContext): Observable<StrictHttpResponse<CityResponse>> {
    return showCity(this.http, this.rootUrl, params, context);
  }

  /**
   * Show city.
   *
   * Show one city
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `showCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  showCity(params: ShowCity$Params, context?: HttpContext): Observable<CityResponse> {
    return this.showCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<CityResponse>): CityResponse => r.body)
    );
  }

  /** Path part for operation `restoreCity()` */
  static readonly RestoreCityPath = '/city/{id}';

  /**
   * Restore city.
   *
   * Restore city
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreCity$Response(params: RestoreCity$Params, context?: HttpContext): Observable<StrictHttpResponse<CityResponse>> {
    return restoreCity(this.http, this.rootUrl, params, context);
  }

  /**
   * Restore city.
   *
   * Restore city
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restoreCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restoreCity(params: RestoreCity$Params, context?: HttpContext): Observable<CityResponse> {
    return this.restoreCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<CityResponse>): CityResponse => r.body)
    );
  }

  /** Path part for operation `deleteCity()` */
  static readonly DeleteCityPath = '/city/{id}';

  /**
   * Delete city.
   *
   * Delete city (soft delete)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCity$Response(params: DeleteCity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCity(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete city.
   *
   * Delete city (soft delete)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCity(params: DeleteCity$Params, context?: HttpContext): Observable<void> {
    return this.deleteCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchCity()` */
  static readonly PatchCityPath = '/city/{id}';

  /**
   * Patch city.
   *
   * Patch one city (partial update)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCity$Response(params: PatchCity$Params, context?: HttpContext): Observable<StrictHttpResponse<CityResponse>> {
    return patchCity(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch city.
   *
   * Patch one city (partial update)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCity(params: PatchCity$Params, context?: HttpContext): Observable<CityResponse> {
    return this.patchCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<CityResponse>): CityResponse => r.body)
    );
  }

  /** Path part for operation `fakeCity()` */
  static readonly FakeCityPath = '/city/faker';

  /**
   * Fake city.
   *
   * Generate fake city
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fakeCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeCity$Response(params?: FakeCity$Params, context?: HttpContext): Observable<StrictHttpResponse<CityResponse>> {
    return fakeCity(this.http, this.rootUrl, params, context);
  }

  /**
   * Fake city.
   *
   * Generate fake city
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fakeCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fakeCity(params?: FakeCity$Params, context?: HttpContext): Observable<CityResponse> {
    return this.fakeCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<CityResponse>): CityResponse => r.body)
    );
  }

}
