import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { Ability, PureAbility } from '@casl/ability';
import { find } from 'lodash';
import { GravatarService } from './gravatar.service';
import { TokenStorageService } from './token-storage.service';
import { WebSocketService } from './web-socket.service';
import { LockService } from './lock.service';
import {
  AuthenticationService,
  RoleService,
  UserRoleService,
  UserService,
} from 'src/api/services';
import { AuthPayload, Company, Role, User } from 'src/api/models';
import { environment } from 'src/environments/environment';

const USER_ID = 'UserId';

@Injectable({
  providedIn: 'root',
})
export class LocalUserService {
  public userId?: string;
  public user?: User & { roles?: Role[] };
  public company?: Company;

  constructor(
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private authentificationService: AuthenticationService,
    private gravatarService: GravatarService,
    private http: HttpClient,
    private webSocketService: WebSocketService,
    private ability: Ability,
    private userRoleService: UserRoleService,
    private roleService: RoleService,
    private pureAbility: PureAbility,
    private injector: Injector,
  ) {
    //try to get the last UserId used before -> allows to not search userId from token each time
    const userIdStorage = localStorage.getItem(USER_ID);

    if (userIdStorage) this.userId = userIdStorage;
  }

  /**
   *
   * @returns url of the picture
   */
  getUserProfilePicture(): string {
    const email = this.user ? this.user.email : '';
    return this.gravatarService.getPictureURL(email);
  }

  /**
   * Return the current local user (not async)
   * @returns
   */
  getUser(): User {
    return this.user as User;
  }

  /**
   * get Actual User, if no exist, try to get it and initiate it
   * @returns
   */
  async checkAndGetUser(): Promise<User> {
    //If user not set
    if (!this.user) {
      const id = await firstValueFrom(this.checkAndGetUserId());
      const user = await firstValueFrom(
        this.userService.showUser({ id, fetch: '[company, roles]' }),
      );

      if (user.id) {
        this.userId = user.id;
        localStorage.setItem(USER_ID, user.id);
        this.company = user.company as Company;
        this.user = user;
      }
    }

    if (this.user) {
      const roles: Role[] = this.user.roles || [];

      //   roles = [{
      //     name : 'manager',
      //     permissions: [
      //     {
      //       "action": [
      //         "create",
      //         "read",
      //         "update"
      //       ],
      //       "subject": "User"
      //     },
      //     {
      //       "action": [
      //         "read",
      //         "update"
      //       ],
      //       "subject": "Company"
      //     }
      //   ]
      // }] as any[]

      //(<any>this.user).roles = []

      this.user.roles = roles;

      for (const [index, role] of roles.entries()) {
        if (!role) {
          console.warn('Invalid user role', this.user, 'index', index);

          continue;
        }

        const { rules } = new Ability(<any>role.permissions);

        this.ability.update(rules);
      }

      // console.log(this.ability.can('update','Company'))
      // console.log(this.hasRole('admin'))

      if (environment.webSockets) {
        this.webSocketService.connect();
      }

      return this.user;
    } else {
      throw Error('User not found');
    }
  }

  /**
   * Return userId (not async)
   * @returns
   */
  getUserId(): string | null | undefined {
    return this.userId;
  }

  /**
   * Get userId from the service if exist or search it with the tokenSorage
   * @returns
   */
  checkAndGetUserId(): Observable<string> {
    return new Observable((subscribe) => {
      if (this.userId) {
        subscribe.next(this.userId);
        subscribe.complete();
      } else {
        const token = this.tokenStorageService.getToken();

        this.getAuthPayLoad(token).subscribe({
          next: (res: AuthPayload) => {
            const newId: string = res?.userId;

            if (newId) {
              this.userId = newId;
              subscribe.next(this.userId);
              subscribe.complete();
            } else {
              subscribe.error({
                message: 'Unable to get userId from decoded token',
                res: res,
              });
            }
          },
          error: (error) => {
            subscribe.error(error);
          },
        });
      }
    });
  }

  /**
   * Get AuthPayload from a token
   * @param token
   * @returns
   */
  getAuthPayLoad(token: string | null): Observable<AuthPayload> {
    if (token) return this.authentificationService.decode({ token });
    else return throwError('Invalid Token');
  }

  /**
   * Get the user's openapi.json
   * @returns
   */
  // getSwaggerJSON(): Observable<any> {
  //   return this.http.get(environment.apiUrl + '/docs/openapi.json');
  // }

  /**
   * Remove all the local storage value
   */
  disconnectUser() {
    this.user = undefined;
    this.userId = undefined;
    const lockService = this.injector.get(LockService);
    lockService.unlock();
    localStorage.removeItem(USER_ID);
  }

  /**
   * Check if current user has a role
   * @param role tab => role1 OR role2
   * @returns
   */
  hasRole(role: string | string[]) {
    //If admin, don't check
    if (role != 'admin' && this.isAdmin()) return true;

    const roles = this.user ? this.user.roles : undefined;
    if (typeof role === 'string') return Boolean(find(roles, { name: role }));

    //If array, check all value
    let exist = false;
    role.forEach((r) => {
      if (find(roles, { name: r })) exist = true;
    });
    return exist;
  }

  /**
   * Check if the user is admin or not
   * @returns
   */
  isAdmin() {
    return this.hasRole('admin');
  }

  /**
   * Check if the user as 2FA enabled
   * @returns
   */
  has2FA() {
    return this.authentificationService.otpStatus$Response();
  }
}
