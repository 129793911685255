import { Component } from '@angular/core';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { BaseTemplateComponent } from './base.template';
import { DynamicModelFormRender } from 'src/app/shared/models/dynamic-model-form-render';
import { CompanyResponse } from 'src/api/models';

@Component({
  templateUrl: 'company-address.template.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CompanyAddressTemplate extends BaseTemplateComponent<CompanyResponse> {
  public override getForm(): DynamicModelFormRender {
    return {
      model: 'Company',
      action: this.action,
      related: 'Addresses',
      fields: {
        CompanyAddress: {
          type: 'tab-form',
          tabs: {
            Info: {
              props: {
                title: 'Informations',
              },
              fieldGroup: {
                companyId: {
                  type: 'select',
                  required: true,
                  model: 'Company',
                  class: 'col-6',
                  props: {
                    resource: {
                      model: 'company',
                    },
                    // disabled: true,
                  },
                },
                Addresses: {
                  type: 'model-template',
                  modelTemplate: {
                    action: 'create',
                    name: 'Address',
                  },
                  required: true,
                },
              },
            },
          },
          // addresses: {
          // type: 'select',
          // required: true,
          // label: 'Adresse',
          // templateOptions: {
          // paramsBuilder: {
          // fields: ['houseNumber', 'road', 'postcode', 'city'],
          // },
          // resource: 'CompanyAddress',
          // }
          // }
          // }
          // addresses: {
          //   required: false,
          //   noImport: true,
          //   customImport : {
          // action : 'showAddress',
          // model : 'Address',
          // params : (data) => ({ id : data.id }),
          // data : (data) => data.data,
          //   },
          //   customExport : {
          // action : 'createAddress',
          // model : 'Address',
          // params : (data) => ({ id : data.id, body: data.addresses })
          //   },
          //   type: 'repeat',
          //   fieldArray: {
          // modelTemplate: {
          //   action: 'update',
          //   name: 'Address',
          // }
          //   }
          // },
          // }
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    throw new Error('Method not implemented.');
  }
}
