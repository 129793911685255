/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createNumber } from '../fn/voip/create-number';
import { CreateNumber$Params } from '../fn/voip/create-number';
import { createSip } from '../fn/voip/create-sip';
import { CreateSip$Params } from '../fn/voip/create-sip';
import { createVoipCompany } from '../fn/voip/create-voip-company';
import { CreateVoipCompany$Params } from '../fn/voip/create-voip-company';
import { deleteNumber } from '../fn/voip/delete-number';
import { DeleteNumber$Params } from '../fn/voip/delete-number';
import { deleteSip } from '../fn/voip/delete-sip';
import { DeleteSip$Params } from '../fn/voip/delete-sip';
import { deleteVoipCompany } from '../fn/voip/delete-voip-company';
import { DeleteVoipCompany$Params } from '../fn/voip/delete-voip-company';
import { getCompany } from '../fn/voip/get-company';
import { GetCompany$Params } from '../fn/voip/get-company';
import { getNumber } from '../fn/voip/get-number';
import { GetNumber$Params } from '../fn/voip/get-number';
import { getSip } from '../fn/voip/get-sip';
import { GetSip$Params } from '../fn/voip/get-sip';
import { listCompanies } from '../fn/voip/list-companies';
import { ListCompanies$Params } from '../fn/voip/list-companies';
import { listNumbers } from '../fn/voip/list-numbers';
import { ListNumbers$Params } from '../fn/voip/list-numbers';
import { listSips } from '../fn/voip/list-sips';
import { ListSips$Params } from '../fn/voip/list-sips';
import { searchNumbers } from '../fn/voip/search-numbers';
import { SearchNumbers$Params } from '../fn/voip/search-numbers';
import { updateNumber } from '../fn/voip/update-number';
import { UpdateNumber$Params } from '../fn/voip/update-number';
import { updateSip } from '../fn/voip/update-sip';
import { UpdateSip$Params } from '../fn/voip/update-sip';
import { updateVoipCompany } from '../fn/voip/update-voip-company';
import { UpdateVoipCompany$Params } from '../fn/voip/update-voip-company';


/**
 * VOX
 */
@Injectable({ providedIn: 'root' })
export class VoipService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listCompanies()` */
  static readonly ListCompaniesPath = '/voip/company/list';

  /**
   * List all companies
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCompanies()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCompanies$Response(params?: ListCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<{
}>;
'total'?: number;
}>> {
    return listCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * List all companies
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listCompanies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCompanies(params?: ListCompanies$Params, context?: HttpContext): Observable<{
'data'?: Array<{
}>;
'total'?: number;
}> {
    return this.listCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<{
}>;
'total'?: number;
}>): {
'data'?: Array<{
}>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createVoipCompany()` */
  static readonly CreateVoipCompanyPath = '/voip/company/create';

  /**
   * Create a company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVoipCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVoipCompany$Response(params: CreateVoipCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return createVoipCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createVoipCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVoipCompany(params: CreateVoipCompany$Params, context?: HttpContext): Observable<{
}> {
    return this.createVoipCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `getCompany()` */
  static readonly GetCompanyPath = '/voip/company/{id}';

  /**
   * Get a company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany$Response(params: GetCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompany(params: GetCompany$Params, context?: HttpContext): Observable<{
}> {
    return this.getCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `updateVoipCompany()` */
  static readonly UpdateVoipCompanyPath = '/voip/company/{id}';

  /**
   * Update a company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVoipCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVoipCompany$Response(params: UpdateVoipCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return updateVoipCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateVoipCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVoipCompany(params: UpdateVoipCompany$Params, context?: HttpContext): Observable<{
}> {
    return this.updateVoipCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `deleteVoipCompany()` */
  static readonly DeleteVoipCompanyPath = '/voip/company/{id}';

  /**
   * Delete a company
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteVoipCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVoipCompany$Response(params: DeleteVoipCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteVoipCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a company
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteVoipCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteVoipCompany(params: DeleteVoipCompany$Params, context?: HttpContext): Observable<void> {
    return this.deleteVoipCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `listSips()` */
  static readonly ListSipsPath = '/voip/sip/list';

  /**
   * List all sip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listSips()` instead.
   *
   * This method doesn't expect any request body.
   */
  listSips$Response(params?: ListSips$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'data'?: Array<{
}>;
'total'?: number;
}>> {
    return listSips(this.http, this.rootUrl, params, context);
  }

  /**
   * List all sip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listSips$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listSips(params?: ListSips$Params, context?: HttpContext): Observable<{
'data'?: Array<{
}>;
'total'?: number;
}> {
    return this.listSips$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data'?: Array<{
}>;
'total'?: number;
}>): {
'data'?: Array<{
}>;
'total'?: number;
} => r.body)
    );
  }

  /** Path part for operation `createSip()` */
  static readonly CreateSipPath = '/voip/sip/create';

  /**
   * Create a sip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSip()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSip$Response(params: CreateSip$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return createSip(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a sip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSip$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSip(params: CreateSip$Params, context?: HttpContext): Observable<{
}> {
    return this.createSip$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `getSip()` */
  static readonly GetSipPath = '/voip/sip/{id}';

  /**
   * Get a sip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSip()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSip$Response(params: GetSip$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getSip(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a sip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSip(params: GetSip$Params, context?: HttpContext): Observable<{
}> {
    return this.getSip$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `updateSip()` */
  static readonly UpdateSipPath = '/voip/sip/{id}';

  /**
   * Update a sip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSip()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSip$Response(params: UpdateSip$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return updateSip(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a sip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSip$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSip(params: UpdateSip$Params, context?: HttpContext): Observable<{
}> {
    return this.updateSip$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `deleteSip()` */
  static readonly DeleteSipPath = '/voip/sip/{id}';

  /**
   * Delete a Sip
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSip()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSip$Response(params: DeleteSip$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSip(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a Sip
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSip(params: DeleteSip$Params, context?: HttpContext): Observable<void> {
    return this.deleteSip$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `listNumbers()` */
  static readonly ListNumbersPath = '/voip/number/list';

  /**
   * List all numbers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  listNumbers$Response(params?: ListNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return listNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * List all numbers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listNumbers(params?: ListNumbers$Params, context?: HttpContext): Observable<{
}> {
    return this.listNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `createNumber()` */
  static readonly CreateNumberPath = '/voip/number/create';

  /**
   * Create a number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNumber$Response(params: CreateNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return createNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNumber(params: CreateNumber$Params, context?: HttpContext): Observable<{
}> {
    return this.createNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `getNumber()` */
  static readonly GetNumberPath = '/voip/number/{id}';

  /**
   * Get a number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumber$Response(params: GetNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumber(params: GetNumber$Params, context?: HttpContext): Observable<{
}> {
    return this.getNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `updateNumber()` */
  static readonly UpdateNumberPath = '/voip/number/{id}';

  /**
   * Update a number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNumber$Response(params: UpdateNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return updateNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNumber(params: UpdateNumber$Params, context?: HttpContext): Observable<{
}> {
    return this.updateNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `deleteNumber()` */
  static readonly DeleteNumberPath = '/voip/number/{id}';

  /**
   * Delete a number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNumber$Response(params: DeleteNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a number
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNumber(params: DeleteNumber$Params, context?: HttpContext): Observable<void> {
    return this.deleteNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `searchNumbers()` */
  static readonly SearchNumbersPath = '/voip/number/search';

  /**
   * Search numbers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchNumbers$Response(params: SearchNumbers$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return searchNumbers(this.http, this.rootUrl, params, context);
  }

  /**
   * Search numbers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchNumbers(params: SearchNumbers$Params, context?: HttpContext): Observable<{
}> {
    return this.searchNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
