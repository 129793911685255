import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  constructor(private utils: UtilsService) {}

  public transform(value: any): string {
    return this.utils.capitalize(value);
  }
}
