/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { vCenterAddTag } from '../fn/vcenter/v-center-add-tag';
import { VCenterAddTag$Params } from '../fn/vcenter/v-center-add-tag';
import { vCenterCreateFolder } from '../fn/vcenter/v-center-create-folder';
import { VCenterCreateFolder$Params } from '../fn/vcenter/v-center-create-folder';
import { vCenterGetTask } from '../fn/vcenter/v-center-get-task';
import { VCenterGetTask$Params } from '../fn/vcenter/v-center-get-task';
import { vCenterInfos } from '../fn/vcenter/v-center-infos';
import { VCenterInfos$Params } from '../fn/vcenter/v-center-infos';
import { vCenterListDataStore } from '../fn/vcenter/v-center-list-data-store';
import { VCenterListDataStore$Params } from '../fn/vcenter/v-center-list-data-store';
import { vCenterListFolder } from '../fn/vcenter/v-center-list-folder';
import { VCenterListFolder$Params } from '../fn/vcenter/v-center-list-folder';
import { vCenterListNetwork } from '../fn/vcenter/v-center-list-network';
import { VCenterListNetwork$Params } from '../fn/vcenter/v-center-list-network';
import { vCenterListRessourcePools } from '../fn/vcenter/v-center-list-ressource-pools';
import { VCenterListRessourcePools$Params } from '../fn/vcenter/v-center-list-ressource-pools';
import { vCenterListService } from '../fn/vcenter/v-center-list-service';
import { VCenterListService$Params } from '../fn/vcenter/v-center-list-service';
import { vCenterListTag } from '../fn/vcenter/v-center-list-tag';
import { VCenterListTag$Params } from '../fn/vcenter/v-center-list-tag';
import { vCenterListTask } from '../fn/vcenter/v-center-list-task';
import { VCenterListTask$Params } from '../fn/vcenter/v-center-list-task';
import { vCenterListVmTags } from '../fn/vcenter/v-center-list-vm-tags';
import { VCenterListVmTags$Params } from '../fn/vcenter/v-center-list-vm-tags';
import { vCenterVmClone } from '../fn/vcenter/v-center-vm-clone';
import { VCenterVmClone$Params } from '../fn/vcenter/v-center-vm-clone';
import { vCenterVmCustomize } from '../fn/vcenter/v-center-vm-customize';
import { VCenterVmCustomize$Params } from '../fn/vcenter/v-center-vm-customize';
import { vCenterVmList } from '../fn/vcenter/v-center-vm-list';
import { VCenterVmList$Params } from '../fn/vcenter/v-center-vm-list';
import { vCenterVmPower } from '../fn/vcenter/v-center-vm-power';
import { VCenterVmPower$Params } from '../fn/vcenter/v-center-vm-power';
import { vCenterVmScreenTicket } from '../fn/vcenter/v-center-vm-screen-ticket';
import { VCenterVmScreenTicket$Params } from '../fn/vcenter/v-center-vm-screen-ticket';
import { vCenterVmTemplateList } from '../fn/vcenter/v-center-vm-template-list';
import { VCenterVmTemplateList$Params } from '../fn/vcenter/v-center-vm-template-list';

@Injectable({ providedIn: 'root' })
export class VcenterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `vCenterVmCustomize()` */
  static readonly VCenterVmCustomizePath = '/vcenter/{node}/vm/customize';

  /**
   * VCenter VM Customize.
   *
   * Get VCenter VM Customize
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterVmCustomize()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterVmCustomize$Response(params: VCenterVmCustomize$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterVmCustomize(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter VM Customize.
   *
   * Get VCenter VM Customize
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterVmCustomize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterVmCustomize(params: VCenterVmCustomize$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterVmCustomize$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterGetTask()` */
  static readonly VCenterGetTaskPath = '/vcenter/{node}/task/show';

  /**
   * VCenter Get Task.
   *
   * Get VCenter Task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterGetTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterGetTask$Response(params: VCenterGetTask$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterGetTask(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter Get Task.
   *
   * Get VCenter Task
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterGetTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterGetTask(params: VCenterGetTask$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterGetTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterListTask()` */
  static readonly VCenterListTaskPath = '/vcenter/{node}/task/list';

  /**
   * VCenter List Task.
   *
   * Get VCenter List Task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterListTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListTask$Response(params: VCenterListTask$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterListTask(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter List Task.
   *
   * Get VCenter List Task
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterListTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListTask(params: VCenterListTask$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterListTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterListNetwork()` */
  static readonly VCenterListNetworkPath = '/vcenter/{node}/network/list';

  /**
   * VCenter List Network.
   *
   * Get VCenter List Network
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterListNetwork()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListNetwork$Response(params: VCenterListNetwork$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterListNetwork(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter List Network.
   *
   * Get VCenter List Network
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterListNetwork$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListNetwork(params: VCenterListNetwork$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterListNetwork$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterListService()` */
  static readonly VCenterListServicePath = '/vcenter/{node}/service/list';

  /**
   * VCenter List Service.
   *
   * Get VCenter List Service
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterListService()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListService$Response(params: VCenterListService$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterListService(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter List Service.
   *
   * Get VCenter List Service
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterListService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListService(params: VCenterListService$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterListService$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterCreateFolder()` */
  static readonly VCenterCreateFolderPath = '/vcenter/{node}/folder/create';

  /**
   * VCenter Create Folder.
   *
   * Get VCenter Create Folder
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterCreateFolder()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterCreateFolder$Response(params: VCenterCreateFolder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterCreateFolder(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter Create Folder.
   *
   * Get VCenter Create Folder
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterCreateFolder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterCreateFolder(params: VCenterCreateFolder$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterCreateFolder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterListFolder()` */
  static readonly VCenterListFolderPath = '/vcenter/{node}/folder/list';

  /**
   * VCenter List Folder.
   *
   * Get VCenter List DataStore
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterListFolder()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListFolder$Response(params: VCenterListFolder$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterListFolder(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter List Folder.
   *
   * Get VCenter List DataStore
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterListFolder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListFolder(params: VCenterListFolder$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterListFolder$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterListDataStore()` */
  static readonly VCenterListDataStorePath = '/vcenter/{node}/datastore/list';

  /**
   * VCenter List DataStore.
   *
   * Get VCenter List DataStore
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterListDataStore()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListDataStore$Response(params: VCenterListDataStore$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterListDataStore(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter List DataStore.
   *
   * Get VCenter List DataStore
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterListDataStore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListDataStore(params: VCenterListDataStore$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterListDataStore$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterListRessourcePools()` */
  static readonly VCenterListRessourcePoolsPath = '/vcenter/{node}/ressource-pools/list';

  /**
   * VCenter List Ressource Pools.
   *
   * Get VCenter List Ressource Pools
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterListRessourcePools()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListRessourcePools$Response(params: VCenterListRessourcePools$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterListRessourcePools(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter List Ressource Pools.
   *
   * Get VCenter List Ressource Pools
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterListRessourcePools$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListRessourcePools(params: VCenterListRessourcePools$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterListRessourcePools$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterListVmTags()` */
  static readonly VCenterListVmTagsPath = '/vcenter/{node}/vm/tags';

  /**
   * VCenter List VM Tags.
   *
   * Get VCenter List VM Tags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterListVmTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListVmTags$Response(params: VCenterListVmTags$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterListVmTags(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter List VM Tags.
   *
   * Get VCenter List VM Tags
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterListVmTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListVmTags(params: VCenterListVmTags$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterListVmTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterListTag()` */
  static readonly VCenterListTagPath = '/vcenter/{node}/tag/list';

  /**
   * VCenter List Tag.
   *
   * Get VCenter List Tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterListTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListTag$Response(params: VCenterListTag$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterListTag(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter List Tag.
   *
   * Get VCenter List Tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterListTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterListTag(params: VCenterListTag$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterListTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterAddTag()` */
  static readonly VCenterAddTagPath = '/vcenter/{node}/tag/add';

  /**
   * VCenter Add Tag.
   *
   * Get VCenter Add Tag
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterAddTag()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterAddTag$Response(params: VCenterAddTag$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterAddTag(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter Add Tag.
   *
   * Get VCenter Add Tag
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterAddTag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterAddTag(params: VCenterAddTag$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterAddTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterInfos()` */
  static readonly VCenterInfosPath = '/vcenter/{node}/infos';

  /**
   * VCenter VM Infos.
   *
   * Get VCenter VM Infos
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterInfos$Response(params: VCenterInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter VM Infos.
   *
   * Get VCenter VM Infos
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterInfos(params: VCenterInfos$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterInfos$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterVmList()` */
  static readonly VCenterVmListPath = '/vcenter/{node}/vm/list';

  /**
   * VCenter VM List.
   *
   * Get VCenter VM List
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterVmList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterVmList$Response(params: VCenterVmList$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterVmList(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter VM List.
   *
   * Get VCenter VM List
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterVmList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterVmList(params: VCenterVmList$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterVmList$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterVmPower()` */
  static readonly VCenterVmPowerPath = '/vcenter/{node}/vm/power';

  /**
   * VCenter VM Power.
   *
   * VCenter VM Power
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterVmPower()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vCenterVmPower$Response(params: VCenterVmPower$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterVmPower(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter VM Power.
   *
   * VCenter VM Power
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterVmPower$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vCenterVmPower(params: VCenterVmPower$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterVmPower$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterVmScreenTicket()` */
  static readonly VCenterVmScreenTicketPath = '/vcenter/{node}/vm/screenticket';

  /**
   * VCenter VM Screen Ticket.
   *
   * Get VCenter VM Screen Ticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterVmScreenTicket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vCenterVmScreenTicket$Response(params: VCenterVmScreenTicket$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterVmScreenTicket(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter VM Screen Ticket.
   *
   * Get VCenter VM Screen Ticket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterVmScreenTicket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vCenterVmScreenTicket(params: VCenterVmScreenTicket$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterVmScreenTicket$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterVmClone()` */
  static readonly VCenterVmClonePath = '/vcenter/{node}/vm/clone';

  /**
   * VCenter VM Clone.
   *
   * VCenter VM Clone
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterVmClone()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vCenterVmClone$Response(params: VCenterVmClone$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterVmClone(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter VM Clone.
   *
   * VCenter VM Clone
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterVmClone$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  vCenterVmClone(params: VCenterVmClone$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterVmClone$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `vCenterVmTemplateList()` */
  static readonly VCenterVmTemplateListPath = '/vcenter/{node}/vm/template/list';

  /**
   * VCenter VM Template List.
   *
   * VCenter VM Template List
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `vCenterVmTemplateList()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterVmTemplateList$Response(params: VCenterVmTemplateList$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return vCenterVmTemplateList(this.http, this.rootUrl, params, context);
  }

  /**
   * VCenter VM Template List.
   *
   * VCenter VM Template List
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `vCenterVmTemplateList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  vCenterVmTemplateList(params: VCenterVmTemplateList$Params, context?: HttpContext): Observable<{
}> {
    return this.vCenterVmTemplateList$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
