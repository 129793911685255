import { FormlyFieldConfig } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { map, of } from 'rxjs';
import { OrderTemplateComponent } from './order.template';
import { LocalUserService } from 'src/app/core/services/local-user.service';
import { CatalogService, CompanyService, UserService } from 'src/api/services';
import { ParamsBuilderService } from 'src/app/shared/services/params-builder.service';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class trunkTemplate extends OrderTemplateComponent {
  constructor(
    companyService: CompanyService,
    userService: UserService,
    paramsBuilderService: ParamsBuilderService,
    localUserService: LocalUserService,
    private catalogService: CatalogService,
  ) {
    super(companyService, userService, paramsBuilderService, localUserService);
  }

  public override getForm(): FormlyFieldConfig[] {
    return [
      {
        type: 'stepper',
        fieldGroup: [
          {
            props: {
              title: 'Informations',
              icon: 'user',
              subTitle: 'Détails du client',
            },
            fieldGroup: [
              {
                type: 'section',
                props: {
                  label: 'Revendeur',
                },
                fieldGroup: [
                  {
                    key: 'company',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      label: 'MODEL.Company',
                      translate: true,
                      required: true,
                      placeholder: 'Sélectionner...',
                      click: () =>
                        this.companyService
                          .listCompany$Response(
                            this.paramsBuilderService.build({
                              limit: 50,
                              fullSearch: {
                                whereILike: { name: this.model.whereLike },
                              },
                            }),
                          )
                          .pipe(map((r) => r.body.data!)),
                      defaultValue: this.companyService
                        .showCompany$Response({
                          id: this.localUserService.getUser()?.companyId,
                        })
                        .pipe(
                          map((r) => {
                            return [r.body];
                          }),
                        ),
                      labelProp: 'name',
                    },
                  },
                  {
                    key: 'commercial',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      label: 'Commercial',
                      required: false,
                      click: () =>
                        this.userService
                          .listUser$Response(
                            this.paramsBuilderService.build({
                              limit: 50,
                              fullSearch: {
                                whereILike: [
                                  { firstName: this.model.whereLike },
                                ],
                                where: { companyId: this.model.company?.id },
                              },
                            }),
                          )
                          .pipe(
                            map((r) => {
                              r.body.data!.forEach((u: any) => {
                                u.fullName = `${u.firstName} ${u.lastName} (${u.email})`;
                              });
                              return r.body.data!;
                            }),
                          ),
                      labelProp: 'fullName',
                    },
                    expressions: {
                      'props.disabled': '!model.company',
                    },
                  },
                ],
              },
              {
                type: 'section',
                props: {
                  label: 'Client Final',
                },
                fieldGroup: [
                  {
                    key: 'client',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      label: 'Client',
                      required: true,
                      add: true,
                      templateName: 'company',
                      click: () =>
                        this.companyService
                          .listCompany$Response(
                            this.paramsBuilderService.build({
                              fullSearch: {
                                whereILike: { name: this.model.whereLike },
                                where: {
                                  tenantId: this.model.company?.tenantId,
                                },
                              },
                            }),
                          )
                          .pipe(map((r) => r.body.data!)),
                      labelProp: 'name',
                    },
                    expressions: {
                      'props.disabled': '!model.company',
                    },
                  },
                  {
                    key: 'contact',
                    type: 'input-select',
                    className: 'col-md-6',
                    props: {
                      label: 'Contact',
                      add: true,
                      templateName: 'user',
                      click: () =>
                        this.userService
                          .listUser$Response(
                            this.paramsBuilderService.build({
                              fullSearch: {
                                whereILike: [
                                  { firstName: this.model.whereLike },
                                ],
                                where: { companyId: this.model.client?.id },
                              },
                            }),
                          )
                          .pipe(
                            map((r) => {
                              r.body.data!.forEach((u: any) => {
                                u.fullName = `${u.firstName} ${u.lastName} (${u.email})`;
                              });
                              return r.body.data!;
                            }),
                          ),
                      labelProp: 'fullName',
                    },
                    expressions: {
                      'props.disabled': '!model.client',
                    },
                  },
                ],
              },
            ],
          },
          {
            props: {
              title: 'Services',
              icon: 'wrench',
              subTitle: 'Informations techniques',
            },
            fieldGroup: [
              {
                type: 'section',
                props: {
                  label: 'Services',
                },
                fieldGroup: [
                  {
                    key: 'data.offer',
                    type: 'input-select',
                    props: {
                      label: 'Offre',
                      required: true,
                      click: () =>
                        this.catalogService
                          .listCatalogOffers$Response(
                            this.paramsBuilderService.build({
                              fullSearch: {
                                whereILike: { 'category.name': '%trunk%' },
                              },
                              join: ['category'],
                            }),
                          )
                          .pipe(map((r) => r.body.data!)),
                      labelProp: 'name',
                    },
                  },
                  {
                    key: 'numberFormat',
                    type: 'input-select',
                    className: 'col-md-3',
                    props: {
                      label: 'Format des numéros',
                      required: true,
                      click: () =>
                        of([
                          { label: 'e164', value: 'e164' },
                          { label: '+e164', value: '+e164' },
                          { label: 'national9', value: 'national9' },
                          { label: 'national10', value: 'national10' },
                        ]),
                    },
                    expressions: {
                      hide: '!model.data?.offer',
                    },
                  },
                  {
                    key: 'postalCode',
                    type: 'maskedInput',
                    className: 'col-md-3',
                    props: {
                      label: 'Code postal',
                      required: true,
                      mask: '00000',
                    },
                    expressions: {
                      hide: '!model.data?.offer',
                    },
                  },
                  {
                    key: 'city',
                    type: 'input-select',
                    className: 'col-md-3',
                    props: {
                      label: 'Ville',
                      required: true,
                      click: () =>
                        of([
                          {
                            label: 'Paris',
                            value: 'Paris',
                          },
                        ]), //To be replace by city service when ready
                      labelProp: 'label',
                      valueProp: 'value',
                    },
                    expressions: {
                      hide: '!model.data?.offer',
                      'props.disabled': '!model.postalCode',
                    },
                  },
                  {
                    key: 'inseeCode',
                    type: 'input',
                    className: 'col-md-3',
                    props: {
                      label: 'Code INSEE',
                    },
                    expressions: {
                      hide: '!model.data?.offer',
                      'props.disabled': 'model.postalCode',
                    },
                  },
                  {
                    key: 'libelle',
                    type: 'input',
                    props: {
                      label: 'Libellé du trunk',
                    },
                    expressions: {
                      hide: '!model.data?.offer',
                    },
                  },
                  {
                    key: 'optionCommand',
                    type: 'optionCommand',
                    props: {
                      label: 'Options',
                      options: this.model.data?.offer.options,
                      required: true,
                      related: 'data.offer',
                    },
                    expressions: {
                      hide: '!model.data?.offer',
                      'props.options': 'model.data?.offer?.options',
                    },
                  },
                ],
              },
            ],
          },
          {
            props: {
              title: 'Confirmation',
              icon: 'check',
              subTitle: 'Récapitulatif de la commande',
            },
            fieldGroup: [
              {
                type: 'section',
                props: {
                  label: 'Informations',
                },
                fieldGroup: [
                  {
                    type: 'summary',
                    props: {
                      label: 'Client',
                    },
                    expressions: {
                      'props.value': 'model.client?.name',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Contact',
                    },
                    expressions: {
                      'props.value': 'model.contact?.fullName',
                    },
                  },
                ],
              },
              {
                type: 'section',
                props: {
                  label: 'Services',
                },
                fieldGroup: [
                  {
                    type: 'summary',
                    props: {
                      label: 'Offre',
                    },
                    expressions: {
                      'props.value': 'model.data?.offer?.name',
                      'props.label':
                        '"Offre("+ model.data?.offer?.options[0].name+")"',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Format des numéros',
                    },
                    expressions: {
                      'props.value': 'model.numberFormat?.value',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Code postal',
                    },
                    expressions: {
                      'props.value': 'model.postalCode',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Ville',
                    },
                    expressions: {
                      'props.value': 'model.city?.value',
                    },
                  },
                  {
                    type: 'summary',
                    props: {
                      label: 'Libellé du trunk',
                    },
                    expressions: {
                      'props.value': 'model.libelle',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
  }

  public override getTable(): DataTables.Settings {
    throw new Error('Method not implemented.');
  }
}
