import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { ModelTemplateService } from '../../services/model-template.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'modal-template',
  templateUrl: './modal-form-template.component.html',
})
export class ModalFormTemplateComponent implements OnInit {
  @Input() action!: string;
  @Input() model!: string;
  @Input() id?: string;
  @Input() form?: UntypedFormGroup;
  @Input() title?: string;

  public alert?: {
    title: string;
    message?: string;
    type: string;
    icon?: string;
  };

  color = 'primary';

  constructor(
    public activeModal: NgbActiveModal,
    private dynamicModelForm: ModelTemplateService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    switch (this.action) {
      case 'list':
        this.color = 'primary';
        break;
      case 'create':
        this.color = 'success';
        break;
      case 'update':
        this.color = 'secondary';
        break;
      case 'delete':
        this.color = 'danger';
        break;
      case 'restore':
        this.color = 'warn';
        break;
    }

    // this.alert = {
    //   title: '',
    //   message: '',
    //   type: 'warning',
    //   icon: 'exclamation-triangle',
    // };
  }

  onSuccess(event: Event, activeModal: NgbActiveModal) {
    this.alert = {
      title: 'Mise à jour effectuée !',
      type: 'success',
      icon: 'check',
    };
    setTimeout(() => {
      activeModal.close();
    }, 1000);
  }

  onFailure(event: Event & { error: Error }) {
    const title =
      event.error && event.error.message
        ? event.error.message
        : JSON.stringify(event);
    let msg = '';

    if (!environment.production) {
      const err = event.error as Error & {
        data?: unknown;
        debug?: { message?: string };
      };
      if (!isEmpty(err?.data)) {
        msg +=
          '<br><h6>DATA</h6><p class="mb-0">' +
          JSON.stringify(err.data, null, 2) +
          '</p>';
      }
      if (err?.debug?.message) {
        msg +=
          '<br><h6>DEBUG</h6><pre class="mb-0 text-wrap">' +
          err.debug.message +
          '</pre>';
      }
    }

    this.alert = {
      title,
      message: msg,
      type: 'danger',
      icon: environment.production ? 'times' : 'bug',
    };
  }
}
