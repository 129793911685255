import { Component } from '@angular/core';
import { DynamicModelFormRender } from '../models/dynamic-model-form-render';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { BaseTemplateComponent } from './base.template';
import { RoleResponse } from 'src/api/models/role-response';

// import { AnyAction } from '../models/actions/action';

// @Injectable()
@Component({
  selector: 'role-template',
  templateUrl: './role.template.html',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class RoleTemplate extends BaseTemplateComponent<RoleResponse> {
  override defaultParamsBuilder = {
    fields: ['name'],
  };

  override defaultViewParams = {
    // fetch: 'company',
  };

  public override getForm(): DynamicModelFormRender {
    return {
      model: 'Role',
      action: this.action,
      fields: {
        Role: {
          type: 'tab-form',
          tabs: {
            Infos: {
              props: {
                title: 'Informations',
              },
              fieldGroup: {
                name: {
                  type: 'input',
                  label: 'Slug',
                  class: 'col-12',
                  row: true,
                  required: true,
                },
                permissions: {
                  type: 'select',
                  label: 'Permissions',
                  class: 'col-12',
                  // required: true,
                },
              },
            },
          },
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    return {
      buttons: this.getButtons('Role'),
      columns: {
        id: {
          type: 'input',
          title: 'ID contact',
          className: 'no-export',
          visible: false,
        },
        name: {
          title: 'Slug',
        },
        permissions: {
          title: 'Permissions',
          render: (data) => JSON.stringify(data),
        },
        updatedAt: true,
        createdAt: true,
      },
    };
  }
}
