import { Component } from '@angular/core';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { DynamicModelFormRender } from '../models/dynamic-model-form-render';
import { MomentDatePipe } from '../pipes/moment.pipe';
import { BaseTemplateComponent } from './base.template';
import { OfferRewrite } from 'src/api/models';

@Component({
  selector: 'app-offer-rewrite-template',
  templateUrl: './offer-rewrite.template.html',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OfferRewriteTemplate extends BaseTemplateComponent<OfferRewrite> {
  public override getForm(): DynamicModelFormRender {
    return {
      model: 'OfferRewrite',
      action: this.action,
      join: ['offer', 'plan'],
      fields: {
        id: {
          type: 'input',
          noExport: true,
          hide: true,
        },
        planId: {
          type: 'input',
          hide: true,
        },
        offer: {
          type: 'select',
          noImport: true,
          submitField: 'offerId',
          class: 'col-6',
          props: {
            allowClear: true,
            resource: {
              model: 'offer',
            },
            paramsBuilder: {
              limit: 50,
            },
            label: 'Offre',
            required: true,
            labelProp: 'name',
            mapOptions: (options) => {
              return {
                id: options,
                name: options.name,
              };
            },
          },
          hooks: {
            onInit: (field) => {
              field.formControl?.valueChanges.subscribe((value) => {
                if (value) {
                  this.model.offer = value;
                }
              });
            },
          },
        },
        name: {
          type: 'input',
          class: 'col-6',
          required: true,
          props: {
            label: "Nom de l'offre",
          },
          expressions: {
            'props.placeholder': () => {
              if (this.model.offer?.name) {
                return this.model.offer.name;
              }
            },
          },
        },
        description: {
          type: 'textarea',
          label: 'Description',
          class: 'col-12',
          props: {
            rows: 5,
          },
          expressions: {
            'props.placeholder': () => {
              if (this.model.offer?.description) {
                return this.model.offer.description;
              }
            },
          },
        },
        terms: {
          type: 'input',
          class: 'col-6',
          props: {
            label: 'Termes',
          },
          expressions: {
            'props.placeholder': () => {
              if (this.model.offer?.terms) {
                return this.model.offer.terms;
              }
            },
          },
        },
        code: {
          type: 'input',
          class: 'col-6',
          props: {
            label: 'Code',
          },
          expressions: {
            'props.placeholder': () => {
              if (this.model.offer?.code) {
                return this.model.offer.code;
              }
            },
          },
        },
        accountCode: {
          type: 'input',
          class: 'col-6',
          props: {
            label: 'Code comptable',
          },
          expressions: {
            'props.placeholder': () => {
              if (this.model.offer?.accountCode) {
                return this.model.offer.accountCode;
              }
            },
          },
        },
        engagement: {
          type: 'select',
          class: 'col-6',
          label: 'Engagement (mois)',
          props: {
            allowClear: true,
            options: [
              {
                id: null,
                name: "Pas d'engagement",
              },
              {
                id: 1,
                name: '1',
              },
              {
                id: 12,
                name: '12',
              },
              {
                id: 24,
                name: '24',
              },
              {
                id: 36,
                name: '36',
              },
            ],
            labelProp: 'name',
            valueProp: 'id',
          },
          expressions: {
            'props.placeholder': (field) => {
              if (this.model.offer?.engagement) {
                field.hide = true;
                field.hide = false;
                return this.model.offer.engagement;
              }
            },
          },
        },
        vat: {
          type: 'select',
          class: 'col-6',
          props: {
            label: 'TVA',
            required: true,
            options: [5.5, 10, 20],
            mapOptions: (options) => {
              return {
                id: options,
                name: options + '%',
              };
            },
          },
          expressions: {
            'props.placeholder': (field) => {
              if (this.model.offer?.vat) {
                field.hide = true;
                field.hide = false;
                return this.model.offer.vat + '%';
              }
              return;
            },
          },
        },
        label: {
          label: 'Label',
          type: 'input',
          class: 'col-6',
          expressions: {
            'props.placeholder': () => {
              if (this.model.offer?.label) {
                return this.model.offer.label;
              }
            },
          },
        },
        billingMode: {
          type: 'select',
          class: 'col-6',
          props: {
            label: 'Mode de facturation',
            allowClear: true,
            options: [
              {
                id: 'before',
                name: 'Avant',
              },
              {
                id: 'after',
                name: 'Après',
              },
            ],
            labelProp: 'name',
            valueProp: 'id',
          },
          expressions: {
            'props.placeholder': (field) => {
              if (this.model.offer?.billingMode) {
                field.hide = true;
                field.hide = false;
                return this.translate.instant(
                  this.model.offer.billingMode.toUpperCase(),
                );
              }
            },
          },
        },
        auto: {
          type: 'checkbox',
          class: 'col-6 d-flex align-items-center',
          props: {
            label: 'Auto',
          },
        },
        recurrenceSlug: {
          type: 'select',
          class: 'col-6',
          label: 'Récurrence',
          props: {
            allowClear: true,
            options: [
              {
                id: 'daily',
                name: 'Quotidien',
              },
              {
                id: 'weekly',
                name: 'Hebdomadaire',
              },
              {
                id: 'monthly',
                name: 'Mensuel',
              },
              {
                id: 'yearly',
                name: 'Annuel',
              },
            ],
            labelProp: 'name',
            valueProp: 'id',
          },
          expressions: {
            'props.placeholder': (field) => {
              if (this.model.offer?.recurrenceSlug) {
                field.hide = true;
                field.hide = false;
                return this.translate.instant(
                  'RECUR.' + this.model.offer.recurrenceSlug.toUpperCase(),
                );
              }
            },
          },
        },
        enabled: {
          type: 'checkbox',
          class: 'col-6 d-flex align-items-center',
          props: {
            label: 'Actif',
          },
        },
        prorated: {
          type: 'checkbox',
          class: 'col-12 d-flex align-items-center',
          props: {
            label: 'Pro-rata',
          },
        },
        startsAt: {
          type: 'datepicker',
          class: 'col-6',
          props: {
            type: 'date',
            label: 'Date de Début',
          },
          expressions: {
            'props.placeholder': (): string => {
              if (this.model.offer?.startsAt) {
                const date = new Date(this.model.offer.startsAt);
                return MomentDatePipe.prototype.transform(date, 'DD/MM/YYYY');
              }
              return '';
            },
          },
        },
        endsAt: {
          type: 'datepicker',
          class: 'col-6 mb-4',
          props: {
            type: 'date',
            label: 'Date de Fin',
          },
          expressions: {
            'props.placeholder': (): string => {
              if (this.model.offer?.endsAt) {
                const date = new Date(this.model.offer.endsAt);
                return MomentDatePipe.prototype.transform(date, 'DD/MM/YYYY');
              }
              return '';
            },
          },
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    throw new Error('Method not implemented.');
  }
}
