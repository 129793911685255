import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DynamicModelFormRender } from '../models/dynamic-model-form-render';
import { DynamicTableRender } from '../models/dynamic-table-render';
import { BaseTemplateComponent } from './base.template';
import { UserResponse } from 'src/api/models/user-response';
import { GravatarService } from 'src/app/core/services/gravatar.service';

// @Injectable()
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  // selector: 'app-view-model-template',
  templateUrl: './user.template.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UserTemplate extends BaseTemplateComponent<UserResponse> {
  override icon = 'user';
  override nameProperty = 'email';

  override defaultParamsBuilder = {
    fields: ['firstName', 'lastName', 'companyId', 'email'],
  };

  override defaultViewParams = {
    fetch: 'company',
  };

  // constructor() {
  //   super();
  // }

  override render = (data: any) => data.firstName + ' ' + data.lastName;

  public override getForm(): DynamicModelFormRender {
    // const input = this.form.controls;
    const self = this;

    return {
      model: 'User',
      action: this.action,
      fields: {
        User: {
          type: 'tab-form',
          tabs: {
            Infos: {
              props: {
                title: 'Informations',
              },
              fieldGroup: {
                id: {
                  key: 'id',
                  type: 'input',
                  noExport: true,
                  props: {
                    type: 'hidden',
                  },
                },
                companyId: {
                  label: 'MODEL.Company',
                  translate: true,
                  type: 'select',
                  class: 'col-lg-6 col-sm-12',
                  required: true,
                  row: true,
                  props: {
                    resource: {
                      model: 'company',
                    },
                    allowClear: true,
                    // multiple : true,
                    actions: [
                      {
                        class: 'btn btn-primary',
                        type: 'button',
                        title: "Modifier l'entreprise",
                        icon: 'pencil',
                        onClick(event: Event, action) {
                          if (action.data.value) {
                            self.createModalService.newModalForm({
                              action: 'patch',
                              model: 'company',
                              id: action.data.value,
                            });
                          }
                        },
                        hidden: (data) => !data.data.value,
                        role: 'manager',
                      },
                      {
                        type: 'button',
                        class: 'btn btn-success',
                        onClick(event: Event, data: any) {
                          self.createModalService.newModalForm({
                            action: 'create',
                            model: 'company',
                          });
                        },
                        title: 'Nouvelle entreprise',
                        icon: 'plus',
                      },
                    ],
                  },
                },
                firstName: {
                  type: 'input',
                  label: 'FIELD.firstName',
                  translate: true,
                  class: 'col-lg-3 col-sm-6',
                  name: 'firstName',
                  required: true,
                },
                lastName: {
                  type: 'input',
                  label: 'FIELD.lastName',
                  translate: true,
                  class: 'col-lg-3 col-sm-6',
                  required: true,
                },
                email: {
                  type: 'input',
                  class: 'col-sm-6',
                  //label: 'Email',
                  label: 'FIELD.EMAIL',
                  translate: true,
                  row: true,
                  required: true,
                  templateOptions: {
                    type: 'email',
                  },
                  validators: {
                    validation: ['email'],
                  },
                },
                password: {
                  type: 'input',
                  class: 'col-sm-3',
                  label: 'Password',
                  templateOptions: {
                    type: 'password',
                  },
                  // row : true,
                  //required : true
                },
                // confirm_password: {
                //   type: 'password',
                //   class: 'col-sm-3',
                //   label: 'Confirm password',
                // },
                enabled: {
                  type: 'checkbox',
                  class:
                    'col-3 col-sm-3 offset-sm-1 col-md-4 offset-md-2 d-flex align-items-end mb-2',
                  props: {
                    label: 'Actif',
                  },
                },
                contact_title: {
                  type: 'title',
                  template:
                    '<h4 class="col-6 my-2"><i class="fa-solid fa-address-book me-1"></i>Coordonnées</h4><hr>',
                  ignore: true,
                },
                // 'addresses': {
                // noImport: true,
                // customImport : {
                // action : 'showUserAddresses',
                // model : 'User',
                // params : (data) => ({ id : data.id }),
                // data : (data) => data.data,
                // },
                // customExport : {
                // action : 'createUserAddresses',
                // model : 'User',
                // params : (data) => ({ id : data.id, body: data.addresses })
                // },
                // type: 'repeat',
                // fieldArray: {
                // modelTemplate: {
                // action: 'update',
                // name: 'Address',
                // }
                // }
                // },
                // phone: {
                //   type: 'input',
                //   row: true,
                //   label: 'Téléphone fixe',
                //   class: 'col-md-6',
                //   icon: 'phone',
                //   extraKey: true,
                //   noImport: true,
                // },
                // mobile: {
                //   type: 'input',
                //   label: 'Téléphone mobile',
                //   class: 'col-md-6',
                //   icon: 'mobile',
                //   extraKey: true,
                //   noImport: true,
                // },
                order_notify: {
                  type: 'checkbox',
                  hideExpression: (model, formState, field) => {
                    return !(
                      (this.action === 'update' && model.company_id === 28) ||
                      (model.company && model.company.parent_id === 28)
                    );
                  },
                  label: 'Récapitulatif des commandes (FlexMorning)',
                  class: 'col-md-4',
                  extraKey: true,
                  noImport: true,
                },
              },
            },
            Accès: {
              active: () => this.action === 'update',
              role: 'admin',
              props: {
                title: 'Accès <i class="fa-solid fa-shield-halved"></i>',
              },
              fieldGroup: {
                test1: {
                  ignore: true,
                  type: 'access-panel',
                },
              },
            },
          },
        },
      },
    };
  }

  public override getTable(): DynamicTableRender {
    const gravatarService = new GravatarService();
    // const input = this.form.controls;
    // const requestId = input.id;
    // const requestModel = this.action;
    // const companyId = input.companyId;

    // const hideCompanyId = requestModel === 'company' && !(companyId) && companyId == requestId;
    return {
      buttons: this.getButtons('User'),
      // actionsCol: this.getActions('User'),
      //RENDER ROW
      // renderRow(row,data,index) {
      //   if(index%2)
      //     row.classList.add('bg-danger');
      //   return row;
      // },
      join: ['company'],
      // groups: {
      //   dataSrc: ['firstName', 'lastName'],
      //   render: {
      //     'firstName': {
      //       data: (data: any) => {
      //         return 'nom' + data;
      //       },
      //       actions: [{
      //         type: 'dropdown',
      //         message : 'Test toggle',
      //         class: 'float-end',
      //         actions: [{
      //           message: 'test1',
      //           type: 'button',
      //           class: 'btn btn-success',
      //           onClick: () => { console.log('test1') },
      //         }, {
      //           message: 'test2',
      //           type: 'button',
      //           class: 'btn btn-success',
      //           onClick: () => { console.log('test2') },
      //         }]
      //       }]
      //     },
      //     'lastName': {
      //       data: (data: any) => {
      //         return data;
      //       }
      //     }
      //   }
      //   //['dataSrc' => ['client_name', 'name'], 'render':['client_name' : '<div></div>', etc]]
      //   //render : ''
      // },
      dtFilters: {
        _all: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-6',
          title: 'Search All',
          filter: true,
        },
        firstName: {
          type: 'search',
          icon: 'search',
          class: 'col-sm-6',
          title: 'Search First Name',
        },
      },
      columns: {
        id: {
          type: 'input',
          title: 'ID contact',
          className: 'no-export',
          visible: false,
        },
        // 'fullName': {
        //   fields: ['firstName', 'lastName'],
        //   //data: (data: any) => data.firstName + ' ' + data.lastName,
        //   title: 'Nom',
        //   // filterColumn: (query: any, term: any) => {
        //   //   // , users.email
        //   //   return 'CONCAT_WS(\' \', users.firstname, users.lastname) LIKE %$term%';
        //   // },
        //   //orderColumn: 'users.lastname $1, users.firstname $1', // , users.email $1',
        //   /* 'render' : '<img ng-src="{{ item.picture }}" class="img-responsive img-circle img-small img-border mrm" style="margin-top: -4px;"/>' */
        //   /*     . ' <span ng-bind-html="item | userFormat"></span>', */

        //   render: (data: any, type: any, full_data: any) => {
        //     return '<div class="no-wrap">'
        //       + '<img src="' + gravatarService.getPictureURL(data.email) + '" class="img-responsive img-circle img-small img-border mrm" style="margin-top: -4px; margin-right: 10px; width: 25px;"/>'
        //       + '<span>' + data.firstName + ' ' + data.lastName + '</span>'
        //       //+ '<button (click)="edit(\''+data+'\')"></button>'
        //       + '</div>';
        //   },
        //   // 'class': 'all',
        // },
        fullName: {
          fields: ['firstName', 'lastName'],

          // FIXME: not needed but warning without
          data: (data: any) => this.render(data),

          actions: [
            {
              type: 'input-editor',
              modelName: 'User',
              modelId: (data) => data.id,
              inputs: {
                firstName: {
                  type: 'input',
                },
                lastName: {
                  type: 'input',
                },
              },
              onReally: (event, action) => {
                this.getDynamicTableComponent().ajaxRefresh();
              },
              render: (data: any) => {
                return (
                  '<div class="no-wrap">' +
                  '<img src="' +
                  gravatarService.getPictureURL(data.email) +
                  '" class="img-responsive img-circle img-small img-border mrm" style="margin-top: -4px; margin-right: 10px; width: 25px;"/>' +
                  '<span>' +
                  data.firstName +
                  ' ' +
                  data.lastName +
                  '</span>' +
                  //+ '<button (click)="edit(\''+data+'\')"></button>'
                  '</div>'
                );
              },
            },
          ],
          title: 'Nom',
          width: '200px',
        },
        email: {
          title: 'Adresse électronique',
          render: (data: any) => {
            return (
              '<a href="mailto:' + data + '" target="_blank">' + data + '</a>'
            );
          },
          //'render': '{{ item.email | linky }}',
          /* 'visible' : false, */
          /* 'class' : 'none', */
          /* 'responsivePriority' : 8, */
          searchable: true,
        },
        // 'phone': {
        //   'title': 'Téléphone',
        //   'render': '{{ item.phone | phony }}',
        //   /* 'visible' : false, */
        //   /* 'class' : 'all', */
        //   /* 'responsivePriority' : 9, */
        // },
        // 'mobile': {
        //   'title': 'Mobile',
        //   'render': '{{ item.mobile | phony }}',
        //   /* 'visible' : false, */
        //   'class': 'all',
        //   // FIXME /* 'responsivePriority' : 9, */
        // },
        // 'full_address': {
        //   title: 'Adresse',
        //   data: (item: any) => {
        //     return [item.number,
        //     item.address,
        //     item.zipcode,
        //     item.city,
        //     item.province,
        //     item.display_country ?? item.country].join(' ');
        //   },
        //   // 'filterColumn': (query: any, term: any) => {
        //   //   return 'CONCAT_WS(\' \', users.number, users.address, users.zipcode, users.city, users.province, users.country) LIKE "%$term%"';
        //   // },
        //   //'orderColumn': 'users.number $1, users.address $1, users.zipcode $1, users.city $1, users.province $1, users.country $1',
        //   /* 'visible' : false, */
        //   'class': 'none',
        //   /* 'responsivePriority' : 7, */
        // },
        // /*'role' : [
        //     'title' : 'Role',
        //     'class' : 'none',
        //     'searchable' : false,
        // ],*/
        // 'company_name': {
        //   title: 'Entreprise',
        //   data: (data:any) => {
        //     return data.company ? data.company.name : null;
        //   },
        //   // 'orderColumn' : 'companies.name $1',
        //   render: '<a ui-sref="model.item.view({model: \'company\', id: item.company_id, tab: null})" ng-bind-html="item.company_name"></a>',
        //   orderable: false,
        //   searchable: false,
        //   hidden: hideCompanyId,
        //   /* 'class' : 'all', */
        //   /* 'role' : 'manager', */
        //   /* 'responsivePriority' : 6, */
        // },
        companyId: {
          type: 'input',
          title: 'ID entreprise',
          className: 'no-export',
          visible: false,
          /* 'filterColumn' : function ($query, $term) { */
          /*     return $query->whereRaw('users.company_id = ?', ["$term"]); */
          /* }, */
          /* 'role' : 'manager', */
        },
        companyName: {
          fields: ['company.name as companyName'],
          type: 'input',
          title: 'Nom entreprise',
          /* 'filterColumn' : function ($query, $term) { */
          /*     return $query->whereRaw('users.company_id = ?', ["$term"]); */
          /* }, */
          /* 'role' : 'manager', */
        },
        //   'prospect': {
        //     title: 'Prospect',
        //     type: 'boolean',
        //     addColumn: (item:any) => {
        //       return item.prospect;
        //     },
        //     'filterColumn': (query:any, term:any) => {

        //       // let column = collect(request() -> input('columns', [])) -> filter(function ($col) {
        //       //   return $col['name'] === 'prospect';
        //       // }) -> first();
        //       // if ($column && $column['search']['value'] !== $term) {
        //       //   return $query;
        //       // }

        //       // if (is_string($term) && !ctype_digit($term)) {
        //       //   return $query;
        //       // }

        //       // $term = (bool)$term ? 1 : 0;
        //       // /* return $query->where(function ($query) use ($term) { */
        //       // /*     if ($term) { */
        //       // /*         $query->where('companies.is_lead', $term); */
        //       // /*     } else { */
        //       // /*         $query->whereNull('companies.is_lead') */
        //       // /*             ->orWhere('companies.is_lead', $term); */
        //       // /*     } */
        //       // /* }); */
        //       // return $query -> whereHas('company', function ($q) use($term) {
        //       //   if($term) {
        //       //     $q -> where('is_lead', $term);
        //       //   } else {
        //       //     $q-> where('is_lead', $term)
        //       //     -> orWhereNull('is_lead');
        //       // }
        //       //               });

        //   },
        //   /* 'orderColumn' : 'companies.is_lead $1', */
        //   'class': 'no-export',
        //   'hidden': true,
        // },
        // 'ticket_category_id' : [
        //     'type' : 'integer',
        //     'title' : 'Catégories',
        //     'addColumn' : function ($item) {
        //         return '';
        //     },
        //     'filterColumn' : function ($query, $term) {
        //         // TEST ME:
        //         return $query->ofCategory($term);
        //         /* return $query->whereRaw('agents.category_id = ?', ["$term"]); */
        //     },
        //     /* 'orderColumn' : 'users.lastname $1, users.firstname $1', */
        //     'class' : 'no-export',
        //     'hidden' : true,
        // ],
        /* 'fulladdress' : [ */
        /*     'title' : 'Email', */
        /*     'visible' : false, */
        /*     'addColumn' : function ($item) { */
        /*         return implode(' ', array_filter([ */
        /*             $item->address, */
        /*             $item->zipcode, */
        /*             $item->city, */
        /*             $item->province, */
        /*             $item->country, */
        /*         ])); */
        /*     }, */
        /*     'orderColumn' : 'users.address $1, users.zipcode $1, users.city $1, users.province $1, users.country $1', */
        /* ], */
        //   'last_active': {
        //   'title' : 'Dernière activité',
        //     'type' : 'time_ago',
        //       /* 'class' : 'none', */
        //       'visible' : false,
        //         'searchable' : false,
        //   },
        // 'last_login': {
        //   'title' : 'Dernière connexion',
        //     'type' : 'time_ago',
        //       /* 'class' : 'none', */
        //       'visible' : false,
        //         'searchable' : false,
        //   },
        enabled: {
          title: 'Activé',
          type: 'boolean',
          width: '1%',
          searchable: false,
        },
        updatedAt: true,
        createdAt: true,
      },
    };
  }
}
